import React from 'react';
import styled from 'styled-components';

import SignUpForm from 'SharedComponents/sign-up-form';

import styles from './index.css';

const SignUpHeroWrapper = styled.div`${styles}`;

const SignUpHero = () => (
  <SignUpHeroWrapper>
    <div className="sign-up-form-container">
      <SignUpForm showEmailLogin showSocialLogin />
    </div>
  </SignUpHeroWrapper>
);

export default SignUpHero;
