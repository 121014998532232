import React from 'react';
import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';
import MuiDialogContent from '@mui/material/DialogContent';

import IconButton from 'SharedComponents/icon-button';
import SignInForm from 'SharedComponents/sign-in-form';

import styles from './index.css';

const TransactionSignInDialogWrapper = styled(Dialog)`
  ${styles}
`;

const TransactionSignInDialog = ({
  open,
  proceedToTransaction,
  fullScreen,
  onCloseSignInDialog,
  onAfterSignIn,
}) => {
  const windowWidth = window.innerWidth;
  return (
    <TransactionSignInDialogWrapper open={open} fullScreen={windowWidth < 768}>
      <div className="content-container">
        <div
          style={{
					  display: 'flex',
					  width: '100%',
					  alignItems: 'center',
					  justifyContent: 'flex-end',
          }}
        >
          <IconButton onClick={onCloseSignInDialog}>
            <i className="material-icons">close</i>
          </IconButton>
        </div>
        <h1>Sign In</h1>
        <p>To continue shopping</p>
        <SignInForm
          onAfterSignIn={onAfterSignIn}
          showSocialLogin
          onCloseDialog={onCloseSignInDialog}
          delayOnAfterSignIn
          showEmailLogin
        />
      </div>
    </TransactionSignInDialogWrapper>
  );
};

export default TransactionSignInDialog;
