export default ({ theme }) => {
  return `
    width: 90%;
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 10px;

    .bar-chart-holder svg {
      height: 120% !important;
      max-height: 160px !important;
    }

    .totals-label-container{
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bolder;
      font-size: 16px;
      margin-bottom: 10px;
    
    }




  `
};