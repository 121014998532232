import React from 'react';
import styled from 'styled-components';

import Footer from 'SharedComponents/footer';

import styles from './index.css';

const PrivacyPolicyWrapper = styled.section`${styles}`;


const PrivacyPolicy = () => (
  <PrivacyPolicyWrapper>
    <div className="content">
      <div className="content-container" style={{ minHeight: 'calc(100vh - 400px)' }}>
        <h1>Privacy Policy</h1>
        <p>This following document sets forth the Privacy Policy for the Boost Your Super website, <a href="boostyoursuper.com.au">https://boostyoursuper.com.au</a> referred to in this document as the Site. Boost Your Super is committed to providing you with the best possible customer service experience. Boost Your Super is bound by the Privacy Act 1988 (Cth), which sets out a number of principles concerning the privacy of individuals.</p>
        <br />
        <h2>Collection of your personal information</h2>
        <p><b>Non personally identifiable information.&nbsp;</b>For each visitor to reach the site, we expressively collect the following non-personally identifiable information through Google Analytics including but not limited to:</p>
        <ul>
          <li>browser type</li>
          <li>version and language</li>
          <li>operating system and technology used</li>
          <li>Browsing data including pages viewed and links clicked</li>
          <li>page access times</li>
          <li>referring website address</li>
        </ul>
        <p><b>Personally identifiable information.</b> There are many aspects of the site which can be viewed without providing personal information. However, to create an account and login to Boost Your Super you are required to submit personally identifiable information. This may include but is not limited to:</p>
        <ul>
          <li>A unique email, phone number, username and/or password</li>
          <li>Once you have created an account and/or logged in we collect information about your browsing habits on the Site including what pages you view and what shops, buttons and links you click on.</li>
          <li>We send non-personally identifiable information to Google Analytics including a unique id that is attributed to every user when they join and create an account</li>
        </ul>

        <h2>Use of your personal information</h2>
        <p>Non-personally identifiable information that you provide is used solely internally for the purpose of gauging visitor traffic and trends so that we may improve the website and provide information consistent with what the users are clicking on. Personally identifiable information collected including browsing habits on our site is used to provide you the services that we offer We use your personal information to:</p>
        <ul>
          <li>Create and manage user accounts</li>
          <li>Assist in confirming your identity</li>
          <li>To attribute, manage and track clicks that you make to stores through the Site</li>
          <li>Provide an experience on the Site that reflects your account set-up preferences and personalised content</li>
          <li>Maintain a record of changes made to your account (eg payment preferences)</li>
          <li>Make payments to your nominated superannuation fund</li>
          <li>Invite you to participate in surveys</li>
          <li>Notify you of activities that pertain to your account (eg payments you receive from us and purchases you make after clicking through to our list of stores)</li>
          <li>Provide customer support in response to your requests and inquiries</li>
          <li>Analyse user activity in order to improve the Site. For example, we use Google Analytics to identify trends of stores being clicked as well as check site speed on different areas of the site. We also use Google Analytics to monitor behaviour of users across different platforms and browsers (eg - is there a difference in how a particular user uses the Site on mobile compared to desktop)</li>
          <li>Provide marketing emails to you on promotions, stores and offerings that we think you may find valuable (You may opt out of marketing emails by using unsubscribe link found in each marketing email we send out)</li>
          <li>Aggregating your non-personally identifiable behaviour with all other users so we can keep abreast of general trends such as browsers and devices (eg mobile, desktop) being used as well as which are the most popular stores being clicked to from the Site. In doing this none of your personally identifiable data is used.</li>
        </ul>

        <h2>Disclosure of your personal information</h2>
        <p>We may occasionally hire other companies to provide services on our behalf, including but not limited to handling customer support enquiries and processing transactions. Those companies will be permitted to obtain only the personal information they need to deliver the service. Boost Your Super takes reasonable steps to ensure that these organisations are bound by confidentiality and privacy obligations in relation to the protection of your personal information. We will also disclose any information you provide in accordance with the laws of Australia as required by law.</p>

        <p><b>Ebay Parther Network Access to Affiliate End User Data.</b> Ebay Partner Network may request access to End User Data from Boost Your Super. We will provide them with the information they will use to verify Boost Your Super’s compliance with eBay Policies. This is to satisfy the agreement that we have in place with eBay.</p>
        <p><b>Affiliate Networks and Stores.</b> In circumstances where there may be a tracking issue we may at times be required to disclose your personal details to Affiliate Networks (who track purchases from Boost Your Super) and their stores to resolve the tracking issues and ensure that you receive a payment for any purchase you are entitled to receive a payment for. Where non-personal identifiable information will suffice to resolve any tracking issues then no personal identifiable information will be provided to other parties.</p>

        <h2>Storage and security of personal information</h2>
        <p>Boost Your Super keeps your personal information securely by use of security features on our website such as access controls, encryption and website firewalls.</p>

        <h2>Website cookies, similar technologies and third party sites</h2>
        <p>Boost Your Super uses cookies and other tracking technology (eg tracking pixels) provided by third parties to provide our service to users. Here are the services that are provided.</p>
        <p><b>Tracking Purchases.</b> The core service that we provide is dependant on tracking technology to track clicks from the Site to participating stores. We are dependant on a number of affiliate companies to provide these services. This includes but is not limited to, Rakuten Affiliate Tracking, Commission Factory Affiliate Tracking and eBay Partner Network’s Tracking Technology. Without these technologies enabled on your browsing device - our service will not work and you are unlikely to qualify for a commission to your superannuation fund for any purchases you make.</p>
        <p><b>Tracking Analytics.</b> We use Google Analytics to collect and monitor user activity on the Site. For example, we monitor which stores are being clicked on the most and what technology (eg mobile or desktop) is being used to access the Site. We do not send any personal identifiable information to Google Analytics. We do send a unique user id associated with each user so that we may analyse users behaviour across different devices. You may opt-out of Google Analytics tracking <a href="https://support.google.com/analytics/answer/181881?hl=en">here</a>.</p>
        <p><b>Remarketing.</b> We use a number of services to provide remarketing. This means that if you view our site you may receive ads from us on third party sites such as Facebook after viewing our site. The remarketing services rely on third parties dropping a cookie on your device when a user is browsing our Site. These sites may include, Facebook, Google Display Network, LinkedIn, Adroll and Perfect Audience. You may opt-out or change your advertising preferences <a href="">here:</a></p>
        <ul>
          <li>Google Display Network ads at <a href="http://www.google.com/ads/preferences/">http://www.google.com/ads/preferences/</a></li>
          <li>Perfect Audience Ads: <a href="http://www.perfectaudience.com/privacy/">http://www.perfectaudience.com/privacy/</a></li>
          <li>Facebook Ad Preferences: <a href="https://www.facebook.com/help/568137493302217">https://www.facebook.com/help/568137493302217</a></li>
          <li>LinkedIn Advertising Preferences here: <a href="https://www.linkedin.com/help/linkedin/answer/62931/manage-advertising-preferences?lang=en">https://www.linkedin.com/help/linkedin/answer/62931/manage-advertising-preferences?lang=en</a></li>
        </ul>

        <h2>Changes to this Privacy Policy</h2>
        <p>Boost Your Super reserves the right to make amendments to this Privacy Policy at any time. If you have objections to the Privacy Policy, you should not access or use the Site.</p>

        <h2>Accessing Your Personal Information</h2>
        <p>You have a right to access your personal information, subject to exceptions allowed by law. If you would like to do so, please let us know. You may be required to put your request in writing for security reasons. Boost Your Super reserves the right to charge a fee for searching for, and providing access to, your information on a per request basis.</p>

        <h2>Deleting Your Personal Information</h2>
        <p>You may request your personal information is deleted from our databases. To do so please email with any such request and we will advise you when it is completed.</p>

        <h2>Contacting us</h2>
        <p>Boost Your Super welcomes your comments regarding this Privacy Policy. If you have any questions about this Privacy Policy and would like further information, please contact us by any of the following means during business hours Monday to Friday.</p>

        <p><b>Email:&nbsp;</b><a href="mailto:admin@boostyoursuper.com.au">admin@boostyoursuper.com.au</a></p>
      </div>
    </div>
    <Footer />
  </PrivacyPolicyWrapper>
);

export default PrivacyPolicy;
