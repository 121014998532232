export default ({ theme }) => {
  return `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${theme.primaryColor};
    /* padding: 20px; */
    margin: 10px;
    /* box-shadow: 0 0 10px 0 rgba(0,0,0,0.1); */
    background-color: ${theme.neutralColor};
    box-shadow: rgb(199 199 199) 4px 1px 12px;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;

    &:hover {
      transition: all .3s ease-in-out;
      box-shadow: 0 0 45px -3px hsla(0,0%,55%,.75);
      transform: scale(1.01);
    }


    .member-card-container {
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 350px;

      .value-prop {
        position: absolute;
        top: 290px;
        left: 0px;
        background: ${theme.alternateAccentColor};
        padding: 5px;
        font-weight: bolder;
        font-size: 18px;
        text-transform: uppercase;
        color: #000;
      }

      .plan-name {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 30px 0;
        background: ${theme.darkSecondaryColor} ;
        color: ${theme.secondaryTextColor};
        font-size: 24px;
        border-radius: 5px 5px 0 0;
      }

      .plan-cost-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 10px 0;
        background: ${theme.secondaryColor};
        color: ${theme.alternateAccentColor};
        font-size: 24px;

        .plan-cost {
          font-size: 24px;
          font-weight: bold;
        }

        .plan-cost-period {
          font-size: 18px;
          margin-left: 10px;
        }
      }

      .how-it-works {
        padding: 20px 20px 10px 20px;
        font-size: 14px;
      }

      .title-and-nav-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0 10px 10px 10px;

        .title {
          font-size: 20px;
          font-weight: bold;
          /* color: ${theme.secondaryTextColor}; */
          margin: 0;
          display: flex;
          align-content: flex-start;
          flex-wrap: wrap;
        }
        .nav-buttons {
          margin: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .material-icons {
            font-size: 16px;
            cursor: pointer;
          }
        }

      }

      .image-container {
        height: 240px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        overflow: hidden;
      }

      .card-details{
        display: flex;
        flex-direction: column;
        width: 100%;


        .card-info-container {
          display: flex;
          flex-direction: column;
          padding:62px 24px 12px 24px;
          border-top: solid 1px #d9d9d9;
          height: 300px;;

          .key-points {
            display: flex;
            align-items: center;
            padding: 5px;
            align-content: center;      
          }

          .key-point {
            font-size: 16px;
            display: flex;
            align-content: center;
            margin: 0;
          }

          .material-icons.styling{
            color:${theme.accentColor};
            font-size: 24px;
            padding: 0px 5px;
          }
          
          .explanation {
            font-size: 14px;
            /* color: ${theme.secondaryTextColor}; */
            margin: 0;
          }
        }
      }

    }

    

    `;
  }
  