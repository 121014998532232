import * as ItemActionTypes from './constants';

const initialState = [];

const actionsMap = {
  [ItemActionTypes.SET_FAQS](state, { payload }) {
    return [...payload];
  },
  [ItemActionTypes.REMOVE_FAQS]() {
    return [];
  },
};

export default function items(state = initialState, action) {
  const reduceFn = actionsMap[action.type];
  if (!reduceFn) return state;
  return reduceFn(state, action);
}
