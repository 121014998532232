export default ({ theme, majorStat }) => `
  width: 100%;
  /* overflow: auto; */
  display: flex;
  flex-direction: column;
  align-items: flex-end;



    .stat-card-container{
      display: flex;
      flex-direction: column;
      max-width:440px;
      width: 100%;

      .stat-card {
        width: calc(100%-10px); 
        /* min-width: 200px; */
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        padding: 5px;
        /* box-shadow: 0 0 3px 1px ${theme.borderColor}; */
        margin: 5px;
        border-radius: 3px;
        background-color: ${ !majorStat? null : 'white' };

      /*       &:last-child {
          margin-right: 20px;
        } */

        .label {
          text-align: end;
          text-transform: ${ !majorStat? 'capitalize' : 'uppercase' };
          font-weight: bold;
          font-size: ${ !majorStat? 'larger' : 'x-large' };
          @media(max-width: 468px) {
            font-size: medium;
          }
        }

        .value {
          font-size: 25px;
          text-align: end;
          font-weight: bold;
          width: 125px;
          @media(max-width: 468px) {
           width: 110px; !important;
           font-size: 20px;
          }
        }

        .pop-over-container{
          display: flex;
          flex-direction:row;
          justify-content: center
          margin: 0 10px 0 10px;
          height: 100%;
          align-items: center;

          p{
            margin: 0px;
          }
        }

      }
    }
  

`;
