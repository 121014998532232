import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import log from "Utils/logger";

import CONFIG from "Config";

import useFetchStoreBoostProfiles from "SharedHooks/fetch-store-boost-profiles";
import useFetchShopperBoostProfile from 'SharedHooks/fetch-shopper-boost-profile';
import useFetchFavoriteShops from 'SharedHooks/fetch-favorite-shops';

import * as ShoppingActions from "Flux/shopping/actions";

const useFetchStores = () => {
  console.log ("*** ** fetchstores: useFetchStores hook triggered")
  const isFetchingStoresRef = useRef(false);
  const hasFetchedStoresRef = useRef(false);

  const dispatch = useDispatch();
  const storedStores = useSelector(state => state.shopping.stores);
  const user = useSelector(state => state.authentication.user);

  const {  fetchStoreBoostProfiles } = useFetchStoreBoostProfiles(); 
  const {  fetchShopperBoostProfile } = useFetchShopperBoostProfile();
  const {  fetchFavoriteShops } = useFetchFavoriteShops();

  const fetchStores = async () => {
    isFetchingStoresRef.current = true;
    try {
      const result = await dispatch(ShoppingActions.fetchStores());

      console.log('*** ** fetchStores: storedStores: ', storedStores)
      console.log ('*** ** fetchStores: result: ', result);
      
      const newStores = result.data.retail_stores.map(store => {
        let rate, commission;

        if (store.commission_rate) {
          if (!store.commission_rate.length) {
            rate = "";
          } else {
            if (store.commission_description.indexOf("$") > -1) {
              commission =
                "$" +
                (parseFloat(store.commission_rate) / 2).toFixed(2);
                 /* + store.commission_description.slice(1); */
            } else {
              commission =
                (parseFloat(store.commission_rate) / 2).toFixed(1)
                + "%";
                 /* + store.commission_description; */
            }
            rate = commission;
          }
        } else {
          rate = "";
        }
        store.rate = rate;
        if (store.retail_store_banner_url == null) {
          store.retail_store_banner_url = `${CONFIG.assetsUrl}/assets/merchants_logos/Drawer-600x150-Default.png`;
        }
        store.retail_store_logo_url = store.custom_logo_directory ? (store.custom_logo_directory.length && store.custom_logo_directory !== 'null' ? CONFIG.assetsUrl + '/assets/' + store.custom_logo_directory : store.avatar_url) : store.avatar_url;
        store.image_alt_text = store.merchant_name;
        store.image_link_url = `${CONFIG.assetsUrl}/shop/${store.retail_store_web_name}`;
        store.more_info_link_url = `${CONFIG.assetsUrl}/shop/${store.retail_store_web_name}`;
        store.deals_link_url = `${CONFIG.assetsUrl}/shop/${store.retail_store_web_name}`;
        store.shop_now_link_url = `${CONFIG.assetsUrl}/transaction/${store.retail_store_web_name}/${store.retail_store_id}`;
        store.deals_count = store.coupons_count;
        if (store.tags) {
          store.tags = JSON.parse(store.tags);
        } else {
          store.tags = [];
        }
              //        store.favorite = null;
        return store;
      });

      console.log('*** ** fetchStores: newStores.keys.length:',Object.keys( newStores).length)
      console.log('*** ** fetchStores: storedStores.keys.length:', Object.keys(storedStores).length)

      //console.log('*** ** fetchStores: newStores.length:',( newStores).length)
      //console.log('*** ** fetchStores: storedStores.length:', (storedStores).length)

      console.log('*** ** fetchStores: newStores: ', newStores);
      console.log('*** ** fetchStores: storedStores: ', storedStores);

      //*****MAYBE NOT NEEDED*****
      if (shouldUpdateStore(newStores, storedStores)) {
        console.log('*** ** fetchStores: newStores and storedStores are different');
        console.log('*** ** fetchStores: newStores.length:',( newStores).length)
        console.log('*** ** fetchStores: storedStores.length:', (storedStores).length)
          console.log('*** ** fetchStores: DISPATCH SET stores with newStores: ', newStores);
          dispatch(ShoppingActions.addStores(newStores));
        
      }
    } catch (exception) {
      console.log(exception);
      log({
        event: "System-Error",
        source: "Fetch Stores",
        info: exception,
        ...exception,
      });
    } finally {

      isFetchingStoresRef.current = false;
      hasFetchedStoresRef.current = true;
    }
  };

  function shouldUpdateStore(newStores, storedStores) {

  if ((newStores).length !== (storedStores).length) {
    return true; // Differences found
  }
    return false; // No differences found
  }

  useEffect(() => {
    console.log('*** ** fetchStores: useEffect - triggered on load to initialise fetchStores');
    if (!isFetchingStoresRef.current && !hasFetchedStoresRef.current) {
      fetchStores();
    }
  }, []);

  useEffect(() => {
    if ( user
      && storedStores.length > 0
      && !isFetchingStoresRef.current
      && hasFetchedStoresRef.current
    ) {
        console.log('*** ** fetchStores: useEffect - user available: hasFetchedStoresRef.current - lets fetchFavoriteShops + fetchShopperBoostProfile')
        console.log ('*** ** fetchStores: useEffect - hasFetchedStoresRef.current - lets fetchFavoriteShops');
        fetchFavoriteShops();
        console.log ('*** ** fetchStores: useEffect - hasFetchedStoresRef.current - lets fetchShopperBoostProfile');
        fetchShopperBoostProfile();
    } 
    
    if (  storedStores.length > 0
        && !isFetchingStoresRef.current
        && hasFetchedStoresRef.current
      ){
       console.log ('*** ** fetchStores: useEffect - hasFetchedStoresRef.current - lets fetchStoreBoostProfiles');
       fetchStoreBoostProfiles();
    }
    
  }, [hasFetchedStoresRef.current]);

  //return { isFetchingStoresRef.current, hasFetchedStores, fetchStores };
  return { fetchStores };
};

export default useFetchStores;
