export default ({ theme }) => `
  .form-container {
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  
  .forgot-password-link {
    text-decoration: none;
    color: ${theme.darkSecondaryColor};
    font-size: 20px;
    margin: 10px;

    &:hover {
      text-decoration: underline;
    }
  }

  .social-login {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: solid 1px #d9d9d9;
    border-bottom: solid 1px #d9d9d9;
    padding: 20px;
    margin: 10px 0;
    flex-wrap: wrap;

    button {
      box-shadow: 0 0 0 2px #d9d9d9 !important;
      border-radius: 3px !important;

      &:hover {
        box-shadow: 0 0 0 2px #d9d9d9, 3px 3px 6px #d9d9d9 !important;
      }
    }
  }

  .signup-link {
    text-decoration: none;
    color: ${theme.darkSecondaryColor};
    margin: 10px;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .form-footer {
    border-top: solid 2px #d9d9d9;
    border-bottom: solid 1px #d9d9d9;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap-reverse;

    @media(max-width: 387px) {
      justify-content: flex-end;
    }
  }
`;
