export default ({ theme }) => {
  return `
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-bottom: solid 1px ${theme.borderColor};
    padding: 10px 0
  `;
}
