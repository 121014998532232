export default ({ theme }) => {
  return `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    .bank-connection-card{
      display: flex;
      flex-direction: column;
      /* max-width: 50%; */
      padding: 20px;
      margin: 20px;
      background-color: ${theme.darkBackgroundColor};

      .logo-and-description-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 5px;
         @media(max-width: 768px) {
          flex-direction: column;
         }

        .logo-container {
          margin: 10px;
          display: flex;
          .logo {
            max-width: 120px;
            object-fit: contain;
          }
        }

        .description-container {
          /* font-size: 20px; */
          text-align: left;
          margin: 10px;
          max-width: unset;
        }
      }


    }


    .text-highlight{
      background: ${theme.alternateAccentColor};
      padding: 0 3px;
  }

    .title {
      font-size: 30px;
      text-align: center;
      margin: 10px;
    }

    .description {
      font-size: 20px;
      text-align: left;
      margin: 10px;
      max-width: unset;
    }
    
    .content-container {
      width: calc(100% - 40px);
      max-width: 1366px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      padding: 20px;

        .bank-accounts-container {
          display: flex;
          width: 100%;
          flex-direction: column;

          .button-container {
            display: flex;
            align-items: flex-start;
            justify-content: left;
            flex-direction: column;
          }
            

          .waiting {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: calc(100% - 40px);
          }

          .title {
            font-size: 30px;
            text-align: center;
            margin: 10px;

            @media(max-width: 768px) {
              font-size: 16px;
            }

          }

          .description {
            text-align: left;
            margin: 10px;
            max-width: unset;

            @media(max-width: 768px) {
              font-size: 14px;
            }

          }

        }

      h3 {
        font-weight: bold;
        text-align: center;
        font-weight: bolder;
        font-size: 17px;
        color: ${theme.accentColor};
        text-transform: uppercase;
      }

      button {
        margin: 10px;
      }

      h2 {
        font-weight: bolder;
        align-items: center;
        text-align: center;
        justify-content: center;
      }

      /* p {
        font-size: 20px;
      } */
      
    }

  `
};