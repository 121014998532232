import React from 'react';
import styled from 'styled-components';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import classNames from 'classnames';

import { isMobile } from 'react-device-detect';

import styles from './index.css';

const ModalDialogWrapper = styled(Dialog)`${styles}`;

const ModalDialog = ({ onClose, open, titleComponent, contentComponent, size }) => {
  return (
    <ModalDialogWrapper
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullScreen={isMobile}
      fullWidth={true}
      maxWidth={size}
    >
      {
        isMobile ? (
          <AppBar className="dialog-app-bar" elevation={1}>
            <Toolbar className="toolbar">
              <Typography variant="h6">
                Boost your Super
              </Typography>
              <Button onClick={onClose}>
                Dismiss
              </Button>
            </Toolbar>
          </AppBar>
        ) : null
      }
      {
        titleComponent ? (
          <DialogTitle id="customized-dialog-title" onClose={onClose}>
            {titleComponent}
          </DialogTitle>
        ) : null
      }
      {
        contentComponent ? (
          <DialogContent className={classNames('dialog-content', { 'full-width': isMobile })}>
            {contentComponent}
          </DialogContent>
        ) : null
      }
      {
        !isMobile ? (
          <DialogActions>
            <Button onClick={onClose}>
              Dismiss
            </Button>
          </DialogActions>
        ) : null
      }
    </ModalDialogWrapper>
  );
}

export default ModalDialog;
