import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PopoverHover from 'SharedComponents/popover-hover';

import style from './index.css';

const StoreBoostsListWrapper = styled.div`${style}`;

//need to check memoization effectiveness on this component.

const StoreBoostsList = ({
  shopperBoostProfile,
  retailStoreId,
  retailStore,
  stores,
}) => {

  //console.log('StoreBoostsList retailStoreId coming through is ', retailStoreId);
  //console.log('StoreBoostsList retailStore coming through is ', retailStore);
  //console.log('StoreBoostsList shopperBoostProfile through is ', shopperBoostProfile);

  function shopperActiveBoostsList(){
    const activeBoostsList = shopperBoostProfile.filter(
      boost =>
      boost.boost_status === 'ACTIVE' 
    )
    return activeBoostsList
  }



  const retailStoreFromState = stores.find(store => store.retail_store_id === retailStoreId);
  
  //console.log('StoreBoostsList retailStoreFromState is ', retailStoreFromState);

  const copyOfShopperActiveBoostsList = JSON.parse(JSON.stringify(shopperActiveBoostsList()));  
  
  const shopperActiveBoosts = copyOfShopperActiveBoostsList.map(({ boost_name, commission_percentage }) => {
    return{
      'boost_name': boost_name,
      'commission_percentage': (commission_percentage)
    }
    
  })
  
  // const retailerActiveBoosts = 
  //   retailStoreFromState.boosts_retailer && retailStoreFromState.boosts_retailer ?
  //     retailStoreFromState.boosts_retailer.filter(boost => boost.boost_status === 'ACTIVE')
  //     .map(({ boost_name, commission_percentage }) => {
  //       return{
  //         'boost_name': boost_name,
  //         'commission_percentage': (commission_percentage)
  //       }
  //     })
  //   : [];

  const retailerActiveBoosts = retailStoreFromState && retailStoreFromState.boosts_retailer 
    && Array.isArray(retailStoreFromState.boosts_retailer)
    ? retailStoreFromState.boosts_retailer
        .filter(boost => boost.boost_status === 'ACTIVE')
        .map(({ boost_name, commission_percentage }) => ({
          'boost_name': boost_name,
          'commission_percentage': commission_percentage,
        }))
    : [];

  const affiliateActiveBoosts = retailStoreFromState && retailStoreFromState.boosts_affiliate
    && Array.isArray(retailStoreFromState.boosts_affiliate) 
    ? retailStoreFromState.boosts_affiliate
      .filter(boost => boost.boost_status === 'ACTIVE')
      .map(({ boost_name, commission_percentage }) => ({
        'boost_name': boost_name,
        'commission_percentage': (commission_percentage)
      })) 
    : [];

  const clientActiveBoosts = retailStoreFromState && retailStoreFromState.boosts_client
    && Array.isArray(retailStoreFromState.boosts_client)
    ? retailStoreFromState.boosts_client
      .filter(boost => boost.boost_status === 'ACTIVE')
      .map(({ boost_name, commission_percentage }) => ({
          'boost_name': boost_name,
          'commission_percentage': (commission_percentage)
      }))
    : [];

  const allActiveBoosts = [
    ...shopperActiveBoosts, 
    ...retailerActiveBoosts,
    ...clientActiveBoosts,
    ...affiliateActiveBoosts, 
  ];

  let estimatedBoosts = [];

  const sumTotalEstimatedBoosts = allActiveBoosts.sort((a,b) => b.commission_percentage - a.commission_percentage).reduce(
    (accumulator, currentValue) => {
      // console.log('**allActiveBoosts is :',allActiveBoosts)
      // console.log('**currentValue  is : ', currentValue)
      // console.log('**accumulator ism :', accumulator)
      if ((accumulator + currentValue.commission_percentage) > 50) {
        //console.log('**currentValue too large to be added ', currentValue);
        return accumulator
      } else {
        estimatedBoosts = [ currentValue, ...estimatedBoosts].sort((a,b) => b.commission_percentage - a.commission_percentage);
        //console.log('**estimatedBoosts is ', estimatedBoosts );
        //console.log('**currentValue being added to displayBoosts ', currentValue );
        return accumulator + currentValue.commission_percentage;
      }
    },0)
  
  //const estimatedStoreWithBoostPercentage = (retailStoreFromState.shopnate_rate*(1 + 2*sumTotalEstimatedBoosts/100)).toFixed(2);

  const estimatedStoreWithBoostPercentage = 
  retailStoreFromState && retailStoreFromState.commission_rate ? 
    (retailStoreFromState.commission_rate * (0.5 + sumTotalEstimatedBoosts / 100)).toFixed(2)
  : retailStore && retailStore.shopnate_rate ?
    (retailStore.shopnate_rate * (1 + 2 * sumTotalEstimatedBoosts / 100)).toFixed(2)
  : null;

  const estimatedBoostsNames = estimatedBoosts.map(({boost_name}) => <li>{boost_name}</li>);

//<pre>{JSON.stringify(sumTotalEstimatedBoosts,null,2)}</pre>

  return (
    <StoreBoostsListWrapper >
      
      <div className="amount-with-boost">

        <div className="rate-header">
          Boost Up To
        </div>
          { !sumTotalEstimatedBoosts ?
            (
              <div className="rate-amount">
                <span className="estimate-rate-amount">
                  {retailStoreFromState && retailStoreFromState.rate}
                </span>
              </div>
            ) : (
            <div className="rate-amount">
              {/* Up To &nbsp; */}
              {retailStoreFromState && retailStoreFromState.commission_type_id != 1 ? "$" : null}
              
              <span className="slash-through"> {retailStoreFromState && (retailStoreFromState.commission_rate/2).toFixed(1)}</span>
              
              {retailStoreFromState && retailStoreFromState.commission_type_id == 1 ? <span>%&nbsp;</span> : <span>&nbsp;</span>}
              <span className="estimate-rate-amount">
                {retailStoreFromState && retailStoreFromState.commission_type_id != 1 ? "$" : null}
                {estimatedStoreWithBoostPercentage}
                {retailStoreFromState && retailStoreFromState.commission_type_id == 1 ? "%" : null }
              </span>
              <div className="pop-over-container">
                <PopoverHover
                  content={ 
                    <div className="content-container" style={{ maxWidth: '340px', }}>
                      Your rate is increased by {Math.round(sumTotalEstimatedBoosts*2)}% due to addition of the following Bonus Boost(s):
                      <h3>{estimatedBoostsNames}</h3>
                      Note, maximum Bonus Boost is 100% and any Bonus Boosts that tip your rate over 100% are excluded.
                    </div>
                  }
                  header = "Rate With Bonus Boosts"
                  img = {null}
                  itemToPopover = {
                    ( <i className="material-icons" style={{ display: 'flex', margin: '0 10px 0 10px', color: '#000' }} >info</i> )
                  }
                  horizontalPosn = "right"
                />
              </div>
            </div>
            )
          }
            { 
                retailStoreFromState && retailStoreFromState.commission_type_id == 1 ? <div className="rate-type">per sale</div>
                : retailStoreFromState && retailStoreFromState.commission_type_id == 2 ? <div className="rate-type">per sale</div>
                : retailStoreFromState && retailStoreFromState.commission_type_id == 3 ? <div className="rate-type">per sign up</div>
                : retailStoreFromState && retailStoreFromState.commission_type_id == 4 ? <div className="rate-type">per lead</div>
                : null
             }
      </div>

    </StoreBoostsListWrapper>
  );
};

StoreBoostsList.propTypes = {
  retailStore: PropTypes.object.isRequired,
};

export default connect((state) => ({ 
  user: state.authentication.user,
  shopperBoostProfile: state.shopping.shopperBoostProfile,
  stores: state.shopping.stores, 
}))(memo(StoreBoostsList));


