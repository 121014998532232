import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import styles from './index.css';

const BlackFridayBannerWrapper = styled.div`${styles}`;

const BlackFridayBanner = () => {
  if (moment() < moment('2021-01-26 23:59:59')) {
    return (
      <BlackFridayBannerWrapper>
        <h1 className="title">Refer a friend by 26 Jan and earn up to EXTRA 40%</h1>
        <p className="description">For each friend you earn extra 10% on your earnings for 1 year. Up to max 4 friends.</p>
        <small className="sub-text">Referred friend also earns extra 10%. Your friend must be a new user to Boost Your Super and sign up with your referral link found in your share button. TnCs apply. See your latest email.</small>
      </BlackFridayBannerWrapper>
    );
  }

  return null;
};

export default BlackFridayBanner;
