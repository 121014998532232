import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';

import styles from './index.css';

const TrustPilotReviewsWrapper = styled.div`${styles}`;

const TrustPilotReviews = () => {
  const ref = useRef(null);

  useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <TrustPilotReviewsWrapper ref={ref} className="trustpilot-widget" data-template-id="539ad60defb9600b94d7df2c" data-businessunit-id="58f20ed40000ff0005a086d7" data-locale="en-GB">
      <a href="https://www.trustpilot.com/review/example.com" target="_blank" rel="noopener">
        {' '}
        Trustpilot
      </a>
    </TrustPilotReviewsWrapper>
  );
};


export default TrustPilotReviews;
