export default ({ theme }) => `
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  max-width: 100vw;
  overflow: auto;
  margin-top: 10px;
  

  .toaster-notification {
    display: flex;
    


    @media(max-width: 768px) {
      width: 100vw;
    }
  }
`;
