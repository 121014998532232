import React, { Component, useState } from 'react';
import styled from 'styled-components';
import { isChrome, isMobile } from 'react-device-detect';

import styles from './index.css.js';

const PopOverWrapper = styled.div`${styles}`;

import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

const PopoverHover =( { content, header, img, itemToPopover, horizontalPosn, anchorOrigin, transformOrigin }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  // console.log('PopoverHover anchorOrigin: ', anchorOrigin);
  // console.log('PopoverHover anchorOrigin.vertical: ', anchorOrigin && anchorOrigin.vertical ? anchorOrigin.vertical : 'undeifined');
  // console.log('PopoverHover transformOrigin: ', transformOrigin);
  // console.log('PopoverHover header: ', header);


  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <PopOverWrapper>
      <div className="hover-popover">

        <Typography
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            onClick={handleClick}
            //style={{ pointerEvents: "none" }}
          >
           <span>{ itemToPopover } </span> 
          </Typography>
            <Popover
                style={{ pointerEvents: 'none' }}
                id="mouse-over-popover"
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: anchorOrigin && anchorOrigin.vertical ? anchorOrigin.vertical : 'center',
                  horizontal: anchorOrigin && anchorOrigin.horizontal ? anchorOrigin.horizontal : 'right',
                }}
                transformOrigin={{
                  vertical: transformOrigin && transformOrigin.vertical ? transformOrigin.vertical : 'center',
                  horizontal: transformOrigin && transformOrigin.horizontal ? transformOrigin.horizontal : 'left',
                }}
/*                 anchorOrigin={{
                  vertical: 'center',
                  horizontal: horizontalPosn && horizontalPosn ==='right' ? 'right' : 'left',
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: horizontalPosn && horizontalPosn ==='left' ? 'right' : 'left',
                }} */
                //onClose={handlePopoverClose}
                disableRestoreFocus
              >     
                <Typography 
                  style={{ pointerEvents: "auto", display: "flex", flexDirection: "column", alignItems: "center", margin: "10px" }}
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                > 
                  { img } 
                  { header ? <h3>{header}</h3>  : null}
                  { content }
                </Typography>
              
            </Popover>

      </div>
    </PopOverWrapper>
  );
};

export default(PopoverHover);
