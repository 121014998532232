import balloon from 'Images/floating-balloon.svg';
import logoSmall from 'Images/logo-400px-BoostYourSuper.png';

export default ({ theme, fixed, isDialogMenuOpen }) => `
    background-color: ${theme.lightPrimaryColor};
    height: 100px;
    width: 100%;
    display: flex;
    color: ${theme.primaryTextColor};
    position: ${fixed ? 'fixed' : 'static'};
    top: ${fixed ? 0 : 'unset'};
    box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.9);
    z-index: ${isDialogMenuOpen? 1 : 0};

    @media(max-width: 768px) {
      height: 64px;
    }

    .search-container {
      width: 100%;
    }

    .actions-container {
      width: 100%;
      align-items: center;
      justify-content: space-between;
      display: flex;

      .logo-positioning {
        align-items: center;
        justify-content: flex-start;
        width: fit-content;
        padding: 0 10px;
        display: flex;
        max-height: 100%;
        text-decoration: none;
        color: ${theme.darkSecondaryColor};

        &.minimal {
          width: calc(100% - 155px);
        }

        .multi-logo-display{
          display: flex;
          justify-content: center;
          align-items: center;
          height:100%;
        }

          .vertical-line{
            height: 100%;
            
            &.minimal {
              position: relative;
              }
          }

            .vertical-line:after{
              content: '';
              height: 60%;
              position: absolute;
              width: 3px;
              top: 20%;
              bottom: 20%;
              background-color: ${theme.borderColor};

            }



        .logo-posn1 {
          height: 100%;
          margin: 0 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          .img{
            max-height: 54px;
            width: auto;
            

            @media(max-width: 768px) {
              max-height: 44px;
              content: url(${logoSmall});
              border-radius: 50%;
            }
          }

          &.minimal {
            white-space: nowrap;
            text-overflow: ellipsis;
           /*  width: calc(100% - 50px); */

            span.header {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              // width: 130px;
              width: 100%;
            }
          }
        }

          .logo-posn2 {
            height: 100%;
            margin: 0 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .img{
              max-height: 54px;
              width: auto;
              
              @media(max-width: 435px) {
                max-height: 44px;
                max-width: 200px;
              }

            }
  
            &.minimal {
              white-space: nowrap;
              text-overflow: ellipsis;
              /* width: calc(100% - 50px); */
  
              span.header {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                // width: 130px;
                width: 100%;
              }
            }

          }
      }
      


      .links {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 10px 0 0;
        height: 100%;

        .link {
          margin: 0 5px;
          padding: 0 15px;
          text-decoration: none;
          color: ${theme.darkSecondaryColor};
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            background-color: ${theme.borderColor};
          }
        }

        .avatar-link {
          margin: 0 10px;
          text-decoration: none;
        }
      }

      .account-container {
        z-index: 1;
        &>.header {
          display: flex;
          align-items: flex-end;
          justify-content: flex-start;
          height: 100px;
          width: 250px;
          background-image: url(${balloon});
          background-position: bottom;
          background-size: cover;

          h2.name {
            margin: 10px;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  `;
