import React from 'react';
import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import classNames from 'classnames';

import { isMobile } from 'react-device-detect';

import IconButton from 'SharedComponents/icon-button';
import FormLinkBankAccount from 'SharedComponents/form-link-bank-account';

import logoSmall from 'Images/logo-400px-BoostYourSuper.png';

import styles from './index.css';

const ModalDialogContributionsFeesWrapper = styled(Dialog)`
  ${styles}
`;

const ModalDialogContributionsFees = ({
  open,
  onCloseModalDialogContributionsFees,
  titleComponent,
  explainerComponent,
  contentComponent,
  errorMessage,
}) => {
  console.log('ModalDialogContributionsFees has been triggered');
  const windowWidth = window.innerWidth;
  return (
    <ModalDialogContributionsFeesWrapper 
      open={open} 
      fullScreen={windowWidth < 768}
      maxWidth={false}
    >
    <div className="content-container">
      <div className="modal-header">
        {/* <div className='logo-container'>
          <img className='img' src={logoSmall} alt="logo1" />
        </div> */}
        <div className="close-dialog-container">
          <IconButton className={"close-dialog"} onClick={onCloseModalDialogContributionsFees}>
            <i className="material-icons">close</i>
          </IconButton>
        </div>
      </div>
      {
        titleComponent ? (
          <div className="title-component">
              {titleComponent}
          </div>
        ) : null
      }
        <div className="error-message">
          <text> &nbsp; {errorMessage}</text>
        </div>
      {
        explainerComponent ? (
          <div className="explainer-component">
              {explainerComponent}
          </div>
        ) : null
      }
      { contentComponent ? (
          <div className="content-component">
            {contentComponent}
          </div>
        ) : null
      }
      
    </div>
    </ModalDialogContributionsFeesWrapper>
  );
};

export default ModalDialogContributionsFees;
