export default ({ theme }) => {
  return `
  padding: 0;
  border: none;
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px #d9d9d9;
  border-top: solid 1px #d9d9d9;
  width: 100%;
  // box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5);

  .acceptance-card{
    display: flex;
    flex-direction: column;
    /* max-width: 50%; */
    padding: 20px;
    margin: 20px;
    background-color: ${theme.darkBackgroundColor};

    .promo-code-entry-and-validate-and-activate{
      display: flex;
      flex-direction: column;

      .promo-code-entry-and-validate{
        display: flex;
        flex-direction: column;
      
        .promo-code-entry{
          display: flex;
        }

        .validation-message{
          color: #ff007d;
          margin: -10px 0 20px 20px;
          minHeight:10px;
        }
      }

      .activate-button-container{
        display: flex;
        margin: 0px 10px;
        height: fit-content;
      }

    }

  }


  .title {
    font-size: 30px;
    text-align: center;
    margin: 10px;
  }

  .description {
    font-size: 20px;
    text-align: left;
    margin: 10px;
    max-width: unset;
  }

  .error-message {
    display: flex;
    width: calc(100% - 20px);
    overflow: auto;
    justify-content: center;
    color: red;
    margin: 10px;
  }

  .enable-contribute-now-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
  }

  }

  .checkbox-entry{
    display: flex;
    padding: 5px;
    margin-bottom: 5px;
    align-items: center;

    .input-checkbox{
      min-width: 30px;
      height: 30px;
      margin-right: 10px;

      :checked:after{
        color: black;
      }
    }

  h3 {
    font-weight: bold;
    text-align: center;
    font-weight: bolder;
    font-size: 17px;
    color: ${theme.accentColor};
    text-transform: uppercase;
  }

  button {
    margin: 10px;
  }

  h2 {
    font-weight: bolder;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  p {
    font-size: 20px;
  }


  .about-round-ups{
    background-color: ${theme.darkBackgroundColor};
    display: flex;
    flex-direction: column;
    background-color: ${theme.darkBackgroundColor};
    padding: 10px 10px;
    margin: 10px;
    /* max-width: 50%; */
    /* height: 100%; */

    .about-round-ups-header{
      background-color: ${theme.darkBackgroundColor};
      text-transform: capitalize;
      justify-content: center;
      font-size: 25px;
      padding: 0px 20px;
      text-align: left;
      background-color: black;
      color: white;
      display: flex;

      h1{
        text-align: center;
        margin: 10px 0px;
      }

      .highlight-in-header{
        color: ${theme.alternateAccentColor};
      }
    }

/*     @media(min-width:769px){
      max-width: 50%;
    } */
    
    h3 {
      margin: 0px;
    }
    p {
      margin: 0px;
    }


  }



  .about-round-ups-entry{
    background-color: ${theme.darkBackgroundColor};
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 10px 0px;
    padding: 10px;
    
    
  }




`
};
