import React, { useEffect } from 'react';
import styled from 'styled-components';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Swiper, SwiperSlide } from 'swiper/react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import Slide from './Slide';

import { Keyboard, Navigation, Pagination, Autoplay } from 'swiper/modules';

import styles from './index.css';

const WhoWeBoostWrapper = styled.div`${styles}`;

const WhoWeBoost = ({ content }) => {

  return (
    <WhoWeBoostWrapper > 
      <Swiper 
        slidesPerView={1}
        pagination={{ clickable:true }}
        navigation={true}
        grabCursor={false}
        keyboard={{ enabled:true }}
        modules={[Pagination, Navigation, Autoplay]}
        autoplay={{ delay: 7000, disableOnInteraction: true }}
        className="mySwiper"
      >
        <SwiperSlide>
          <Slide
            imageUrl={content.photo_1_who_we_boost}
            demographicGenderImageUrl={content.image_1_gender_demographics}
            demographicAgeImageUrl={content.image_1_age_demographics}
            title={content.who_we_boost_title_text}
            text1={content.who_we_boost_text_1}
            text2={content.who_we_boost_text_2}
            text3={content.who_we_boost_text_3}
            text4={content.who_we_boost_text_4}
            backgroundPosition="50% 50%"
          />  
        </SwiperSlide>
{/*         <SwiperSlide>
          <Slide
            imageUrl={content.photo_2_value_prop}
            title={content.photo_2_heading}
            text1={content.photo_2_message_line_1}
            text2={content.photo_2_message_line_2}
            text3={content.photo_2_message_line_3}
            backgroundPosition="50% 50%"
        /> 
        </SwiperSlide>
       <SwiperSlide>
          <Slide
            imageUrl={content.photo_3}
            title={content.photo_3_heading}
            text1={content.photo_3_message_line_1}
            text2={content.photo_3_message_line_2}
            text3={content.photo_3_message_line_3}
            backgroundPosition="50% 50%"
          />
        </SwiperSlide> */}
      </Swiper>
     </WhoWeBoostWrapper> 
  );
};

WhoWeBoost.propTypes = {
  content: PropTypes.object.isRequired,
};

export default WhoWeBoost;
