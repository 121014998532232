import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import log from "Utils/logger";

//import CONFIG from "Config";

import * as TransactionActions from 'Flux/transactions/actions';

const useFetchCashbackSummary = () => {
  console.log ("*** ** fetchCashbackSummary: hook triggered")
  const isFetchingCashbackSummaryRef = useRef(false);
  const hasFetchedCashbackSummaryRef = useRef(false);
  const dispatch = useDispatch();
  //const roundups = useSelector(state => state.roundups);
  
  const storedCashbackSummary = useSelector(state => state.transactions.cashBackSummary);
  
  const user = useSelector(state => state.authentication.user);
  
  const fetchCashbackSummary = async () => {
    console.log('*** ** fetchCashbackSummary: function Triggered: ');
    isFetchingCashbackSummaryRef.current = true;
    try {
      // if (!storedCashbackSummary || !storedCashbackSummary.length || !user) {
      //   console.log('*** ** fetchCashbackSummary: exit function as no storedCashbackSummary or no user');
      //   isFetchingCashbackSummaryRef.current = false;
      //   return;
      // }
      console.log('*** ** fetchCashbackSummary: about to await: ');
      const result = await dispatch(TransactionActions.fetchCauseDetails());
      
      //const newCauseDetails = result.data.data;

     console.log('*** ** fetchCashbackSummary: result: ', result);

     const causeDetails = result.data;
      
      const {
        referred_causes,
        amount_raised_by_referred_causes,
        free_bonus,
        total_waiting_payment_donation_amount,
        total_pending_amount,
        total_paid_amount,
        total_commission,
        total_charity_commission
      } = causeDetails;
    
      const newCashbackSummary = {
          referred_causes,
          amount_raised_by_referred_causes,
          free_bonus,
          total_waiting_payment_donation_amount,
          total_pending_amount,
          total_paid_amount,
          total_commission,
          total_charity_commission
      };

      console.log('*** ** fetchCashbackSummary: storedCashbackSummary: ', storedCashbackSummary)
      console.log ('*** ** fetchCashbackSummary: newCashbackSummary: ', newCashbackSummary);
      //console.log('*** ** fetchCashbackSummary: JSON.stringify(storedFavouriteShops): ', JSON.stringify(storedCashbackSummary));
      //console.log ('*** ** fetchCashbackSummary: JSON.stringify(newCashbackSummary): ', JSON.stringify(newCashbackSummary));

      if (shouldUpdateStore(newCashbackSummary, storedCashbackSummary)) {
        console.log('*** ** fetchCashbackSummary: DIFFERENCE - should update store');
        dispatch(TransactionActions.setTransactionSummary(newCashbackSummary));
      }

    } catch (exception) {
      console.log(exception);
      log({
        event: "System-Error",
        source: "Fetch CashbackSummary",
        info: exception,
        ...exception,
      });
    } finally {
      isFetchingCashbackSummaryRef.current = false;
      hasFetchedCashbackSummaryRef.current = true;
    }
  };

  function shouldUpdateStore(newCashbackSummary, storedCashbackSummary) {

    if (!isEqual(newCashbackSummary, storedCashbackSummary)) {
      return true; // Differences found
    } 

    return false; // No differences found
  }

  useEffect(() => {
    console.log('*** ** fetchCashbackSummary: - Mounting UseEffect triggered');
    if ( user
        && !isFetchingCashbackSummaryRef.current
        && !hasFetchedCashbackSummaryRef.current) {
          console.log('*** ** fetchCashbackSummary: - UseEffect about to launch FetchCashbackSummary');
          fetchCashbackSummary();
    }
  }, []);


  return { 
          //isFetchingCashbackSummary: isFetchingCashbackSummaryRef.current,
          // hasFetchedCashbackSummary: hasFetchedCashbackSummaryRef.current,
           fetchCashbackSummary };

};

export default useFetchCashbackSummary;
