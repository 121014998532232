import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Formsy from 'formsy-react';
import styled from 'styled-components';
//import { Redirect } from 'react-router';
import classNames from 'classnames';
import log from 'Utils/logger';
import { GoogleLogin } from '@react-oauth/google';

import TextInput from 'SharedComponents/text-input';
import FacebookLogin from 'SharedComponents/facebook-login';

import * as AuthenticationActions from 'Flux/authentication/actions';
import * as AppActions from "Flux/app/actions";

import ActionButton from 'SharedComponents/action-button';

import styles from './index.css';

const SignUpFormWrapper = styled.div`${styles}`;

const ContactInfoForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authentication = useSelector(state => state.authentication);
  const app = useSelector(state => state.app);
  const { user } = authentication;

  const [isFormValid, setIsFormValid] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState('');
  const [form, setForm] = useState({
    email: '',
    password: '',
    name: '',
    phone: '',
  });
  const [isAuthenticatingWithGoogle, setIsAuthenticatingWithGoogle] = useState(false);
  const [isAuthenticatingWithFacebook, setIsAuthenticatingWithFacebook] = useState(false);

  const onChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const onSignIn = useCallback(async () => {
    setIsAuthenticating(true);
    setError(false);
    setMessage('');

    try {
      const onSignInResult = await dispatch(AuthenticationActions.authenticate(form));

      if (!onSignInResult.data.status) {
        log({
          shopper_id: null,
          event: 'System-Login',
          method: 'email',
          status: false,
          error: onSignInResult.data.error,
        });

        setError(true);
        setMessage(onSignInResult.data.error);
        setTimeout(() => {
          setError(false);
          setMessage('');
        }, 3000);
      } else {
        dispatch(AuthenticationActions.setUser(onSignInResult.data));
        dispatch(AppActions.setPartnerContent(onSignInResult.data.partner));
        if (props.onCloseDialog) {
          props.onCloseDialog();
        }

        const { user } = onSignInResult.data;
        log({
          shopper_id: user.shopper_id,
          event: 'System-Login',
          method: 'email',
          status: true,
        });
        navigate('/');
      }
    } catch (exception) {
      log({
        shopper_id: null,
        event: 'Login',
        method: 'email',
        status: false,
      });

      setError(true);
      setMessage(exception.message);
    } finally {
      setIsAuthenticating(false);
    }
  }, [form, dispatch, navigate, props]);

  const onSignUp = useCallback(async () => {
    setIsAuthenticating(true);
    setError(false);
    setMessage('');

    try {
      const onSignUpResult = await dispatch(AuthenticationActions.register(form));

      if (!onSignUpResult.data.status) {
        log({
          shopper_id: null,
          event: 'System-SignUp',
          method: 'email',
          status: false,
          bysAPI_message: onSignUpResult.data.error,
        });

        setError(true);
        setMessage(onSignUpResult.data.error);
        setTimeout(() => {
          setError(false);
          setMessage('');
        }, 3000);
      } else {
        log({
          event: 'System-SignUp',
          method: 'email',
          status: true,
        });
        onSignIn();
      }
    } catch (exception) {
      log({
        shopper_id: null,
        event: 'System-SignUp',
        method: 'email',
        status: false,
        error: exception,
      });

      setError(true);
      setMessage(exception.message);
    } finally {
      setIsAuthenticating(false);
    }
  }, [form, dispatch, onSignIn]);

  const onValid = () => {
    setIsFormValid(true);
  };

  const onInvalid = () => {
    setIsFormValid(false);
  };

  const onValidSubmit = () => {
    onSignUp();
  };

  const onInvalidSubmit = () => {
    console.log('onInvalidSubmit');
  };

  const onGoogleResponseSuccess = useCallback(async ({ credential }) => {
    setIsAuthenticatingWithGoogle(true);

    try {
      const registerViaGoogleResult = await dispatch(AuthenticationActions.registerViaNewGoogle(credential));
      const onSignInResult = await dispatch(AuthenticationActions.authenticateViaNewGoogle(credential));

      if (!registerViaGoogleResult.data.status) {
        setIsAuthenticatingWithGoogle(false);
        setMessage(registerViaGoogleResult.data.error);
        setError(true);

        log({
          shopper_id: null,
          event: 'System-SignUp',
          method: 'google',
          status: false,
          error: registerViaGoogleResult.data.error,
        });

        setTimeout(() => {
          setMessage('');
          setError(false);
        }, 3000);
      } else {
        dispatch(AuthenticationActions.setUser(onSignInResult.data));
        dispatch(AppActions.setPartnerContent(onSignInResult.data.partner));

        const { user } = registerViaGoogleResult.data;
        log({
          shopper_id: !user.shopper_id ? null : user.shopper_id,
          event: 'System-SignUp',
          method: 'google',
          status: true,
        });

        setIsAuthenticatingWithGoogle(false);
        navigate('/');
      }
    } catch (exception) {
      log({
        shopper_id: null,
        event: 'System-SignUp',
        method: 'google',
        status: false,
        error: exception,
      });

      setIsAuthenticatingWithGoogle(false);
      setMessage(exception.message);
      setError(true);
    }
  }, [dispatch, navigate]);

  const onGoogleGetCredentialsFailure = (errorDetails) => {
    log({
      event: "System-Error",
      method: "google",
      status: false,
      location: "get google creds failed on Sign Up",
      error: errorDetails.error,
      details: errorDetails.details
    });

    setIsAuthenticatingWithGoogle(false);
    setMessage("Difficulty with obtaining your credentials. Try again");
    setError(true);

    setTimeout(() => {
      setMessage('');
      setError(false);
    }, 3000);
  };

  const onLoginWithFacebookClicked = (payload) => {
    const { onPersistOnClickOutside } = props;
    setIsAuthenticatingWithFacebook(true);

    if (onPersistOnClickOutside) {
      onPersistOnClickOutside(true);
    }
  };

  const onFacebookProfileSuccess = useCallback(async (userObject) => {
    setIsAuthenticatingWithFacebook(true);

    try {
      const registerViaFacebookResult = await dispatch(AuthenticationActions.registerViaFacebook(userObject));
      const onSignInResult = await dispatch(AuthenticationActions.authenticateViaFacebook(userObject));

      if (!onSignInResult.data.status) {
        log({
          shopper_id: null,
          event: 'System-SignUp',
          method: 'facebook',
          status: false,
          error: onSignInResult.data.error,
        });

        setIsAuthenticatingWithFacebook(false);
        setMessage(onSignInResult.data.error);
        setError(true);

        setTimeout(() => {
          setMessage('');
          setError(false);
        }, 3000);
      } else {
        dispatch(AuthenticationActions.setUser(onSignInResult.data));
        dispatch(AppActions.setPartnerContent(onSignInResult.data.partner));

        const { user } = onSignInResult.data;
        log({
          shopper_id: user.shopper_id,
          event: 'System-Login',
          method: 'facebook',
          status: true,
        });

        setIsAuthenticatingWithFacebook(false);
        navigate('/');
      }
    } catch (exception) {
      log({
        shopper_id: null,
        event: 'System-Login',
        method: 'facebook',
        status: false,
        error: exception,
      });

      setIsAuthenticatingWithFacebook(false);
      setMessage(exception.message);
      setError(true);
    } finally {
      const { onPersistOnClickOutside } = props;
      if (onPersistOnClickOutside) {
        onPersistOnClickOutside(false);
      }
    }
  }, [dispatch, navigate, props]);

  const onFacebookResponseFailure = (error) => {
    console.log("login facebook - onFacebookResponseFailure", error);
    log({
      event: "SystemError",
      method: "facebook",
      status: false,
      error
    });

    setIsAuthenticatingWithFacebook(false);
    setMessage("Difficulty with obtaining your credentials. Try again");
    setError(true);

    setTimeout(() => {
      setMessage('');
      setError(false);
    }, 3000);
  }

  if (user) {
    navigate('/');
  }

  const onFacebookResponseSuccess = (profile) => {
    //we are not currently using onFacebookResponseSuccess. Profile getting info we need.
    console.log("login facebook - onFacebookResponseSuccess", profile);
  } 

  const { labelColor, header, showSocialLogin, showEmailLogin, showBackground } = props;

  return (
    <SignUpFormWrapper {...props}>
      <Formsy
        style={{ width: '100%' }}
        onValidSubmit={onValidSubmit}
        onInvalidSubmit={onInvalidSubmit}
        onValid={onValid}
        onInvalid={onInvalid}
        className="formsy-container"
      >
        {header}
        {showSocialLogin && (
          <div className="social-login">
            <GoogleLogin
              text="continue_with"
              onSuccess={ credentialResponse => {
                onGoogleResponseSuccess( credentialResponse )
              }}
              onError={ errorDetails => {
                onGoogleGetCredentialsFailure ( errorDetails )
              }}
              size="large"
              useOneTap
            />
            <FacebookLogin
              onClick={onLoginWithFacebookClicked}
              onFacebookResponseSuccess={onFacebookResponseSuccess}
              onFacebookResponseFailure={onFacebookResponseFailure}
              onFacebookProfileSuccess={onFacebookProfileSuccess}
              isAuthenticatingWithFacebook={isAuthenticatingWithFacebook}
              buttonText="Sign Up with Facebook"
            />
          </div>
        )}
        {showEmailLogin && (
          <div className={classNames('form-container', { 'form-background': showBackground })}>
            <div style={{ margin: '10px 0', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
{/*               {!isFormValid && onInvalidSubmit && (
                <div style={{ width: 'calc(100% - 18px)', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10, backgroundColor: '#ff007d', color: '#fff' }}>
                  Some fields are invalid. Please complete them then resubmit the form
                </div>
              )} */}
              {message && (
                <span style={{ color: error ? '#ff007d' : '##03031', fontSize: 20, marginLeft: 20 }}>{message}</span>
              )}
            </div>
            <TextInput
              name="name"
              type="text"
              validations={{ isExisty: true, matchRegexp: /^([a-zA-Z]{2,}\s[a-zA-z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)/ }}
              validationErrors={{ 
                isDefaultRequiredValue: 'this field is required',
                isExisty: 'this field is required',
                matchRegexp: 'enter your full name'
              }}
              required
              value={form.name}
              onChange={onChange}
              placeholder="Firstname Lastname"
              label="Name"
              disabled={isAuthenticating}
              labelColor={labelColor}
            />
            <TextInput
              name="email"
              type="email"
              validations={{ isEmail: true, isExisty: true, matchRegexp: /^(((?!(info@|admin@|web@|support@)).)*)$/ }}
              validationErrors={{
                isEmail: 'the email is invalid',
                matchRegexp: 'some role based emails are disallowed including admin@, info@ and web@',
                isDefaultRequiredValue: 'this field is required',
                isExisty: 'this field is required'
              }}
              required
              value={form.email}
              onChange={onChange}
              placeholder="email"
              label="Email"
              disabled={isAuthenticating}
              labelColor={labelColor}
            />
            <TextInput
              name="password"
              type="password"
              validations={{ 
                isExisty: true, 
                minLength: 8,
                matchRegexp: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/ 
              }}
              validationErrors={{
                isDefaultRequiredValue: 'this field is required',
                isExisty: 'this field is required',
                minLength: 'too short',
                matchRegexp: 'Min 8 characters, include at least 1 lowercase and 1 uppercase letter, 1 digit, and 1 special character.'
              }}
              required
              value={form.password}
              onChange={onChange}
              placeholder="password"
              label="Password"
              disabled={isAuthenticating}
              labelColor={labelColor}
            />
            <TextInput
              name="phone"
              type="tel"
              validations={{ isExisty: true, minLength: 10, maxLength: 16, matchRegexp: /^\+{0,1}(?:[0-9] ?){6,14}[0-9]$/ }}
              validationErrors={{ isExisty: 'this field is required', isDefaultRequiredValue: 'this field is required', minLength: 'too short', maxLength: 'too long', matchRegexp: 'must be a valid phone number' }}
              required
              value={form.phone}
              onChange={onChange}
              placeholder="0402123456"
              label={<div><span style={{ color: labelColor ? labelColor : null }}>Mobile Phone</span></div>}
              labelColor={labelColor}
            />
          </div>
        )}
        <div className="form-footer">
          <Link to="/login" className="login-link"><small style={{ color: labelColor }}>Already have an account?</small></Link>
          <ActionButton
            text={isAuthenticating ? 'Signing Up' : 'Sign Up For Free'}
            loading={isAuthenticating}
            disabled={isAuthenticating}
            active={!isAuthenticating}
            color="#fff"
            large={true}
          />
        </div>
        <div>
          <p style={{ textAlign: 'center' }}>
            By continuing, you agree to our
            {' '}
            <Link
              to="/terms-and-conditions"
              style={{
                color: '#FF9820',
                textDecoration: 'none',
                textAlign: 'center',
              }}
            >
              Terms and conditions
            </Link>
            {' '}
            and
            {' '}
            <Link
              to="/privacy-policy"
              style={{ color: '#FF9820', textDecoration: 'none' }}
            >
              Privacy Policy
            </Link>
          </p>
        </div>
      </Formsy>
    </SignUpFormWrapper>
  );
};

ContactInfoForm.propTypes = {
  showSocialLogin: PropTypes.bool,
  header: PropTypes.node,
  showEmailLogin: PropTypes.bool,
  showBackground: PropTypes.bool,
  onCloseDialog: PropTypes.func,
  onPersistOnClickOutside: PropTypes.func,
  labelColor: PropTypes.string,
};

export default ContactInfoForm;
