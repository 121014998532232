import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import log from "Utils/logger";

import * as RoundUpsActions from 'Flux/round-ups/actions';

const useFetchActivePromoCodes = ( code, type ) => {
  console.log ('*** ** useFetchActivePromoCodes: hook triggered');
  const hasFetchedActivePromoCodesRef = useRef(false);
  const isFetchingActivePromoCodesRef = useRef(false);
  const [isFetchingActivePromoCodes, setIsFetchingActivePromoCodes] = useState(false);
  const [fetchActivePromoCodesResponse, setFetchActivePromoCodesResponse] = useState(null);
  const [fetchActivePromoCodesResponseRoundUps, setFetchActivePromoCodesResponseRoundUps] = useState(null);
  const [fetchActivePromoCodesResponseContributeNow, setFetchActivePromoCodesResponseContributeNow] = useState(null);
  const dispatch = useDispatch();
  
 
  const fetchActivePromoCodes = async ()  => {
    //setIsFetchingActivePromoCodes(true);
    isFetchingActivePromoCodesRef.current = true;
    console.log('2.1 *** ** fetchActivePromoCodes: about to commence fetchActivePromoCodesValid');
    try {
      const result = await dispatch(RoundUpsActions.fetchActivePromoCodes(code, type));
      console.log ('4.0 *** ** fetchActivePromoCodes result: ', result);
      console.log ('4.1 *** ** fetchActivePromoCodes result.data.promo_codes[0]: ', result.data.promo_codes[0]);
      console.log ('4.2 *** ** fetchActivePromoCodes result.data.promo_codes[0].length: ', result.data.promo_codes.length);

      if (result.data.promo_codes.length > 0) {
        console.log ('4.2 *** ** fetchActivePromoCodes result.data.promo_codes[0].length: ', result.data.promo_codes.length);

        const [latestPromoCodeRoundup, latestPromoCodeContributeNow] = 
        ["Roundup", "Contribute Now"].map(promoType => {
          return result.data.promo_codes.reduce((latest, promoCode) => {
            if (!promoCode.active || !promoCode.shopper.active || promoCode.promo_type.name !== promoType) {
              return latest;
            }

            const currentDate = new Date();
            const startDate = new Date(promoCode.shopper.start_date);
            const endDate = new Date(promoCode.shopper.end_date);

            if (startDate <= currentDate && endDate > currentDate) {
              if (!latest || endDate > new Date(latest.end_date)) {
                return promoCode;
              }
            }
            return latest;
          }, null);
        });

      console.log('4.3 *** ** fetchActivePromoCodes latestPromoCodeRoundup: ', latestPromoCodeRoundup);
      console.log('4.3 *** ** fetchActivePromoCodes latestPromoCodeContributeNow: ', latestPromoCodeContributeNow);
      
      setFetchActivePromoCodesResponseRoundUps(latestPromoCodeRoundup);
      setFetchActivePromoCodesResponseContributeNow(latestPromoCodeContributeNow);
      }

    } catch (exception) {
      console.log(exception);
      log({
        event: "System-Error",
        source: "check Promo Code",
        info: exception,
        ...exception,
      });
    } finally {
      console.log('5.0 *** ** fetchActivePromoCodes: completed SET FALSE isFetchingActivePromoCodesRef.current: ', isFetchingActivePromoCodesRef.current);
      hasFetchedActivePromoCodesRef.current = true;
      isFetchingActivePromoCodesRef.current = false;
      //setIsFetchingActivePromoCodes(false);
    }
  };

  useEffect(() => {
    console.log ('2.0 *** ** useFetchActivePromoCodes: useEffect triggered with code: ', code);
    if ( !isFetchingActivePromoCodesRef.current
         && !hasFetchedActivePromoCodesRef.current
        ) {
      fetchActivePromoCodes();
    }
  }, []);


  return { fetchActivePromoCodes, fetchActivePromoCodesResponseRoundUps, fetchActivePromoCodesResponseContributeNow, isFetchingActivePromoCodesRef };
};

export default useFetchActivePromoCodes;
