import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";

import styles from './favorite.styles.js';

const FavoriteWrapper = styled.div`${styles}`;

const Favorite = ({ favorite, setFavShops, retailStoreId }) => {

  //const [favorite, setFavorite] = useState(false);

    return (
      !favorite ? (     
        <FavoriteWrapper      
          onClick={() => setFavShops(retailStoreId,'userAddFavShop')}
        >
          <i className="material-icons" style={{ color: '#ff4569' }}>favorite_border</i>
        </FavoriteWrapper>
      ) : (
        <FavoriteWrapper
          onClick={() => setFavShops(retailStoreId,'userDeleteFavShop')}
        > 
          <i className="material-icons" style={{ color: '#ff4569' }}>favorite</i>
        </FavoriteWrapper>
      )
    );
}
export default Favorite;

