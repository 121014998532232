import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import log from "Utils/logger";

//import CONFIG from "Config";

import * as ShoppingActions from "Flux/shopping/actions";

const useFetchCategories = () => {
  console.log ('*** ** fetchCategories: hook triggered')
  const isFetchingCategoriesRef = useRef(false);
  const hasFetchedCategoriesRef = useRef(false);
  const dispatch = useDispatch();
  const storedCategories = useSelector(state => state.shopping.categories);

  
  const fetchCategories = async () => {
    isFetchingCategoriesRef.current = true;
    try {
      const result = await dispatch(ShoppingActions.fetchCategories());
      const newCategories = result.data.tags;

      console.log('*** ** fetchCategories: storedCategories: ', storedCategories)
      console.log ('*** ** fetchCategories: newCategories: ', newCategories);
      
      
      if (!isEqual(storedCategories, newCategories)) {
        dispatch(ShoppingActions.addCategories(newCategories));
        console.log('*** ** fetchCategories: DISPATCH - addCategories: ', newCategories);
      }


    } catch (exception) {
      console.log(exception);
      log({
        event: "System-Error",
        source: "Fetch Categories",
        info: exception,
        ...exception,
      });
    } finally {
      isFetchingCategoriesRef.current = false;
      hasFetchedCategoriesRef.current = true;
    }
  };

  useEffect(() => {
    if (!isFetchingCategoriesRef.current && !hasFetchedCategoriesRef.current) {
      fetchCategories();
    }
  }, []);

  return { fetchCategories };
};

export default useFetchCategories;
