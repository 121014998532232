import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import CONFIG from 'Config';
import PopoverHover from 'SharedComponents/popover-hover';
import { isChrome, isMobile } from 'react-device-detect';
//import IconButton from 'SharedComponents/icon-button';

import ActionButton from 'SharedComponents/action-button';
import IconButton from 'SharedComponents/icon-button';
import AvailAtChromeWebStore from 'Images/avail-in-chrome-web-store.png';
import ExtHowItWorksImg from 'Images/ext-how-it-worksx2.png';

//import useCheckIsExtensionInstalled from 'SharedHooks/check-extension-installed';

import styles from './index.css';


const GetExtensionStripWrapper = styled.div`${styles}`;

const extensionPopoverHeader = (
  <div className="ext-popover-how-it-works-header">
    <h3> Activate Boosts on retailer's sites</h3>
  </div>
);

const extensionPopoverImg = (
  <div className="ext-popover-how-it-works-img-container" style={{ width: '100%', margin: '5px' }}>
    <img src={ ExtHowItWorksImg } alt="How the extension works" />
  </div>
);

const extensionPopoverText = (
  <div className="ext-popover-how-it-works-text" style={{ width: '100%'}}>
    <div className="text-content-container" style={{ margin: '0px 0px 15px 0px' }}>
      <div style={{ width: '80%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',  }} >
          <i className="material-icons" style={{ color: '#47E6B1', margin: '0px 0px 15px 15px' }}>check_circle</i>
          <h2 style={{ margin: '0px 0px 15px 5px' }}>Coupons displayed on retailer's site</h2>
        </div>
        <div style={{ width: '80%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', }} >
          <i className="material-icons" style={{ color: '#47E6B1', margin: '0px 0px 15px 15px' }}>check_circle</i>
          <h2 style={{ margin: '0px 0px 15px 5px' }}>Activate BOOST on retailer's site</h2>
        </div>
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',}} >
          <i className="material-icons" style={{ color: '#47E6B1', margin: '0px 0px 5px 15px'}}>check_circle</i>
          <h2 style={{ margin: '0px 0px 5px 5px' }}><i>I have tried a few cashback extensions. This one works well. </i></h2>
        </div>
          <em style={{ margin: '0px 0px 0px 40px' }}>Gloria D. - Boost Your Super Customer</em>
      </div>
    
  
  
  </div>
);



let isChromeExtensionInstalledInterval;

const GetExtensionStrip = () => {
  
  //const { checkIsExtensionInstalled } = useCheckIsExtensionInstalled();
  
  //const [isExtensionInstalled, setIsExtensionInstalled] = useState(false);

  // useEffect(() => {
  //   checkExtensionInstallationStatus();
  //   return () => {
  //     window.clearInterval(isChromeExtensionInstalledInterval);
  //   }
  // })

  // function checkExtensionInstallationStatus() {
  //   console.log('[CHECKING EXTENSION INSTALLATION]');
  //   const img = new Image();
  //   img.src = `chrome-extension://${CONFIG.chromeExtensionId}/assets/images/bys_logo.png`;

  //   img.onload = () => {
  //     setIsExtensionInstalled(true);
  //   };
  // }

  // function onLearnHowItWorks() {
  //   console.log('[onLearnHowItWorks]', `${window.location.host}/notifier/testrun`);
  //   const howItWorksWindow = window.open(`${window.location.host}/notifier/testrun`, '_blank');
  //   howItWorksWindow.focus();
  // }

  function onInstallChromeExtension() {
    const webstoreWindow = window.open(`https://chrome.google.com/webstore/detail/boost-your-super/${CONFIG.chromeExtensionId}`, '_blank');
    webstoreWindow.focus();

    // isChromeExtensionInstalledInterval = window.setInterval(() => {
    //   console.log('[RUNNING INTERVAL]');
	  //   const img = new Image();
		// 	img.src = `chrome-extension://${CONFIG.chromeExtensionId}/assets/images/bys_logo.png`;

		// 	img.onload = () => {
    //     // // redirect to test run page
		// 		// if (window.location.href.indexOf('notifier/testrun') === -1) {
		// 		// 	// redirect to test run page if not already there
		// 		// 	webstoreWindow.location = `${window.location.host}/notifier/testrun`;
    //     //   webstoreWindow.focus();
		// 		// }
    //     //checkExtensionInstallationStatus();
	  //     window.clearInterval(isChromeExtensionInstalledInterval);
	  //   };

	  //   img.onerror = () => {
	  //     console.log('[EXTENSION NOT INSTALLED]');
	  //   };
	  // }, 1000);
  }

  return (

        <GetExtensionStripWrapper>
          <div className="get-extension-strip-container right-align">
            <h2 style={{ margin: '0 10px' }}>Don't miss a free super boost with our Chrome Extension.</h2>
            <div className="pop-over-container">
              <PopoverHover
                content = {extensionPopoverText}
                header = {null}
                img = {extensionPopoverImg}
                itemToPopover = { 
                  (<i className="material-icons" style={{ margin: '0 10px 0 10px' }} >info</i>)
                }
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'right',
                }}
              />
          </div>
            <h3 style={{ margin: '0 10px' }}>Now with coupons.</h3>
            <img className="avail-at-chrome-store" src={ AvailAtChromeWebStore } onClick={onInstallChromeExtension} alt="availabe at Chrome Web Store" />
            <ActionButton text="Add&nbsp;to&nbsp;Chrome" icon="extension" onClick={onInstallChromeExtension} large={true} />
          </div>
        </GetExtensionStripWrapper>
  );
};



export default GetExtensionStrip;



//export default connect((state) => ({ user: state.authentication.user, collaboratingCauses: state.authentication.collaboratingCauses }))(ShareStrip);
