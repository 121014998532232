export default ({ theme }) => {
  return `
    width: 100%;

    .activity-log-item {
      background-color: ${theme.neutralColor};
      border-bottom: solid 1px ${theme.borderColor};
      width: 100%;

      &:hover {
        background-color: ${theme.lightBorderColor};
      }
    }
  `
};
