import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import styles from './index.css';

const DialogTriggerWrapper = styled.div`${styles}`;

const DialogTrigger = ({ trigger, dialogContent, setIsDialogMenuOpen }) => {

  console.log('DialogTrigger triggered with setIsDialogMenuOpen: ', setIsDialogMenuOpen);
  console.log('DialogTrigger triggered with dialogContent: ', dialogContent);
  console.log('DialogTrigger triggered with trigger: ', trigger); 
  const dialogContainer = useRef(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [persistOnClickOutside, setPersistOnClickOutside] = useState(false);

  const onOpenDialog = () => {
    setIsDialogOpen(true);
    setIsDialogMenuOpen(true);
  };

  const onCloseDialog = () => {
    setIsDialogOpen(false);
    setIsDialogMenuOpen(false);
  };

  const onPersistOnClickOutside = (state) => {
    setPersistOnClickOutside(state);
  };

  const onHandleClickOutside = (event) => {
    
    if (persistOnClickOutside) {
      return;
    } 

    if (isDialogOpen && !dialogContainer.current.contains(event.target)) {  
      setIsDialogOpen(false);
      setIsDialogMenuOpen(false);
    }
  };
  

  useEffect(() => {
    document.addEventListener('mousedown', onHandleClickOutside, false);
    return () => {
      document.removeEventListener('mousedown', onHandleClickOutside, false);
    };
  }, [isDialogOpen]);


  return (
    <DialogTriggerWrapper>
      {React.cloneElement(trigger, {
        onClick: () => onOpenDialog()
      })}
      {isDialogOpen && (
        <div className="dialog-container" ref={dialogContainer}>
          {React.cloneElement(dialogContent, { 
             onClick: () => onCloseDialog(), 
             onCloseDialog: () => onCloseDialog(), 
             onPersistOnClickOutside: () => onPersistOnClickOutside(),
            })}
        </div>
      )}
    </DialogTriggerWrapper>
  );
};

DialogTrigger.propTypes = {
  trigger: PropTypes.node.isRequired,
  dialogContent: PropTypes.node.isRequired,
  setIsDialogMenuOpen: PropTypes.func.isRequired
};

export default DialogTrigger;
