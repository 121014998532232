/* eslint-disable react/forbid-prop-types */
import React from 'react';
import styled from 'styled-components';
import numeral from 'numeral';
import PropTypes from 'prop-types';

import PopoverHover from 'SharedComponents/popover-hover';

import styles from './DonationsStrip.css';

const DonationStripWrapper = styled.div`${styles}`;

const keyMap = {
  referred_causes: 'referred members',
  amount_raised_by_referred_causes: 'amount raised by referred members',
  free_bonus: 'free bonus',
  total_pending_amount: 'total pending approval:',
  total_waiting_payment_donation_amount: 'total approved:',
  total_paid_amount: 'total paid:',
  total_commission: 'total commission:',
  total_charity_commission: 'total boost:',
  status : 'status',
  csrf_token : 'token',
};

const KeySummaryTransactionStatusDescriptionsMap = {
  total_pending_amount: 'These amounts are waiting approval from the shop. Each shop has their own approval time but in general shops wait 30-45 days to ensure goods are not returned to store before they will approve.',
  total_waiting_payment_donation_amount: 'These amounts have been approved to be paid by the shops but payment has not yet been made to your super.',
  total_paid_amount: 'This amount has been paid to your super account where it can grOW.',
  total_charity_commission: 'Total of all amounts Pending Approval, Approved and Paid.',
  Clicked: 'Click has been registered and will track any transaction you make within 24 hrs',
};

const DonationsStrip = ({ causeDetails }) => {
  if (!causeDetails) {
    return null;
  }

  const { charity, success, ...stats } = causeDetails;

  return (
    <DonationStripWrapper className="hide-scrollbars">
      <div className="all-stats-container">
        <div className="stat-card-container">
            {
              Object.keys(stats).filter((key) => key !== 'total_commission' 
              && key !== 'status' 
              && key !== 'csrf_token' 
              && key !== 'amount_raised_by_referred_causes'
              && key !== 'free_bonus'
              && key !== 'referred_causes'
              ).map((stat, i) => (
                <div className="stat-card" key={stat}>
                  <span className="label">{keyMap[stat]}</span>
                  <span className="value">$ { numeral(stats[stat]).format('0,0.00') }</span>
                  <div className="pop-over-container">
                    <PopoverHover
                      content={
                        <div style={{
                          maxWidth: '300px', padding: 10, fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left',
                        }}
                        >
                        {KeySummaryTransactionStatusDescriptionsMap[stat]}
                        </div>
                      }
                      header = {null}
                      img = {null}
                      itemToPopover = {
                        ( <i className="material-icons" style={{ margin: '0 5px 0 5px', verticalAlign: '-40%' }} >info</i> )
                      }
                      horizontalPosn = "right"
                    />
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      <div style={{ width: 50 }}>&nbsp;</div>
    </DonationStripWrapper>
  );
};

DonationsStrip.propTypes = {
  causeDetails: PropTypes.object.isRequired,
};

export default DonationsStrip;
