import React, { useState, useEffect } from 'react';
import numeral from 'numeral';
import { BarChart, Bar, Cell, XAxis, Tooltip, ResponsiveContainer, LabelList, Label, Text } from 'recharts';
import styled from 'styled-components';

import styles from './index.css';

const GraphStackedBarWrapper = styled.div`${styles}`;

const GraphStackedBar = ({ data }) => {

  console.log("GraphStackedBar component triggered with data: ", data);

  const WrappedText = styled(Text)`
    white-space: pre-wrap;
    text-align: center;
  `;

  const totalAmounts = (data) => {
    const dataArray = Array.isArray(data) ? data : data?.data || [];
    return dataArray.reduce((acc, entry) => acc + (entry.total || 0), 0);
  };

  const cellColors = ['#cc9400', '#eead00', '#febe11', '#ffc732', '#fed155', '#ffda76', '#ffe399', '#ffecbb', '#fff5dd', '#fed155', '#ffcd47', '#ffc93a', '#ffc62d', '#ffc220', '#febf13', '#ffbb06', '#f8b500', '#ebab00', '#dea200'];

  const chartData = Array.isArray(data) ? data : data.data;

  // Custom label renderer to check the X-Axis 'name' and decide whether to show the label
  const renderCustomLabel = ({ x, y, width, height, value, name, segmentKey }) => {
    //const { x, y, width, height, value, name, segmentKey } = props;
    // Ensure name exists before accessing its properties
    console.log("Custom label triggered with: value, name, segmentKey: ", value, name, segmentKey);
    let label = '';
    
    if (name && name === 'BEFORE TAX' && segmentKey === 'beforeTax' && height > 20) {
      label = "Salary Sacrifice";  // Show 'Salary Sacrifice' only for 'BEFORE TAX' and value > 20
    } else if (name && name === 'AFTER TAX' && segmentKey === 'cashback' && height > 20) {
      label = "Cashback";  // Show 'Cashback' for AFTER TAX
    } else if (name && name === 'AFTER TAX' && segmentKey === 'roundups' && height > 20) {
      label = "Round-Ups";  // Show 'Round-ups' for AFTER TAX
    } else if (name && name === 'AFTER TAX' && segmentKey === 'personal' && height > 20) {
      label = "Personal";  // Show 'Personal' for AFTER TAX
    } else if (name && name === 'AFTER TAX' && segmentKey === 'other' && height > 20) {
      label = "Other";  // Show 'Other' for AFTER TAX 
    }

    // If there's no label to show, return nothing
    if (!label) return null;

    return (
      <text
        x={x + width / 2}
        y={y + height / 2}
        fill="#000"
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {label}
      </text>
    );
  };

 

  const renderTotalLabel = (props) => {
    console.log("renderTotalLabel props:", props);
    const { x, y, width, height, value } = props;
    const thresholdHeight = 14;

    const labelYPosition = y > thresholdHeight ? y - 10 : y + 9;

    return (
      <text
        x={x + width / 2}
        y={labelYPosition}
        fill="#000"
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {numeral(value).format('$0,0.00')}
      </text>
    );
  };

  return (
    <GraphStackedBarWrapper>
      <div className="totals-label-container">
        TOTAL ADDITIONAL CONTRIBUTIONS: ${numeral(totalAmounts(data)).format('0,0.00')}&nbsp;PA
      </div>

      <ResponsiveContainer width="100%" height={150}>
        <BarChart data={chartData} className="bar-chart-holder">
          <XAxis
            dataKey="name"
            interval={0}
          />
          <Tooltip
            formatter={(value) => {
              if (value === 0) {
                return '$0.00';
              } else {
                return numeral(value).format('$0,0.00');
              }
            }}
          />
          {/* Bar for Before Tax */}
          <Bar dataKey="beforeTax" stackId="a">
            {/* Use custom label renderer to pass entry and show custom labels */}
            {
              chartData.map((entry, index) => (
                <Cell key={`cell-beforeTax-${index}`} fill={cellColors[index % cellColors.length]} />
              ))
            }
              <Label 
                position="top"  // Optional, adjust based on your needs
                fill="#000"
                content={({ data }) => renderCustomLabel({ ...data, segmentKey: 'beforeTax' })}
              />
              <LabelList
                dataKey="beforeTax"
                content={(props) => {
                  console.log("LabelList props:", props);
                  // Rest of your logic for rendering labels
                  return renderCustomLabel({ ...props, segmentKey: 'beforeTax' });
                }}
              />
          </Bar>
          {/* Bar for Cashback */}
          <Bar dataKey="cashback" stackId="a">
            {
              chartData.map((entry, index) => (
                <Cell key={`cell-cashback-${index}`} fill={cellColors[(index + 1) % cellColors.length]} />
              ))
            }
            <LabelList
              dataKey="cashback"
              content={(props) => renderCustomLabel({ ...props, segmentKey: 'cashback' })}
            />
          </Bar>
          {/* Bar for Roundups */}
          <Bar dataKey="roundups" stackId="a">
            {
              chartData.map((entry, index) => (
                <Cell key={`cell-roundups-${index}`} fill={cellColors[(index + 2) % cellColors.length]} />
              ))
            }
            <LabelList
              dataKey="roundups"
              content={(props) => renderCustomLabel({ ...props, segmentKey: 'roundups' })}
            />
          </Bar>
          {/* Bar for Personal */}
          <Bar dataKey="personal" stackId="a">
            {
              chartData.map((entry, index) => (
                <Cell key={`cell-personal-${index}`} fill={cellColors[(index + 3) % cellColors.length]} />
              ))
            }
            <LabelList
              dataKey="personal"
              content={(props) => renderCustomLabel({ ...props, segmentKey: 'personal' })}
            />
          </Bar>
          {/* Bar for Other (with Total on Top) */}
          <Bar dataKey="other" stackId="a">
            {
              chartData.map((entry, index) => (
                <Cell key={`cell-other-${index}`} fill={cellColors[(index + 4) % cellColors.length]} />
              ))
            }
            <LabelList
              dataKey="other"
              content={(props) => renderCustomLabel({ ...props, segmentKey: 'other' })}
            />
            <LabelList
              dataKey="total"
              content={renderTotalLabel} 
              //position="top"
              //fill="#000"  // Make total label black
              //dataKey="total"  // Show total on top
              //formatter={(total) => total > 0 ? numeral(total).format('$0,0.00') : ''}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </GraphStackedBarWrapper>
  );
};

export default GraphStackedBar;
