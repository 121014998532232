import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import log from "Utils/logger";
import * as AuthenticationActions from 'Flux/authentication/actions';

const useFetchActivityLogs = () => {
  console.log ("**A useFetchActivityLogs hook triggered")
  const isFetchingActivityLogsRef = useRef(false);
  const hasFetchedActivityLogsRef = useRef(false);
  const dispatch = useDispatch();
  const storedActivityLogs = useSelector(state => state.authentication.activityLogs);

  const fetchActivityLogs = async () => {
    isFetchingActivityLogsRef.current = true;
    try {
      const result = await dispatch(AuthenticationActions.fetchActivityLogs());
      console.log ('**A result of fetching activity logs  is:', result.data.activityLogs)
      const newActivityLogs = result.data.activityLogs;
      if (JSON.stringify(newActivityLogs) !== JSON.stringify(storedActivityLogs)) {
        console.log('DISPATCH SET ACTIVITY LOGS with newActivityLogs: ', newActivityLogs);
        dispatch(AuthenticationActions.setActivityLogs(newActivityLogs));
      }
    } catch (exception) {
      console.log(exception);
      log({
        event: "System-Error",
        source: "Fetch Activity Logs",
        //'shopper_id': authentication? authentication.user.shopper_id : '000',
        info: exception,
        ...exception,
      });
    } finally {
      isFetchingActivityLogsRef.current = false;
      hasFetchedActivityLogsRef.current = true;
    }
  };

  useEffect(() => {
    if (!isFetchingActivityLogsRef) {
      fetchActivityLogs();
    }
  }, []);

  return {  isFetchingActivityLogs: isFetchingActivityLogsRef.current, 
            fetchActivityLogs 
          };
};

export default useFetchActivityLogs;
