import * as TestimonialActionTypes from './constants';

const initialState = [];

const actionsMap = {
  [TestimonialActionTypes.SET_TESTIMONIALS](state, { payload }) {
    return payload;
  },
  [TestimonialActionTypes.REMOVE_TESTIMONIALS]() {
    return [];
  }
}

export default function testimonials(state = initialState, action) {
  const reduceFn = actionsMap[action.type];
  if (!reduceFn) return state;
  return reduceFn(state, action);
}
