import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-grid-system';
import PropTypes from 'prop-types';
import moment from 'moment';
//import { Stepper, Step, StepLabel, StepContent } from '@mui/material'; // Update Material-UI imports
import classNames from 'classnames';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import { isEqual } from 'lodash';

import { isSafari, isIOS, isMobile } from 'react-device-detect';

import ActionButton from 'SharedComponents/action-button';

import * as RoundUpsActions from 'Flux/round-ups/actions';

import useUpdateEnableContributeNow from 'SharedHooks/update-enable-contribute-now';

import AboutRoundUps from './AboutRoundUps';
import SetDefaultAmounts from './SetDefaultAmounts';
import SetBankAccounts from './SetBankAccounts';
import steps from './steps';

import log from "Utils/logger";


import styles from './index.css';

const SetRoundUpsWrapper = styled.section`${styles}`;

const SetRoundUps = () => {
  const dispatch = useDispatch();
  //const app = useSelector(state => state.app);
  //const authentication = useSelector(state => state.authentication);
  const roundups = useSelector(state => state.roundups);

  console.log('*SetRoundUps Index has been triggered with roundups: ', roundups);
  const [activeStepIndex, setActiveStepIndex] = useState(getStartStep());
  //const { showSetRoundUps } = true;
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  //const [hasFetchedRoundUpsLinkedAccounts, setHasFetchedRoundUpsLinkedAccounts] = useState(false);
  //const [fetchingRoundUpsLinkedAccounts, setFetchingRoundUpsLinkedAccounts] = useState(false);
  //const [fetchingRoundUpsUserData, setFetchingRoundUpsUserData] = useState(false);
  //const [hasFetchedRoundUpsShopperData, sethasFetchedRoundUpsShopperData] = useState(false);
  const [syncingShopperDataFromOpenBank, setSyncingShopperDataFromOpenBank] = useState(false);

  const [registeringNewShopperForRoundups, setRegisteringNewShopperForRoundups ] = useState(false);
  //const [requestingConsentLink, setRequestingConsentLink ] = useState(false);
  const requestingConsentLinkRef = useRef(false);
  const [localActiveBankConsentLink, setLocalActiveBankConsentLink] = useState(null);
  const [localRoundups, setLocalRoundups] = useState(roundups);



  console.log('SetRoundUps has been triggered  with localRoundups: ', localRoundups);

  console.log('SetRoundUps has been triggered with activeStepIndex: ', activeStepIndex);

  //const { isFetchingRoundUpsShopperDataRef, hasFetchedRoundUpsShopperDataRef, fetchRoundUpsShopperData } = useFetchRoundUpsShopperData();
  //console.log('*** ** fetchRoundUpsShopperData: TRIGGER FROM SET-ROUNDUPS');

  const { updateEnableContributeNow } = useUpdateEnableContributeNow();

  useEffect(() => {
    console.log('setRoundups Mounted');
    console.log('useEffect in SetRoundups triggered with roundups: ', roundups);
    console.log('useEffect in SetRoundups triggered with localRoundups: ', localRoundups);
    //console.log('useEffect in SetRoundups triggered with isFetchingRoundUpsShopperDataRef : ', isFetchingRoundUpsShopperDataRef);
    //console.log('useEffect in SetRoundups triggered with hasFetchedRoundUpsShopperDataRef : ', hasFetchedRoundUpsShopperDataRef);
      // if (!isFetchingRoundUpsShopperDataRef && !hasFetchedRoundUpsShopperDataRef) {
      //   console.log('*** ** fetchRoundUpsShopperData: TRIGGER FROM USE-EFFECT - SET-ROUNDUPS');
      //   fetchRoundUpsShopperData();
      // }
    return () => {
      console.log('SetRoundUps unmounted');
    } 
  }, []);


  useEffect(() => {
    setLocalRoundups(prevRoundups => ({
      ...prevRoundups,
      links: prevRoundups?.links ?? []
    }));
  }, []);

  // useEffect(() => {
  //   const relevantRoundupsProps = { 
  //     acceptedRoundupsTerms: roundups.acceptedRoundupsTerms,
  //     confirmedContactDetails: roundups.confirmedContactDetails,
  //   };
  
  //   if (JSON.stringify(relevantRoundupsProps) !== JSON.stringify(localRoundups)) {
  //     setLocalRoundups(roundups);
  //   }
  // }, [roundups.acceptedRoundupsTerms, roundups.confirmedContactDetails]); // Add relevant dependencies
  

  // useEffect(() => {
  //   console.log('useEffect in SetRoundups triggered with roundups: ', roundups);
  //   setLocalRoundups(roundups || {});
  // }, [roundups]);

  useEffect(() => {
    console.log('useEffect in SetRoundups triggered with roundups: ', roundups);
    console.log('useEffect in SetRoundups triggered with localRoundups: ', localRoundups);
    if (!isEqual(roundups, localRoundups)) {
      console.log('useEffect in SetRoundups !isEqual setting localRoundps) to roundups');
      setLocalRoundups(roundups || {});
    }
}, [roundups]);

  // useEffect(() => {
  //     console.log('**** useEffect roundups checking new variables')
  //     console.log('**** useEffect roundups.accountsConnectingStatus: ', roundups.accountsConnectingStatus);
  //     console.log('**** useEffect roundups.consentLinkStatus: ', roundups.consentLinkStatus);
  // }, [roundups.accountsConnectingStatus, roundups.consentLinkStatus ]);

  useEffect(() => {

    console.log('UseEffect localRoundups.links has been updated');
    console.log('UseEffect localRoundups.links: ', localRoundups.links);

    const bankConsentLinks = localRoundups.links.filter(link => link.link_type === 'consent' && !link.expired);

    const activeBankConsentLinks = bankConsentLinks.filter(link => {
      const createdMoment = moment(link.created_at, 'YYYY-MM-DD HH:mm:ss');
      const hourAgo = moment().subtract(1, 'hour');
      return createdMoment.isAfter(hourAgo);
    });
    
    const latestActiveBankConsentLink = 
      activeBankConsentLinks ?
      activeBankConsentLinks
      .map(link => ({ ...link, created_at: moment(link.created_at, 'YYYY-MM-DD HH:mm:ss') })) // Convert created_at to moment objects
      .sort((a, b) => b.created_at - a.created_at)[0]
      : null;
    
    console.log('**UseEffect Index bankConsentLinks: ', bankConsentLinks);
    console.log('**UseEffect Index activeBankConsentLinks: ', activeBankConsentLinks);
    console.log('**UseEffect Index latestActiveBankConsentLink: ', latestActiveBankConsentLink);

    setLocalActiveBankConsentLink(latestActiveBankConsentLink);

    if (latestActiveBankConsentLink) {
      const expirationTime = moment(latestActiveBankConsentLink.created_at).add(55, 'minutes');
      const currentTime = moment();
      const timeToExpire = expirationTime.diff(currentTime);
  
      if (timeToExpire > 0) {
        // Schedule an expiration after 5 mins
        const timer = setTimeout(() => {
          console.log('UseEffect localActiveBankConsentLink : ', localActiveBankConsentLink);
          console.log('UseEffect timer has just expired. Set localActiveBankConsentLink to null');
          setLocalActiveBankConsentLink(null);
        }, timeToExpire);
        
        // Cleanup the timer when the component unmounts or when the effect re-runs
        return () => clearTimeout(timer);
      } else {
        console.log('UseEffect timeToExpire is less than 0. Lets expire localActiveBankConsentLink');
        setLocalActiveBankConsentLink(null);
      }
    }

  }, [localRoundups.links]);

  useEffect(() => {
    //useEffect to monitor link updates. Can be deleted once links fully tested.
    console.log('*SetRoundups UseEffect localActiveBankConsentLink has been updated');
    console.log('*SetRoundups UseEffect updated localActiveBankConsentLink: ', localActiveBankConsentLink);
    const createdMoment = localActiveBankConsentLink ? moment(localActiveBankConsentLink.created_at, 'YYYY-MM-DD HH:mm:ss') : null;
    log({
      event: "System-Notification",
      source: "SetRoundups",
      notification: `localActiveBankConsentLink updated with new createdMoment:  ${createdMoment}`,
      error: null,
    });
  }, [localActiveBankConsentLink]);

  
  async function registerNewRoundupsShopper() {
    console.log('registerNewRoundupsShopper triggered');
    setRegisteringNewShopperForRoundups(true);
    try {
      const response = await dispatch(RoundUpsActions.registerNewRoundupsShopper())
      console.log('registerNewRoundupsShopper response: ', response);
      dispatch(RoundUpsActions.setRoundUpsShopperData(response.data.data));
    } catch (exception) {
      console.log('registerNewRoundupsShopper exception: ', exception);
      log({
        event: "System-Error",
        source: "Roundups- registerNewRoundupsShopper",
        //'shopper_id': authentication? authentication.user.shopper_id : '000',
        info: JSON.stringify(exception),
        ...exception,
      });
    } finally {
      setRegisteringNewShopperForRoundups(false);
    }
  }

  async function requestConsentLink() {
    console.log('requestConsentLink triggered');
    //setRequestingConsentLink(true);
    requestingConsentLinkRef.current = true;
    try {
      const response = await dispatch(RoundUpsActions.requestConsentLink());
      console.log('requestConsentLink response: ', response);
      dispatch(RoundUpsActions.setRoundUpsShopperData(response.data.data));
    } catch (exception) {
      console.log('requestConsentLink exception: ', exception);
      log({
        event: "System-Error",
        source: "Roundups- requestConsentLink",
        //'shopper_id': authentication? authentication.user.shopper_id : '000',
        info: JSON.stringify(exception),
        ...exception,
      });
    } finally {
      //setRequestingConsentLink(false);
      requestingConsentLinkRef.current = false;
    }
  }

  async function syncShopperDataFromOpenBank() {
    console.log('syncShopperDataFromOpenBank triggered with localRoundups: ', localRoundups);
    setSyncingShopperDataFromOpenBank(true);

    try {
      const result = await dispatch(RoundUpsActions.syncShopperDataFromOpenBank());
      console.log('syncShopperDataFromOpenBank result: ', result);
      console.log('syncShopperDataFromOpenBank.data.data result: ', result.data.data);
      if (result.data){
        dispatch(RoundUpsActions.setRoundUpsShopperData(result.data.data));
      } else {
        console.log('syncShopperDataFromOpenBank result.data.data is null'); // get rid of else after testing
      }
    } catch (exception) {
      console.log('syncShopperDataFromOpenBank exception: ', exception);
      log({
        event: "System-Error",
        source: "Index RoundUps-syncShopperDataFromOpenBank",
        //'shopper_id': authentication? authentication.user.shopper_id : '000',
        info: JSON.stringify(exception),
        ...exception,
      });
      //handleStepError(exception); turn this on if we want to show error message to user
    } finally {
      setSyncingShopperDataFromOpenBank(false);
      console.log('syncShopperDataFromOpenBank finally completed');
    }
  }

  async function deleteBankConnection() {
    console.log('deleteBankConnection triggered with localRoundups: ', localRoundups);
    //setDeletingBankConnection(true);

    try {
      const result = await dispatch(RoundUpsActions.deleteBankConnection());
      console.log('deleteBankConnection result: ', result);
      console.log('deleteBankConnection.data.data result: ', result.data.data);
      if (result.data){
        dispatch(RoundUpsActions.setRoundUpsShopperData(result.data.data));
      } else {
        console.log('deleteBankConnection result.data.data is null'); // get rid of else after testing
      }
    } catch (exception) {
      console.log('deleteBankConnection exception: ', exception);
      log({
        event: "System-Error",
        source: "Index RoundUps-deleteBankConnection",
        //'shopper_id': authentication? authentication.user.shopper_id : '000',
        info: JSON.stringify(exception),
        ...exception,
      });
      //handleStepError(exception); turn this on if we want to show error message to user
    } finally {
      //setDeletingBankConnection(false);
      updateEnableContributeNow(false);
      console.log('deleteBankConnection finally completed');
    }
  }

  // const whatIsRoundUpsText = (
  //   <div className="ext-popover-text-container"style={{ maxWidth: '800px' }}>
  //     <div className="text-content-container" style={{ margin: '20px'}}>
  //         <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',}} >
  //           <h2 style={{ margin: '0px 0px 5px 5px' }}><i>A Bonus Boost is a building block for your Bonus Boost Power.</i></h2>
  //         </div>
  //         <p> Each ACTIVE Bonus Boost you earn is added to your Bonus Boost Power. This increases the amount you earn on each transaction.</p>
  //     </div>
  //   </div>
  // );

  // const whatDoRoundUpsCost = (
  //   <div className="ext-popover-text-container"style={{ maxWidth: '800px' }}>
  //     <div className="text-content-container" style={{ margin: '20px' }}>
  //         <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',}} >
  //           <h2 style={{ margin: '0px 0px 5px 5px' }}><i>We normanlly let you know via email & sometimes our website when there are opportunities to earn Bonus Boosts and add them to your profile. </i></h2>
  //         </div>
  //           <em style={{ margin: '0px 0px 0px 40px' }}>Keep an eye out for actions you can take to increase your Boost Power</em>
  //     </div>
  //   </div>
  // );


  const handleStepError = (errorMessage) => {
    setHasError(true);
    setErrorMessage(errorMessage);
    console.log('errorMessage in STEP found: ', errorMessage)
    //the following line is commented out to avoid logging errors which are initialised on landing on the page
/*     log({
      event: "System-Error",
      source: "Set-RoundUps-Step-Error",
      'shopper_id': authentication? authentication.user.shopper_id : '000',
      'errorMessage': JSON.stringify(errorMessage),
      'stepIndex': activeStepIndex,
    }); */
  };

  const filteredSteps = steps.filter((step) => {
    if ((isSafari && !isIOS) && step.excludeOnSafariMac) {
      return false;
    }

    if (isMobile && step.excludeOnMobile) {
      return false;
    }

    if (!isMobile && step.excludeOnDesktop) {
      return false;
    }

    return true;
  });

  const activeStep = filteredSteps[activeStepIndex];

  // function getStartStep() {
  //   if ( localRoundups.acceptedRoundupsTerms && localRoundups.confirmedContactDetails) {
  //     return 2;
  //   } else {
  //     return 0;
  //   }
  // }

  function getStartStep() {
    const acceptedTerms = localRoundups?.acceptedRoundupsTerms ?? false;
    const confirmedDetails = localRoundups?.confirmedContactDetails ?? false;
    return acceptedTerms && confirmedDetails ? 2 : 0;
  }


  function onNext() {
    if (activeStepIndex < steps.length - 1) {
      setActiveStepIndex(activeStepIndex + 1);
    }
  }

  function onPrevious() {
    //if (activeStepIndex > 1) {
    if (activeStepIndex > 0) {
      setActiveStepIndex(activeStepIndex - 1);
    }
  }

  function onFinish() {
    console.log('Finished onBoarding');
    //AppActions.finishOnboarding();
  }

    //  if (!isMobile) {
    //    console.log('isMobile', isMobile);
    return (
      <SetRoundUpsWrapper>
        <div>
          <h3 >For example. Buy a coffee for $4.50, round up your spending to $5.00. We place the $0.50 cents in your super account. You can round up to the nearest $1, $2 or $5 on your spend.</h3>
        </div>

        <h2>Round Up Settings</h2>
        {/*<div>
           <BroadcastAbly 
            channelName={"private:shopper."+authentication.user.shopper_id}
            fetchRoundUpsShopperData={fetchRoundUpsShopperData}
          /> 
        </div>*/}
        <Stepper activeStep={activeStepIndex} alternativeLabel className="stepper">
          {filteredSteps.map(({ label }, index) => (
            <Step key={label} className={classNames('step', { active: index <= activeStepIndex })}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div className="stepper-control-and-error-message">
          <div className="stepper-control">
            <div className="button-group">
              <ActionButton 
                text="Back" 
                icon="keyboard_backspace" 
                onClick={onPrevious} 
                disabled={activeStepIndex === 0} 
              />
              <ActionButton 
                text={activeStepIndex + 1 === filteredSteps.length ? 'End' : 'Continue'} 
                disabled={!!errorMessage || activeStepIndex === filteredSteps.length - 1} 
                icon={activeStepIndex + 1 === filteredSteps.length ? null : 'navigate_next'} 
                onClick={activeStepIndex === filteredSteps.length - 1 ? onFinish : onNext}
              />
            </div>
          </div>
          <div className="error-message"><text> &nbsp; {errorMessage}</text></div>
        </div>
        <div className="active-step">
          {
            activeStep.tag === 'terms-and-conditions' ? (
              <AboutRoundUps 
                //app={app}
                //roundups={localRoundups}
                onError={handleStepError}
                //onNext={onNext}
                //registerNewRoundupsShopper={registerNewRoundupsShopper} 
                //registeringNewShopperForRoundups={registeringNewShopperForRoundups}
                //requestConsentLink={requestConsentLink}
                //requestingConsentLink={requestingConsentLink}
                //localActiveBankConsentLink={localActiveBankConsentLink}
                //hasFetchedRoundUpsShopperData={hasFetchedRoundUpsShopperDataRef}
                //syncShopperDataFromOpenBank={syncShopperDataFromOpenBank}

              />
            ) : activeStep.tag === 'connect-bank' ? (
                <SetBankAccounts
                  //app={app} 
                  onError={handleStepError}
                  roundups={localRoundups}
                  //onNext={onNext}
                  registeringNewShopperForRoundups={registeringNewShopperForRoundups}
                  requestConsentLink={requestConsentLink}
                  //requestingConsentLink={requestingConsentLink}
                  requestingConsentLinkRef={requestingConsentLinkRef}
                  localActiveBankConsentLink={localActiveBankConsentLink}
                  //hasFetchedRoundUpsShopperData={hasFetchedRoundUpsShopperDataRef}
                  syncShopperDataFromOpenBank={syncShopperDataFromOpenBank}
                  deleteBankConnection={deleteBankConnection}
                  // deletingBankConnection={deletingBankConnection}
                  // retrievingAccounts={retrievingAccounts}
                />
            ) : activeStep.tag === 'default-amounts-and-limits' ? (
                <SetDefaultAmounts 
                  //app={app} 
                  onError={handleStepError} 
                  //onNext={onNext} 
                  //roundups={localRoundups}
                  registerNewRoundupsShopper={registerNewRoundupsShopper} 
                  registeringNewShopperForRoundups={registeringNewShopperForRoundups}
                  requestConsentLink={requestConsentLink}
                  //requestingConsentLink={requestingConsentLink}
                  requestingConsentLinkRef={requestingConsentLinkRef}
                  localActiveBankConsentLink={localActiveBankConsentLink}
                  //hasFetchedRoundUpsShopperData={hasFetchedRoundUpsShopperDataRef}
                  //syncShopperDataFromOpenBank={syncShopperDataFromOpenBank}
                />
            ) : null  
          }
        </div>
      </SetRoundUpsWrapper>
    );
 // }

};


//SetRoundUps.propTypes = {
  //roundups: PropTypes.object.isRequired,
  //authentication: PropTypes.object.isRequired,
  //app: PropTypes.object.isRequired,
//};

export default SetRoundUps;
