/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Accordion from 'SharedComponents/accordion';
import * as FAQsActionCreators from 'Flux/faqs/actions';

import styles from './index.css';

const FAQWrapper = styled.div`
  ${styles}
`;

const FAQ = ({ faqs, FAQsActions }) => {
  const [isFetchingFAQs, setIsFetchingFAQs] = useState(false);
  const [hasFetchedFAQs, setHasFetchedFAQs] = useState(false);

  async function fetchFAQs() {
    setIsFetchingFAQs(true);

    try {
      const result = await FAQsActions.fetchFAQs();
      FAQsActions.setFAQs(result.data.content);
      setIsFetchingFAQs(false);
      setHasFetchedFAQs(true);
    } catch (exception) {
      console.log('[exception]', exception);
    } finally {
      setIsFetchingFAQs(false);
    }
  }

  useEffect(() => {
    console.log('UseEffect triggered to fetchFAQs in FAQs:', faqs);
    if (!isFetchingFAQs && !hasFetchedFAQs && !faqs.length) {
      fetchFAQs();
    }
  }, [faqs]);

  return (
    <FAQWrapper>
      <div className="content-container" id="faq">
        <h2 className="title">Questions? Here is what we are asked most:</h2>
        {
          faqs.map((faq, i) => {
            let link;
            if ('link' in faq) {
              link = (<a className="link" target="_blank" href={faq.link.link}>{faq.link.text}</a>);
            }
            return (
              <Accordion
                header={faq.question}
                content={
                  <p>
                    {faq.answer}
                  </p>
                }
                key={i}
                link={link} 
              />
            );
          })
        }
      </div>
    </FAQWrapper>
  );
};

FAQ.propTypes = {
  faqs: PropTypes.array.isRequired,
  FAQsActions: PropTypes.object.isRequired,
};

export default connect((state) => ({ faqs: state.faqs }), (dispatch) => ({
  FAQsActions: bindActionCreators(FAQsActionCreators, dispatch),
}))(FAQ);
