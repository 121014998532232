export default ({ theme }) => `
  background-color: transparent;
  display: flex;

  .hover-popover {
    display:flex;
    flex-direction:column;
    /* height: 100%; */
    justify-content: center;
    align-items: inherit;

    p {
      margin: 0px;    
      /* height: 100%; */
      max-width: 255px;
      display: flex;
      align-items: center;
      justify-content center;
    }

    i {
      line-height: normal;
      display: flex;
    }



  }

  @media(max-width: 768px) {
    max-width: 100vw;
  }
 
`;
