export default ({fullscreen, theme}) => `
  .content-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    max-width: ${fullscreen ? '100%' : '700px'};

    .modal-header {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .close-dialog-container {
        display: flex;
        justify-content: flex-end;
        padding: 0px 10px;

        i {
          font-size: 30px;
          color: #000;
          cursor: pointer;
        }
      }

      .logo-container {
        display: flex;
        justify-content: flex-start ;
        align-items: center;
        padding: 10px;

        .img{
          max-height: 74px;
          width: auto;
        }

      }
    }

    .title-component {
      font-size: 30px !important;
      font-weight: bold !important;
      padding: 20px 10px;
    }

    .error-message {
      display: none;
      width: calc(100% - 20px);
      overflow: auto;
      justify-content: flex-start;
      color: red;
    }

    .explainer-component {
      font-size: 14px;
      padding: 10px;
    }

    .content-component {
      padding: 10px;
    }

    .bsc-shopping-cashback-info{
        display: flex;
        justify-content: flex-end;
        align-content: center;
        width: 100%;
        flex-wrap: wrap;
    }
    

    .bsc-highlight{
      background: ${theme.alternateAccentColor};
      padding: 0 3px;
    }

  .pop-over-container{
      display: flex;
      align-items: center;
      padding-left: 5px;
      max-width: 200px;
  }
  
  .pop-over-content{
      padding: 0px 20px 20px 20px;
  }

  .pop-over-header{
      padding: 10px 10px 0px 20px;
      align-items: center;
  }

  i {
      display: flex;
  }

  .more-info-results-explanation-container{
      display: flex;
      max-width: 150px;
      padding: 10px;
  }

  .bsc-switch2 {
      position: relative;
      display: flex;
      width: 80px;
      height: 34px;
  }
      
  .bsc-switch2 input { 
      opacity: 0;
      width: 0;
      height: 0;
  }
      
  .bsc-slider2 {
      position: relative;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #C7C7C7;
      -webkit-transition: .4s;
      transition: .4s;
  }
      
  .bsc-slider2:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
  }
      
  input:checked + .bsc-slider2 {
      background-color: #6CEBC1;
  }
      
  input:focus + .bsc-slider2 {
      box-shadow: 0 0 1px #2196F3;
  }
      
  input:checked + .bsc-slider2:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
  }
      
      /* Rounded sliders */
  .bsc-slider2.round {
      border-radius: 34px;
  }
      
  .bsc-slider2.round:before {
      border-radius: 50%;
  }

  .bsc-wrapper{
      background: #F5F5F5;
      width: 100%;
      margin-bottom: 20px;
  }
  .bsc-container{
      width: 80%;
      margin: 0 auto;
      padding: 10px;
  }
  .bsc-heading,
  .bsc-sub-heading,
  .bsc-info-text,
  .bsc-content-heading{
      text-align: center;
  }
  .bsc-content-heading{
      font-weight: bold;
      font-size: 20px;
      margin: 15px;
  }
  .bsc-buttons-container{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 10px;
  }
  .bsc-button{
      margin: 5px;
  }
  .bsc-section{
      margin: 30px 0px;
      width: 100%;
  }
  .bsc-section-row{
      display: flex;
      justify-content: flex-start;
      margin: 10px 0;
  }
  .bsc-section-row .bsc-label,
  .bsc-section-row .bsc-result{
      display: inline-flex;
      align-items: center;
  }
  .bsc-label{
      width: 35%;
      justify-content: flex-end;
      font-size: larger;
  }
  .bsc-entry{
      display: flex;
      justify-content: space-between;
      width: 50%;
      padding: 0 5px;
  }
  .bsc-section-row .bsc-input{
      width: 100%;
  }
  .bsc-section-row .bsc-select{
      width: 100%;
      margin-left: 10px;
  }
  .bsc-section-row .bsc-input input,
  .bsc-section-row .bsc-select select{
      width: 100%;
      border: solid 1px #d9d9d9 !important;
      font-size: 20px;
      font-weight: 300;
      padding: 10px 15px !important;
      margin: 10px 0; 
      color: #495C63;
      



/*         width: 100%;
      height: 30px;
      padding: 5px;
      box-sizing: border-box;
      border: 1px solid transparent;
      background: #C4C4C4;
      border-radius: 0;
      font-size: 15px; */

  }
  .bsc-section-row .bsc-input input{
/*         width: 100%
      border: solid 1px #d9d9d9 !important;
      font-size: 20px;
      font-weight: 300;
      padding: 10px 15px !important;
      margin: 10px 0; 
      color: #495C63; */
  }
  .bsc-section-row .bsc-input input:focus,
  .bsc-section-row .bsc-select select:focus{
      
      /* padding: 5px; */
      outline: -webkit-focus-ring-color auto 1px;
      /* box-sizing: border-box;
      border-radius: 0;
      outline: 0;
      border: 1px solid transparent; */
  }
  .bsc-section-row .bsc-result{
      min-width: 100px;
      justify-content: flex-end;
      display: inline-flex;
  }
  .bsc-slider{
      width: 100%;
      margin: 0 5px;
      /* direction: rtl; */
      position: relative;
  }
  .bsc-slider input[type=range]   {
      width: 100%;
      background: unset;
  }
  .tickMarks{
      display: flex;
      justify-content: space-between;
      /* position: absolute; */
      top: 18px;
      font-size: 12px;
      max-width: 100%;
      /* margin-left: -5%; */
      /* width: 110%; */
  }
  input[type=range] {
      height: 26px;
      -webkit-appearance: none;
      width: 100%;
      background: #F5F5F5;
      margin: auto;
      height: 100%;
  }
  input[type=range]:focus {
      outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
      width: 100%;
      height: 5px;
      cursor: pointer;
      animate: 0.2s;
      box-shadow: 0px 0px 0px #000000;
      background: #D9D4D4;
      border-radius: 0px;
      border: 0px solid #000000;
  }
  input[type=range]::-webkit-slider-thumb {
      box-shadow: 0px 0px 0px #000000;
      border: 0px solid #D9D4D4;
      height: 20px;
      width: 20px;
      border-radius: 20px;
      background: #FF9820;
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -7.5px;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
      background: #D9D4D4;
  }
  input[type=range]::-moz-range-track {
      width: 100%;
      height: 5px;
      cursor: pointer;
      animate: 0.2s;
      box-shadow: 0px 0px 0px #000000;
      background: #D9D4D4;
      border-radius: 0px;
      border: 0px solid #000000;
  }
  input[type=range]::-moz-range-thumb {
      box-shadow: 0px 0px 0px #000000;
      border: 0px solid #D9D4D4;
      height: 20px;
      width: 20px;
      border-radius: 20px;
      background: #FF9820;
      cursor: pointer;
  }
  input[type=range]::-ms-track {
      width: 100%;
      height: 5px;
      cursor: pointer;
      animate: 0.2s;
      background: transparent;
      border-color: transparent;
      color: transparent;
  }
  input[type=range]::-ms-fill-lower {
      background: #D9D4D4;
      border: 0px solid #000000;
      border-radius: 0px;
      box-shadow: 0px 0px 0px #000000;
  }
  input[type=range]::-ms-fill-upper {
      background: #D9D4D4;
      border: 0px solid #000000;
      border-radius: 0px;
      box-shadow: 0px 0px 0px #000000;
  }
  input[type=range]::-ms-thumb {
      margin-top: 1px;
      box-shadow: 0px 0px 0px #000000;
      border: 0px solid #D9D4D4;
      height: 20px;
      width: 20px;
      border-radius: 20px;
      background: #FF9820;
      cursor: pointer;
  }
  input[type=range]:focus::-ms-fill-lower {
      background: #D9D4D4;
  }
  input[type=range]:focus::-ms-fill-upper {
      background: #D9D4D4;
  }

  .calculator-btn{
      display: flex;
      flex-direction: row;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      color: rgb(255, 255, 255);
      cursor: pointer;
      background-color: rgb(33, 33, 33);
      font-size: 17px;
      box-shadow: rgb(33, 33, 33) 0px 0px 3px;
      border-radius: 3px;
      margin: 0px 5px;
      padding: 10px;
      outline: none;
      border-width: initial;
      border-style: none;
      border-color: initial;
      border-image: initial;
  }
  .flex{
      display: flex;
  }
  .align-items-center{
      align-items: center;
  }
  .justify-center{
      justify-content: center;
  }
  .justify-start{
      justify-content: flex-start;
  }
  .flex-column{
      flex-direction: column;
  }
  .text-center{
      text-align: center;
  }
  .w100{
      width: 100% !important;
  }
  .w50{
      width: 50%!important;
  }
  .ml0{
      margin-left: 0 !important;
  }
  .mt35{
      margin-top: 35px;
  }
  .red-text{
      color: red;
  }
  .btn-desktop-center{
      /* padding: 0!important; */
  }
  .btn-info-text{
      position: absolute;
      left: 50vw;
      bottom: 30%;
  }


  .bsc-center-res-label,
  .bsc-center-res-text{
      font-weight: bold;
      font-size: 22px;
  }
  .bsc-center-res-label{
      text-align: right;
      width: 70%;
      padding: 20px 5px;
      
  }
  .bsc-center-res-text{
      text-align: right;
      width: calc(160px);
  }
  /* // */

  .bsc-bottom-res{
      display: flex;
      justify-content: center;
      width: 100%;
      margin: 0 auto;
  }
  .bsc-bottom-res-label,
  .bsc-bottom-res-text{
      padding: 10px 15px;
      font-weight: bold;
      font-size: 22px;
      width: 50%;
  }
  .bsc-bottom-res-label{
      text-align: right;
  }
  .bsc-bottom-res-text{
      text-align: left;
  }
  
  .bsc-more-contributions-container{

  }

  .bsc-more-contributions-header{
      padding-left: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  
  .bsc-graphs-container{
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;
  }

  .bsc-graph-and-label-container{
      display: flex;
      flex-direction: column;
      background-color: white;
      border-radius: 3px;
      margin: 10px;
      max-width: 100%;
  }

  .bsc-graph{
      display: flex;
      flex-direction: column;
      height: 300px;
  }

  .bsc-graph-stats-summary{
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      justify-content: space-evenly;
  }

  .bsc-graph-result-label-container{
      display: flex;
      padding: 10px 15px;
      justify-content: space-between;
      flex-direction: column;
      flex-wrap: wrap;
  }

  .bsc-graph-contributions-label-container{
      display: flex;
      padding: 0px 15px;
      justify-content: space-between;
      flex-direction: column;
      flex-wrap: wrap;
  }

  .bsc-graph-result-label{
      display: flex;
      /* font-size: 30px; */
      font-weight: bold;
      padding: 0px 10px;
      justify-content: flex-start;
      align-items: center;
  }

  .bsc-graph-contributions-label{
      display: flex;
      font-size: 10px;
      font-weight: bold;
      padding: 0px 10px;
      justify-content: flex-start;
      align-items: center;
      text-transform: uppercase;
  }

  .bsc-graph-result-text-amount{
      display: flex;
      align-items: center;
      font-size: 36px;
      padding: 0px 10px;
      font-weight: bold;
      justify-content: flex-start;
  }

  .bsc-graph-contributions-text-amount{
      display: flex;
      align-items: center;
      font-size: 20px;
      padding: 0px 10px;
      font-weight: bold;
      justify-content: flex-start;
  }
  
  .bsc-label-on-graph{
      display: flex;
      flex-direction: column;
  }

  .bsc-graph-more-info{
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      background-color: gainsboro;
  }



  .bsc-disclaimer-assumption-limitation{
      padding: 2%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

  }
  .bsc-disclaimer-assumption-limitation-title{
      font-weight: bold;
  }

  .bsc-disclaimer-assumption-limitation p{
      font-weight: bold;
  }
  .calc-btn-sec{
      position: relative;
  }
  .input-change-note{
      position: absolute;
  }
  /* /////////////// */
  .tooltip-container{
      position: relative;
      display: flex;
  }
  

  .tooltip-icon{
      width: 20px;
      height: 20px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: #000;
      color: #fff;
      border-radius: 50%;
      font-size: 18px;
      margin: 0 5px;
  }
  .tooltip{
      opacity: 0;
      display: none;
      position: absolute;
      /* top: -2rem; */
      /* white-space: nowrap; */
      left: 50%;
      transform: translateX(-50%);
      transition: opacity 0.25s, top 0.25s;
      z-index: 1000;
      min-width: 250px;
  }
  
  .tooltip .content{
      background: #D9D4D4;
      padding: 0.5em 0.75em;
      border-radius: 0.25rem;
      text-align: center;
  }
  
  .tooltip::after{
      content:"";
      position: absolute;
      background: #D9D4D4;
      width: 10px;
      height: 10px;
      transform: translate(-50%, 50%) rotate(45deg);
      left: 50%;
      top: -10px;
  }
  .tooltip-icon:hover+.tooltip{
      opacity: 1;
      top: 30px;
      display: block;
  }
  /* ////// */

  /* input append/prepend group styles */
  .append-input-group,
  .prepend-input-group{
      display: flex;
  }
  .append-input-group input{
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-right: none !important;
      padding-right: 0 !important;
  }
  .prepend-input-group input{
      border-top-left-radius: 0!important;
      border-bottom-left-radius: 0!important;
      border-left: none !important;
      padding-left: 0 !important;
  }
  .append-input-group span,
  .prepend-input-group span{
      height: 100%;
      /* background-color: #fff; */
      color: #495C63;
      padding: 0px 10px;
      border: 1px solid transparent;
      border-radius: 0px;
      font-size: 20px;
      font-weight: 400;
      text-align: center;
      /* padding: 5px 8px; */
      box-sizing: border-box;
      display: flex;
      align-items: center;
      width: 20px;
      justify-content: center;
  }
  .append-input-group span{
      border-left: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
  }
  .prepend-input-group span{
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
  }
  @media screen and (max-width:1000px){
      .bsc-label {
          font-size: large;
      }
      .btn-info-text{
          position: absolute;
          left: 65%;
          bottom: 35%;
      }
      .tickMarks{
          font-size: 10px;
      }
  }




  @media screen and (max-width: 786px){

      .btn-info-text{
          position: absolute;
          left: 70%;
          bottom: 5%;
      }

      .bsc-slider{
          margin-bottom: 25px;
      }
      .bsc-section-row {
          flex-direction: column;
      }
      .bsc-label {
          width: 100%;
          justify-content: center;
          font-size: 15px;
      }
      .bsc-entry {
          display: flex;
          justify-content: space-between;
          width: 100%;
          padding:5px 0;
      }
      .bsc-section-row .bsc-result {
          min-width: 150px;
          justify-content: center;
          width: 100%;
      }
      .bsc-section-1 .bsc-section-row,
      .bsc-section-2 .bsc-section-row,
      .bsc-section-3 .bsc-section-row{
          width: 100%;
          border-bottom: .5px solid #c7c7c7;
          padding: 8px 0;
      }
      .bsc-section-2 .bsc-input,
      .bsc-section-2 .bsc-select,
      .bsc-section-3 .bsc-input,
      .bsc-section-3 .bsc-select{
          width: 100%;
      }
      .btn-desktop-center{
          width: 100% !important;
          flex-direction: column;
          justify-content: center;
      }
      .bsc-bottom-res{
          border-bottom: .5px solid #c7c7c7;
      }
      .bsc-bottom-res-label,
      .bsc-bottom-res-text{
          width: 100%;
          text-align: center;
          padding: 10px 0;
      }
      .tickMarks{
          font-size: 10px;
      }
  }



  }
`;


