import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import log from "Utils/logger";

//import CONFIG from "Config";

import * as ShoppingActions from "Flux/shopping/actions";

const useFetchStoreTags = () => {
  console.log ('*** ** fetchStoreTags: hook triggered')
  const isFetchingStoreTagsRef = useRef(false);
  const hasFetchedStoreTagsRef = useRef(false);
  const dispatch = useDispatch();
  const storedStoreTags = useSelector(state => state.shopping.tags);

  
  const fetchStoreTags = async () => {
    console.log('*** ** fetchStoreTags: fetchStoreTags function triggered');
    isFetchingStoreTagsRef.current = true;
    try {
      const result = await dispatch(ShoppingActions.fetchStoreTags());

      const newStoreTags = result.data.data;
      console.log('*** ** fetchStoreTags: storedStoreTags: ', storedStoreTags)
      console.log ('*** ** fetchStoreTags: newStoreTags: ', newStoreTags);

      if (!isEqual(storedStoreTags, newStoreTags)) {
        console.log('*** ** fetchStoreTags: newStoreTags is different from storedStoreTags');
        dispatch(ShoppingActions.addStoreTags(newStoreTags));
        console.log('*** ** fetchStoreTags: DISPATCH - ADD_STORE_TAGS: ', newStoreTags);
      }


    } catch (exception) {
      console.log(exception);
      log({
        event: "System-Error",
        source: "Fetch StoreTags",
        info: exception,
        ...exception,
      });
    } finally {
      isFetchingStoreTagsRef.current = false;
      hasFetchedStoreTagsRef.current = true;
      console.log('*** ** fetchStoreTags: setting TRUE hasFetchedStoreTagsRef: ' , hasFetchedStoreTagsRef);
    }
  };

  useEffect(() => {
    console.log('*** ** fetchStoreTags: useEffect to initialise triggered');
    console.log('*** ** fetchStoreTags: isFetchingStoreTagsRef: ' , isFetchingStoreTagsRef);
    console.log('*** ** fetchStoreTags: hasFetchedStoreTagsRef: ' , hasFetchedStoreTagsRef);
    
    if ( !storedStoreTags?.length
         &&  !isFetchingStoreTagsRef.current 
         && !hasFetchedStoreTagsRef.current) {
      console.log('*** ** fetchStoreTags: isFetchingStoreTagsRef.current: ' , isFetchingStoreTagsRef.current);
      console.log('*** ** fetchStoreTags: hasFetchedStoreTagsRef.current: ' , hasFetchedStoreTagsRef.current);
      console.log('*** ** fetchStoreTags: about to call fetchStoreTags');
      fetchStoreTags();
    }
  }, []);

  return { fetchStoreTags };
};

export default useFetchStoreTags;
