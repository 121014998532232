import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Spinner from 'react-spinner-material';
import { BarLoader } from 'react-spinners';

import styles from './index.css';

const ToggleSwitchWrapper = styled.div`${styles}`;

const ToggleSwitch = ({ isChecked, onToggle, updating }) => {
  console.log ('ToggleSwitch activated with isChecked: ', isChecked, 'onToggle: ', onToggle, 'updating: ', updating);
  
/*   const handleToggle = async () => {
    if (updating) return; // Prevent multiple clicks while updating
    onToggle(!isChecked);
  }; */


    const handleToggle = () => {
      if (updating) return;   //possibly add error message here?
      onToggle(!isChecked);
    };
  

  return (
    <ToggleSwitchWrapper>
      <div className="toggle-switch-container">
        <div className="toggle-switch-wrapper">
          <label className={`toggle-switch`}>
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleToggle}
            />
            <span className="slider"></span>
          </label>
          <div className="toggle-switch-updating">
            {updating && <BarLoader 
                          label='Hallo...'
                          height={4} 
                          width={50} 
                          /* sizeUnit={"px"} */
                          loading={true}
                          />}
{/*             <div className="toggle-switch-updating-text">
            {updating ? 'Updating...' : ''}
            </div> */}
          </div>
        </div>
      </div>
    </ToggleSwitchWrapper>
  );
};

ToggleSwitch.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  updating: PropTypes.bool.isRequired, // Added 'updating' prop
};

export default ToggleSwitch;


