export default ({fullscreen}) => `
  .content-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    max-width: ${fullscreen ? '100%' : '472px'};

    .modal-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .close-dialog-container {
        display: flex;
        justify-content: flex-end;
        padding: 0px 10px;

        i {
          font-size: 30px;
          color: #000;
          cursor: pointer;
        }
      }

      .logo-container {
        display: flex;
        justify-content: flex-start ;
        align-items: center;
        padding: 10px;

        .img{
          max-height: 74px;
          width: auto;
        }

      }
    }

    .title-component {
      font-size: 30px !important;
      font-weight: bold !important;
      padding: 20px 10px;
    }

    .error-message {
      display: flex;
      width: calc(100% - 20px);
      overflow: auto;
      justify-content: flex-start;
      color: red;
    }

    .explainer-component {
      font-size: 14px;
      padding: 10px;
    }

    .content-component {
      padding: 10px;
    }

  }
`;


