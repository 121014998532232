import React from 'react';
import styled from 'styled-components';

import styles from './shop.css';

const ShopWrapper = styled.button`${styles}`;

const Shop = ({ shop, onGoToShop }) => (
  <ShopWrapper shop={shop} onClick={() => onGoToShop(`${encodeURIComponent(shop.merchant_name)}`)}>
    {/* <div className="header">
      <span>{shop.merchant_name}</span>
    </div> */}
    <div className="image-container" alt={shop.merchant_name} />
    {/* <div className="footer">
      {shop.rate.slice(6)}
    </div> */}
  </ShopWrapper>
);

export default Shop;
