export default ({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
    .content {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      max-width: 1366px;
      flex-direction: column;
      width: calc(100% - 40px);
      padding: 20px 20px 0;

    }

    .put-recommender-here {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    .title {
      text-align: center;
      // margin: 30px 0px;
      width: 100%;
      font-size: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media(max-width: 768px) {
        font-size: 25px;
      }
    }
    
  `;