//import { hot } from 'react-hot-loader/root';
import React from 'react';
import { Provider } from 'react-redux';
//import { ConnectedRouter } from 'connected-react-router';
import { BrowserRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import Routes from './routes';

const App = ({ store }) => (
<div>
  {console.log('App entry point triggered with store:', store)}
    <Provider store={store}>
      <BrowserRouter >
        <Routes />
      </BrowserRouter>
      <div>
        <Toaster
        position="top-left"
        containerClassName="toaster" 
        toastOptions={{
          className: 'toast',
          duration: 4000,
          style: {
            'box-shadow': '0px 0px 20px 5px rgba(0, 0, 0, 0.7)',
            'font-weight': 'bold',
            'minWidth': '250px',
            'padding': '26px'
          }
        }}    
        />
      </div>
    </Provider>
  </div>
);

export default App;
