import React, { Component } from 'react';
import styled from 'styled-components';

import SignUpForm from 'SharedComponents/sign-up-form';
import styles from './index.css';


const NotFoundWrapper = styled.div`${styles}`;

export default class NotFound extends Component {
  render() {
    return (
      <NotFoundWrapper>
        <div className="sign-in-form-container">
          <SignUpForm
            showEmailLogin
            showSocialLogin
            header={(
              <div style={{
                width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start',
              }}
              >
                <h1>Sign Up</h1>
                <span>To boost your super for free</span>
              </div>
						)}
          />
        </div>
      </NotFoundWrapper>
    );
  }
}
