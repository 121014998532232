export const SET_ROUNDUPS_SHOPPER_DATA = 'SET_ROUNDUPS_SHOPPER_DATA';
export const UNSET_ROUNDUPS_SHOPPER_DATA = 'UNSET_ROUNDUPS_SHOPPER_DATA';
export const SET_ACCEPTED_ROUNDUPS_TERMS = 'SET_ACCEPTED_ROUNDUPS_TERMS';
export const SET_CONFIRMED_CONTACT_DETAILS = 'SET_CONFIRMED_CONTACT_DETAILS';
export const SET_ROUNDUPS_ONBOARDING_COMPLETED = 'SET_ROUNDUPS_ONBOARDING_COMPLETED';
export const SET_ROUNDUPS_CONSENT_STATUS = 'SET_ROUNDUPS_CONSENT_STATUS';
export const SET_REGISTERED_FOR_ROUNDUPS = 'SET_REGISTERED_FOR_ROUNDUPS';
export const SET_REGISTERED_FOR_CONTRIBUTE_NOW = 'SET_REGISTERED_FOR_CONTRIBUTE_NOW';
export const SET_ROUNDUPS_LINKED_ACCOUNTS = 'SET_ROUNDUPS_LINKED_ACCOUNTS';
export const SET_ROUNDUP_AMOUNT = 'SET_ROUNDUP_AMOUNT';
export const SET_WEEKLY_LIMIT = 'SET_WEEKLY_LIMIT';
export const SET_CONTRIBUTE_NOW_AMOUNT = 'SET_CONTRIBUTE_NOW_AMOUNT';
export const SET_ACCEPTED_CONTRIBUTE_NOW_TERMS = 'SET_ACCEPTED_CONTRIBUTE_NOW_TERMS';
export const SET_ENABLED_CONTRIBUTE_NOW = 'SET_ENABLED_CONTRIBUTE_NOW';
export const SET_CONTRIBUTE_NOW_CONTINUE_NOTIFICATION = 'SET_CONTRIBUTE_NOW_CONTINUE_NOTIFICATION';
export const SET_IS_FETCHING_ROUNDUPS_SHOPPER_DATA = 'SET_IS_FETCHING_ROUNDUPS_SHOPPER_DATA';
