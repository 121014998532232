import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import styles from './index.css.js';

const MenuWrapper = styled.div`${styles}`;

const Menu = ({ items, onCloseDialog }) => {
  return (
    <MenuWrapper>
      {
        items.map((item) => (
          <button
            className="menu-item"
            key={item.label}
            onClick={() => {
              item.onClick();
              if (onCloseDialog) {
                onCloseDialog();
              }
            }}
          >
            {item.label}
          </button>
        ))
      }
    </MenuWrapper>
  );
}

Menu.propTypes = {
  items: PropTypes.array.isRequired,
  onCloseDialog: PropTypes.func,
}

export default Menu;
