import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Formsy from 'formsy-react';
import axios from 'axios';
import qs from 'qs';

import MyInput, { isRequired } from 'SharedComponents/text-input';
import ActionButton from 'SharedComponents/action-button';

import CONFIG from 'Config';

@connect((state) => ({
	authentication: state.authentication
}), (dispatch) => ({

}))
export default class ContactInfoForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isFormValid: false,
			isSubmitting: false,

			error: false,
			message: '',

			form: {
				current_password: '',
				new_password: '',
				new_password_confirmation: '',
			}
		}

		this.onChange = this.onChange.bind(this);
		this.onValid = this.onValid.bind(this);
		this.onInvalid = this.onInvalid.bind(this);
		this.onValidSubmit = this.onValidSubmit.bind(this);
		this.onInvalidSubmit = this.onInvalidSubmit.bind(this);
		this.updateShopperPassword = this.updateShopperPassword.bind(this);
	}

	onChange(event) {
    this.setState({ form: Object.assign({}, this.state.form, { [event.target.name]: event.target.value }) });
  }

	onValid() {
		this.setState({ isFormValid: true });
	}

	onInvalid() {
		this.setState({ isFormValid: false });
	}

	onValidSubmit() {
		this.updateShopperPassword()
	}

	onInvalidSubmit() {
		// TODO: show appropriate error messages
		console.log('onInvalidSubmit');
	}

	async updateShopperPassword() {
		const { user } = this.props.authentication;

		this.setState({ isSubmitting: true });

		const { current_password, new_password } = this.state.form;

		try {
			const updateShopperPasswordResult = await axios({
				url: `${CONFIG.apiV3Url}/passwords/change-password`,
				method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Bearer ' + window.localStorage.getItem('auth_token')
                },
                data: qs.stringify({
                    'current_password': current_password,
                    'new_password': new_password
                }),
			});

			if (updateShopperPasswordResult.data.status) {
				this.setState({ error: false, message: 'Password updated successfully' });
			} else {
				this.setState({ error: true, message: updateShopperPasswordResult.data.message });
			}
		} catch(exception) {
			// console.log(JSON.stringify(exception, null, '\t'));
			this.setState({ error: true, message: exception.response.data.message || 'Oops! Something went wrong' });
		} finally {
			this.setState({ isSubmitting: false });

			setTimeout(() => {
				this.setState({ error: false, 'message': '' });
			}, 3000);

			// // TODO: implement fetchShopper on actions
			// this.props.fetchShopper(user.shopper_id);
		}

	}

	render() {
		const { isSubmitting } = this.state;

		return (
			<Formsy ref={(form) => this.contactInfoForm = form} style={{ width: 'calc(100% - 20px)', padding: 10 }} onValidSubmit={this.onValidSubmit} onInvalidSubmit={this.onInvalidSubmit} onValid={this.onValid} onInvalid={this.onInvalid}>
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<div style={{ width: '100%', margin: '20px 0 0', display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
						<p style={{ fontSize: 16, margin: '7px 0 0', color: '#303031', textAlign: 'left', }}><b style={{ fontWeight: 'bold' }}>Password & Authentication – </b>Update your password.</p>
						<hr style={{ border: 'solid 1px #c5c5c5', margin: '10px 0', width: '100%' }} />
					</div>
					{
						!this.state.isFormValid && this.state.onInvalidSubmit ? (
							<div style={{ width: 'calc(100% - 18px)', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10, backgroundColor: '#ff007d', color: '#fff' }}>
								Some fields are invalid. Please complete them then resubmit the form
							</div>
						) : null
					}
					<MyInput
						name="current_password"
						type="password"
						validations={{ isExisty: true }}
						validationErrors={{ isExisty: 'this field is required', isDefaultRequiredValue: 'this field is required' }}
						required
						value={this.state.form.oldPassword}
						onChange={this.onChange}
						placeholder="type your current password"
						label={<div><span>Current Password</span></div>}
					/>
					<MyInput
						name="new_password"
						type="password"
						validations={{ isExisty: true, minLength: 8 }}
						validationErrors={{ isExisty: 'this field is required', isDefaultRequiredValue: 'this field is required', minLength: 'password is too short' }}
						required
						value={this.state.form.new_password}
						onChange={this.onChange}
						placeholder="type your new password"
						label={<div><span>New Password</span><small style={{ fontSize: 'smaller' }}>&nbsp;(8 characters mimimum)</small></div>}
					/>
					<MyInput
						name="new_password_confirmation"
						type="password"
						validations={{ isExisty: true, minLength: 8, equalsField: 'new_password' }}
						validationErrors={{ isExisty: 'this field is required', isDefaultRequiredValue: 'this field is required', minLength: 'password is too short', equalsField: 'does not match new password' }}
						required
						value={this.state.form.new_password_confirmation}
						onChange={this.onChange}
						placeholder="confirm new password"
						label={<div><span>Confirm New Password</span><small style={{ fontSize: 'smaller' }}>&nbsp;(8 characters mimimum)</small></div>}
					/>
				<div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
						<ActionButton type="submit" text={isSubmitting ? 'Updating Password' : 'Update Password'} large={true} disabled={isSubmitting} loading={isSubmitting} active={!isSubmitting} />
						{
							this.state.message ? (
								<span style={{ color: this.state.error ? '#ff007d' : '##03031', fontSize: 20, marginLeft: 20 }}>{this.state.message}</span>
							) : null
						}
					</div>
				</div>
			</Formsy>
		)
	}
}
