import React from 'react';
import styled from 'styled-components';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import SuperCalculator from 'SharedComponents/super-calculator';
import BonusBoostRecommendation from 'SharedComponents/bonus-boost-recommendation';
import Footer from 'SharedComponents/footer';

import useFetchShopperBoostProfile from 'SharedHooks/fetch-shopper-boost-profile';
import useFetchRoundUpsShopperData from 'SharedHooks/fetch-roundups-shopper-data';
import useFetchShopperProfile from 'SharedHooks/fetch-shopper-profile';

import styles from './index.css.js';

const CalculatorsWrapper = styled.div`${styles}`;

const Calculators = () => {

  const { fetchShopperBoostProfile } = useFetchShopperBoostProfile();
  const { fetchRoundUpsShopperData } = useFetchRoundUpsShopperData();
  const { fetchShopperProfile } = useFetchShopperProfile();

  return (
    <CalculatorsWrapper>
      <Helmet>
        <title>Superannuation Calculator</title>
      </Helmet>
      <div className="content">
        <h2 className="title">Superannuation Calculators</h2>
        <div className="put-recommender-here">
          <BonusBoostRecommendation specifiedBoost="SuperCalculatorBonusBoost5%" />
        </div>
        <SuperCalculator />
      </div>
      <Footer />
    </CalculatorsWrapper>
  );
};

export default Calculators;
