import React, { useState, useRef, useEffect } from 'react';
import numeral from 'numeral';
import moment from 'moment';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-grid-system';
import Formsy, { withFormsy } from 'formsy-react';
import { isEqual } from 'lodash';

import ActionButton from 'SharedComponents/action-button';
import ModalDialogV3 from 'SharedComponents/modal-dialog-v3';
import ContactInfoForm from 'SharedComponents/contact-info-form';
import TextInput from 'SharedComponents/text-input';
import PopoverHover from 'SharedComponents/popover-hover';

import * as RoundUpsActions from 'Flux/round-ups/actions';
import useCheckPromoCode from 'SharedHooks/check-promo-code';
import useActivatePromoCode from 'SharedHooks/activate-promo-code';
import useFetchActivePromoCodes from 'SharedHooks/fetch-shopper-promo-codes';

import styles from './AboutRoundUps.css';
import log from 'Utils/logger';

// Define default format for numbers in numeral
numeral.defaultFormat('0,0.00');

// Set default format for nulls
numeral.nullFormat('0.00');
numeral.zeroFormat('0.00');

const AboutRoundUpsWrapper = styled.div`${styles}`;

const TextInputWithFormsy = withFormsy(TextInput);

const AboutRoundUps = ({ onError,
                         //roundups
                        }) => {

  const roundups = useSelector(state => state.roundups);
  
  console.log ('*Set AboutRoundUps triggered with roundups: ', roundups);
  
  const dispatch = useDispatch();
  
  const authentication = useSelector(state => state.authentication);

  const [localRoundups , setLocalRoundups] = useState(roundups);
  // const [localRoundups, setLocalRoundups] = useState({
  //   acceptedRoundupsTerms: true,
  //   confirmedContactDetails: true,
  // });
  

  const [showModalDialogV3, setShowModalDialogV3] = useState(false);
  const [localCompleteContactDetails, setLocalCompleteContactDetails] = useState(true);
  const [isPromoCodeValid, setIsPromoCodeValid] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [checkCode, setCheckCode] = useState("");
  const [activateOnClick, setActivateOnClick] = useState(false);
  const [roundUpCharge, setRoundUpCharge] = useState(4.40);
  const [validationError, setValidationError] = useState('');
  const [activePromoCode, setActivePromoCode] = useState({});

  const [updatingCompleteContactDetails, setUpdatingCompleteContactDetails] = useState(false);
  const [updatingAcceptedRoundUpTerms, setUpdatingAcceptedRoundUpTerms] = useState(false);
  
  const { fetchActivePromoCodes, fetchActivePromoCodesResponseRoundUps, isFetchingActivePromoCodesRef } = useFetchActivePromoCodes();
  const { checkPromoCode, checkCodeResponse, isCheckingPromoCode } = useCheckPromoCode(checkCode, 'roundup', promoCode);
  const { activatePromoCode, activateCodeResponse, isActivatingPromoCode } = useActivatePromoCode(checkCode, 'roundup', isPromoCodeValid, activateOnClick);

  const debounceTimeoutRef = useRef(null);
  const formRef = useRef(null);

  useEffect(() => {
    console.log('AboutRoundUps useEffect - mounted');
    return (() => {
      console.log('AboutRoundUps useEffect - unmounted');
    });
  }, []);

  // useEffect(() => {
  //   setLocalRoundups(roundups);
  // }, [roundups]);

    // Use the usual effect to update localRoundups from roundups
  useEffect(() => {
    if (!isEqual(roundups, localRoundups)) {
      console.log('AboutRoundUps useEffect - roundups changed - updating localRoundups to roundups: ', roundups);
      setLocalRoundups(roundups || {});
    }
  }, [roundups]);
  
  useEffect(() => {
    const {
      shopper_fname,
      shopper_lname,
      shopper_email,
      shopper_phone
    } = authentication.user;

    const areDetailsComplete =
      shopper_fname && shopper_lname && shopper_email && shopper_phone;

    setLocalCompleteContactDetails(areDetailsComplete);
  }, [authentication.user]);

  useEffect(() => {
      if (!localRoundups.acceptedRoundupsTerms) {
        const errorMessage1 = "Please accept the Terms & Conditions (including charges) to continue";
        onError(errorMessage1);
      } else if (!localCompleteContactDetails) {
        const errorMessage2 = "Please complete all contact details to continue";
        onError(errorMessage2);
      } else if (!localRoundups.confirmedContactDetails) {
        const errorMessage3 = "Please confirm your details are correct to continue";
        onError(errorMessage3);
      } else {
        onError("");
      }
  }, [localRoundups, localRoundups.confirmedContactDetails, localCompleteContactDetails, localRoundups.acceptedRoundupsTerms]);




  async function updateAcceptedRoundUpTerms() {
    console.log('updateAcceptedRoundUpTerms triggered with !localRoundups.acceptedRoundupsTerms: ', !localRoundups.acceptedRoundupsTerms);
    setUpdatingAcceptedRoundUpTerms(true);

    try {
      const result = await dispatch(RoundUpsActions.updateAcceptedRoundUpsTerms(!localRoundups.acceptedRoundupsTerms));
      console.log('updateAcceptedRoundUpsTerms using result: ', result);
      dispatch(RoundUpsActions.setAcceptedRoundUpsTerms( result.data.data.acceptedRoundupsTerms ));
    } catch (exception) {
      console.log('updateAcceptedRoundUpTerms exception: ', exception);
      log({
        event: "System-Error",
        'shopper_id': authentication? authentication.user.shopper_id : 'Not Signed In',
        source: "AboutRoundUps- UpdateAcceptedRoundUpTerms",
        info: exception,
        ...exception,
      });
      onError("Error - please try again");
    } finally {
      setUpdatingAcceptedRoundUpTerms(false);
    }
  }

  async function updateConfirmedContactDetails() {
    console.log('updateConfirmedContactDetails triggered with !localRoundups.confirmedContactDetails: ', !localRoundups.confirmedContactDetails);
    setUpdatingCompleteContactDetails(true);

    try {
      const result = await dispatch(RoundUpsActions.updateConfirmedContactDetails(!localRoundups.confirmedContactDetails));
      console.log('updateConfirmedContactDetails result: ', result);
      dispatch(RoundUpsActions.setConfirmedContactDetails(result.data.data.confirmedContactDetails));
    } catch (exception) {
      console.log('updateConfirmedContactDetails exception: ', exception);
      log({
        event: "System-Error",
        source: "AboutRoundUps-UpdateConfirmedContactDetails",
        'shopper_id': authentication? authentication.user.shopper_id : '000',
        info: exception,
        ...exception,
      });
      onError("Error - Please try again");
    } finally {
      setUpdatingCompleteContactDetails(false);
      console.log('updateConfirmedContactDetails finally triggered');
    }
  }

  const toggleConfirmedContactDetails = () => {
    console.log('toggleConfirmedContactDetails triggered');
    updateConfirmedContactDetails();
  };

  const toggleAcceptedRoundUpTerms = () => {
    console.log('toggleAcceptedRoundUpTerms triggered');
    console.log('toggleAcceptedROundUpTerms localRoundups.acceptedRoundupsTerms: ', localRoundups.acceptedRoundupsTerms);
    console.log('toggleAcceptedROundUpTerms !localRoundups.acceptedRoundupsTerms: ', !localRoundups.acceptedRoundupsTerms);
    updateAcceptedRoundUpTerms();
  };

  const onChangePromoCode2 = (event) => {
    const newPromoCode = event.target.value;
    console.log('1. onChangePromoCode2 triggered with newPromoCode: ', newPromoCode);

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    
    debounceTimeoutRef.current = setTimeout(() => {
      setCheckCode(newPromoCode);
      if (newPromoCode.length < 2) {
      setIsPromoCodeValid(false);
      setValidationError('');
      }

    }, 700);
  };


  useEffect(() => {
    console.log('6.0 useEffect checkCodeResponse - promo response triggered with checkCodeResponse: ', checkCodeResponse);
      if (!promoCode && checkCodeResponse?.valid && checkCodeResponse?.promo_code?.active && !checkCodeResponse?.promo_code?.shopper) {
        console.log('6.1 checkCodeResponse VALID PROMO CODE FOUND');
        setIsPromoCodeValid(true);
        setValidationError('VALID');
      } else {
        console.log('6.1 checkCodeResponse VALID PROMO CODE NOT FOUND');
        setTimeout(() => {
          console.log('6.2 checkCodeResponse VALID PROMO CODE NOT FOUND - setting isPromoCodeValid to false');
          setIsPromoCodeValid(false);
          setValidationError('Invalid promo code');
        }, 400); // Delay setting the validation error by 500ms
      }
  }, [checkCodeResponse]);

  useEffect(() => {
    console.log('7.0 useEffect isCheckingPromoCode triggered with status: ', isCheckingPromoCode);
    if (isCheckingPromoCode) {
      setValidationError('checking...');
    }
  }, [isCheckingPromoCode]);

  useEffect(() => {
    console.log('8.0 useEffect fetchActivePromoCodesResponseRoundUps triggered with fetchActivePromoCodesResponseRoundUps: ', fetchActivePromoCodesResponseRoundUps);
    console.log('8.02 useEffect activateCodeResponse triggered with activateCodeResponse: ', activateCodeResponse);
    if (fetchActivePromoCodesResponseRoundUps && fetchActivePromoCodesResponseRoundUps.active && fetchActivePromoCodesResponseRoundUps.shopper.active) {
      console.log('8.1 useEffect fetchActivePromoCodesResponseRoundUps triggered with fetchActivePromoCodesResponseRoundUps: ', fetchActivePromoCodesResponseRoundUps);
      setActivePromoCode(fetchActivePromoCodesResponseRoundUps);
      setPromoCode(fetchActivePromoCodesResponseRoundUps.code);
      console.log('*Set - Promocode Updated after fetch');
    }
  },  [fetchActivePromoCodesResponseRoundUps ]);

  useEffect(() => {
    console.log('8.2 useEffect activateCodeResponse triggered with activateCodeResponse: ', activateCodeResponse);
    if (activateCodeResponse?.active 
      && activateCodeResponse?.shopper?.active
      ) {
      console.log('8.3 useEffect activateCodeResponse valid code triggered with activateCodeResponse: ', activateCodeResponse);
      setActivePromoCode(activateCodeResponse);
      setPromoCode(activateCodeResponse.code);
      setValidationError('');
    } else if (activateCodeResponse?.valid === false || activateCodeResponse?.shopper.active === null) {
        console.log('8.4 useEffect activateCodeResponse code not valid activateCodeResponse: ', activateCodeResponse);
        setValidationError('Activation Error - try another code');
    }
  }, [activateCodeResponse]);


  const handleActivatePromoCode = () => {
    if (isPromoCodeValid) {
      setActivateOnClick(true);
    }
  };

  useEffect(() => {
    if (activateOnClick) {
      activatePromoCode();
      setActivateOnClick(false);
    }
  }, [activateOnClick]);

  
  const checkForIsPromoCodeValid = () => {
    return isPromoCodeValid;
  };


  return (
    <AboutRoundUpsWrapper>
     <div>
      <Container fluid className="links-container" style={{ margin: 0, padding: 0, width: '100%' }}>
        <Row style={{ margin: 0, padding: 0, width: '100%' }}>
          <Col xl={6} lg={6} md={6} sm={12} xs={12} className="links-group" style={{ margin: 0, padding: 0 }}>

          <div className="acceptance-card">
            <h2 className="title">Terms & Conditions (Including Charges)</h2>
            
                <div className="promo-code-entry-and-validate-and-activate">
                  <div className="promo-code-entry-and-validate">
                    <div className="promo-code-entry">
                      <Formsy
                        ref={formRef} style={{ maxWidth: '300px', padding: '0px 10px' }}
                        >
                          <TextInputWithFormsy
                            name="promo-code"
                            type="text"
                            validations={{
                              isExisty: false,
                              customValidation: checkForIsPromoCodeValid,
                            }}
                            required
                            value={ activePromoCode.code ? activePromoCode.code : checkCode }
                            onChange={onChangePromoCode2}
                            placeholder="Promo Code"
                            label={activePromoCode.code ? "Promo Code - Activated" : "Promo Code"}
                            disabled={ activePromoCode.code }
                          />
                      </Formsy>
                    </div>
                      { 
                        <span className="validation-message">
                            { validationError && checkCode.length > 1 ? validationError : null}
                        </span>
                      } 
                  </div>
                  <div className="activate-button-container">
                    { !activePromoCode.code && isPromoCodeValid ?
                      ( <ActionButton
                            text="Activate"
                            onClick={() =>  handleActivatePromoCode() }
                            color="#fff"
                            large={true}
                            //disabled={ !isPromoCodeValid || isActivatingPromoCode }
                          />
                          ) : null}
                  </div>
                </div> 

              <ul>
                <li>  <b>Monthly Charge (inc GST):&nbsp;</b> 
                    { 
                      activePromoCode?.description
                      ? <span> <del style={{color:"red" }}>AU $ {numeral(roundUpCharge).format()} per month</del>&nbsp; AU $ {numeral((1-activePromoCode.discount_percentage*.01)*roundUpCharge).format()} per month</span>
                      : isPromoCodeValid && checkCodeResponse?.promo_code?.discount_percentage
                      ? <span> <del style={{color:"red" }}>AU $ {numeral(roundUpCharge).format()} per month</del>&nbsp; AU $ {numeral((1-checkCodeResponse.promo_code.discount_percentage*.01)*roundUpCharge).format()} per month</span>
                      : <span>AU $ {numeral(roundUpCharge).format()} per month</span>
                    }
                </li>
                <li>  <b>Promo Code Details :&nbsp;</b>
                    { 
                      activePromoCode?.description
                      ? <span style={{ color: 'red' }}>{activePromoCode.description}</span>
                      : isPromoCodeValid && checkCodeResponse?.promo_code?.description 
                      ? <span style={{ color: 'red' }}>{checkCodeResponse.promo_code.description}</span>
                      : "You have not entered a valid promo code"
                    }
                </li>
                <li>  <b>Promo Code Expiry Date :&nbsp;</b> 
                     { 
                      activePromoCode?.shopper?.end_date
                      ? <span><span style={{ color: 'red' }}>{moment(activePromoCode?.shopper?.end_date).local().format('Do MMM YYYY')} </span><span>&nbsp;After this date you will be charged normal monthly fees unless you cancel prior to this date. You will be notified via email before this date.</span></span>
                      : isPromoCodeValid && checkCodeResponse?.promo_code?.shopper?.end_date 
                      ? <span><span style={{ color: 'red' }}>{moment(checkCodeResponse.promo_code.shopper.end_date).local().format('Do MMM YYYY')} </span><span>&nbsp;After this date you will be charged normal monthly fees unless you cancel prior to this date. You will be notified via email before this date.</span></span>
                      : "N/A"
                     }
                </li>
                <li>  <b>Charge Date :&nbsp;</b> Last Day of every month</li>
                <li>  <b>No Round Ups? :&nbsp;</b> Monthly charge will only be applied if you have round-ups for that month</li>
                <li>  <b>Charge Account :&nbsp;</b> Charges will be taken from your funding account once it is set up</li>
                <li>  <b>Insufficient Funds? :&nbsp;</b> If you have insufficient funds on the date we try to charge you then we simply will NOT charge you for that month</li>
                <li>  <b>Cancel Anytime :&nbsp;</b> You can cancel your roundups at any time by deleting all connected banks at step 3</li>
                <li>  <b>Variations to T&Cs :&nbsp;</b>We reserve the right to unilaterally vary these T&Cs. If any variation is made they will be reflected here and you will be notified via email. </li>
                <li>  <b>Bank Connection Approval Expiry :&nbsp;</b>If you provide approval to connect to a bank and share your data so that round ups can be calculated, the approval will automatically expire after one year. You will be required to manually reinstate approval to continue to use the Round Ups service. </li>
                <li>  <b>Transaction Issues :&nbsp;</b>If you have any issues with transactions you can email us at accounts@boostyoursuper.com.au </li>
              </ul>

              <div className="checkbox-entry">
                      <input
                        className="input-checkbox"
                        name="accept-roundup-charges"
                        type="checkbox"
                        placeholder="Accept roundup Terms"
                        checked={localRoundups.acceptedRoundupsTerms}
                        required
                        onChange={localRoundups.acceptedRoundupsTerms ? null : toggleAcceptedRoundUpTerms }
                        disabled={ localRoundups.acceptedRoundupsTerms }
                      />
                      <span>I agree to the Terms & Conditions including the Charges above .</span>
                      <span className="pop-over-container">
                          <PopoverHover
                            content={
                              <div style={{
                                maxWidth: '300px', padding: 10, fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left',
                              }}
                              >
                              You must accept the Terms & Conditions in order to use this service.
                              </div>
                            }
                            header = {null}
                            img = {null}
                            itemToPopover = {
                              ( <i className="material-icons" style={{ margin: '0 5px 0 5px', verticalAlign: '-40%' }} >info</i> )
                            }
                            anchorOrigin={{
                              vertical: 'center',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'center',
                              horizontal: 'right',
                            }}
                          />
                      </span>
              </div>
          </div>
          </Col>

          <Col xl={6} lg={6} md={6} sm={12} xs={12} className="links-group" style={{ margin: 0, padding: 0 }}>
          <div className="acceptance-card">
            <h2 className="title">Contact Details</h2>
            <p className="description">The following details must be correct to proceed. Use the update button to update if required.</p>
            <ul>
              <li> {authentication.user.shopper_fname ? <b>Legal First Name :&nbsp;</b> : <b style={{color:"red"}}>Legal First Name :&nbsp;</b>}{authentication.user.shopper_fname}</li>
              <li> {authentication.user.shopper_lname ? <b>Legal Last Name :&nbsp;</b> : <b style={{color:"red"}}>Legal Last Name :&nbsp;</b>}{authentication.user.shopper_lname}</li>
              <li> {authentication.user.shopper_email ? <b>Email : &nbsp;</b> : <b style={{color:"red"}}>Email :&nbsp;</b>}{authentication.user.shopper_email}</li>
              <li> {authentication.user.shopper_phone ? <b>Mobile Phone :&nbsp;</b> : <b style={{color:"red"}}>Phone :&nbsp;</b>}{authentication.user.shopper_phone}</li>
            </ul>
          <ActionButton
            text="Update Contact Details"
            onClick={() =>  setShowModalDialogV3(true) }
            color="#fff"
            large={true}
          />
            { !localCompleteContactDetails ? null : 
              (
                <div className="checkbox-entry">
                    <input
                      className="input-checkbox"
                      name="confirmed-contact-details"
                      type="checkbox"
                      placeholder="Confirm contact details"
                      checked={localRoundups.confirmedContactDetails}
                      required
                      onChange={localRoundups.confirmedContactDetails ? null : toggleConfirmedContactDetails}
                      disabled={localRoundups.confirmedContactDetails}
                    />
                    <span>I confirm my details above are correct.</span>
                    <span className="pop-over-container">
                        <PopoverHover
                          content={
                            <div style={{
                              maxWidth: '300px', padding: 10, fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left',
                            }}
                            >
                            You will be sent a verification code through these details which must be correct to continue.
                            </div>
                          }
                          header = {null}
                          img = {null}
                          itemToPopover = {
                            ( <i className="material-icons" style={{ margin: '0 5px 0 5px', verticalAlign: '-40%' }} >info</i> )
                          }
                          anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'center',
                            horizontal: 'right',
                          }}
                        />
                    </span>
                  </div>
              )
            }
          </div>
          </Col>
          </Row>
          </Container>
        </div>
        <div>
          <ModalDialogV3 
            open={showModalDialogV3}
            onCloseModalDialogV3={() => setShowModalDialogV3(false)}
            titleComponent={"Update Contact Details"}
            explainerComponent={"You must provide following contact details to continue."}
            contentComponent={
              <ContactInfoForm />
            }
          /> 
      </div>

    </AboutRoundUpsWrapper>
  );
}

export default AboutRoundUps;

