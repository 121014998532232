export default ({ theme }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.accentColor};

  .sign-up-form-container {
    max-width: 1366px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
