export default ({ theme, adornment, isSharing }) => `
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    /* width: calc(100% - 20px); */
    padding: 10px;
    background-color: ${theme.darkSecondaryColor};
    color: ${theme.secondaryTextColor};
    z-index: 0;

    .share-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 20px;
      width: ${!adornment || isSharing ? '100%' : 'calc(100% - 30px)'};
      &.right-align {
        justify-content: flex-end;
      }
    }

    .share-buttons-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .button-container {
        margin: 8.5px 12px;
      }
    }
  `;
