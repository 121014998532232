import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Formsy from 'formsy-react';
import PropTypes from 'prop-types';
import TextInput from 'SharedComponents/text-input';
import ActionButton from 'SharedComponents/action-button';
import * as AuthenticationActions from 'Flux/authentication/actions';
import styles from './index.css';
import log from "Utils/logger";

const SignInFormWrapper = styled(Formsy)`${styles}`;

const SignInForm = ({ header, onAfterSignIn, onCloseDialog }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authentication = useSelector(state => state.authentication);

  const [isFormValid, setIsFormValid] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState('');
  const [form, setForm] = useState({ email: '' });

  const onChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const onResetPassword = useCallback(async () => {
    setIsAuthenticating(true);
    setError(false);
    setMessage('');

    try {
      const onResetPasswordResult = await dispatch(AuthenticationActions.resetPassword({ email: form.email }));

      if (!onResetPasswordResult.data.status) {
        setError(true);
        setMessage(onResetPasswordResult.data.error);
        setTimeout(() => {
          setError(false);
          setMessage('');
        }, 3000);
      } else {
        setMessage('Password reset was processed successfully. Please check your email to complete resetting your password');

        if (onCloseDialog) {
          onCloseDialog();
        }

        if (onAfterSignIn) {
          onAfterSignIn();
        } 
				/* else {
					setTimeout(() => {
          	navigate('/account');
					}, 000);
        } */
      }
    } catch (exception) {
      console.error(exception);
      setError(true);
      setMessage(exception.message);
      log({
        shopper_id: authentication.user ? authentication.user.shopper_id : '000',
        event: 'System-Error',
        source: 'Forgot Password - resetting password',
        info: exception,
        ...exception,
      });
    } finally {
      setIsAuthenticating(false);
    }
  }, [dispatch, form.email, navigate, onAfterSignIn, onCloseDialog, authentication.user]);

  const onValid = () => {
    setIsFormValid(true);
  };

  const onInvalid = () => {
    setIsFormValid(false);
  };

  const onValidSubmit = () => {
    onResetPassword();
  };

  const onInvalidSubmit = () => {
    console.log('onInvalidSubmit');
  };

  return (
    <SignInFormWrapper
      style={{ width: '100%' }}
      onValidSubmit={onValidSubmit}
      onInvalidSubmit={onInvalidSubmit}
      onValid={onValid}
      onInvalid={onInvalid}
    >
      {header}
      <div style={{ margin: '10px 0', width: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
       {/*  {form.email.length > 2 && !isFormValid && onInvalidSubmit && (
          <div style={{ width: 'calc(100% - 18px)', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10, backgroundColor: '#ff007d', color: '#fff' }}>
            Some fields are invalid. Please complete them then resubmit the form
          </div>
        )} */}
        {message && (
          <span style={{ color: error ? '#ff007d' : '##03031', fontSize: 20 }}>{message}</span>
        )}
      </div>
      <TextInput
        name="email"
        type="email"
        validations={{
          isEmail: true,
          isExisty: true,
          matchRegexp: /^(((?!(info@|admin@|web@)).)*)$/
        }}
        validationErrors={{
          isEmail: 'the email is invalid',
          matchRegexp: 'some role based emails are disallowed like admin@, info@ and web@',
          isExisty: 'this field is required'
        }}
        required
        value={form.email}
        onChange={onChange}
        placeholder="email"
        label="Email"
        disabled={isAuthenticating}
      />
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
        <Link to="/login" className="forgot-password-link">Login</Link>
        <ActionButton
          text={isAuthenticating ? 'Resetting Password' : 'Reset Password'}
          loading={isAuthenticating}
          disabled={isAuthenticating || !isFormValid}
          active={!isAuthenticating}
          color="#fff"
          large
        />
      </div>
    </SignInFormWrapper>
  );
};

SignInForm.propTypes = {
  header: PropTypes.node,
  onAfterSignIn: PropTypes.func,
  onCloseDialog: PropTypes.func,
};

export default SignInForm;


