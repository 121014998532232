import React, { useState, useCallback, useContext } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Formsy from "formsy-react";
import { GoogleLogin } from '@react-oauth/google';
import log from "Utils/logger";

import TextInput from "SharedComponents/text-input";
import ActionButton from "SharedComponents/action-button";
import FacebookLogin from "SharedComponents/facebook-login";

import * as AuthenticationActions from "Flux/authentication/actions";
import * as AppActions from "Flux/app/actions";

import styles from "./index.css";

const SignInFormWrapper = styled(Formsy)`
  ${styles}
`;

const SignInForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authentication = useSelector(state => state.authentication);
  const app = useSelector(state => state.app);

  const { user } = authentication;

  const [isFormValid, setIsFormValid] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [form, setForm] = useState({
    email: "",
    password: ""
  });
  const [isAuthenticatingWithGoogle, setIsAuthenticatingWithGoogle] = useState(false);
  const [isAuthenticatingWithFacebook, setIsAuthenticatingWithFacebook] = useState(false);

  const onChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const onSignIn = useCallback(async () => {
    const { onAfterSignIn, delayOnAfterSignIn } = props;

    setIsAuthenticating(true);
    setError(false);
    setMessage("");

    try {
      const onSignInResult = await dispatch(AuthenticationActions.authenticate(form));

      if (!onSignInResult.data.status) {
        log({
          shopper_id: null,
          event: "System-Login",
          method: "email",
          status: false,
          error: onSignInResult.data.error,
        });

        setError(true);
        setMessage(onSignInResult.data.error);

        setTimeout(() => {
          setError(false);
          setMessage("");
        }, 5000);
      } else {
        const { user } = onSignInResult.data;
        log({
          shopper_id: user.shopper_id,
          event: "System-Login",
          method: "email",
          status: true
        });

        dispatch(AuthenticationActions.setUser(onSignInResult.data));
        dispatch(AppActions.setPartnerContent(onSignInResult.data.partner));

        if (props.onCloseDialog) {
          props.onCloseDialog();
        }

        if (onAfterSignIn) {
          onAfterSignIn();
        } else {
          navigate("/");
        }
      }
    } catch (exception) {
      log({ shopper_id: null, event: "System-Login", method: "email", status: false });
      setError(true);
      setMessage(exception.message);
    } finally {
      setIsAuthenticating(false);
    }
  }, [form, dispatch, navigate, props]);

  const onValid = () => {
    setIsFormValid(true);
  };

  const onInvalid = () => {
    setIsFormValid(false);
  };

  const onValidSubmit = () => {
    onSignIn();
  };

  const onInvalidSubmit = () => {
    console.log("onInvalidSubmit");
  };

  const onGoogleLoginSuccess = useCallback(async (user) => {
    const { profileObj } = user;
    const { onAfterSignIn, delayOnAfterSignIn } = props;

    setIsAuthenticatingWithGoogle(true);

    try {
      const onSignInResult = await dispatch(AuthenticationActions.authenticateViaGoogle(profileObj));

      if (!onSignInResult.data.status) {
        setIsAuthenticatingWithGoogle(false);
        setMessage(onSignInResult.data.error);
        setError(true);

        log({
          shopper_id: null,
          event: "SystemError",
          method: "google",
          status: false,
          error: onSignInResult.data.error,
        });

        setTimeout(() => {
          setMessage("");
          setError(false);
        }, 3000);
      } else {
        dispatch(AuthenticationActions.setUser(onSignInResult.data));
        dispatch(AppActions.setPartnerContent(onSignInResult.data.partner));

        if (props.onCloseDialog) {
          props.onCloseDialog();
        }

        const { user } = onSignInResult.data;
        log({
          shopper_id: user.shopper_id,
          event: "System-Login",
          method: "google",
          status: true
        });

        setIsAuthenticatingWithGoogle(false);

        if (onAfterSignIn) {
          onAfterSignIn(delayOnAfterSignIn ? 1000 : null);
        } else {
          navigate("/");
        }
      }
    } catch (exception) {
      log({
        shopper_id: null,
        event: "System-Login",
        method: "google",
        status: false,
        exception
      });

      setIsAuthenticatingWithGoogle(false);
      setMessage(exception.message);
      setError(true);
      console.log("[exception]", exception);
    }
  }, [dispatch, navigate, props]);

    const onGoogleResponseSuccess = useCallback(async ({ credential }) => {
      const { onAfterSignIn, delayOnAfterSignIn } = props;
  
      setIsAuthenticatingWithGoogle(true);
  
      try {
        const onSignInResult = await dispatch(AuthenticationActions.authenticateViaNewGoogle(credential));
  
        if (!onSignInResult.data.status) {
          setError(true);
          setMessage(onSignInResult.data.error);
  
          log({
            shopper_id: null,
            event: "System-Login",
            method: "google",
            status: false,
            location: "sign-in-form on GoogleResponseSuccess",
            error: onSignInResult.data.error,
          });
  
          setTimeout(() => {
            setError(false);
            setMessage("");
          }, 3000);
        } else {
          dispatch(AuthenticationActions.setUser(onSignInResult.data));
          dispatch(AppActions.setPartnerContent(onSignInResult.data.partner));
  
          const { user } = onSignInResult.data;
          log({
            shopper_id: user.shopper_id,
            event: "System-Login",
            method: "google",
            status: true
          });
  
          if (props.onCloseDialog) {
            props.onCloseDialog();
          }
  
          if (onAfterSignIn) {
            onAfterSignIn(delayOnAfterSignIn ? 1000 : null);
          } else {
            navigate("/");
          }
        }
      } catch (exception) {
        log({
          shopper_id: null,
          event: "System-Login",
          method: "google",
          status: false,
          exception
        });
  
        setError(true);
        setMessage(exception.message);
      } finally {
        setIsAuthenticatingWithGoogle(false);
      }
    }, [dispatch, navigate, props]);
  
    const onGoogleGetCredentialsFailure = (error, details) => {
      log({
        event: "SystemError",
        method: "google",
        status: false,
        location: "get google creds failed on Sign In",
        error,
        details
      });
  
      setIsAuthenticatingWithGoogle(false);
      setMessage("Difficulty with obtaining your credentials. Try again");
      setError(true);
  
      setTimeout(() => {
        setMessage('');
        setError(false);
      }, 3000);
    };
  
    const onLoginWithFacebookClicked = (payload) => {
      console.log('login facebook - onLoginWithFacebookClicked', payload);
      const { onPersistOnClickOutside } = props;
      setIsAuthenticatingWithFacebook(true);
  
      if (onPersistOnClickOutside) {
        onPersistOnClickOutside(true);
      }
    };
    
    const onFacebookProfileSuccess = useCallback(async (userObject) => {
      console.log("login facebook - onFacebookProfileSuccess", userObject);
      const { onAfterSignIn, delayOnAfterSignIn } = props;
  
      setIsAuthenticatingWithFacebook(true);
  
      try {
        const onSignInResult = await dispatch(AuthenticationActions.authenticateViaFacebook(userObject));
  
        if (!onSignInResult.data.status) {
          log({
            shopper_id: null,
            event: "System-Login",
            method: "facebook",
            status: false,
            error: onSignInResult.data.error
          });
  
          setError(true);
          setMessage(onSignInResult.data.error);
  
          setTimeout(() => {
            setError(false);
            setMessage("");
          }, 3000);
        } else {
          dispatch(AuthenticationActions.setUser(onSignInResult.data));
          dispatch(AppActions.setPartnerContent(onSignInResult.data.partner));
  
          const { user } = onSignInResult.data;
          log({
            shopper_id: user.shopper_id,
            event: "System-Login",
            method: "facebook",
            status: true
          });
  
          if (props.onCloseDialog) {
            props.onCloseDialog();
          }
  
          if (onAfterSignIn) {
            onAfterSignIn(delayOnAfterSignIn ? 1000 : null);
          } else {
            navigate("/");
          }
        }
      } catch (exception) {
        log({
          shopper_id: null,
          event: "System-Login",
          method: "facebook",
          status: false,
          exception
        });
  
        setError(true);
        setMessage(exception.message);
      } finally {
        setIsAuthenticatingWithFacebook(false);
  
        const { onPersistOnClickOutside } = props;
        if (onPersistOnClickOutside) {
          onPersistOnClickOutside(false);
        }
      }
    }, [dispatch, navigate, props]);


    const onFacebookResponseFailure = (error) => {
      console.log("login facebook - onFacebookResponseFailure", error);
      log({
        event: "SystemError",
        method: "facebook",
        status: false,
        error
      });
  
      setIsAuthenticatingWithFacebook(false);
      setMessage("Difficulty with obtaining your credentials. Try again");
      setError(true);
  
      setTimeout(() => {
        setMessage('');
        setError(false);
      }, 3000);
    }


    // const onFacebookProfileSuccess = (profile) => {
    //   console.log("login facebook - onFacebookProfileSuccess", profile);
    // } 

    const onFacebookResponseSuccess = (profile) => {
      console.log("login facebook - onFacebookResponseSuccess", profile);
    } 
  
    if (user) {
      return <Redirect to="/" />;
    }
  
    const {
      header,
      showSocialLogin,
      showEmailLogin,
      style
    } = props;
  
    return (
      <SignInFormWrapper
        onValidSubmit={onValidSubmit}
        onInvalidSubmit={onInvalidSubmit}
        onValid={onValid}
        onInvalid={onInvalid}
        style={{ ...style }}
      >
        {header}
        {showSocialLogin && (
          <div className="social-login">
            <GoogleLogin
              text="continue_with"
              onSuccess={ credentialResponse => {
                onGoogleResponseSuccess( credentialResponse )
              }}
              onError={onGoogleGetCredentialsFailure}
              size="large"
              useOneTap
            />
            <FacebookLogin
              onClick={onLoginWithFacebookClicked}
              //callback={onFacebookResponse}
              onFacebookResponseSuccess={onFacebookResponseSuccess}
              onFacebookResponseFailure={onFacebookResponseFailure}
              onFacebookProfileSuccess={onFacebookProfileSuccess}
              isAuthenticatingWithFacebook={isAuthenticatingWithFacebook}
              buttonText="Login&nbsp;with&nbsp;Facebook"
            />
          </div>
        )}
        {showEmailLogin && (
          <div className="form-container">
            <div style={{
              margin: "10px 0",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column"
            }}>
           {/* {!isFormValid && onInvalidSubmit && (
              <div style={{
                width: "calc(100% - 18px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: 10,
                backgroundColor: "#ff007d",
                color: "#fff"
              }}>
                Some fields are invalid.
              </div>
            )} */}
            </div>
            <TextInput
              name="email"
              type="email"
              validations={{
                isEmail: true,
                isExisty: true,
                matchRegexp: /^(((?!(info@|admin@|web@)).)*)$/
              }}
              validationErrors={{
                isEmail: "the email is invalid",
                matchRegexp: "some role based emails are disallowed like admin@, info@ and web@",
                isDefaultRequiredValue: "this field is required",
                isExisty: "this field is required"
              }}
              required
              value={form.email}
              onChange={onChange}
              placeholder="email"
              disabled={isAuthenticating}
              label={<div><span>Email</span></div>}
            />
            <TextInput
              name="password"
              type="password"
              validations={{ isExisty: true }}
              validationErrors={{
                isDefaultRequiredValue: "this field is required",
                isExisty: "this field is required"
              }}
              required
              value={form.password}
              onChange={onChange}
              placeholder="password"
              disabled={isAuthenticating}
              label={<div><span>Password</span></div>}
            />
            <div className="error-message" 
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: 30,
                padding: 10,
                color:  "#03031",
                maxWidth: "428px",
              }}>
              {message && (
                <span style={{
                  color: error ? "#ff007d" : "##03031",
                  fontSize: 15,
                  marginLeft: 10,
                  display: "flex",
                  flexWrap: "wrap",
                }}>
                  {message}
                </span>
              )}
            </div>
          </div>
        )}
        <div className="form-footer">
          <Link to="/forgot-password" className="forgot-password-link">
            Forgot&nbsp;Password
          </Link>
          <ActionButton
            text={isAuthenticating ? "Logging In" : "Login"}
            loading={isAuthenticating}
            disabled={isAuthenticating}
            active={!isAuthenticating}
            color="#fff"
            large={true}
          />
        </div>
        <div style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          width: "100%",
          padding: "10px 0"
        }}>
          <Link to="/signup" className="signup-link">
            <small>Don't have an account?</small>
          </Link>
        </div>
      </SignInFormWrapper>
    );
  };
  
  SignInForm.propTypes = {
    onAfterSignIn: PropTypes.func,
    header: PropTypes.node,
    showSocialLogin: PropTypes.bool,
    showEmailLogin: PropTypes.bool,
    delayOnAfterSignIn: PropTypes.bool,
    onCloseDialog: PropTypes.func,
    onPersistOnClickOutside: PropTypes.func,
    style: PropTypes.object
  };
  
  export default SignInForm;
