export default ({ theme }) => `
    width: auto;
    position: relative;

    .dialog-container {
      position: absolute;
      top: -5px;
      right: 0;
      border: solid 1px #d9d9d9;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      background-color: ${theme.neutralColor};
      border-radius: 8px;
      width: max-content;
      z-index: 2;
    }
  `;
