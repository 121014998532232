import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Formsy from 'formsy-react';
import axios from 'axios';
import qs from 'qs';
import log from "Utils/logger";

import * as AuthenticationActions from 'Flux/authentication/actions';

import MyInput from 'SharedComponents/text-input';
import ActionButton from 'SharedComponents/action-button';
import CONFIG from 'Config';

const PaymentDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const authentication = useSelector(state => state.authentication);
  const [localAuthentication, setLocalAuthentication] = useState(authentication);

  const { user, collaboratingCauses } = localAuthentication;

  const ownedCause = collaboratingCauses.find((cause) => cause.charity_id === user.charity_id);

  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState('');
  const [form, setForm] = useState({
    charity_bank: ownedCause.charity_bank,
    charity_bank_account: ownedCause.charity_bank_account,
    charity_bank_account_no: ownedCause.charity_bank_account_no,
    charity_bsb: ownedCause.charity_bsb,
    is_smsf: "0",
  });

  useEffect(() => {
    if (authentication !== localAuthentication) {
      setLocalAuthentication(authentication);
    }
  }, [authentication]);

  const onChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const onValid = () => {
    setIsFormValid(true);
  };

  const onInvalid = () => {
    setIsFormValid(false);
  };

  const onValidSubmit = () => {
    onUpdateAccountDetails();
  };

  const onInvalidSubmit = () => {
    console.log('onInvalidSubmit');
  };

  const onUpdateAccountDetails = useCallback(async () => {
    setIsSubmitting(true);

    try {
      const onUpdateAccountDetailsResult = await axios({
        url: `${CONFIG.apiV3Url}/charities/resource`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Bearer ' + window.localStorage.getItem('auth_token')
        },
        data: qs.stringify({ ...form }),
      });

      dispatch(AuthenticationActions.setUser(onUpdateAccountDetailsResult.data));
      
      setError(false);
      setMessage('Payment details updated successfully');
    } catch (exception) {
      setError(true);
      setMessage('Oops! Something went wrong');
      console.log(exception);
      log({
        event: "System-Error",
        source: "updateAccount",
        info: exception,
        ...exception,
        //shopper_id: authentication.user.shopper_id,
      });
    } finally {
      setIsSubmitting(false);
      console.log(['finally paydetails updated: ']);

      setTimeout(() => {
        setError(false);
        setMessage('');
      }, 3000);
      navigate(location.pathname + location.search);
    }
  }, [form, dispatch]);

  return (
    <div style={{ margin: '10px 0', width: 'calc(100% - 20px)', padding: 10 }}>
      <div style={{ width: '100%', margin: '20px 0 0', display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
        <p style={{ fontSize: 16, margin: '7px 0 0', color: '#303031', textAlign: 'left', }}>
          <b style={{ fontWeight: 'bold' }}>{`${ownedCause.charity_country_id === 'AU' ? 'Australian' : 'New Zealand'} Super Details – `}</b>Update where we send payments to.
        </p>
        <small style={{ margin: '10px 0' }}>
          We make BPAY payments to super accounts weekly. As long as you have accumulated more than the minimum contribution amount specified by your super fund (usually $1) then any payments we have received and reconciled from stores are paid within 7 days. Contact your Superannuation Fund to get your unique BPAY payment details. The details are usually available within your Super Fund online account.
        </small>
        <hr style={{ border: 'solid 1px #c5c5c5', margin: '10px 0', width: '100%' }} />
      </div>
      <Formsy
        style={{ width: '100%' }}
        onValidSubmit={onValidSubmit}
        onInvalidSubmit={onInvalidSubmit}
        onValid={onValid}
        onInvalid={onInvalid}
      >
        <MyInput
          name="charity_bank_account"
          type="text"
          validations={{ isExisty: true, minLength: 2, isWords: true }}
          validationErrors={{
            isExisty: 'this field is required',
            isDefaultRequiredValue: 'this field is required',
            minLength: 'too short',
            isAlphanumeric: 'invalid account name',
            isDefaultRequiredValue: 'this field is required',
          }}
          required
          value={form.charity_bank_account}
          onChange={onChange}
          placeholder="Superannuation Fund Name"
          label="Superannuation Fund Name"
        />
        {ownedCause.charity_country_id === 'AU' && (
          <MyInput
            name="charity_bsb"
            type="text"
            validations={{ isExisty: true, minLength: 3, maxLength: 10, isNumeric: true }}
            validationErrors={{
              isExisty: 'this field is required',
              isDefaultRequiredValue: 'this field is required',
              minLength: 'too short',
              maxLength: 'too long',
              isNumeric: 'invalid bsb'
            }}
            required
            value={form.charity_bsb}
            onChange={onChange}
            placeholder="BPAY Biller Code"
            label="BPAY Biller Code"
          />
        )}
        <MyInput
          name="charity_bank_account_no"
          type="text"
          validations={{ isExisty: true, minLength: 2, isNumeric: true }}
          validationErrors={{
            isExisty: 'this field is required',
            isDefaultRequiredValue: 'this field is required',
            minLength: 'too short',
            isNumeric: 'invalid account number'
          }}
          required
          value={form.charity_bank_account_no}
          onChange={onChange}
          placeholder="BPAY Reference Number"
          label={(
            <div>
              <span>BPAY Reference Number</span>
              {/* 
                // Additional conditionally rendered label can be added here if needed
              */}
            </div>
          )}
        />
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
          <ActionButton 
            type="submit"
            text={isSubmitting ? 'Updating Payment Details' : 'Update Payment Details'}
            large={true}
            disabled={isSubmitting || !isFormValid}
            loading={isSubmitting}
            active={!isSubmitting}
          />
          {message && (
            <span style={{ color: error ? '#ff007d' : '##03031', fontSize: 20, marginLeft: 20 }}>{message}</span>
          )}
        </div>
      </Formsy>
    </div>
  );
};

export default PaymentDetails;
