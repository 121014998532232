/* eslint-disable react/forbid-prop-types */
import React from 'react';
import styled from 'styled-components';
import numeral from 'numeral';
import PropTypes from 'prop-types';

import PopoverHover from 'SharedComponents/popover-hover';

import styles from './index.css';

const StatCardWrapper = styled.div`${styles}`;

const StatCard = ( {label, value, valueTypePercent, moreInfo, majorStat } ) => {
/*   if (!value || !label || !moreInfo) {
    return null;
  } */

  console.log('StatCard function triggered with label: ', label, ' value: ', value, ' valueTypePercent: ', valueTypePercent, ' moreInfo: ', moreInfo, ' majorStat: ', majorStat);

  return (
    <StatCardWrapper majorStat={majorStat}>

        <div className="stat-card-container">
                <div className="stat-card" >
                  <span className="label">{label}</span>
                  { !valueTypePercent 
                    ? <span className="value">$ { numeral(value).format('0,0.00') }</span>
                    : <span className="value">{ numeral(value).format('0,0.00') }%</span>
                  }
                  <div className="pop-over-container">
                    <PopoverHover
                      content={
                        <div style={{
                          maxWidth: '300px', padding: 10, fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left',
                        }}
                        >
                        {moreInfo}
                        </div>
                      }
                      header = {null}
                      img = {null}
                      itemToPopover = {
                        ( <i className="material-icons" style={{ margin: '0 5px 0 5px', verticalAlign: '-40%' }} >info</i> )
                      }
                      horizontalPosn = "right"
                    />
                  </div>
                </div>
          </div>
    </StatCardWrapper>
  );
};

StatCard.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  valueTypePercent: PropTypes.bool.isRequired,
  moreInfo: PropTypes.string.isRequired,
  majorStat: PropTypes.bool.isRequired,
};

export default StatCard;
