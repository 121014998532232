import React from 'react';
import styled from 'styled-components';
import numeral from 'numeral';

import styles from './testimonial.css';

const TestimonialWrapper = styled.div`${styles}`;

const Testimonial = ({ testimonial }) => {
  return (
    <TestimonialWrapper>
      <div className="person">
        <div className="avatar">
          <img src={testimonial.image_url} />
        </div>
        <div className="name-boosted-by">
          <span className="name">{testimonial.name}</span>
          {
            parseFloat(testimonial.total_donation) > 0 ? (
              <span className="boosted-by">{`Boosted Super by $${numeral(testimonial.total_donation).format('0,0.00')}`}</span>
            ) : null
          }
        </div>
      </div>
      <div className="body">
        <div className="header">
          <h4>{testimonial.headline}</h4>
          <small>{testimonial.testimonial_text}</small>
        </div>
        <div className="top-tip">
          <span>{`${testimonial.name}'s Top Tip`}</span>
          <small>{testimonial.top_tip}</small>
        </div>
      </div>
    </TestimonialWrapper>
  );
}

export default Testimonial;
