export default ({ theme, size, color, backgroundColor }) => {
  return `
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${size ? `${size}px` : '44px'};
    width: ${size ? `${size}px` : '44px'};
    border-radius: ${size ? `${size / 2}px` : '22px'};
    border: solid 1px ${theme.borderColor};
    background-color: ${backgroundColor ? backgroundColor : theme.lightBorderColor};
    color: ${color ? color : theme.secondaryTextColor};
    transition: background-color 1s;
    text-transform: uppercase;

    &:hover {
      background-color: ${theme.lightSecondaryColor};
      transition: background-color 1s;
    }
  `;
}
