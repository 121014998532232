export default ({ theme, open }) => {
  const windowWidth = window.innerWidth;
  return `
    background-color: ${theme.neutralColor};
    width: ${!open ? '0px' : windowWidth < 768 ? '100vw' : '600px'};
    transform: ${!open ? 'translate3d(100%,0,0)' : 'translate3d(0,0,0)'};
    transition: transform 0.5s ease-in-out, width 0.5s ease-in-out;
    
    will-change: transform;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 99;
    box-shadow: -3px 0 3px 0 ${theme.borderColor};
    backdrop-filter: blur(5px) contrast(.8);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .header {
      height: ${theme.headerHeight}px;
      border-bottom: solid 1px ${theme.borderColor};
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .header-text {
        width: calc(100% - ${theme.headerHeight}px);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 10px;
        font-size: 20px;
      }

      .header-close-icon-container {
        width: ${theme.headerHeight}px;
        height: ${theme.headerHeight}px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .contents {
      height: calc(100% - ${theme.headerHeight}px);
      width: calc(100% - 20px);
      overflow: auto;
      padding: 10px;
      display: flex;
      flex-direction: column;

      .shopping-faqs-container {
        display: flex;
        flex-direction: column;
        background-color: rgb(239, 239, 239);
        padding: 5px 10px;
        margin: 10px 0px;
        
        .shopping-info-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

          .how-to-shop-item-container {
            display: flex;
            flex-direction:row;
            justify-content: flex-start;
            align-items: center;
            height: 3vh;

            .how-to-shop-text-container{
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-content: center;
              height: 100%;
              align-items: center;
            }

            .pop-over-container{
              display: flex;
              flex-direction:row;
              justify-content: center
              /* margin: 0 10px 0 10px; */
              /* height: 100%; */
              align-items: center;
            }

          }

        }
      }

      .tips-and-info-content {
        width: calc(100% - 20px);
        display: flex;
        max-width: 600px;
      }

      p {
        
        max-width: 600px;
      }


      .store-rate {
        text-transform: capitalize;
        color: ${theme.darkSecondaryColor};
      }

      .summary {
        margin: 10px 0 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre-wrap;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;

        &:hover {
          overflow: visible;
        }

      }

       .shop-and-contribute-container {
          display: flex;
          flex-direction: row;
          -webkit-box-pack: center;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;

          .shop-now-container {
            display: flex;
            /* width: 100%; */
            align-items: center;
            justify-content: center;

            button.shop-now {
              padding: 10px;
              background-color: ${theme.darkSecondaryColor};
              color: ${theme.secondaryTextColor};
              border-radius: 3px;
              align-self: center;
              outline: none;

              &:hover {
                background-color: ${theme.lightSecondaryColor};
              }

              &.large {
                height: 50px;
                border-radius: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;
                font-weight: bold;
                text-transform: uppercase;
                padding: 0 30px;
              }
            }
          }

          .contribute-now-holder {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin: 10px 0px;
          }

        }

      .active-deals {
        display: flex;
        flex-direction: column;
        width: calc(100% - 40px);

        @media (max-width: 768px) {
          width: 100%;
        }

        >.header {
          color: ${theme.darkSecondaryColor};
          text-transform: uppercase;
          border: none;
          margin: 0;
        }

        .offers-container{
          display: flex;
          justify-content: center;
          width: 100%;
          flex-wrap: wrap;

          @media (max-width: 768px) {
            justify-content: flex-start;
            flex-wrap: nowrap;
            flex-flow: row;
            width: calc(100% - 0px);
            overflow-x: auto; /* Enable horizontal scrolling */
            white-space: nowrap; /* Prevent offers from wrapping to the next line */
            -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS devices */
          }
        }
      }
    }
  `;
};
