import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class DropDownSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      value: null,
    }

    this.onToggleDropDown = this.onToggleDropDown.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClickOutside, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, false);
  }

  onToggleDropDown() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  onSelect(option) {
    this.props.onChange(option);
    this.setState({ isOpen: false });
  }

  handleClickOutside(event) {
    if (!this.container.contains(event.target)) {
      this.setState({ isOpen: false });
    }
  }

  render() {
    const dropdownStyle = {
      width: 200,
      position: 'relative',
      margin: '10px 0',
      ...this.props.style,
    };

    const optionsStyle = {
      width: 'calc(100% - 20px)',
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      top: this.props.dropUp ? '-260%' : 'initial', // Position below the trigger
      //bottom: '100%', // Position above the trigger
      left: 0,
      boxShadow: '0 0 3px rgba(0, 0, 0, 0.2)',
      backgroundColor: '#fff',
      zIndex: 120,
    };

    return (
      <div ref={(container) => this.container = container} className={`shopnate-select ${this.props.className}`} style={dropdownStyle}>
        <div role="button" onClick={this.onToggleDropDown} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', height: 40, border: 'solid 1px #d9d9d9', padding: '0 10px' }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
            <span>
              {this.props.value !== null
                ? this.props.options.find((option) => option.value === this.props.value)?.label
                : 'Select an option'}
            </span>
          </div>
          <div role="button" onClick={this.onToggleDropDown}>
            {
              this.state.isOpen ? (
                <i className="material-icons">keyboard_arrow_up</i>
              ) : (
                <i className="material-icons">keyboard_arrow_down</i>
              )
            }
          </div>
        </div>
        {
          this.state.isOpen ? (
            <div className="options" style={optionsStyle}>
              {
                this.props.options.map((option) => (
                  <div className="option" role="button" key={option.value} onClick={() => this.onSelect(option)} style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', backgroundColor: option.value === this.props.value ? '#e5e5e5' : '#fff', padding: 10 }}>
                    <span>{option.label}</span>
                  </div>
                ))
              }
            </div>
          ) : null
        }
      </div>
    );
  }
}

DropDownSelect.propTypes = {
  style: PropTypes.object,
  //value: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  //options: PropTypes.array.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
};
