import React from 'react';
import styled from 'styled-components';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import FAQs from 'SharedComponents/faq';
import Footer from 'SharedComponents/footer';

import styles from './index.css.js';

const FAQWrapper = styled.div`
  ${styles}
`;

const FAQ = () => {

  //const { fetchRoundUpsShopperData } = useFetchRoundUpsShopperData();

  return (
    <FAQWrapper>
      <Helmet>
        <title>FAQs – Boost your Super</title>
      </Helmet>
      <FAQs />
      <Footer />
    </FAQWrapper>
  );

};

export default FAQ;
