import { configureStore } from '@reduxjs/toolkit';
import createReducer from './createReducer';

export function storeConfiguration(initialState = {}) {
  console.log('storeConfiguration triggered with initialState:', initialState);
  
  const store = configureStore({
    reducer: createReducer,
    preloadedState: initialState,
    devTools: process.env.NODE_ENV !== 'production', // Redux DevTools integration
  });

  console.log('store at storeConfiguration is:', store);

  // Make reducers hot reloadable (if needed)
  if (module.hot) {
    module.hot.accept('./createReducer', () => {
      const newRootReducer = require('./createReducer').default;
      store.replaceReducer(newRootReducer);
    });
  }

  return store;
}

export default storeConfiguration;












// import { createStore, applyMiddleware, compose } from 'redux';
// import { configureStore } from '@reduxjs/toolkit';
// import thunkMiddleware from 'redux-thunk';
// import createReducer from './createReducer';
// //import { composeWithDevTools } from 'redux-devtools-extension';

// export function storeConfiguration(initialState = {}) {

//   console.log ('storeConfiguration triggered with initialState:', initialState);
//   //const middlewares = [thunkMiddleware];

//   //const enhancers = [applyMiddleware(...middlewares)];

//   const combinededReducers = createReducer();

//   // If Redux DevTools Extension is installed use it, otherwise use Redux compose
//   const composeEnhancers =
//     process.env.NODE_ENV !== 'production' &&
//     typeof window === 'object' &&
//     window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
//       ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
//           shouldHotReload: true,
//         })
//       : compose;

//   //const store = createStore(
//   const store = configureStore({
//     combinededReducers,
//     initialState,
//     //createReducer(),
//     //initialState,
//     //composeEnhancers(...enhancers)
//     //composeEnhancers(applyMiddleware(...middlewares))
//   }
//   );

//   console.log('store at storeConfiguration is:', store );
//   //console.log('configured store with initialState:', initialState, 'store:', store, 'createReducer:', createReducer, 'middlewares:', middlewares, 'enhancers:', enhancers, 'composeEnhancers:', composeEnhancers)

//   // Make reducers hot reloadable
//   if (module.hot) {
//     module.hot.accept('./createReducer', () => {
//       store.replaceReducer(createReducer());
//     });
//   }

//   return store;
// }

// // Export singleton store instance directly
// export default storeConfiguration;

