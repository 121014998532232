export default ({ theme }) => {
  return `
    height: 40px;
    width: 40px;
    border-radius: 20px;
    color: ${theme.secondaryColor}
    outline: none;
    border: none;
    background-color: ${theme.neutralColor}
    display: flex;
    align-items: center;
    justify-content: center;

    &:disabled {
      pointer-events: none;

      i {
        color: ${theme.darkPrimaryColor};
      }
    }

    &:hover {
      background-color: ${theme.shadowColor}
      color: ${theme.darkSecondaryColor}

      i {
        transform: scale(1.1);
      }
    }
  `;
}
