import React, { useEffect, useState }  from 'react';
import numeral from 'numeral';
import moment from 'moment';
import styled from 'styled-components';
import Spinner from 'react-spinner-material';
import DataTable from 'react-data-table-component-with-filter';

import PopoverHover from 'SharedComponents/popover-hover';


import styles from './TransactionsTable.css';

// define default format for numbers in numeral
numeral.defaultFormat('0,0.00');

// set default format for nulls
numeral.nullFormat('0.00');
numeral.zeroFormat('0.00');


  //<pre>{JSON.stringify(data, null, 2)}</pre>;

  // const TransactionStatus = (transaction_status,paid_charity) =>
  
  // {
  //   console.log('calculating transactionStatus using');
  //   console.log('transaction_status : ', transaction_status );
  //   console.log('paid_charity : ', paid_charity );
  //   let transactionStatus = 'Clicked';
  //   transactionStatus = paid_charity === '1' ? 'Paid'
  //   : transaction_status === '5' ? 'Inquiry Raised'
  //   : transaction_status === '6' ? 'Manually Approved'
  //   : transaction_status === '4' ? 'Pending'
  //   : transaction_status === '3' ? 'Void'
  //   : transaction_status === '2' ? 'Approved'
  //   : transaction_status === '7' ? 'Payment Bounced'
  //   : 'Clicked';
  //   return (transactionStatus);
  // };


const TransactionsTableWrapper = styled.div`${styles}`;

const TransactionsTableRoundups = ({
  roundUpsTransactions, 
  currentPage, 
  pageSize, 
  isFetchingTableData,
  showRaiseInquiryDialog,
  onShowRaiseInquiryDialog,
  //onCloseRaiseInquiryDialog,
  //KeyTransactionStatusDescriptionsMap,
}) => {

  console.log('roundUpsTransactions in TransactionsTable is : ', roundUpsTransactions);
  console.log('typeof(roundUpsTransactions) is: ', typeof(roundUpsTransactions));
  console.log('showRaiseInquiryDialog in TransactionsTable status is : ', showRaiseInquiryDialog);
  console.log('props.onShowRaiseInquiryDialog in TransactionsTable status is : ', onShowRaiseInquiryDialog);

  const [pageNumber, setPageNumber] = useState(currentPage);
  const [rowsPerPage, setRowsPerPage] = useState(pageSize);
  
  const handlePageChange = (newPage, pageSize) => {
    console.log('handlePageChange in Transactions Table Triggered with newPage: ', newPage);
    setPageNumber(newPage);
  }

  const handleChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
    console.log('handleChangeRowsPerPage in Transactions Table Triggered with currentRowsPerPage: ', currentRowsPerPage);
    console.log('handleChangeRowsPerPage in Transactions Table Triggered with currentPage: ', currentPage);
    setRowsPerPage(currentRowsPerPage);
    setPageNumber(currentPage);
  }

  const ExpandedComponent = ({data}) => 
    
    (
      <div className="expander-row-container">
        <div className="transaction-details">
          <div className="transaction-item"> Date Time: <span style={{fontWeight:"bold"}}>&nbsp;{moment(data.created_at).local().format('DD MMM YYYY, h:mm:ss a')}</span></div>
          <div className="transaction-item"> Spent: <span style={{fontWeight:"bold"}}>&nbsp;{'$'+ numeral(-data.amount).format()}</span></div>
          <div className="transaction-item"> Round Up To Setting: <span style={{fontWeight:"bold"}}>&nbsp;{'$'+numeral(data.roundup_amount_cents/100).format()}</span></div>
          <div className="transaction-item"> Boost To Super: <span style={{fontWeight:"bold"}}>&nbsp;{'$'+numeral(data.debit_amount_cents/100).format()}</span></div>
          <div className="transaction-item"> Status: 
            <span style={{fontWeight:"bold"}}>&nbsp;{ data.status}</span>
            {/* <span style={{fontWeight:"bold"}}>&nbsp;{TransactionStatus (data.status,data.paid_charity)}</span> */}
              <span className="pop-over-container">
                <PopoverHover
                  content={
                    <div style={{
                      maxWidth: '300px', padding: 10, fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left',
                    }}
                    >
                    status descriptions coming soon... {/* {KeyTransactionStatusDescriptionsMap[TransactionStatus (data.transaction_status,data.paid_charity)]} */}
                    </div>
                  }
                  header = {null}
                  img = {null}
                  itemToPopover = {
                    ( <i className="material-icons" style={{ margin: '0 5px 0 5px', verticalAlign: '-40%' }} >info</i> )
                  }
                  horizontalPosn = "right"
                />
              </span>
          </div>
          <div className="transaction-item"> Description: <span style={{fontWeight:"bold"}}>&nbsp;{data.description}</span></div>

        </div>
      </div>
    );

  if (isFetchingTableData && !roundUpsTransactions.length) {
    return (
      <div style={{
        width: '100%', padding: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 80,
      }}
      >
        <Spinner spinnerColor="#000000" size={20} spinnerWidth={3} />
        <p>Fetching transactions</p>
      </div>
    );
  }

  if (!roundUpsTransactions.length) {
    return (
      <div style={{
        width: '100%', padding: 10, display: 'flex', alignItems: 'center', justifyContent: 'center', height: 80,
      }}
      >
        <p>There are no transactions to display. Once initiated they can take up to 2 business days to start displaying.</p>
      </div>
    );
  }

  const columns = [
    {
      name: '#',
      selector: (row,index) => ((index+1)+(pageNumber-1)*rowsPerPage),
      maxWidth: '60px',
      minWidth: 'unset',
    },
    {
      name: 'ACTION DATE',
      selector: row => new Date(row.created_at), // Convert to Date object
      format: row => moment(row.created_at).local().format('Do MMM YYYY'), // Format for display
      filterable: true,
      sortable: true,
    },
    {
      name: 'WITHDRAWAL DATE',
      selector: row => new Date(row.withdrawalDate), // Convert to Date object
      format: row => moment(row.withdrawalDate).local().format('Do MMM YYYY'),
      filterable: true,
      sortable: true,
    },
    {
      name: 'BANK REF',
      selector: row => row.party_bank_ref,
      filterable: true,
      sortable: true,
    },
    {
      name: 'SPENT AMT',
      selector: row => -row.amount_cents,
      format: row => `$ ${numeral(-row.amount_cents / 100).format()}`,
      filterable: true,
      sortable: true,
    },
    {
      name: 'ROUND UP SETTING',
      selector: row => numeral(row.roundup_amount_cents / 100).value(),
      format: row => `$ ${numeral(row.roundup_amount_cents / 100).format()}`,
      filterable: true,
      sortable: true,
    },
    {
      name: 'BOOST TO SUPER',
      selector: row => row.debit_amount_cents, // Remove '$' here
      format: row => `$ ${numeral(row.debit_amount_cents / 100).format()}`, // Keep '$' in the formatted output
      filterable: true,
      sortable: true,
    },
    
    // {
    //   name: 'PAID DATE',
    //   selector: row => 
    //     row.paid_charity_date != null
    //       ? moment(row.paid_charity_date).local().format('Do MMM YYYY')
    //       : '-',
    //   conditionalCellStyles: [
    //     {
    //       when: row => 
    //       row.paid_charity_date == null,
    //       style: {color: 'white'},
    //     }
    //   ],
    //   filterable: true,
    //   sortable: true,
    // },
    {
      name: 'STATUS',
      selector: row => row.status,
      filterable: true,
      sortable: true,
    },

  ]


  return (
    <TransactionsTableWrapper>
      <h3>Please note round up transactions can take 2-3 business days after you make a purchase before they appear on this table. </h3>
        <DataTable
          columns={columns}
          pageSize={pageSize}
          data={roundUpsTransactions}
          highlightOnHover={true}
          responsive={true}
          customStyles={styles}
          defaultSortFieldId={2}
          defaultSortAsc={false}
          pagination
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          expandableRows
          expandableRowsComponent={ExpandedComponent}
          expandOnRowClicked={true}
        />
    </TransactionsTableWrapper>
  );
};

export default TransactionsTableRoundups;