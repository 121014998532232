import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { has, isEqual } from 'lodash';

import log from "Utils/logger";

//import CONFIG from "Config";

import * as ShoppingActions from "Flux/shopping/actions";

const useFetchStoreBoostProfiles = () => {
  console.log ("**** ** fetchStoreBoostProfiles: hook triggered")
  const isFetchingStoreBoostProfilesRef = useRef(false);
  const hasFetchedStoreBoostProfilesRef = useRef(false);
  const dispatch = useDispatch();
  const storedStoreBoostProfileRetailer = useSelector(state => state.shopping.boostsRetailer);
  const storedStoreBoostProfileClient = useSelector(state => state.shopping.boostsClient);
  const storedStoreBoostProfileAffiliate = useSelector(state => state.shopping.boostsAffiliate);
  const storedStores = useSelector(state => state.shopping.stores);
  //const storedStoreBoostProfile = useSelector(state => state.shopping.boosts);
  
  const fetchStoreBoostProfiles = async () => {
    isFetchingStoreBoostProfilesRef.current = true;
    try {
      const result = await dispatch(ShoppingActions.fetchStoreBoostProfiles());
      console.log ('*** ** fetchStoreBoostProfiles: fetch result  is:', result);

      
      const newStoreBoostProfiles = result.data.boosts;
      const newStoreBoostProfilesRetailer = result.data.boosts.retail_stores;
      const newStoreBoostProfilesClient = result.data.boosts.clients;
      const newStoreBoostProfilesAffiliate = result.data.boosts.affiliates;

      //console.log('*** ** fetchStoreBoostProfiles: storedStoreBoostProfiles: ', storedStoreBoostProfiles)
      console.log ('*** ** fetchStoreBoostProfiles: newStoreBoostProfiles: ', newStoreBoostProfiles);
      console.log('*** ** fetchStoreBoostProfiles: newStoreBoostProfilesRetailer: ', newStoreBoostProfilesRetailer);
      console.log('*** ** fetchStoreBoostProfiles: storedStoreBoostProfilesRetailer: ', storedStoreBoostProfileRetailer);

      console.log('*** ** fetchStoreBoostProfiles: newStoreBoostProfilesClient: ', newStoreBoostProfilesClient);
      console.log('*** ** fetchStoreBoostProfiles: storedStoreBoostProfilesClient: ', storedStoreBoostProfileClient); 

      console.log('*** ** fetchStoreBoostProfiles: newStoreBoostProfilesAffiliate: ', newStoreBoostProfilesAffiliate);
      console.log('*** ** fetchStoreBoostProfiles: storedStoreBoostProfilesAffiliate: ', storedStoreBoostProfileAffiliate);

      //****NEED TO CHECK HOW TO DO THIS ONE. boosts_retailer || boosts_client || boosts_affiliate to trigger update */
      if ( storedStores.length > 0
        && (newStoreBoostProfilesRetailer.length !== storedStoreBoostProfileRetailer.length
            || newStoreBoostProfilesClient.length !== storedStoreBoostProfileClient.length
            || newStoreBoostProfilesAffiliate.length !== storedStoreBoostProfileAffiliate.length)
         ) {
      //if (!isEqual(storedStoreBoostProfiles, newStoreBoostProfiles)) {
        console.log('*** ** fetchStoreBoostProfiles: newStoreBoostProfilesRetailer.length: ', newStoreBoostProfilesRetailer.length);
        console.log('*** ** fetchStoreBoostProfiles: storedStoreBoostProfilesRetailer.length: ', storedStoreBoostProfileRetailer.length);
        console.log('*** ** fetchStoreBoostProfiles: newStoreBoostProfilesClient.length: ', newStoreBoostProfilesClient.length);
        console.log('*** ** fetchStoreBoostProfiles: storedStoreBoostProfilesClient.length: ', storedStoreBoostProfileClient.length);
        console.log('*** ** fetchStoreBoostProfiles: DISPATCH - ADD_STORE_BOOSTS with: ', newStoreBoostProfiles);
        await dispatch(ShoppingActions.addStoreBoosts(newStoreBoostProfiles))
        console.log('*** ** fetchStoreBoostProfiles: DISPATCH - SET_STORE_BOOSTS_ON_STORES: ', newStoreBoostProfiles);
        dispatch(ShoppingActions.setStoreBoostProfilesOnStores());

      }

    } catch (exception) {
      console.log(exception);
      log({
        event: "System-Error",
        source: "Fetch StoreBoostProfiles",
        info: exception,
        ...exception,
      });
    } finally {
      isFetchingStoreBoostProfilesRef.current = false;
      hasFetchedStoreBoostProfilesRef.current = true;
    }
  };

  useEffect(() => {
    if (!isFetchingStoreBoostProfilesRef.current && !hasFetchedStoreBoostProfilesRef.current) {
      fetchStoreBoostProfiles();
    }
  }, []);

  return { 
          //isFetchingStoreBoostProfiles: isFetchingStoreBoostProfilesRef.current,
          // hasFetchedStoreBoostProfiles: hasFetchedStoreBoostProfilesRef.current,
           fetchStoreBoostProfiles };
  //return { fetchStoreBoostProfiles };
};

export default useFetchStoreBoostProfiles;
