import React from 'react';
import styled from 'styled-components';

import businessInsiderLogo from 'Images/logo400x200_businessInsider.png';
import msnMoneyLogo from 'Images/logo400x200_msnMoney.png';
import nineNewsLogo from 'Images/logo400x200_nineNews.png';
import todayLogo from 'Images/logo400x200_today.png';
import yahooFinanceLogo from 'Images/logo400x200_yahooFinance.png';

import styles from './index.css';

const AsSeenOnBannerWrapper = styled.div`${styles}`;

export default () => (
  <AsSeenOnBannerWrapper>
    <h4 className="title">As seen on</h4>
    <div className="content">
      <div className="logos-holder">
        <img className="logo" src={businessInsiderLogo} alt="business-insider-logo" />
        <img className="logo" src={msnMoneyLogo} alt="msn-money-logo" />
        <img className="logo" src={nineNewsLogo} alt="nine-news-logo" />
        <img className="logo" src={todayLogo} alt="today-logo" />
        <img className="logo" src={yahooFinanceLogo} alt="yahoo-finance-logo" />
      </div>
    </div>
  </AsSeenOnBannerWrapper>
);
