import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import ActionButton from 'SharedComponents/action-button';

import onlineShopping from 'Images/bys-online-shopping.png';
import styles from './index.css';


const AboutUsWrapper = styled.div`${styles}`;

const AboutUs = ({ content }) => (
  <AboutUsWrapper backgroundimage={content.what_is_bys_photo} id="what-is-bys">
    <div className="content-container">
      <div className="image-container" />
      <div className="text-container">
        <h2>{content.what_is_bys_heading}</h2>
        <p>{content.what_is_bys_text_1}</p>
        <p>{content.what_is_bys_text_2}</p>
        <p>{content.what_is_bys_text_3}</p>
        <div style={{
          display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%',
        }}
        >
          <Link to="/signup"><ActionButton text="Sign Up Free" large={true} /></Link>
        </div>
      </div>
    </div>
  </AboutUsWrapper>
);

AboutUs.propTypes = {
  content: PropTypes.object.isRequired,
};

export default AboutUs;
