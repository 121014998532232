import * as TransactionsActionTypes from './constants';
import axios from 'axios';

import CONFIG from 'Config';

export function addTransactions(items) {
  return {
    type: TransactionsActionTypes.ADD_TRANSACTIONS,
    payload: items,
  }
}

export function setTransactionSummary(summary) {
  return {
    type: TransactionsActionTypes.SET_TRANSACTION_SUMMARY,
    payload: summary,
  }
}

export function removeTransactions() {
  return {
    type: TransactionsActionTypes.REMOVE_TRANSACTIONS,
  }
}

export function setTransactions(items, count, page) {
  return {
    type: TransactionsActionTypes.SET_TRANSACTIONS,
    payload: { items, count, page }
  }
}

export function fetchTransactions(limit, offset) {
  return (dispatch, getState) => {
    const { authentication } = getState();
    const { user, collaboratingCauses } = authentication;

    const ownedCause = collaboratingCauses.find((cause) => cause.charity_id === user.charity_id);

    return axios({
      url: `${CONFIG.apiV3Url}/charitytransactions/resources?limit=${limit}&offset=${offset}`,
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + window.localStorage.getItem('auth_token')
      },
    });
  }
}

export function fetchCauseDetails(charityId) {
  console.log('*** ** fetchCauseDetails: ACTION in flux function Triggered: ');
  return (dispatch, getState) => {
    const { authentication } = getState();

    return axios({
      url: `${CONFIG.apiV3Url}/charities/resource`,
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + window.localStorage.getItem('auth_token')
      },
    });
  };
}

export function onRaiseTransactionInquiry( {uniqueID, orderNumber, spentAmount, commentsFromCust}) {
  return (dispatch, getState) => {
    const { app, authentication } = getState();
    console.log(['raiseTransactionInquiry in Transactions ACTIONS triggered']);
    console.log(['uniqueID to Raise Transaction Inquiry ' ,uniqueID]);
    console.log(['orderNumber to Raise Transaction Inquiry ' ,orderNumber]);
    console.log(['spentAmount to Raise Transaction Inquiry ' ,spentAmount]);
    console.log(['commentsFromCust to Raise Transaction Inquiry ' ,commentsFromCust]);

    return axios({
      url: `${CONFIG.apiV3Url}/transactions/raise-inquiry`, 
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + window.localStorage.getItem('auth_token'),
      },
      data: JSON.stringify({
        _token: window.localStorage.getItem('csrf_token'),
        transaction_id: uniqueID,
        inquiry_transaction_amount: spentAmount,
        inquiry_order_id: orderNumber,
        customer_notes: commentsFromCust,
      }),
    });
  };
}