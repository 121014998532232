import axios from 'axios';

import log from 'Utils/logger';

import CONFIG from 'Config';
import * as CalculatorActionTypes from './constants';
import * as AppActions from "Flux/app/actions";

export function fetchShopperCalculatorData() {
  return () => axios({
    url: `${CONFIG.apiV3Url}/shopper-calculator/`,
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + window.localStorage.getItem('auth_token')
    },
  });
}

export function saveShopperCalculatorData( {
  age,
  gender,
  desired_retirement_age,
  income,
  current_super_balance,
  pa_investment_return,
  inflation,
  superfund_fees_pa,
  superfund_fees_pc,
  before_tax_contributions,
  before_tax_contributions_frequency,
  after_tax_contributions,
  after_tax_contributions_frequency,
  } ) {
    console.log('current_super_balance in flux calculators actions is: ', current_super_balance)
    const form = {
      age: age,
      gender: gender,
      desired_retirement_age: desired_retirement_age,
      income: income,
      current_super_balance: current_super_balance,
      pa_investment_return: pa_investment_return,
      inflation: inflation,
      superfund_fees_pa: superfund_fees_pa,
      superfund_fees_pc: superfund_fees_pc,
      before_tax_contributions: before_tax_contributions,
      before_tax_contributions_frequency: before_tax_contributions_frequency,
      after_tax_contributions: after_tax_contributions,
      after_tax_contributions_frequency: after_tax_contributions_frequency,
    }
    console.log('form in flux calculators actions is: ', form );


    return (dispatch, getState) => {
      const { authentication } = getState();
      const { user } = authentication;

      return axios({
          url: `${CONFIG.apiV3Url}` + (user ? '/shopper-calculator/' : '/calculator/'), 
          method: 'POST',
          data: form,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + window.localStorage.getItem('auth_token'),
          },
      });

    } 
  }

export function setShopperCalculatorData(content) {
  console.log('setShopperCalculatorData function in flux calculators actions triggered with content: ', content)
  return {
    type: CalculatorActionTypes.SET_SHOPPER_CALCULATOR_DATA,
    payload: content,
  };
}

export function fetchDefaultCalculatorCashbackVariables() {
  return () => axios({
    url: `${CONFIG.apiV3Url}/calculator/resources`,
    method: 'GET',
  });
}



