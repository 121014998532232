import axios from 'axios';

import CONFIG from 'Config';
import * as ItemActionTypes from './constants';

export function setFAQs(items) {
  return {
    type: ItemActionTypes.SET_FAQS,
    payload: items,
  };
}

export function removeFAQs() {
  return {
    type: ItemActionTypes.REMOVE_FAQS,
  };
}

export function fetchFAQs() {
  return () => axios({
    url: `${CONFIG.apiV3Url}/faqs/resources`,
    method: 'GET',
  });
}
