import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import log from 'Utils/logger';

import styles from './index.css.js';

import IconButton from 'SharedComponents/icon-button';
import DialogTrigger from 'SharedComponents/dialog-trigger';
import ActionButton from 'SharedComponents/action-button';
import SignInForm from 'SharedComponents/sign-in-form';
import Avatar from 'SharedComponents/avatar';
import Menu from 'SharedComponents/menu';

import logo from 'Images/BYS_Default_logo_780x120.png';

import * as AuthenticationActions from 'Flux/authentication/actions';
import * as AppActions from 'Flux/app/actions';
import * as RoundUpsActions from 'Flux/round-ups/actions';
import * as RoundUpsTransactionsActions from 'Flux/transactions-round-ups/actions';

const NavigationBarWrapper = styled.div`${styles}`;

const NavigationBar = ({ minimal }) => {
  const [visibleSection, setVisibleSection] = useState(null);
  const [isDialogMenuOpen, setIsDialogMenuOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();


  const authentication = useSelector((state) => state.authentication);
  const app = useSelector((state) => state.app);

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', onBeforeInstallPrompt, true);
    window.addEventListener('appinstalled', (event) => {
      console.log('[Application was installed successfully]', event);
    });

    return () => {
      window.removeEventListener('beforeinstallprompt', onBeforeInstallPrompt, true);
    };
  }, []);


  const onBeforeInstallPrompt = (event) => {
    event.preventDefault();
    window.deferredInstallPrompt = event;
  };

  const onInstallButtonClicked = () => {
    window.deferredInstallPrompt.prompt();
    window.deferredInstallPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
        log({
          event: 'User ACCEPTED the A2HS prompt',
          shopper_id: authentication.user.shopper_id,
        });
      } else {
        console.log('User dismissed the A2HS prompt');
        log({
          event: 'User DISMISSED the A2HS prompt',
          shopper_id: authentication.user.shopper_id,
        });
      }
      window.deferredInstallPrompt = null;
      dispatch(AppActions.setBeforeInstallPromptFire(false));
    });
  };

  const scrollToId = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const activeRoute = window.location.pathname + window.location.hash;

  return (
    <NavigationBarWrapper isDialogMenuOpen={isDialogMenuOpen}>
      <div className="actions-container">
        <Link className={classNames('logo-positioning', { minimal })} to="/#main" onClick={() => scrollToId('main')}>
          {app.partnerContent ? (
            parseInt(app.partnerContent.partner_member_status) >= 2 ? (
              <div className={classNames('logo-posn2', { minimal })}>
                <img className={classNames('img')} src={app.partnerContent.partner_logo} alt={app.partnerContent.partner_name} />
              </div>
            ) : (
              <div className="multi-logo-display">
                <div className={classNames('logo-posn1', { minimal })}>
                  <img className={classNames('img')} src={logo} alt="logo1" />
                </div>
                <div className={classNames('vertical-line', { minimal })}></div>
                <div className={classNames('logo-posn2', { minimal })}>
                  <img className={classNames('img')} src={app.partnerContent.partner_logo} alt={app.partnerContent.partner_name} />
                </div>
              </div>
            )
          ) : null}
        </Link>

        <div className="links">
          {!minimal ? (
            <Link to="/#how-it-works" className={classNames('link', { active: visibleSection === 'how-it-works' })} onClick={() => scrollToId('how-it-works')}>
              <span>How&nbsp;it&nbsp;Works</span>
            </Link>
          ) : null}
          {!minimal ? (
            <Link to="/superannuation-calculators" className={classNames('link', { active: activeRoute === '/superannuation-calculators' })}>
              <span>Super Calculators</span>
            </Link>
          ) : null}
          {!minimal ? (
            <Link to="/#faq" className={classNames('link', { active: activeRoute === 'faq' })} onClick={() => scrollToId('faq')}>
              <span>FAQ</span>
            </Link>
          ) : null}
          {!minimal ? (
            <Link to="/shops" className={classNames('link', { active: activeRoute === '/shops' })}>
              <span>Shops</span>
            </Link>
          ) : null}
          {authentication.user ? (
            <DialogTrigger
              trigger={<Avatar name={`${authentication.user.shopper_fname} ${authentication.user.shopper_lname}`} backgroundColor="#000" color="#FFF" />}
              dialogContent={
                <Menu
                  items={[
                    { label: 'Shops', onClick: () => navigate('/shops') },
                    { label: 'Account', onClick: () => navigate('/account') },
                    { label: 'Transactions', onClick: () => navigate('/transactions') },
                    { label: 'Dashboard', onClick: () => navigate('/') },
                    { label: 'Calculators', onClick: () => navigate('/superannuation-calculators') },
                    {
                      label: 'Logout',
                      onClick: async () => {
                        log({ event: 'Logout', shopper_id: authentication.user.shopper_id });
                        dispatch(AuthenticationActions.logout());
                        dispatch(AppActions.unSetPartnerContent());
                        dispatch(RoundUpsActions.unSetRoundUpsShopperData());
                        dispatch(RoundUpsTransactionsActions.reSetRoundUpsTransactions());
                        navigate('/login');
                      },
                    },
                    ...(authentication.user && minimal && app.hasBeforeInstallPromptBeenFired && !(window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true)
                      ? [{ label: 'Install App', onClick: () => onInstallButtonClicked() }]
                      : []),
                  ]}
                />
              }
              setIsDialogMenuOpen={setIsDialogMenuOpen}
            />
          ) : !minimal ? (
            <>
              <Link to="/signup">
                <ActionButton text="Sign&nbsp;Up" large={true} />
              </Link>
              <Link to="/login">
                <ActionButton text="Login" large={true} />
              </Link>
            </>
          ) : (
            <Link to="/signup">
              <ActionButton text="Sign&nbsp;Up" large={true} />
            </Link>
          )}
        </div>
      </div>
    </NavigationBarWrapper>
  );
};

NavigationBar.propTypes = {
  minimal: PropTypes.bool,
};

export default NavigationBar;
