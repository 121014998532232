export default ({ theme, isRightDrawerOpen }) => {
  const windowWidth = window.innerWidth;
  return `
    background-color: ${theme.neutralColor};
    color: ${theme.neutralTextColor};
    position: relative;
    display: flex;
    width: ${!isRightDrawerOpen ? '100%' : windowWidth < 768 ? '100vw' : `calc(${windowWidth}px - 620px)`};
    transition: width 0.5s ease-in-out;


    position: relative;

    .shopping-container{
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    
    .shopping-notification{
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .shopping-header-strip {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    
    .main-shopping-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .categories {
      width: 200px;
      display: flex;
      height: calc(100vh - 64px);
      position: sticky;
      top: 0;
      background-color: #fff;
    }

    .search-stores-listing {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 1286px;

      .search-tabs-pagination-container {
        width: 100%;
        background-color: ${theme.neutralColor};
        position: sticky;
        top: 0px;
        z-index: 1;
        border-bottom: solid 1px ${theme.borderColor};
        margin-top: 20px;

      }
    }

    @media(max-width: 1023px) {
      .categories {
        display: none;
      }

      .search-stores-listing {
        width: 100%;
        margin-left: 0px;

        .search-tabs-pagination-container {
          margin-top: 5px;
        }
      }
    }
  `;
};
