export default ({ theme }) => `
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  max-width: 100vw;
  overflow: auto;
  margin-top: 10px;
  

  .simple-popover {
    height: calc(100% - ${theme.headerHeight}px);
    width: calc(100% - 20px);
    max-width: 500px;
    overflow: auto;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    flex-direction: row;

  @media(max-width: 768px) {
    width: 100vw;
  }
`;
