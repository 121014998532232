/* eslint-disable react/require-default-props */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import ActionButton from 'SharedComponents/action-button';

import styles from './Slide.css';

const SlideWrapper = styled.div`${styles}`;

const Slide = ({
  imageUrl, demographicGenderImageUrl, demographicAgeImageUrl, title, text1, text2, text3, text4, backgroundPosition,
}) => (
    <SlideWrapper imageurl={imageUrl} backgroundposition={backgroundPosition}>
      <div className="overlay-container">
        <div className="text-container">
          <h1><span className="highlight-text">{title}</span></h1>
          <p>{text1}</p> 
          <div className="demographic-images-container">
            <img className="demographic-age-image" src={demographicAgeImageUrl}/>
            <img className="demographic-gender-image" src={demographicGenderImageUrl}/>
          </div>
          <div className="button-container">
            <Link to="/sign-up"><ActionButton text="Sign Up Free" large={true} /></Link>
          </div>
        </div>

        <div className="user-type-container">
          <div className="user-type">
            <h1>Target Retirement Goals</h1>
            <p>Helping members with a target retirement goal stay on track.</p>
          </div>
          <div className="user-type">
            <h1>Closing the Gap</h1>
            <p>Helping <span className="highlight-text">Women, Recent Migrants, Self-Employed </span>& anyone unable to contribute to super for their entire working age.</p>
          </div>
          <div className="user-type">
            <h1>Unsure on Financial Advice</h1>
            <p>Helping members whom are unsure if they want to see a financial advisor take some steps to learn and earn.</p>
          </div>
          <div className="user-type">
            <h1>Saving for First Home</h1>
            <p>Users benefit by using their super account to reach their first home deposit on the FHSS scheme.</p>
          </div>
        </div>

      </div>


    </SlideWrapper>
  );

Slide.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  demographicGenderImageUrl: PropTypes.string.isRequired,
  demographicAgeImageUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text1: PropTypes.string,
  text2: PropTypes.string,
  text3: PropTypes.string,
  backgroundPosition: PropTypes.string.isRequired,
};

export default Slide;
