export default () => `
  padding: 10px;
  background-color: #EFEFEF;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px 0 30px;


  >.title {
    font-size: 24px;
    text-align: center;
  }

  .logos-holder {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: calc(100% - 40px) !important;

    @media(min-width: 1023px) {
      flex-wrap: nowrap;
    }

    .logo {
      display: flex;
      padding: 10px;
      margin: 10px;
      background: #ffffff;
      border-radius: 3px;
      max-width: 25vh;
      box-shadow: rgb(0 0 0 / 40%) 0px 0px 20px 2px;

      @media(max-width: 768px) {
        max-width: 15vh;
      }

      @media(min-width: 1024px) {
        max-width: 20vh;
      }

      
    }
  }

  >.content {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 820px;
    flex-wrap: wrap;
  }
`;
