import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { EmailShareButton } from 'react-share';

import CardMemberPlan from 'SharedComponents/card-membership-plan'; 

import styles from './index.css';

const MemberPlansWrapper = styled.div`${styles}`;

const MemberPlans = () => {
  
  const navigate = useNavigate();

  const triggerEmail = () => {
    const subject = encodeURIComponent("Employer Group Inquiry");
    const body = encodeURIComponent("Hello, \n I'd like more information about your Employer Group plan. \n \n My details are: \n Name: \n Phone: \n Company: \n \n Thank You.");
    const mailtoUrl = `mailto:accounts@boostyoursuper.com.au?subject=${subject}&body=${body}`;
    window.location.href = mailtoUrl;
  };

  return(
    <MemberPlansWrapper>
      <div className="content-container" id="funds-we-pay-to">
        <h2 className="title">Pricing</h2>
        <div className="content">
            <p>Current available plans</p>
            <div className="member-cards-container">
              <CardMemberPlan 
                planName="Basic"
                planCost="Free"
                planCostPeriod="Forever"
                keyFeatures={[
                  "Free Cashback to Super",
                  "Superannuation Calculator",
                  "Earn Bonus Boosts",
                  "Hacks for Your Super",
                ]}
                buttonText="Sign Up Free"
                howItWorks="Sign up for free and start earning cashback on your superannuation today."
                valuePropText="Learn and Earn"
                onClick={() => navigate('/signup')}
              />
              <CardMemberPlan 
                planName="Round-Ups"
                planCost="$4"
                planCostPeriod="+GST per month"
                keyFeatures={[
                  "Free Cashback to Super",
                  "Superannuation Calculator",
                  "Earn Bonus Boosts",
                  "Hacks for Your Super",
                  <b>Round-Ups to Super</b>,
                ]}
                buttonText="Sign Up Free"
                howItWorks="Sign up for free and then set up Round-Ups in your account"
                valuePropText="Our Most Impactful Service"
                onClick={() => navigate('/signup')}
              />
              <CardMemberPlan 
                planName="Round-Ups Employer Group"
                planCost="$4"
                planCostPeriod="+GST per month"
                keyFeatures={[
                  "Free Cashback to Super",
                  "Superannuation Calculator",
                  "Earn Bonus Boosts",
                  "Hacks for Your Super",
                  <b>Round-Ups to Super</b>,
                  <b>Employer Group Boosts</b>,
                  <b>Optional 30 Min Online Presentation for Employees</b>,
                ]}
                buttonText="Email Us"
                howItWorks="Email us using your work email and we can give you all the reasons your employer should pay."
                valuePropText="Get Your Employer To Pay"
                onClick={triggerEmail}
                customAction={(
                  <EmailShareButton
                    subject="Employer Group Inquiry"
                    body="Hello, I'd like more information about your Employer Group plan."
                    url="mailto:admin@boostyoursuper.com.au" // Optional: can be omitted for direct email
                    separator=" - "
                  >
                    Email Us
                  </EmailShareButton>
                )}
              />
            </div>
        </div>
      </div>

    </MemberPlansWrapper>
  );
};

export default MemberPlans;
