import React, { useEffect, useState } from 'react';
import { useDetectAdBlock } from 'adblock-detect-react';

import NotificationBanner from 'SharedComponents/notification-banner';
import styled from 'styled-components';

import styles from './index.css.js';

const AdBlockWrapper = styled.section`${styles}`;


const AdblockDetector =() => {
  const adBlockerDetected = useDetectAdBlock();

  console.log('AdBlockDetector triggered');

  console.log('AdBlockDetector status is : ', adBlockerDetected);

  return (
    <div id="adblock-wrapper">
    {
      !adBlockerDetected ? (
        null
      ) : (
        <NotificationBanner 
        title="Are you using an ad blocker?"
        description="Transactions are unlikely to be tracked if you see this notification."
        subtext="Completely disable ad blockers and anything else that could be interfering with tracking before clicking through. Be sure to refresh the page before clicking through. You should not see this message before continuing."
      />
      )
    }
    </div>
  )

}
export default AdblockDetector;
