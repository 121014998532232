import React from 'react';

const SpecialOffers = () => (
  <section style={{ minHeight: 'calc(100vh - 400px)' }}>
    <h2>Special Offers</h2>
    <p>From time to time Boost Your Super will have specific offers or specials. T&Cs for specific offers are listed below.</p>

    <ul>
      <li>The BONUS amount will be specified in the promotional material (eg on our website or email)</li>
      <li>If there is a promotion code the member must be registered on Boost Your Super and have entered the PROMOTION code whilst registering their membership</li>
      <li>Additional bonus payments will be made in accordance with the normal payment processes and schedules made to members.</li>
      <li>The member needs to be registered and active on Boost Your Super</li>
      <li>Boost Your Super reserves the right to disqualify any member engaged in collusive conduct or any other conduct which Boost Your Super considers unfair, fraudulent or untoward.</li>
      <li>Any offers cannot be be transferred</li>
      <li>To the extent permitted by law, Boost Your Super will not be responsible for any loss incurred by you in redeeming or attempting to redeem the offer or for any costs, damages, accident, delay, injury, loss, expense, or inconvenience that may arise from this offer.</li>
      <li>Boost Your Super reserves the right to vary the terms and conditions of the offer at its reasonable discretion.</li>
    </ul>
  </section>
)

export default SpecialOffers;
