export const ADD_STORES = 'ADD_STORES';
export const ADD_DEALS = 'ADD_DEALS';
export const ADD_PROMOTED_DEALS = 'ADD_PROMOTED_DEALS';
export const REMOVE_STORES = 'REMOVE_STORES';
export const REMOVE_DEALS = 'REMOVE_DEALS';
export const REMOVE_PROMOTED_DEALS = 'REMOVE_PROMOTED_DEALS';
export const ADD_CATEGORIES = 'ADD_CATEGORIES';
export const REMOVE_CATEGORIES = 'REMOVE_CATEGORIES';
export const ADD_STORE_TAGS = 'ADD_STORE_TAGS';
export const SET_CURRENT_PROMOTION = 'SET_CURRENT_PROMOTION';
export const ADD_FAVORITE_SHOP = 'ADD_FAVORITE_SHOP';
export const ADD_FAVORITE_SHOPS = 'ADD_FAVORITE_SHOPS';
export const UPDATE_FAVORITE_SHOP = 'UPDATE_FAVORITE_SHOP';
export const REMOVE_FAVORITE_SHOP = 'REMOVE_FAVORITE_SHOP';
export const REMOVE_FAVORITE_SHOPS = 'REMOVE_FAVORITE_SHOPS';
export const SET_FAVORITES_ON_STORES = 'SET_FAVORITES_ON_STORES';
export const SET_FAVORITE_ON_STORE = 'SET_FAVORITE_ON_STORE';
export const UN_SET_FAVORITE_ON_STORE = 'UN_SET_FAVORITE_ON_STORE';
export const ADD_STORE_BOOSTS = 'ADD_STORE_BOOSTS';
export const SET_STORE_BOOSTS_ON_STORES = 'SET_STORE_BOOSTS_ON_STORES';
export const ADD_SHOPPER_BOOSTS = 'ADD_SHOPPER_BOOSTS';
export const TOGGLE_RIGHT_DRAWER = 'TOGGLE_RIGHT_DRAWER';
export const UNSET_SHOPPER_BOOSTS = 'UNSET_SHOPPER_BOOSTS';