export default ({ theme, adornment, isSharing }) => `
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    /* width: calc(100% - 20px); */
    padding: 10px;
    background-color: ${theme.darkSecondaryColor};
    color: ${theme.secondaryTextColor};
    z-index: 0;

    .get-extension-strip-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      padding-right: 20px;
      &.right-align {
        justify-content: flex-end;
      }

      h3 {
        font-weight: bold;
        font-weight: bolder;
        font-size: 1.5em;
        color: ${theme.secondaryTextColor};
      }

      button {
        height: 80px;
      }

    }

    .pop-over-container{
      display: flex;
      flex-direction:row;
      justify-content: center
      margin: 0 10px 0 10px;
      height: 100%;
      align-items: center;

      p {
        color: white;
        font-size: x-large;    
      }

      i {
        font-size: 50px;
        display: flex;
      }
    }

    .ext-popover-how-it-works-header{
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 5px;
      margin-left: 10px; 
      width: 100%;
    }

    .ext-popover-how-it-works-img-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 5px;
      width: 100%;
    }

    .ext-popover-how-it-works-text {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      max-width: 100%;
      margin: 10px;
      padding: 10px;

      p {
        max-width: 200px !important;
        font-size: large;    
      }

      i {
        vertical-align: bottom;
      }
      
    }

    .text-content-container {
      display: flex;
      margin: 10px;
      padding: 10px;
    
    }

    .avail-at-chrome-store {
      max-height: 80px;
      margin: 0px 10px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;

    }



    .share-buttons-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      div[role="button"] {
        margin: 8.5px 5px;
      }
    }
  `;
