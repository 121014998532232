import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {PieChart, Pie, Sector, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Spinner from 'react-spinner-material';
import styled from 'styled-components';

import PopoverHover from 'SharedComponents/popover-hover';

import styles from './index.css';

const GraphCalculatorBalanceWrapper = styled.div`${styles}`;

const GraphCalculatorBalance = ({ 
  innerRadius = 70,
  outerRadius = 120,
  graphData,
  boostedAmount, 
}) => {

  console.log ("GraphCalculatorBalance component triggered")
  console.log('GraphCalculatorBalance graphData is: ', graphData);
  const [activeIndex, setActiveIndex] = useState(0);
  const altAccentColor = useSelector ((state) => state.app.partnerContent.alternate_accent_color);
  //const app = useSelector((state) => state.app);
  //const shopperBoostProfile = useSelector((state) => state.shopping.shopperBoostProfile);
  //const shopperId = useSelector((state) => state.authentication.user.shopper_id);

  // Method to handle mouse enter event on pie chart
  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const cellColors = ['#484848', altAccentColor,'#cc9400','#eead00','#febe11','#ffc732','#fed155','#ffda76','#ffe399','#ffecbb','#fff5dd','#fed155','#ffcd47','#ffc93a','#ffc62d','#ffc220','#febf13','#ffbb06','#f8b500','#ebab00','#dea200']; 

  
  // Method to render customized label for pie chart

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.8;
    const x = cx > 250 ? cx + radius * Math.cos(-midAngle * (Math.PI/180)) : cx;
    const y = cx > 250 ? cy + radius * Math.sin(-midAngle * (Math.PI/180)) : cy;
  
        return (
            index > 0 ?

                    <text x={x} y={y} fill="black" fontSize="15" fontWeight="bold" textAnchor={ x == cx ? 'middle' : x > cx ? 'start' : 'end'} dominantBaseline="central">
                        {'Extra $' + boostedAmount}
                    </text>

            : null
        );
  
  };
  

/*   const CustomTooltip = ({ active, payload}) => {
    console.log('payload at CustomTooltip is: ', payload);
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <h2 className="label">{`${payload[0].payload.boost_name}`}</h2>
        </div>
      );
    }
    return null;
  }; */

  const graphCaptionPopoverText = ("Testng onlny"
/*     <div className="ext-popover-text-container" style={{ maxWidth: '400px' }}>
      <div className="text-content-container" style={{ margin: '20px' }}>
        { sumActiveBoostsCommission() < 100 ?
        (
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center',}} >
            <p style={{ margin: '0px' }}><strong>Your Bonus Boost Power ({sumActiveBoostsCommission()}%) is the sum of your ACTIVE Bonus Boosts.</strong></p>
            {sumActiveBoostsCommission() > 0 ?
            (
              <p> On each transaction you will earn an extra {sumActiveBoostsCommission()}% above normal earnings. For example, for every $1 normally earned you will earn ${(1 + sumActiveBoostsCommission()/100).toFixed(2)}.</p>
            )  : (
              <p> On each transaction you can earn extra above your normal earnings up to a maximum of 100%. For example, if your Bonus Boost Power is 60%, you would earn $1.60 instead of $1.</p>
            )}
            <p>Find actions you can take to get to 100%.</p>
          </div>
        ) : (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center',}} >
            <h2 style={{ margin: '0px 0px 5px 5px' }}><i>Sum of all ACTIVE Bonus Boosts is 100% or more</i></h2>
            <p> Well done! You have reached the maximum Bonus Boost Power of 100%.</p>
            <em style={{ margin: '0px 0px 0px 40px' }}>Keep earning Bonus Boosts to ensure you maintain a Bonus Boost Power of 100%.</em>
        </div>
        )
          }

      </div>
    </div> */
  );


  return (
    <GraphCalculatorBalanceWrapper className="bonus-boost-profile">
        {/* <div className="profile-intro-and-graph" > */}
          {/* <div className="graphs-container">  */} 
            <div className="graph-and-label-container">
                <div className="graph">
                    <ResponsiveContainer width="100%" height="100%">
                        <PieChart width={400} height={400}>
                        <Pie
                            //activeIndex={activeIndex}
                            //activeShape={renderCustomizedLabel}
                            //onMouseEnter={onPieEnter}
                            //data={ JSON.parse(JSON.stringify((graphData))) }
                            data={ graphData }
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            //isAnimationActive={true}
                            label={renderCustomizedLabel}
                            innerRadius={innerRadius}    //{70}
                            //paddingAngle={0}
                            outerRadius={outerRadius}    //{120}
                            //startAngle={90}
                            //endAngle={450}
                            fill="#8884d8"
                            dataKey="value"
                        >
                          {/* {console.log('boostGraphData2() at render Pie is ', boostsGraphData2())} */}
                            {graphData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={cellColors[index % cellColors.length]} />
                            ))}
                        </Pie>
                        <Tooltip
                          content={"Hello tool tip test" //<CustomTooltip />
                           // <text fill="black" fontSize="larger">{`${boost.boost_name}`}
                           // </text>
                          }
                          />
                        </PieChart>
                    </ResponsiveContainer>
                </div>
                <div className="info-holder">
                  <PopoverHover
                      content={graphCaptionPopoverText}
                      header = {null}
                      img = {null}
                      itemToPopover = {
                      ( <i className="material-icons" style={{ fontSize: '36px', display: 'flex' }} >info</i> ) 
                      }
                      horizontalPosn = "right"
                  />
                </div>
                
                {/* <div className="graphs-result-label-container">
                    <div className="graph-result-label">
                        BONUS BOOST POWER:
                    </div>
                    <div className="graph-result-text-amount"> 
                        {   
                  
                            (
                              sumActiveBoostsCommission() > 100 ?
                              '100%' :
                               Math.round(sumActiveBoostsCommission()) + '%'
                            )
                        }
                        <div className="pop-over-container">
                            <PopoverHover
                                content={graphCaptionPopoverText}
                                header = {null}
                                img = {null}
                                itemToPopover = {
                                ( <i className="material-icons" style={{ margin: '0 10px 0 10px', fontSize: '36px', display: 'flex' }} >info</i> ) 
                                }
                                horizontalPosn = "right"
                            />
                        </div>
                    </div>
                </div> */}
            </div>
          {/* </div> */}
        {/* </div> */}

        
    </GraphCalculatorBalanceWrapper>
  );
//};
};

export default GraphCalculatorBalance;