export default ({ theme }) => {
  return `
    width: 100%;
    height: 100%;

    .bonus-boost-profile{
      display: flex;
      flex-direction: column;
      minHeight: 500;
      width: 100%;
    }

    .profile-heading{
      align-items: left;
      justifyContent: center;
      margin-top: 10px;
    }

    .profile-intro-and-graph{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      justify-content: flex-start;
      align-items: center;
    }

  .graphs-container{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
  }

  .graph-and-label-container{
      display: flex;
      flex-direction: column;
      background-color: ${theme.lightBackgroundColor};
      border-radius: 3px;
      margin: 10px;
      height: 100%;
      justify-content: center;
      /* max-width: 100%; */
  }


  .graph{
      display: flex;
      flex-direction: column;
      height: 70%; 
  }

  .info-holder{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 98%;
    padding: 0px 0px 10px 0px;
  }

  .graphs-result-label-container{
      display: flex;
      padding: 10px 15px;
      justify-content: center;
      flex-wrap: wrap;
  }

  .graph-result-label{
      display: flex;
      font-size: 30px;
      font-weight: bold;
      padding: 0px 10px;
      justify-content: flex-end;
      align-items: center;
      text-align: center;
  }

  .graph-result-text-amount{
      display: flex;
      align-items: center;
      font-size: 36px;
      padding: 0px 10px;
      font-weight: bold;
      justify-content: flex-start;
  }
  
  .bsc-label-on-graph{
      display: flex;
      flex-direction: column;
  }

    .bonus-boost-item {
      background-color: ${theme.neutralColor};
      border-bottom: solid 1px ${theme.borderColor};
      width: 100%;
  
      &:hover {
        background-color: ${theme.lightBorderColor};
      }
    }

    .ext-popover-text-container {
      padding: 20px;
    }


  `
};