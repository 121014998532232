import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Shop from './shop';

import styles from './index.css';

const WhyUSWrapper = styled.div`${styles}`;

const categories = ['Groceries', 'Sport and Fitness', 'Health', 'Beauty', 'Department Stores', 'Fashion', 'Children’s toys', 'Gifts', 'Christmas', 'Homewares', 'Baby products'];

const WhyUs = ({ shops, content }) => (
  <WhyUSWrapper>
    <div className="content-container" id="why-bys">
      <div className="heading">
        <h1>{content.why_bys_heading}</h1>
      </div>
{/*       <p>{content.why_bys_text_1}</p>
      <p>{content.why_bys_text_2}</p>
      <p>{content.why_bys_text_3}</p> */}
      <div className="key-points">
        <i className="material-icons styling">check_circle</i>
        <h2>Extremely quick and easy to sign up and use</h2>
      </div>
      <div className="key-points">
        <i className="material-icons styling">check_circle</i>
        <h2>Largest selection of online stores for cashback to super</h2>
      </div>
      <div className="key-points">
        <i className="material-icons styling">check_circle</i>
        <h2>Earn more on each transaction with Bonus Boosts</h2>
      </div>
      <div className="key-points">
        <i className="material-icons styling">check_circle</i>
        <h2>We make weekly payments to super accounts</h2>
      </div>
      <div className="key-points">
        <i className="material-icons styling">check_circle</i>
        <h2>Hacks for Your Super email series</h2>
      </div>
      <div className="key-points">
        <i className="material-icons styling">check_circle</i>
        <h2>Coupons and promotions so you can save when you boost</h2>
      </div>

      <div className="content">
        <div className="categories-container left-content">
          {categories.map((category) => (
            <div className="category">{category}</div>
          ))}
        </div>
        <div className="shops-container right-content">
          {
            shops.sort((a, b) => parseInt(b.clicks) - parseInt(a.clicks)).slice(0, 10).map((shop) => (
              <Shop shop={shop} key={shop.retail_store_id} />
            ))
          }
        </div>
      </div>
    </div>
  </WhyUSWrapper>
);

WhyUs.propTypes = {
  content: PropTypes.object.isRequired,
  shops: PropTypes.array.isRequired,
};

export default connect((state) => ({ shops: state.shopping.stores }))(WhyUs);
