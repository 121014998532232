import React from 'react';
import styled from 'styled-components';

import styles from './index.css';

const ShowShopLogoWrapper = styled.button`${styles}`;

const ShowShopLogo = ({ shop, onGoToShop, logoSize }) => {
  
  console.log('ShowShopLogo triggered with shop: ', shop);
  console.log('ShowShopLogo triggered with logoSize: ', logoSize);
  
  return(
    <ShowShopLogoWrapper shop={shop} onClick={() => onGoToShop(`${encodeURIComponent(shop.merchant_name)}`)} logoSize={logoSize} >
      {/* <div className="header">
        <span>{shop.merchant_name}</span>
      </div> */}
      <div className="logo-image-container" alt={shop.merchant_name} />
      {/* <div className="footer">
        {shop.rate.slice(6)}
      </div> */}
    </ShowShopLogoWrapper>
  );
}

export default ShowShopLogo;
