export default ({ theme }) => `
  width: 100%;
  position: relative;
  

    .rdt_Pagination{
      color: black;
      font-size: inherit;
    }

    .partner-bonus{
      background-color: ${theme.accentColor};
      color: white;
      font-size: smaller;
      font-weight: bold;
      width: fit-content;
      padding: 3px 15px;
      border-radius: 3px;
    }

    .rdt_Table {
      border-spacing: 0;
      min-width: 100%;
      padding-top:20px;

      .rdt_TableHead {
        border-bottom: solid 2px ${theme.darkBorderColor};


        /* .rdt_TableRow {
          height: 70px;
          } */

          .rdt_TableHeadRow {
            display: flex;
            align-items: center;
            text-align: left;
            height: 100%;
            font-size: 18px;
            font-weight: bold;
            color: black;
            border: none;
            padding: 10px;
            /* border-bottom: 1px solid ${theme.borderColor}; */
            /* border-top: 1px solid ${theme.borderColor}; */
            text-transform: uppercase;
            padding: 20px 0px;
            
            .rdt_TableCol {
              min-width: unset;
              padding: 10px 5px 10px 10px;

              rdt_TableColSortable {
                padding: 5px 0px;
              }

              input{
                max-width: 120px;
                border-radius: 2px;
                padding: 5px 10px;
                font-size: unset;
              }
            }


          }
        
      }

      .rdt_TableBody {
        
        .rdt_TableRow {
          background-color: ${theme.neutralColor};
          box-shadow: 0 0 0 1px ${theme.borderColor};
          font-size: unset;
          /* padding: 20px 30px 0px 10px; */

          &:hover {
            background-color: ${theme.lightBorderColor};
          }

          .rdt_TableCell{

           

          }

          td {
            text-align: left;
            border: none;
            padding: 10px;
          }
        }
        
        .rdt_ExpanderRow{
          display: flex;
          padding: 10px;
          
          .expander-row-container{
            width:80vw;
            padding: 10px;
            margin-left:40px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;

            @media(min-width: 1288px) {
              width: 100%;
            }

            .transaction-details{
              padding: 10px;
              display: flex;
              flex-direction: column;

              .transaction-item{
                display: flex;
                flex-direction: row;
                padding: 5px;
                align-items: center;

                .pop-over-container{
                  p{
                    margin: unset;
                  }
                }
              }

              .store-logo{
                max-width: 80px;
                padding: 10px;
              }
              
              .notes{
                padding: 10px;
                font-weight: normal;
                border-color: black;
                border-style: solid;
                border-radius: 3px;
                display: flex;
                flex-wrap: wrap;
                border-width: thin;
                margin-left: 5px;
                
                @media(min-width: 768px) {
                  max-width: 60%;
                }

              }
            }

            .actions-container{
              display: flex;
              flex-direction: column;

              .actions-header{

              }

              .actions-buttons{
                display: flex;
                flex-direction: row;
                padding: 10px;
                align-items: center;

                .pop-over-container{
                  p{
                    margin: unset;
                  }
                }

              }
            }
          }

        }
      }
    }

  
`;
