import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Formsy from 'formsy-react';
import axios from 'axios';
import qs from 'qs';
import ActionButton from 'SharedComponents/action-button';
import log from "Utils/logger";

import styled from 'styled-components';
import styles from './index.css';

const ContactInfoWrapper = styled.div`${styles}`;

import * as AuthenticationActions from 'Flux/authentication/actions';

import MyInput, { isRequired } from 'SharedComponents/text-input';
import CONFIG from 'Config';


@connect((state) => ({
	authentication: state.authentication
}), (dispatch) => ({
	AuthenticationActions: bindActionCreators(AuthenticationActions, dispatch),
}))
export default class ContactInfoForm extends Component {
	constructor(props) {
		super(props);

		const { user } = props.authentication;

		this.state = {
			isFormValid: false,
			isSubmitting: false,

			error: false,
			message: '',

			form: {
				shopper_fname: user.shopper_fname,
				shopper_lname: user.shopper_lname,
				shopper_email: user.shopper_email,
				shopper_email_confirmation: user.shopper_email,
				shopper_phone: user.shopper_phone,
			}
		}

		this.onChange = this.onChange.bind(this);
		this.onValid = this.onValid.bind(this);
		this.onInvalid = this.onInvalid.bind(this);
		this.onValidSubmit = this.onValidSubmit.bind(this);
		this.onInvalidSubmit = this.onInvalidSubmit.bind(this);
		this.updateShopperDetails = this.updateShopperDetails.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		const { user } = nextProps.authentication;
		this.setState({
			form: {
				shopper_fname: user.shopper_fname,
				shopper_lname: user.shopper_lname,
				shopper_email: user.shopper_email,
				shopper_email_confirmation: user.shopper_email,
				shopper_phone: user.shopper_phone,
			}
		});
	}

	onChange(event) {
		console.log('COntact info form onChange', event.target.name, event.target.value);
    this.setState({ form: Object.assign({}, this.state.form, 
			{ 
				[event.target.name]: event.target.value 
			}) });
  }

	onValid() {
		this.setState({ isFormValid: true });
	}

	onInvalid() {
		this.setState({ isFormValid: false });
	}

	onValidSubmit() {
		// TODO: Implement form submission logic
		this.updateShopperDetails();
	}

	onInvalidSubmit() {
		// TODO: show appropriate error messages
		console.log('onInvalidSubmit');
	}

	async updateShopperDetails() {
		const { AuthenticationActions, authentication } = this.props;
		const { user } = authentication;
		this.setState({ isSubmitting: true });

		try {
			const updateShopperDetailsResult = await axios({
				url: `${CONFIG.apiV3Url}/shoppers/resource`,
				method: 'PUT',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					'Authorization': 'Bearer ' + window.localStorage.getItem('auth_token')
		    },
				data: qs.stringify({
					shopper_fname: this.state.form.shopper_fname,
					shopper_lname: this.state.form.shopper_lname,
					shopper_email: this.state.form.shopper_email,
					shopper_phone: this.state.form.shopper_phone,
				}),
			});

			AuthenticationActions.setUser(updateShopperDetailsResult.data);
			console.log('updateShopperDetailsResult.data', updateShopperDetailsResult.data	);

			this.setState({ error: false, message: 'Shopper details updated successfully' });
		} catch(exception) {
			console.log(exception);
			log({
				event: "System-Error",
				source: "ContactInfoForm",
				info: exception,
				...exception,
				shopper_id: authentication.user.shopper_id,
			});

			this.setState({ error: true, message: 'Oops! Something went wrong' });
		} finally {
			this.setState({ isSubmitting: false });
			setTimeout(() => {
				this.setState({ error: false, 'message': '' });
			}, 3000);

		}
	}

	render() {
		const { isSubmitting } = this.state;

		return (
			<ContactInfoWrapper>
				<Formsy
					ref={(form) => this.contactInfoForm = form}
					style={{ width: 'calc(100% - 20px)', padding: 10 }}
					onValidSubmit={this.onValidSubmit}
					onInvalidSubmit={this.onInvalidSubmit}
					onValid={this.onValid}
					onInvalid={this.onInvalid}
				>
					<div style={{ margin: '10px 0', width: '100%' }}>
						<div style={{ width: '100%', margin: '20px 0 0', display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
							<p style={{ fontSize: 16, margin: '7px 0 0', color: '#303031', textAlign: 'left' }}><b style={{ fontWeight: 'bold' }}>Contact Information – </b>Update your contact information.</p>
							<hr style={{ border: 'solid 1px #c5c5c5', margin: '10px 0', width: '100%' }} />
						</div>
						{
							!this.state.isFormValid && this.state.onInvalidSubmit ? (
								<div style={{ width: 'calc(100% - 18px)', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10, backgroundColor: '#ff007d', color: '#fff' }}>
									Some fields are invalid. Please complete them then resubmit the form
								</div>
							) : null
						}
					</div>
					<MyInput
						name="shopper_fname"
						type="text"
						validations={{ 
							isExisty: true, 
							minLength: 2, 
							isAlpha: true 
						}}
						validationErrors={{ 
							isExisty: 'this field is required',
							isDefaultRequiredValue: 'this field is required',
							minLength: 'too short', 
							isAlpha: 'invalid name'
						}}
						required
						value={this.state.form.shopper_fname}
						onChange={this.onChange}
						placeholder="First Name"
						label="First Name"
					/>
					<MyInput
						name="shopper_lname"
						type="text"
						validations={{
							isExisty: true, 
							minLength: 2,
							isAlpha: true 
						}}
						validationErrors={{ 
							isExisty: 'this field is required',
							minLength: 'too short', 
							isAlpha: 'invalid name' 
						}}
						required
						value={this.state.form.shopper_lname}
						onChange={this.onChange}
						placeholder="Last Name"
						label="Last Name"
					/>
					<MyInput
						name="shopper_email"
						type="email"
						disabled
						validations={{ isEmail: true, isExisty: true, matchRegexp: /^(((?!(info@|admin@|web@)).)*)$/ }}
						validationErrors={{ isEmail: 'the email is invalid',  matchRegexp: 'some role based emails are disallowed like admin@, info@ and web@', isDefaultRequiredValue: 'this field is required', isExisty: 'this field is required' }}
						required
						value={this.state.form.shopper_email}
						onChange={this.onChange}
						placeholder="email"
						label="Email"
						//label={<div><span>Email</span></div>}
					/>
					<MyInput
						name="shopper_phone"
						type="tel"
						validations={{ isExisty: true, minLength: 10, maxLength: 16, matchRegexp: /^\+{0,1}(?:[0-9] ?){6,14}[0-9]$/ }}
						validationErrors={{ isExisty: 'this field is required', isDefaultRequiredValue: 'this field is required', minLength: 'too short', maxLength: 'too long', matchRegexp: 'must be a valid phone number' }}
						required
						value={this.state.form.shopper_phone}
						onChange={this.onChange}
						placeholder="0402123456"
						label="Mobile Phone"
					/>
				<div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
						<ActionButton type="submit" text={isSubmitting ? 'Updating Contact Info' : 'Update Contact Info'} large={true} disabled={isSubmitting} loading={isSubmitting} active={!isSubmitting} />
						{
							this.state.message ? (
								<span style={{ color: this.state.error ? '#ff007d' : '##03031', fontSize: 20, marginLeft: 20 }}>{this.state.message}</span>
							) : null
						}
					</div>
				</Formsy>
			</ContactInfoWrapper>
		)
	}
}
