import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import log from "Utils/logger";

import * as AuthenticationActions from 'Flux/authentication/actions';

const useFetchShopperProfile = () => {
  console.log ('*** * fetchShopperProfile: hook triggered');
  const isFetchingShopperProfileRef = useRef(false);
  const hasFetchedShopperProfileRef = useRef(false);
  const dispatch = useDispatch();
  
  const storedShopperProfile = useSelector(state => state.authentication.user);

  const fetchShopperProfile = async () => {
    isFetchingShopperProfileRef.current = true;
    try {

      const result = await dispatch(AuthenticationActions.fetchShopper());
      console.log ('*** * fetchShopperProfile: fetch  result  is:', result)
      console.log('*** * fetchShopperProfile: storedShopperProfile:', storedShopperProfile)

      const newShopperProfile = result.user_profile;

      const { roundups_visible, contribute_now_visible } = result.data.user_profile[0];

      console.log('*** * fetchShopperProfile: roundups_visible:', roundups_visible);
      console.log('*** * fetchShopperProfile: contribute_now_visible:', contribute_now_visible);
      

      //fetchShopper response is slightly different to sign-in response. Hence only
      //checking for roundups and contribute now visibility
      //once active accounts have all been updated to roundups and contribute now visible we can stop checking for updates.
      //might take 1-2 years and then check how many accounts not updated and then decide if we need to keep checking

      if (roundups_visible !== storedShopperProfile.roundups_visible){
        console.log('*** * fetchShopperProfile: roundups_visible are different');
        console.log('*** * fetchShopperProfile: storedShopperProfile.roundups_visible: ', storedShopperProfile.roundups_visible);
        console.log('*** * fetchShopperProfile: updateRoundupsVisibility with: ', roundups_visible);
        dispatch(AuthenticationActions.updateRoundupsVisibility(roundups_visible));
      }

      if (contribute_now_visible !== storedShopperProfile.contribute_now_visible){
        console.log('*** * fetchShopperProfile: contribute_now_visible are different');
        dispatch(AuthenticationActions.updateContributeNowVisibility(contribute_now_visible));
      }

    } catch (exception) {
      console.log(exception);
      log({
        event: "System-Error",
        source: "Fetch Shopper Profile",
        //'shopper_id': authentication? authentication.user.shopper_id : '000',
        info: exception,
        ...exception,
      });
    } finally {
      isFetchingShopperProfileRef.current = false;
      hasFetchedShopperProfileRef.current = true;
    }
  };

  useEffect(() => {
    if ( storedShopperProfile 
        && !isFetchingShopperProfileRef.current 
        && !hasFetchedShopperProfileRef.current) {
        fetchShopperProfile();
    }
  } , []);


  return { 
           fetchShopperProfile };
};

export default useFetchShopperProfile;
