import React, { memo } from 'react';
import styled from 'styled-components';

import ActionButton from 'SharedComponents/action-button';

import styles from './index.css';

const MemberPlanCardWrapper = styled.div`${styles}`;

const CardMemberPlan = ({
  planName,
  planCost,
  planCostPeriod,
  keyFeatures,
  buttonText,
  howItWorks,
  onClick = 'signup', // Default action
  valuePropText,
  customAction // New prop to accept custom action like EmailShareButton
}) => {

  console.log('MemberPlanCard triggered with MemberPlanCard planName', planName);

  function openInNewWindow() {
    console.log('openInNewWindow triggered with typeof : ', typeof buttonLink);
    console.log('openInNewWindow buttonLink: ', buttonLink);
    if (typeof buttonLink === 'string') {
      const webstoreWindow = window.open(buttonLink, '_blank');
      if (webstoreWindow) {
        webstoreWindow.focus();
      }
    } else if (typeof onClick === 'function') {
      const url = onClick();
      if (url) {
        const webstoreWindow = window.open(url, '_blank');
        if (webstoreWindow) {
          webstoreWindow.focus();
        }
      }
    }
  }
  
  return (
    <MemberPlanCardWrapper>
      <div className="member-card-container">
        <div className="plan-name">
          {planName}
        </div>
        <div className="plan-cost-container">
          <div className="plan-cost">
            {planCost}
          </div>
          <div className="plan-cost-period">
            {planCostPeriod}
          </div>
        </div>
        <div className="how-it-works">
          {howItWorks}
        </div>
        <div className="title-and-nav-button">
              {/* <div className="title">
                Included
              </div> */}
              <div className="nav-buttons">
                <ActionButton
                  style={{ margin: "10px" }}
                  large={true}
                  text={buttonText} // Button label
                  onClick={onClick} // If customAction exists, use it, else fallback to onClick
                />
                {//openInNewWin && !buttonDisabled &&
                  //<i className="material-icons" onClick={openInNewWindow}>open_in_new</i>
                }
              </div>
            </div>
        { valuePropText && 
          (<div className="value-prop">
            {valuePropText}
          </div>
          )
        }
        <div className="card-details">
          <div className="card-info-container">

            <div className="title">
                Included:
              </div>
            {keyFeatures.map((keyFeature) => (
              <div className="key-points">
                <i className="material-icons styling">check_circle</i>
                <div className="key-point">
                  {keyFeature}
                </div>
              </div>
            ))}
            {/* <div className="explanation">{howItWorks}</div> */}
          </div>
        </div>
      </div>

    </MemberPlanCardWrapper>
  );
}

export default memo(CardMemberPlan);
