const env = process.env.NODE_ENV;

const config = {
  local: {
    apiUrl: 'http://localhost/apiv2',
    apiV3Url: 'http://localhost/apiv3',
    assetsUrl: 'http://localhost/shopnate',
    baseUrl:'http://localhost/shopnate',
    chromeExtensionId: 'ddnnlfoonlgjjflmlbfopafcdfjigggo',
  },
  homestead: {
      apiUrl: 'http://apiv2.boostyoursuper.local',
      apiV3Url: 'http://apiv3.boostyoursuper.local',
      assetsUrl: 'http://boostyoursuper.local',
      baseUrl:'http://boostyoursuper.local',
      chromeExtensionId: 'inhjlkjhjnghocpkokpgplknanapgeam',
  },
  development: {
    apiUrl: 'https://apiv3.boostyoursuper.com.au',
    apiV3Url: 'https://api-staging.boostyoursuper.com.au',
    //apiV3Url: 'https://apiv3.boostyoursuper.com.au', //prod
    assetsUrl: 'https://assets.boostyoursuper.com.au',
    baseUrl: 'https://boostyoursuper.com.au',
    chromeExtensionId: 'inhjlkjhjnghocpkokpgplknanapgeam',
    ablyBroadcastApiKey: '1GRrKg.Ut4jOQ:rw6wXfKB4vOavWYpDsPVZhLNd3shm7tCBauaai92zDE', //dev
    //ablyBroadcastApiKey: 'iNy6LQ.0Kkr6A:aHYCdpn0BcA5SgoJ9vnk5ukE-9Br2gWTotmTr9pPVyg', //prod
  },
  staging: {
    apiUrl: 'https://staging-apiv3.boostyoursuper.com.au',
    apiV3Url: 'https://api-staging.boostyoursuper.com.au',
    assetsUrl: 'https://assets.boostyoursuper.com.au',
    baseUrl:'https://boostyoursuper.com.au',
    chromeExtensionId: 'inhjlkjhjnghocpkokpgplknanapgeam',
  },
  production: {
    apiUrl: 'https://api.boostyoursuper.com.au',
    apiV3Url: 'https://apiv3.boostyoursuper.com.au',  //prod
    //apiV3Url: 'https://api-staging.boostyoursuper.com.au', //dev
    assetsUrl: 'https://assets.boostyoursuper.com.au',
    baseUrl: 'https://boostyoursuper.com.au',
    chromeExtensionId: 'inhjlkjhjnghocpkokpgplknanapgeam',
    //ablyBroadcastApiKey: '1GRrKg.Ut4jOQ:rw6wXfKB4vOavWYpDsPVZhLNd3shm7tCBauaai92zDE', //dev
    ablyBroadcastApiKey: 'iNy6LQ.0Kkr6A:aHYCdpn0BcA5SgoJ9vnk5ukE-9Br2gWTotmTr9pPVyg', //prod
  }
}

export default config[env];
