import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-grid-system';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { isChrome, isMobile } from 'react-device-detect';


import facebookLogo from 'Images/facebook.svg';
import instagramLogo from 'Images/instagram.svg';
import twitterLogo from 'Images/twitter.svg';
import pintrestLogo from 'Images/pintrest.png';
import linkedinLogo from 'Images/linkedin.svg';
import styles from './index.css';

const FooterWrapper = styled.div`${styles}`;

const Footer = ({ user }) => (
  <FooterWrapper>
    <div className="content-container">
      <Container fluid className="links-container" style={{ margin: 0, padding: 0, width: '100%' }}>
        <Row style={{ margin: 0, padding: 0, width: '100%' }}>
          <Col lg={3} md={3} sm={4} xs={12} className="links-group" style={{ margin: 0, padding: 0 }}>
            <div className="header">
              <span>Legal</span>
            </div>
            <div className="links">
              <Link className="link" to="/terms-and-conditions">Terms & Conditions</Link>
              <Link className="link" to="/privacy-policy">Privacy Policy</Link>
              <Link className="link" to="/financial-services-guide">Financial Services Guide</Link>
              <span className="link">
                &copy;
                {moment().format('YYYY')}
                , ABN 30631200425
              </span>
            </div>
          </Col>
          <Col lg={3} md={3} sm={4} xs={12} className="links-group" style={{ margin: 0, padding: 0 }}>
            <div className="header">
              <span>Get Started</span>
            </div>
            <div className="links">
              {
                !user ? (
                  <div style={{ display: "flex", flexDirection: "column"}}>
                    <Link className="link" to="/login">Login</Link>
                    <Link className="link" to="/signup">Sign Up</Link>
                  </div>
                ) : (
                  <div  style={{ display: "flex", flexDirection: "column"}}>
                    <Link className="link" to="/dashboard">Dashboard</Link>
                    <Link className="link" to="/settings?tab=round-ups">Round-Ups</Link>
                  </div>
                )
              }
{/*               {
                !user ? (
                  <Link className="link" to="/signup">Sign Up</Link>
                ) : null
              } */}
              <Link className="link" to="/shops">Shops</Link>
              <Link className="link" to="/superannuation-calculators">Super Calculators</Link>
              <Link className="link" to="/faq">FAQ</Link>
              {/*
                <Link className="link" to="/share">Share</Link>
              */}
               { (!isMobile && isChrome) ? (
                    <a className="link" href="https://chrome.google.com/webstore/detail/boost-your-super/inhjlkjhjnghocpkokpgplknanapgeam" target="_blank">Chrome Extension</a>
                 ) : null
                }
            </div>
          </Col>
          <Col lg={3} md={3} sm={4} xs={12} className="links-group" style={{ margin: 0, padding: 0 }}>
            <div className="header">
              <span>Partnerships</span>
            </div>
            <div className="links">
              <Link className="link" to="/partnerships">Super funds</Link>
              <Link className="link" to="/partnerships">Employers</Link>
              <Link className="link" to="/partnerships">Merchants</Link>
              <Link className="link" to="/partnerships">Investors</Link>
              <Link className="link" to="/partnerships">Financial Advisors</Link>
              <Link className="link" to="/partnerships">Founders</Link>
            </div>
          </Col>
          <Col lg={3} md={3} sm={4} xs={12} className="links-group" style={{ margin: 0, padding: 0 }}>
            <div className="header">
              <span>Connect</span>
            </div>
            <div className="links">
              <a className="link image-link" href="https://facebook.com/boost-your-super" target="_blank">
                <div className="image-container">
                  <img src={facebookLogo} alt="facebook logo" />
                </div>
                <span>Facebook</span>
              </a>
              <a className="link image-link" href="https://www.instagram.com/boostyoursuper" target="_blank">
                <div className="image-container">
                  <img src={instagramLogo} alt="instagram logo" />
                </div>
                <span>Instagram</span>
              </a>
              <a className="link image-link" href="https://twitter.com/boostyoursuper" target="_blank">
                <div className="image-container">
                  <img src={twitterLogo} alt="twitter logo" />
                </div>
                <span>Twitter</span>
              </a>
              <a className="link image-link" href="https://linkedin.com/company/boostyoursuper" target="_blank">
                <div className="image-container">
                  <img src={linkedinLogo} alt="instagram logo" />
                </div>
                <span>LinkedIn</span>
              </a>
              <a className="link image-link" href="https://www.pinterest.com/boostyoursuper" target="_blank">
                <div className="image-container">
                  <img src={pintrestLogo} alt="pintrest logo" />
                </div>
                <span>Pintrest</span>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="gen-advice-container">
        <p><b>General Advice Warning:</b> The information provided by Boost Your Super is general advice and does not take into account the individual’s objectives, financial situation or needs. Before acting on this general advice, individuals should, consider the appropriateness of the advice having regard to their objectives, financial situation or needs. The information provided on this website is prepared by Boost Your Super Pty Ltd (ABN 30631200425) an authorised representative of Hejaz Financial Advisers Pty Ltd (ABN 49 634 683 613 Australian Financial Services Licence (AFSL) Number: 517686). The content of this website has been prepared for informational purposes only and is not intended to amount to financial product advice or a recommendation. </p>
      </div>  
    </div>
  </FooterWrapper>
);

export default connect((state) => ({ user: state.authentication.user }))(Footer);
