import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import log from "Utils/logger";

import * as AppActions from 'Flux/app/actions';

const useFetchLandingPageContent = () => {
  console.log ('*** * fetchLandingPageContent: hook triggered');
  const isFetchingLandingPageContentRef = useRef(false);
  const hasFetchedLandingPageContentRef = useRef(false);
  const dispatch = useDispatch();
  
  const storedLandingPageContent = useSelector(state => state.app.landingPageContent || null);
  const app = useSelector(state => state.app);
  console.log('*** * fetchLandingPageContent: storedLandingPageContent:', storedLandingPageContent)
  console.log('*** * fetchLandingPageContent: app:', app)

  const fetchLandingPageContent = async () => {
    isFetchingLandingPageContentRef.current = true;
    try {
      const result = await dispatch(AppActions.fetchLandingPageContent());
      console.log ('*** * fetchLandingPageContent: fetch  result  is:', result)
      console.log('*** * fetchLandingPageContent: storedLandingPageContent:', storedLandingPageContent)

      const newLandingPageContent = result.data.content[0];

      console.log('*** * fetchLandingPageContent: newLandingPageContent:', newLandingPageContent);

      if (shouldUpdateStore(newLandingPageContent, storedLandingPageContent)) {
        console.log('*** * fetchLandingPageContent: and storedLandingPageContent are different');
        console.log('*** * fetchLandingPageContent: DISPATCH SET boostProfile with newLandingPageContent: ', newLandingPageContent);
        dispatch(AppActions.setLandingPageContent(newLandingPageContent));
      }
    } catch (exception) {
      console.log(exception);
      log({
        event: "System-Error",
        source: "Fetch Shopper boostProfile",
        //'shopper_id': authentication? authentication.user.shopper_id : '000',
        info: exception,
        ...exception,
      });
    } finally {
      isFetchingLandingPageContentRef.current = false;
      hasFetchedLandingPageContentRef.current = true;
    }
  };

  function shouldUpdateStore(newLandingPageContent, storedLandingPageContent) {

    if (!isEqual(newLandingPageContent,storedLandingPageContent)) {
      console.log('*** * fetchLandingPageContent: confirmed not equal');
      return true; 
    }
  
    return false; 
  }

  useEffect(() => {
    console.log('*** * fetchLandingPageContent: useEffect triggered');
    if (   //!storedLandingPageContent
        !isFetchingLandingPageContentRef.current 
        && !hasFetchedLandingPageContentRef.current) {
        fetchLandingPageContent();
    }
  } , []);

  return { fetchLandingPageContent };
};

export default useFetchLandingPageContent;