import React, { Component } from 'react';
import styled from 'styled-components';

import styles from  './index.css';

import ForgotPasswordForm from 'SharedComponents/forgot-password-form';

const ForgotPasswordWrapper = styled.div`${styles}`;

export default class ForgotPassword extends Component {
  render() {
    return (
      <ForgotPasswordWrapper>
        <div className="sign-in-form-container">
          <ForgotPasswordForm
            header={(
              <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                <h1>Reset Password</h1>
                <span>To regain access to your account</span>
              </div>
            )}
          />
        </div>
      </ForgotPasswordWrapper>
    );
  }
}
