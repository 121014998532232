import React, { useState, useEffect, useRef, useCallback } from 'react';
import { withFormsy } from 'formsy-react';

const TextInput = (props) => {
  const inputRef = useRef(null);
  const [isTouched, setIsTouched] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const changeValue = useCallback((event) => {
    props.setValue(event.currentTarget.value);
    if (props.onChange) props.onChange(event);
  }, [props]);

  const handleFocus = useCallback(() => {
    setIsFocused(true);
    console.log(`Focus gained on input: ${props.name}`);
  }, []);

  const handleBlur = useCallback(() => {
    setIsTouched(true);
    props.setValue(props.value);
    setIsFocused(false);
    console.log(`Focus lost on input: ${props.name}`);
  }, [props]);

  useEffect(() => {
    if (props.autoFocus && inputRef.current && !isFocused) {
      inputRef.current.focus(); // Remove timeout and focus directly
    }
  }, [props.autoFocus, isFocused]);

  const showError = isTouched && !props.isValid;
  const errorMessage = showError ? props.errorMessage : null;

  return (
    <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', color: '#495C63' }}>
      {props.label && (
        <label style={{ margin: '10px 0 -5px', fontSize: '18px', fontWeight: 'bold', padding: 0, width: '100%' }}>
          {props.label}
        </label>
      )}
      <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
        <input
          ref={inputRef}
          onChange={changeValue}
          onFocus={handleFocus}
          onBlur={handleBlur}
          type={props.type || "text"}
          value={props.value || ''} // Use props.value directly
          name={props.name}
          id={props.id}
          placeholder={props.placeholder}
          style={{ border: 'solid 1px #d9d9d9', fontSize: 20, fontWeight: 300, padding: '10px 15px', margin: '10px 0', color: '#495C63', width: props.adornment ? 'calc(100% - 60px)' : 'calc(100% - 30px)', ...props.style }}
          required={props.required}
          disabled={props.disabled}
        />
        {props.adornment}
      </div>
      {errorMessage && (
        <span style={{ color: '#ff007d', margin: '-10px 0 20px 10px' }}>{errorMessage}</span>
      )}
    </div>
  );
};

export default withFormsy(TextInput);
