import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import log from "Utils/logger";

//import CONFIG from "Config";

import * as TransactionActions from 'Flux/transactions/actions';

const useFetchCashbackTransactions = () => {
  console.log ("*** ** fetchCashbackTransactions: hook triggered")
  const isFetchingCashbackTransactionsRef = useRef(false);
  const hasFetchedCashbackTransactionsRef = useRef(false);
  const dispatch = useDispatch();
  //const roundups = useSelector(state => state.roundups);
  
  const storedCashbackTransactions = useSelector(state => state.transactions.items);
  
  const user = useSelector(state => state.authentication.user);

  const fetchCashbackTransactions = async () => {
    isFetchingCashbackTransactionsRef.current = true;
    try {
            //   transactions.perPage, 
      //   (nextPage - 1) * transactions.perPage
      // );
      // if (onFetchCauseDonationsResult.data.status) {
      //   TransactionActions.setTransactions(
      //     onFetchCauseDonationsResult.data.transaction, 
      //     onFetchCauseDonationsResult.data.count, 
      //     nextPage
      //   );
      
      // if (!storedCashbackTransactions || !storedCashbackTransactions.length || !user) {
      //   console.log('*** ** fetchCashbackTransactions: exit function as no storedCashbackTransactions or no user');
      //   isFetchingCashbackTransactionsRef.current = false;
      //   return;
      // }
      
      const result = await dispatch(TransactionActions.fetchTransactions(2000,1));
      //const newCashbackTransactions = result.data.data;

     console.log('*** ** fetchCashbackTransactions: result: ', result);

     const newCashbackTransactions = result.data.transaction;
     
      console.log('*** ** fetchCashbackTransactions: storedCashbackTransactions: ', storedCashbackTransactions)
      console.log ('*** ** fetchCashbackTransactions: newCashbackTransactions: ', newCashbackTransactions);
      
      //console.log('*** ** fetchCashbackTransactions: JSON.stringify(storedFavouriteShops): ', JSON.stringify(storedCashbackTransactions));
      //console.log ('*** ** fetchCashbackTransactions: JSON.stringify(newCashbackTransactions): ', JSON.stringify(newCashbackTransactions));

      if (shouldUpdateStore(newCashbackTransactions, storedCashbackTransactions)) {
        console.log('*** ** fetchCashbackTransactions: DIFFERENCE - should update store');
        dispatch(TransactionActions.setTransactions(newCashbackTransactions, result.data.count, 1));
      }
    

    } catch (exception) {
      console.log(exception);
      log({
        event: "System-Error",
        source: "Fetch CashbackTransactions",
        info: exception,
        ...exception,
      });
    } finally {
      isFetchingCashbackTransactionsRef.current = false;
      hasFetchedCashbackTransactionsRef.current = true;
    }
  };

  function shouldUpdateStore(newCashbackTransactions, storedCashbackTransactions) {


    if (!isEqual(newCashbackTransactions, storedCashbackTransactions)) {
      return true; // Differences found
    } 

  
    return false; // No differences found
  }

  useEffect(() => {
    if ( user
        && !isFetchingCashbackTransactionsRef.current
        && !hasFetchedCashbackTransactionsRef.current) {
          fetchCashbackTransactions();
    }
  }, []);


  return { 
          isFetchingCashbackTransactions: isFetchingCashbackTransactionsRef.current,
          // hasFetchedCashbackTransactions: hasFetchedCashbackTransactionsRef.current,
           fetchCashbackTransactions };

};

export default useFetchCashbackTransactions;
