import React, { useEffect, useState } from 'react';
import numeral from 'numeral';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import DataTable from 'react-data-table-component-with-filter';

import ToggleSwitch from 'SharedComponents/toggle-switch';
import PopoverHover from 'SharedComponents/popover-hover';

import * as RoundUpsActions from 'Flux/round-ups/actions';

import log from "Utils/logger";

import styles from './index.css';

numeral.defaultFormat('00.00');

const RoundUpsAcctsTableWrapper = styled.div`${styles}`;

const RoundUpsAcctsTable = ({ 
  roundUpsLinkedAccounts,
  RoundUpsActions,
  onError,
  authentication,
}) => {

  console.log('RoundUpsAcctsTable triggered with roundUpsLinkedAccounts', roundUpsLinkedAccounts);
  
  const [linkedAccounts, setLinkedAccounts] = useState(roundUpsLinkedAccounts);
  const [updatingRoundUpsEnabledStates, setUpdatingRoundUpsEnabledStates] = useState(new Array(linkedAccounts.length).fill(false));
  const [updatingActiveDebitEnabledStates, setUpdatingActiveDebitEnabledStates] = useState(new Array(linkedAccounts.length).fill(false));

  const [updatingAccountRoundupStatus, setUpdatingAccountRoundupStatus] = useState(false);
  const [updatingAccountDebitStatus, setUpdatingAccountDebitStatus] = useState(false);

  const generateFalseArray = (length) => new Array(length).fill(false);

  const resetUpdatingActiveDebitEnabledStates = () => {
    const newUpdatingStates = generateFalseArray(linkedAccounts.length);
    console.log('* resetUpdatingActiveDebitEnabledStates triggered with newUpdatingStates: ', newUpdatingStates);
    setUpdatingActiveDebitEnabledStates(newUpdatingStates);
  };

  const resetUpdatingRoundUpsEnabledStates = () => {
    const newUpdatingStates = generateFalseArray(linkedAccounts.length);
    console.log('* resetUpdatingRoundUpsEnabledStates triggered with newUpdatingStates: ', newUpdatingStates);  
    setUpdatingRoundUpsEnabledStates(newUpdatingStates);
  };
  //const [updatingStates, setUpdatingStates] = useState(roundUpsLinkedAccounts.map(() => false));


  useEffect(() => {
    console.log('*useEffect roundUpsLinkedAccounts triggered with roundUpsLinkedAccounts: ', roundUpsLinkedAccounts);
    console.log('*useEffect roundUpsLinkedAccounts triggered with linkedAccounts: ', linkedAccounts);
    setLinkedAccounts(roundUpsLinkedAccounts);
  }, [ roundUpsLinkedAccounts ]);

  useEffect(() => {
    const hasDebitEnabledAccount = linkedAccounts.some(account => account.debit_enabled);
    const hasRoundupEnabledAccount = linkedAccounts.some(account => account.roundup_enabled);
    if (!hasDebitEnabledAccount) {
      const errorMessage1 = "You will need to set an account to Debit Enabled so we can transfer funds from here to your super.";
      onError(errorMessage1);
    } else if (!hasRoundupEnabledAccount) {
      const errorMessage2 = "You will need to enable Round Ups on at least one account so we can calculate Round Ups amounts";
      onError(errorMessage2);
    } else {
      onError("");
    }
  }, [linkedAccounts, onError]); 


  async function enableAccountRoundups (account_id, newRoundUpEnabledStatus) {
    console.log('*enableAccountRoundups triggered with account_id: ', account_id);
    console.log('*enableAccountRoundups triggered with newRoundUpEnabledStatus: ', newRoundUpEnabledStatus);
    setUpdatingAccountRoundupStatus(true);

    try {
      //const updatedAccounts = [...linkedAccounts];
      const result = await RoundUpsActions.enableAccountRoundUps(account_id, newRoundUpEnabledStatus);
      console.log('*enableAccountRoundups using result: ', result);
      console.log('*enableAccountRoundups.data.data result: ', result.data.data);
      if (result.data){
        RoundUpsActions.setRoundUpsShopperData(result.data.data);
      } else {
        console.log('enableAccountRoundups result.data.data is null'); // get rid of else after testing
      }

      //RoundUpsActions.setRoundUpsLinkedAccounts(updatedAccounts);
    } catch (exception) {
      console.log('enableAccountRoundups exception: ', exception);
      log({
        event: "System-Error",
        source: "RoundUps Table - enableAccountRoundups",
        info: exception,
        'shopper_id': authentication ? authentication.user.shopper_id : 'Not Signed In',
        ...exception,
      });
      onError(exception);
    } finally {
      
      console.log('* time to reset updating RoundUPs Enabled states');
      resetUpdatingRoundUpsEnabledStates();
      setUpdatingAccountRoundupStatus(false);
      console.log('finally in setUpdatingAccountRoundupStatus has inkedAccounts: ', linkedAccounts);
    }
  }

  async function enableAccountDebit (account_id, newRoundUpEnabledStatus) {
    console.log('*enableAccountDebit triggered with account_id: ', account_id);
    console.log('*enableAccountDebit triggered with newRoundUpEnabledStatus: ', newRoundUpEnabledStatus);
    setUpdatingAccountDebitStatus(true);

    try {
      //const updatedAccounts = [...linkedAccounts];
      const result = await RoundUpsActions.enableAccountDebit(account_id, newRoundUpEnabledStatus);
      console.log('*enableAccountDebit using result: ', result);
      console.log('*enableAccountDebit.data.data result: ', result.data.data);
      if (result.data){
        RoundUpsActions.setRoundUpsShopperData(result.data.data);
      } else {
        console.log('setRoundUpsShopperData result.data.data is null'); // get rid of else after testing
      }

    } catch (exception) {
      console.log('enableAccountDebit exception: ', exception);
      log({
        event: "System-Error",
        source: "RoundUps Table - enableAccountDebit",
        info: exception,
        'shopper_id': authentication ? authentication.user.shopper_id : 'Not Signed In',
        ...exception,
      });
      //onError(exception);
    } finally {
      
      console.log('* time to reset updating RoundUPs Enabled states');
      resetUpdatingActiveDebitEnabledStates();
      setUpdatingAccountDebitStatus(false);
      console.log('finally in setUpdatingAccountRoundupStatus has inkedAccounts: ', linkedAccounts);
    }
  }

  const roundupInfo = (
      <span className="pop-over-container">
        <PopoverHover
            content={
              <div style={{
                maxWidth: '300px', padding: 10, fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left',
              }}
              >
              If enabled then spending on this account will be Rounded Up and sourced from your Debit Enabled account to be paid to your super.
              </div>
            }
            header = {null}
            img = {null}
            itemToPopover = {
              ( <i className="material-icons" style={{ margin: '0 5px 0 5px', verticalAlign: '-40%' }} >info</i> )
            }
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
        />
      </span>
  );

  const debitInfo = (
    <span className="pop-over-container">
      <PopoverHover
            content={
              <div style={{
                maxWidth: '300px', padding: 10, fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left',
              }}
              >
              You must have one account Debit Enabled where you source funds for your super. This account cannot be a credit, loan or mortgage account
              </div>
            }
            header = {null}
            img = {null}
            itemToPopover = {
              ( <i className="material-icons" style={{ margin: '0 5px 0 5px', verticalAlign: '-40%' }} >info</i> )
            }
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
      />
    </span>
  );

  const unavailableMessage = (
    <span className="pop-over-container">
      <PopoverHover
        content={
          <div style={{
            maxWidth: '300px', padding: 10, fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left',
          }}
          >
          This account is unavailable for Round-Ups. It may be closed or sharing of account transactions to Boost Your Super is not enabled. To make it available you can try CONNECT A BANK using the button below and sharing transactions with POKITPAL who are the Round-Ups service provider for Boost Your Super.
          </div>
        }
        header = {null}
        img = {null}
        itemToPopover = {
          ( <i className="material-icons" style={{ margin: '0 5px 0 5px', verticalAlign: '-40%' }} >info</i> )
        }
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      />
  </span>
  )

  const handleToggleEnableAccountRoundUps = (accountId, property, index) => {
    console.log('*handleToggle triggered with accountId: ', accountId);
    console.log('*handleToggle triggered with property: ', property);
    console.log('*handleToggle triggered with index: ', index);

    if ( updatingAccountRoundupStatus ) {
      return; 
    }

    if (property === 'activeRoundUpsEnabled') {
      const newUpdatingStates = [...updatingRoundUpsEnabledStates];
      newUpdatingStates[index] = true;
      console.log('*handleToggle lets set updatingRoundUpsEnabledStates: ', newUpdatingStates);
      setUpdatingRoundUpsEnabledStates(newUpdatingStates);
    } else if (property === 'activeDebitEnabled') {
      const newUpdatingStates = [...updatingActiveDebitEnabledStates];
      newUpdatingStates[index] = true;
      console.log('*handleToggle lets set updatingActiveDebitEnabledStates: ', newUpdatingStates);
      setUpdatingActiveDebitEnabledStates(newUpdatingStates);
    }

    console.log('*handleToggle lets call enableAccountRoundups with !linkedAccounts[index].roundup_enabled: ', !linkedAccounts[index].roundup_enabled);
    enableAccountRoundups(accountId, !linkedAccounts[index].roundup_enabled);

  };

  const handleToggleEnableAccountDebit = (accountId, property, index) => {
    console.log('*handleToggle triggered with accountId: ', accountId);
    console.log('*handleToggle triggered with property: ', property);
    console.log('*handleToggle triggered with index: ', index);

    if ( updatingAccountDebitStatus ) {
      return; 
    }
      const newUpdatingStates = [...updatingActiveDebitEnabledStates];
      newUpdatingStates[index] = true;
      console.log('*handleToggle lets set updatingActiveDebitEnabledStates: ', newUpdatingStates);
      setUpdatingActiveDebitEnabledStates(newUpdatingStates);

      console.log('*handleToggle lets call enableAccountDebit with new status !linkedAccounts[index].debit_enabled: ', !linkedAccounts[index].debit_enabled);  
      enableAccountDebit(accountId, !linkedAccounts[index].debit_enabled);

  };

  const ExpandedComponent = ({ data }) => {
    
    const rowNumber = linkedAccounts.findIndex(account => account.id === data.id);
    
    return(
      <div className="expander-row-container">
        <div className="account-details">

          <div className="account-details-item">
            <span style={{fontWeight:"bold", fontSize:"larger", textTransform:"uppercase"}}>{data.name}</span>
          </div>
          <div className="account-details-item">
            
              
                { data.type && (
                    data.type.includes('credit') || data.type.includes('mortgage') || data.type.includes('loan') 
                    ?   
                    <span className="account-details-item-label">Debit Enabled:
                      <div className='borrowing-account'> NA 
                          <span className="pop-over-container">
                          <PopoverHover
                            content={
                              <div style={{
                                maxWidth: '300px', padding: 10, fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left',
                              }}
                              >
                              A credit card, mortgage or loan account cannot be used for debits. Ensure you link a transaction or savings account for debit facilities.
                              </div>
                            }
                            header = {null}
                            img = {null}
                            itemToPopover = {
                              ( <i className="material-icons" style={{ margin: '0 5px 0 5px', verticalAlign: '-40%' }} >info</i> )
                            }
                            anchorOrigin={{
                              vertical: 'center',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'center',
                              horizontal: 'right',
                            }}
                          />
                          </span>
                      </div>
                    </span>
                    :   
                      <div className="account-details-item-label">Debit Enabled:
                          {debitInfo}
                          <div className="toggle-switch-holder">
                            <ToggleSwitch
                              isChecked={data.debit_enabled}
                              onToggle={() => handleToggleEnableAccountDebit(data.id, 'activeDebitEnabled', rowNumber)}
                              updating={updatingActiveDebitEnabledStates[rowNumber]}
                            />
                          </div>
                      </div>

                )}
          {/* </div> */}
          </div>
          <div className="account-details-item">
            <span className="account-details-item-label">Round Up Enabled:
                                  {roundupInfo}
            </span>
            <div className="toggle-switch-holder">
              <ToggleSwitch
                isChecked={data.roundup_enabled}
                onToggle={() => handleToggleEnableAccountRoundUps(data.id, 'activeRoundUpsEnabled', rowNumber)}
                updating={updatingRoundUpsEnabledStates[rowNumber]}
              />
            </div>
          </div>
          <div className="account-details-item">
            <span className="account-details-item-label">Account Type:&nbsp;</span>
            <span className="account-details-item-data">&nbsp;{data.type}</span>
          </div>
          <div className="account-details-item">
            <span className="account-details-item-label">Account Holder:&nbsp;</span>
            <span className="account-details-item-data">&nbsp;{data.holder_name}</span>
          </div>
          <div className="account-details-item">
            <span className="account-details-item-label">Account BSB:&nbsp;</span>
            <span className="account-details-item-data">&nbsp;{data.bsb}</span>
          </div>
          <div className="account-details-item">
            <span className="account-details-item-label">Account Number:&nbsp;</span>
            <span className="account-details-item-data">&nbsp;{data.number}</span>
          </div>
          <div className="account-details-item">
            <span className="account-details-item-label">Account Status:&nbsp;</span>
            <span className="account-details-item-data">
              {data.status 
                ? data.status.toLowerCase() === 'unavailable' ? (
                    <span className="unavailable">
                      UNAVAILABLE {unavailableMessage}
                    </span>
                  ) : data.status.toLowerCase() === 'available' ? (
                    <span className="available">AVAILABLE</span>
                  ) : (
                    <span className="otherstatus">{data.status}</span>
                  )
                : <span className="otherstatus"> Unknown</span>
              }
            </span>
          </div>
        </div>
      </div>
    )
  };
  
  const columns = [
      {
        name: '#',
        selector: (row,index) => index+1,
        maxWidth: '60px',
        minWidth: 'unset',
      },
      {
        name: 'Account Name',
        selector: row => row.name,
        filterable: false,
        sortable: false,
  
      },
    ]

  return (
    <RoundUpsAcctsTableWrapper>
            <div className="table-container hide-scrollbars">
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th style={{ textAlign: 'left' }}>Acct.&nbsp;Name</th>
                      <th style={{ textAlign: 'left' }}>Acct.&nbsp;Type</th>
                      <th>Holder</th>
                      <th>Acct.&nbsp;BSB</th>
                      <th>Acct.&nbsp;Number</th>
                      <th>Debit&nbsp;Enabled{debitInfo}</th>
                      <th>Round Up Enabled{roundupInfo}</th>
                      <th>Acct&nbsp;Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      linkedAccounts.map((account,i) => {
                        return (
                          <tr key={account.id}>
                            <td data-title="Number"><div className="td" style={{ whiteSpace: 'nowrap' }}>{(i + 1)}</div></td>
                            <td data-title="AccountName"><div className="td" style={{ whiteSpace: 'nowrap' , textAlign: 'left'}}>{account.name}</div></td>
                            <td data-title="Type"><div className="td" style={{ whiteSpace: 'nowrap' }}>{account.type}</div></td>
                            <td data-title="accountHolderName"><div className="td" style={{ whiteSpace: 'nowrap' }}>{account.holder_name}</div></td>
                            <td data-title="Account BSB"><div className="td" style={{ whiteSpace: 'nowrap' }}>{account.bsb}</div></td>
                            <td data-title="Account Number"><div className="td" style={{ whiteSpace: 'nowrap' }}>{account.number}</div></td>
                            <td data-title="Active Debit">
                              <div className="td" style={{ whiteSpace: 'nowrap' }}>
                                { account.type && (
                                    account.type.includes('credit') || account.type.includes('mortgage') || account.type.includes('loan') 
                                    ?   
                                      <div className='borrowing-account'> NA 
                                          <span className="pop-over-container">
                                          <PopoverHover
                                            content={
                                              <div style={{
                                                maxWidth: '300px', padding: 10, fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left',
                                              }}
                                              >
                                              A credit card, mortgage or loan account cannot be used for debits. Ensure you link a transaction or savings account for debit facilities.
                                              </div>
                                            }
                                            header = {null}
                                            img = {null}
                                            itemToPopover = {
                                              ( <i className="material-icons" style={{ margin: '0 5px 0 5px', verticalAlign: '-40%' }} >info</i> )
                                            }
                                            anchorOrigin={{
                                              vertical: 'center',
                                              horizontal: 'left',
                                            }}
                                            transformOrigin={{
                                              vertical: 'center',
                                              horizontal: 'right',
                                            }}
                                          />
                                          </span>
                                      </div>
                                    : 
                                      <ToggleSwitch
                                        isChecked={account.debit_enabled}
                                        onToggle={() => handleToggleEnableAccountDebit(account.id, 'activeDebitEnabled', i)}
                                        updating={updatingActiveDebitEnabledStates[i]}
                                      />
                                )}
                              </div>
                            </td>

                            <td data-title="Active Round Ups">
                              <div className="td" style={{ whiteSpace: 'nowrap' }}>
                                <ToggleSwitch
                                  isChecked={account.roundup_enabled}
                                  onToggle={() => handleToggleEnableAccountRoundUps(account.id, 'activeRoundUpsEnabled', i)}
                                  updating={updatingRoundUpsEnabledStates[i]}
                                />
                              </div>
                            </td>
                            <td data-title="Account Status">
                              <div className="td" style={{ whiteSpace: 'nowrap' }}>
                                {account.status 
                                  ? account.status.toLowerCase() === 'unavailable' ? (
                                      <span className="unavailable">
                                        UNAVAILABLE {unavailableMessage}
                                      </span>
                                    ) : account.status.toLowerCase() === 'available' ? (
                                      <span className="available">AVAILABLE</span>
                                    ) : (
                                      <span className="otherstatus">{account.status}</span>
                                    )
                                  : <span className="otherstatus">Unknown</span>
                                }
                              </div>
                            </td>

                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
                
          </div>


          <DataTable
            title={null}
            columns={columns}
            data={linkedAccounts}
            expandableRows
            expandableRowsComponent={(props) => <ExpandedComponent {...props} index={props.index} />}
            //expandableRowsComponent={ExpandedComponent}
            expandOnRowClicked={true}
            defaultExpanded={true}
          />

    </RoundUpsAcctsTableWrapper>

  );
};

RoundUpsAcctsTable.propTypes = {
  roundUpsLinkedAccounts: PropTypes.array.isRequired,
  //updateLinkedAccounts: PropTypes.func.isRequired,
};


export default connect((state) => ({
  roundUpsLinkedAccounts: state.roundups.linkedAccounts,
  authentication: state.authentication,
}), (dispatch) => ({
  RoundUpsActions: bindActionCreators(RoundUpsActions, dispatch),
}))(RoundUpsAcctsTable);
