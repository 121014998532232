import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import StoreBanner from '../store-banner/index.js';
import StoreBoostsList from 'SharedComponents/store-boosts-list';
import StoreTags from 'SharedComponents/store-tags/';
import Accordion from 'SharedComponents/accordion';
//import moment from 'moment';

import styles from './index.css';

const StoreDetailsWrapper = styled.div`${styles}`;

const StoreDetails = ({ storeLogo, storeBannerUrl, retailName, dealsCount, storeSummary, storeRate, onShopNow, onSearchTermChanged, onClose, item, hasFetchedStoreTags }) => {

  //const tagsArray = item?.categories_string?.split(' ') || [];

    return (
      <StoreDetailsWrapper>
        <div className="store-details-container">
          {
          storeBannerUrl ? (
            <StoreBanner
              storeBannerUrl={storeBannerUrl}
              merchantName={retailName}
              storeLogo={storeLogo}
            />
          ) : null
          }  
          <div className="store-details">
          { storeBannerUrl ? 
            null : (
              <div className="store-logo-container">
                <img className="store-logo" src={storeLogo} alt={retailName} />
              </div>
            ) 
          }
            <div className="deals-and-summary-container"> 
              {
                parseInt(dealsCount) ? (
                  <div className="deals-container">
                    <i className="material-icons">star</i>
                    <i className="material-icons">star</i>
                    <span>{`${dealsCount} ${parseInt(dealsCount) === 1 ? 'offer' : 'offers'} available`}</span>
                    <i className="material-icons">star</i>
                    <i className="material-icons">star</i>
                  </div>
                ) : null
              }
                <small className="summary">{storeSummary}</small>
              </div>
          </div>
          <StoreTags
            storeTags={item.tags}
            onSearchTermChanged = { onSearchTermChanged }
            onClose = { onClose }
            hasFetchedStoreTags = { hasFetchedStoreTags }
            maxRows={1}
          />
          <div className="rate-shopnow-container">
            <h3>
            <StoreBoostsList
              retailStoreId={item.retail_store_id}
              retailStore={item}
            />
            </h3>

            <button type="button" className="shop-now" onClick={() => onShopNow(item, 'shop')}>SHOP&nbsp;NOW</button>
          </div>
          { item.terms_conditions && (
            <div className="terms-and-conditions-container">
              <div className="accordion-container">
                <Accordion
                  header="Terms & Conditions"
                  content={
                    <ul>
                      {item.terms_conditions
                        ?.split('. ') // Split the string into sentences based on the period followed by a space
                        .filter(condition => condition.trim() !== '') // Filter out any empty strings
                        .map((condition, index) => (
                          <li key={index}>{condition.endsWith('.') ? condition : `${condition}.`}</li> // Add a period if one is missing
                        ))}
                    </ul>
                  }
                />
              </div>
            </div>
          )}
        </div>
      </StoreDetailsWrapper>
    );
};
export default StoreDetails;