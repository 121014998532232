/* eslint-disable react/forbid-prop-types */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import styles from './index.css';


const AboutUsWrapper = styled.div`${styles}`;

const AboutUs = ({ content }) => (
  <AboutUsWrapper backgroundimage={content.about_us_image} id="about-bys">
    <div className="content-container">
      <div className="text-container">
        <h2>{content.about_us_heading}</h2>
        <p>{content.about_us_text_1}</p>
        <p>{content.about_us_text_2}</p>
        <p>{content.about_us_text_3}</p>
      </div>
      <div className="image-container" />
    </div>
  </AboutUsWrapper>
);

AboutUs.propTypes = {
  content: PropTypes.object.isRequired,
};

export default AboutUs;
