import React from 'react';
import styled from 'styled-components';

import Footer from 'SharedComponents/footer';

import styles from './index.css';

const PrivacyPolicyWrapper = styled.section`${styles}`;


const PrivacyPolicy = () => (
  <PrivacyPolicyWrapper>
    <div className="content-container">
      <h1>End User Licence Agreement</h1>
      <code>
        IMPORTANT - READ CAREFULLY THE FOLLOWING EXTENSION END USER LICENSE AGREEMENT (THE "AGREEMENT") BEFORE INSTALLING THE Boost Your Super Reminder ("EXTENSION"). BY CLICKING THE "I AGREE" BUTTON (OR ANY SIMILAR BUTTON), YOU AGREE TO BE BOUND BY THIS AGREEMENT. IF YOU DO NOT AGREE TO THE TERMS OF THIS AGREEMENT, CLICK THE "CANCEL" BUTTON (OR ANY SIMILAR BUTTON) AND THE INSTALLATION PROCESS WILL NOT BEGIN, OR DO NOT USE OR INSTALL THE EXTENSION. DO NOT SELECT "I AGREE" OR INSTALL OR USE THE EXTENSION UNTIL YOU HAVE CAREFULLY READ, UNDERSTOOD, AND AGREED TO THIS AGREEMENT. FURTHERMORE, YOU HEREBY WAIVE ANY RIGHTS OR REQUIREMENTS UNDER ANY LAWS OR REGULATIONS IN ANY JURISDICTION WHICH REQUIRE AN ORIGINAL (NON-ELECTRONIC) SIGNATURE OR DELIVERY OR RETENTION OF NON-ELECTRONIC RECORDS, TO THE EXTENT PERMITTED UNDER APPLICABLE LAW. THE TERMS OF THIS AGREEMENT CONSTITUTE THE COMPLETE AND EXCLUSIVE STATEMENT OF THE AGREEMENT BETWEEN YOU, OR THE COMPANY ON WHOSE BEHALF YOUR ARE INSTALLING THE EXTENSION, ("YOU") AND THE DEVELOPER OF SUCH EXTENSION ("DEVELOPER"), WHICH SUPERSEDES ANY PROPOSAL OR PRIOR AGREEMENT, ORAL OR WRITTEN, AND ANY OTHER COMMUNICATION RELATING TO THE SUBJECT MATTER OF THESE TERMS.
      </code>

      <ol>
        <li>ACKNOWLEDGMENT. You acknowledge that the licensed Extension includes software components of Developer's licensors. You further acknowledge that this Agreement is concluded between you and the Developer only, and not with any of the Licensors. Developer, not the Licensors, is solely responsible for the licensed Extension and the content thereof.</li>
        <li>GRANT OF LICENSE. Subject to the terms and conditions of this Agreement, the Developer grants you a limited, personal, nonexclusive, non-transferable, non-sublicensable license to use, only in binary executable form and script code form only, the Extension, for your internal use.</li>
        <li>LICENSE RESTRICTIONS. The Extension should be installed in accordance with the instructions of the Developer. Except as expressly and unambiguously permitted by these Terms, you may not, nor permit anyone else to, directly or indirectly: (i) commercially distribute, rent, lease, market, sublicense, resell or otherwise transfer the Extension, (ii) copy or modify the Extension either alone or in conjunction with any other product or program, (iii) decompile, disassemble or reverse engineer all or any portion of the Extension, except and only to the extent, that such activity is expressly permitted by applicable law notwithstanding this limitation, (iv) remove any identification, including copyright, trademark, patent or other notices, contained in or on the Extension; or (v) use the Developer's or its licensors' name, logo or trademarks without prior written consent from Developer or respective licensors.</li>
        <li>OWNERSHIP; COPYRIGHT PROTECTION. The Extension is licensed, not sold. As between the parties, all title, ownership rights, and intellectual property rights (including all copyrights, patents, trade secret rights and trademarks) in and to the Extension, including all documentation, shall remain in Developer, its affiliates, or their respective suppliers and/or licensors. All title and intellectual property rights in and to the content, which may be accessed through use of the Extension, are the property of the respective content owner and may be protected by applicable copyright or other intellectual property laws and treaties.</li>
        <li>AUTOMATIC UPDATES. You acknowledge that the Extension may include automatic updating. You may not deactivate such automatic updates.</li>
        <li>TERM AND TERMINATION. The license is effective until terminated by you or by the Developer. This license may also be automatically terminated upon termination of applicable Developer's license/s by its Licensors. Your rights under these Terms will terminate automatically without notice from the Developer if you fail to comply with any term(s) of these Terms. Upon termination of the licenses granted hereunder, you shall cease all use of the licensed Extension and destroy all copies, full or partial, of the licensed Extension. The provisions in the paragraphs above labeled LICENSE RESTRICTIONS, OWNERSHIP; COPYRIGHT PROTECTION, AUTOMATIC UPDATES, TERM AND TERMINATION, NOTIFICATIONS, THIRD-PARTY MATERIALS, COLLECTION OF ANONYMOUS INFORMATION, DISCLOSURE OF INFORMATION, DEVELOPER PRIVACY, ABUSE REPORT, LIMITATION OF LIABILITY AND WARRANTY, LICENSORS LIMITATION OF LIABILITY AND WARRANTY and MISCELLANEOUS will survive any termination of these Terms.</li>
        <li>NOTIFICATIONS, THIRD-PARTY MATERIALS. The Extension may enable access to third-party services and websites ("External Services"). In addition, you acknowledge that Licensors may use certain features and/or monetization tools that display certain information and content to you while using the Extensions including without limitation systems updates, service announcements, administrative messages regarding the service, advertisements, coupons, sponsored messages, photographs, graphics and information about the Extensions. You understand that by using the Extension and/or any of the External Services, you may be exposed to content from a variety of sources, and that neither the Developer nor Licensors are responsible for the accuracy, usefulness, safety, or intellectual property rights of or relating to such content. You may be exposed to content that is inaccurate, offensive, indecent, or objectionable, and you agree to waive, and hereby do waive, any legal or equitable rights or remedies you may have against Developer or Licensors with respect thereto. You will be able to opt out of receiving advertisements.</li>
        <li>COLLECTION OF ANONYMOUS INFORMATION. Licensors may collect Anonymous Information about you. Licensors may use such Anonymous Information or disclose it to authorized service providers, to improve Licensors' services, to enhance your experience with the Extension and Licensors' services, during the term of this Agreement or anytime thereafter. Licensors may also disclose Anonymous Information (with or without compensation) to third parties, including advertisers and partners, for purposes including, but not limited to, targeted advertisements. Anonymous Information means information which does not enable identification of an individual user, such as aggregated and analytics information about the use of the Extension. , demographic information, including but not limited to your browser type, operating system type and IP address.</li>
        <li>CHANGING YOUR INTERNET BROWSER SETTINGS. As part of the installing of the Extensions, Licensors may offer changes to your Internet Browser settings. This change if approved by you can be reconfigured by you at any time from the options dialog available on your Internet Browser. Such changes may include the following
          <ol>
            <li>The homepage of your Internet Browser.</li>
            <li>The default search engine in your Internet Browser's built-in search box.</li>
            <li>The page displayed when opening a new tab.</li>
          </ol>
        </li>
        <li>DISCLOSURE OF INFORMATION. Licensors may disclose any information you submitted via the use of the Extensions if they have a good faith belief that disclosure of such information is helpful or reasonably necessary to (i) comply with any applicable law, regulation, legal process or governmental request, (ii) detect, prevent, or otherwise address fraud or security issues, or (iii) protect against harm to the rights, property or safety of Licensors, their users, yourself or the public. In the event that Licensors are acquired by or merged with a third party entity, Licensors reserve the right to transfer or assign the information Licensors have collected from you as part of such merger, acquisition, sale, or other change of control. In the unlikely event of Licensors' bankruptcy, insolvency, reorganization, receivership, or assignment for the benefit of creditors, or the application of laws or equitable principles affecting creditors' rights generally, Licensors may not be able to control how your information is treated, transferred, or used. In any of the circumstances described in this paragraph, the information will be subject to the privacy policy of the purchaser or assignee.</li>
        <li>DEVELOPER PRIVACY. You acknowledge that the Licensors are not responsible for the privacy practices or the content of the Developer. Any information collected by the Developer is subject to the Developer's own privacy policies.</li>
        <li>ABUSE REPORT. Licensors respect the legal rights of third parties. If you have a reason to believe that any of our Developer has infringed upon your legal rights (including but not limited to privacy and/or intellectual property rights) or violated the terms of Developer Agreement you may report it at your earliest convenience by sending email to the following address <a href="mailto:privacy@boostyoursuper.com.au">privacy@boostyoursuper.com.au</a>. As soon as Licensors receive your notification, they will, at their discretion, examine your complaint and take the necessary measures to resolve it.</li>
        <li>LIMITATION OF LIABILITY AND WARRANTY. THE DEVELOPER, ITS AFFILIATES, SUBSIDIARIES AND ITS THIRD PARTY LICENSORS DISCLAIM ANY WARRANTIES REGARDING THE SECURITY, RELIABILITY, TIMELINESS, AND PERFORMANCE OF THE EXTENSION. THE DEVELOPER DOES NOT WARRANT THAT THE EXTENSION WILL OPERATE ERROR-FREE, THAT THE EXTENSION IS FREE OF COMPUTER VIRUSES OR OTHER HARMFUL MECHANISMS OR THAT THE DEVELOPER WILL CORRECT ANY ERRORS IN THE EXTENSION. THE DEVELOPER DISCLAIMS ANY AND ALL RESPONSIBILITY OR LIABILITY FOR THE ACCURACY, CONTENT, COMPLETENESS, LEGALITY, RELIABILITY, OR OPERABILITY OR AVAILABILITY OF THE EXTENSION. THE DEVELOPER DISCLAIMS ANY RESPONSIBILITY FOR INFORMATION DEEMED INACCURATE OR INCOMPLETE. TO THE FULLEST EXTENT PROVIDED BY LAW, THE EXTENSION IS PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND And THE DEVELOPER, ITS AFFILIATES, SUBSIDIARIES AND ITS THIRD PARTY LICENSORS, IF ANY, DISCLAIM ALL WARRANTIES, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT, WITH RESPECT TO THE EXTENSION. IN NO EVENT SHALL the developer, ITS AFFILIATES, SUBSIDIARIES OR ITS THIRD PARTY LICENSORS BE LIABLE FOR ANY DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, CONSEQUENTIAL, INDIRECT, DIRECT, SPECIAL, PUNITIVE OR INCIDENTAL DAMAGES, OR DAMAGES FOR LOSS OF BUSINESS PROFITS, BUSINESS INTERRUPTION, LOSS OF BUSINESS INFORMATION, non-infringement of intellectual property rights OR OTHER PECUNIARY LOSS) ARISING OUT OF THE USE OF OR INABILITY TO USE THE EXTENSION, EVEN IF the developer HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE LIABILITY OF THE DEVELOPER, ITS AFFILIATES, SUBSIDIARIES AND ITS THIRD PARTY LICENSORS, IF ANY, FOR DAMAGES UNDER THESE TERMS SHALL IN NO EVENT EXCEED THE AMOUNT PAID BY YOU TO DEVELOPER FOR THE EXTENSION, IF ANY, IN THE 6 MONTHS IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO THE CLAIM, FOR USING THE EXTENSION.</li>
        <li>LICENSORS LIMITATION OF LIABILITY AND WARRANTY. FOR THE SAKE OF CLARITY, LICENSORS HAVE NO RESPONSIBILITY WHATSOEVER FOR THE OPERATION OF THE EXTENSION. SUPPORT, IF ANY, FOR THE EXTENSION WILL BE PROVIDED BY THE DEVELOPER.. LICENSORS HAVE NO OBLIGATION WHATSOEVER TO FURNISH ANY MAINTENANCE AND SUPPORT SERVICES WITH RESPECT TO THE EXTENSION. UNDER NO CIRCUMSTANCES SHALL LICENSORS, THEIR AFFILIATES AND THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, LICENSORS, ASSIGNS AND AGENTS BE LIABLE FOR ANY LOSS OF MONEY, GOODWILL, REPUTATION, SPECIAL, INDIRECT, DIRECT, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES THAT RESULT FROM THE USE OF, OR THE INABILITY TO USE, THE EXTENSIONS EVEN IF LICENSORSHAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.</li>
        <li>GOVERNMENT USE. If you are part of an agency, department, or other entity of the United States Government ("Government"), the use, duplication, reproduction, release, modification, disclosure or transfer of the Extension is restricted in accordance with the Federal Acquisition Regulations as applied to civilian agencies and the Defense Federal Acquisition Regulation Supplement as applied to military agencies. The Extension is a "commercial item", "commercial computer software" and "commercial computer software documentation". In accordance with such provisions, any use of the Extension by the Government shall be governed solely by the terms of these Terms.</li>
        <li>EXPORT CONTROLS. You acknowledge that the Extension may be subject to international rules that govern the export of software. You agree to comply with all applicable international and national laws that apply to the Extension as well as end-user, end-use and destination restrictions issued by national governments.</li>
        <li>MISCELLANEOUS. If any provision of these Terms is held to be unenforceable for any reason, such provision shall be reformed only to the extent necessary to make it enforceable. Any waiver of any provision of these Terms will be effective only if in writing and signed by the Developer. These Terms are personal to you and may not be assigned or transferred for any reason whatsoever without the consent of the Developer and any action or conduct in violation of the foregoing shall be void and without effect. These Terms are governed by and construed under the laws of the State of Israel, excluding its conflicts of law rules.</li>
      </ol>
    </div>
    <Footer />
  </PrivacyPolicyWrapper>
);

export default PrivacyPolicy;
