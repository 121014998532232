export default () => `
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #000;
  color: #fff;
  padding: 10px 30px 30px;

  &> * {
    text-align: center !important;
  }

  &> h1 {
    margin: 5px;
  }

  &> p {
    margin: 5px;
    font-size: 20px;
  }
`;
