export default () => `
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  max-width: 100vw;
  overflow: auto;
  margin-top: 10px;
  
  }
`;
