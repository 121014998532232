
import React from 'react';
import styled from 'styled-components';
//import Carousel from 'nuka-carousel';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { isMobile } from 'react-device-detect';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Swiper, SwiperSlide } from 'swiper/react';
//import Carousel from 'SharedComponents/carousel';
//import { Carousel }from 'react-responsive-carousel';
//import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import IconButton from 'SharedComponents/icon-button';
import Testimonial from './testimonial';
import TestimonialPlaceholder from './testimonialPlaceholder';

import { Keyboard, Navigation, Pagination, Autoplay } from 'swiper/modules';

import styles from './index.css';

const TestimonialsWrapper = styled.div`${styles}`;

const Testimonials = () => {
  const testimonials = useSelector((state) => state.testimonials); // Get testimonials from Redux store

  return (
    <TestimonialsWrapper>
      <div className="content-container">
        <div className="carousel-container">
          {/* <Carousel
          autoplay={testimonials.length > 2}
          pauseOnHover
          autoplayInterval={10 * 1000}
          wrapAround
          swiping={(isMobile && testimonials.length > 2) || (!isMobile && testimonials.length > 3)}
          slidesToShow={isMobile ? 1 : testimonials.length === 1 ? 1 : 2}
          renderCenterLeftControls={null}
          renderCenterRightControls={null}
          heightMode="max"
          renderBottomCenterControls={({ slideCount, currentSlide, goToSlide }) => (
            <div className="controls-container">
              <IconButton onClick={() => goToSlide(currentSlide - 1)} disabled={(isMobile && testimonials.length < 2) || (!isMobile && testimonials.length < 3)}>
                <i className="material-icons">chevron_left</i>
              </IconButton>
              <div className="paging-dots-container">
                {
                  (new Array(slideCount).fill(0)).map((item, index) => (
                    <button type="button" key={index} onClick={() => goToSlide(index)} className={classNames('paging-dot', { active: index === currentSlide })} />
                  ))
                }
              </div>
              <IconButton onClick={() => goToSlide(currentSlide + 1)} disabled={(isMobile && testimonials.length < 2) || (!isMobile && testimonials.length < 3)}>
                <i className="material-icons">chevron_right</i>
              </IconButton>
            </div>
          )}
        > */}
      <Swiper 
        slidesPerView={isMobile ? 1 : testimonials.length === 1 ? 1 : 2}
        pagination={{ clickable:true }}
        navigation={true}
        grabCursor={false}
        keyboard={{ enabled:true }}
        modules={[Pagination, Navigation, Autoplay]}
        autoplay={{ delay: 7000, disableOnInteraction: true }}
        className="mySwiper"
      >
          {
            !testimonials.length ? (
              <TestimonialPlaceholder />
            ) : testimonials.map((testimonial) => (
              <SwiperSlide>
                <Testimonial testimonial={testimonial} key={testimonial.testimonial_id} />
              </SwiperSlide>
            ))
          }
      </Swiper>
      </div>
    </div>
  </TestimonialsWrapper>
);
};

export default Testimonials; 