import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {PieChart, Pie, Sector, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Spinner from 'react-spinner-material';
import styled from 'styled-components';

import PopoverHover from 'SharedComponents/popover-hover';

import styles from './index.css';

const ShopperBonusBoostsGraphWrapper = styled.div`${styles}`;

const ShopperBonusBoostsGraph = ({ innerRadius = 70, outerRadius = 120 }) => {

  console.log ("ShopperBonusBoostsGraph component triggered")
  const [activeIndex, setActiveIndex] = useState(0);
  const app = useSelector((state) => state.app);
  const shopperBoostProfile = useSelector((state) => state.shopping.shopperBoostProfile);
  const shopperId = useSelector((state) => state.authentication.user.shopper_id);

  // Method to handle mouse enter event on pie chart
  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const cellColors = ['grey','#cc9400','#eead00','#febe11','#ffc732','#fed155','#ffda76','#ffe399','#ffecbb','#fff5dd','#fed155','#ffcd47','#ffc93a','#ffc62d','#ffc220','#febf13','#ffbb06','#f8b500','#ebab00','#dea200']; 

  // Method to generate data for pie chart
  const boostsGraphData2 = () => {
    const remainingBoostCapacity = 
      {
        boost_name: 'Earn more boost here',
        commission_percentage: (100 - sumActiveBoostsCommission())
      };

    const copyOfActiveBoostsList = JSON.parse(JSON.stringify(activeBoostsList()));

    const activeBoostsForGraphData2 = copyOfActiveBoostsList.map(( { boost_name, commission_percentage } ) => {
    return {
            'boost_name': boost_name,
            'commission_percentage': (commission_percentage*2),
           }
    }).sort((a, b) => a.commission_percentage - b.commission_percentage);
    
    const boostsGraphData2 = [
      remainingBoostCapacity.commission_percentage > 0.5
      ? remainingBoostCapacity 
      : null, 
      ...activeBoostsForGraphData2 
    ];
    console.log( 'activeBoostsForGraphData2 is : ',activeBoostsForGraphData2);
    console.log( 'remainingBoostCapacity is : ',remainingBoostCapacity);
    console.log( 'boostsGraphData2 is : ',boostsGraphData2);
    console.log('shopperBoostProfile is: ', shopperBoostProfile)
    console.log( 'copyOfActiveBoostsList is : ',copyOfActiveBoostsList);

    return boostsGraphData2
  }

  // Method to generate list of active boosts
  const activeBoostsList = () => {
    const activeBoostsList = shopperBoostProfile.filter(
      boost =>
      boost.boost_status === 'ACTIVE' 
    )
    return activeBoostsList
  }

  // Method to calculate sum of active boosts commission
  const sumActiveBoostsCommission = () => {
    const sumActiveBoostsCommission = activeBoostsList().reduce(
      (accumulator, currentValue) => 
      accumulator + currentValue.commission_percentage,0,
    )*2;
    console.log('shopperBoostProfile (in sumActiveBoostsCommission is: ', shopperBoostProfile)
    console.log('activeBoosts are: ',activeBoostsList());
    console.log('sumActiveBoosts are: ',sumActiveBoostsCommission);
    return Math.round(sumActiveBoostsCommission);
  }

  // Method to render customized label for pie chart
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, payload, percent, startAngle, endAngle, fill, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.8;
    const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180));
    const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180));
    const sin = Math.sin(-Math.PI / 180 * midAngle);
    const cos = Math.cos(-Math.PI / 180 * midAngle);

    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    console.log('renderCustomezedLabel payload is: ', payload);
  
        return (
            //index > 0 ?
                    <g>
                      <Sector
                        cx={cx}
                        cy={cy}
                        innerRadius={innerRadius-1}
                        outerRadius={outerRadius+1}
                        startAngle={startAngle}
                        endAngle={endAngle}
                        fill={fill}
                      />
                      {/* <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${payload.boost_name}`}</text> */}
                    <text x={cx} y={cy} dy={14} fill="black" fontSize="35px" fontWeight="bold" textAnchor={ 'middle' } >
                    {
                      sumActiveBoostsCommission() > 100 
                      ? '100%'
                      : Math.round(sumActiveBoostsCommission()) + '%'
                      }
                    </text>

                    </g>

            //: null {`${(percent * 100).toFixed(0)}%`}
            //<text x={x} y={y} fill="black" fontSize="larger" fontWeight="bold" textAnchor={ x > cx ? 'middle' : 'middle'} dominantBaseline="central">
            //{payload.boost_name}
            //</text>
        );
  };

  const CustomTooltip = ({ active, payload}) => {
    console.log('payload at CustomTooltip is: ', payload);
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <h2 className="label">{`${payload[0].payload.boost_name}`}</h2>
        </div>
      );
    }
    return null;
  };

  const graphCaptionPopoverText = (
    <div className="ext-popover-text-container" style={{ maxWidth: '400px' }}>
      <div className="text-content-container" style={{ margin: '20px' }}>
        { sumActiveBoostsCommission() < 100 ?
        (
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center',}} >
            <p style={{ margin: '0px' }}><strong>Your Bonus Boost Power ({sumActiveBoostsCommission()}%) is the sum of your ACTIVE Bonus Boosts.</strong></p>
            {sumActiveBoostsCommission() > 0 ?
            (
              <p> On each transaction you will earn an extra {sumActiveBoostsCommission()}% above normal earnings. For example, for every $1 normally earned you will earn ${(1 + sumActiveBoostsCommission()/100).toFixed(2)}.</p>
            )  : (
              <p> On each transaction you can earn extra above your normal earnings up to a maximum of 100%. For example, if your Bonus Boost Power is 60%, you would earn $1.60 instead of $1.</p>
            )}
            <p>Find actions you can take to get to 100%.</p>
          </div>
        ) : (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center',}} >
            <h2 style={{ margin: '0px 0px 5px 5px' }}><i>Sum of all ACTIVE Bonus Boosts is 100% or more</i></h2>
            <p> Well done! You have reached the maximum Bonus Boost Power of 100%.</p>
            <em style={{ margin: '0px 0px 0px 40px' }}>Keep earning Bonus Boosts to ensure you maintain a Bonus Boost Power of 100%.</em>
        </div>
        )
          }

      </div>
    </div>
  );


  return (
    <ShopperBonusBoostsGraphWrapper className="bonus-boost-profile">
        {/* <div className="profile-intro-and-graph" > */}
          {/* <div className="graphs-container">  */} 
            <div className="graph-and-label-container">
                <div className="graph">
                    <ResponsiveContainer width="100%" height="100%">
                        <PieChart width={400} height={400}>
                        <Pie
                            activeIndex={activeIndex}
                            activeShape={renderCustomizedLabel}
                            onMouseEnter={onPieEnter}
                            data={ JSON.parse(JSON.stringify((boostsGraphData2()))) }
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            isAnimationActive={true}
                            label={false}
                            innerRadius={innerRadius}    //{70}
                            paddingAngle={0}
                            outerRadius={outerRadius}    //{120}
                            startAngle={90}
                            endAngle={450}
                            fill="#8884d8"
                            dataKey="commission_percentage"
                        >
                          {/* {console.log('boostGraphData2() at render Pie is ', boostsGraphData2())} */}
                            {boostsGraphData2().map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={cellColors[index % cellColors.length]} />
                            ))}
                        </Pie>
                        <Tooltip
                          content={<CustomTooltip />
                           // <text fill="black" fontSize="larger">{`${boost.boost_name}`}
                           // </text>
                          }
                          />
                        </PieChart>
                    </ResponsiveContainer>
                </div>
                <div className="info-holder">
                  <PopoverHover
                      content={graphCaptionPopoverText}
                      header = {null}
                      img = {null}
                      itemToPopover = {
                      ( <i className="material-icons" style={{ fontSize: '36px', display: 'flex' }} >info</i> ) 
                      }
                      horizontalPosn = "right"
                  />
                </div>
                
                {/* <div className="graphs-result-label-container">
                    <div className="graph-result-label">
                        BONUS BOOST POWER:
                    </div>
                    <div className="graph-result-text-amount"> 
                        {   
                  
                            (
                              sumActiveBoostsCommission() > 100 ?
                              '100%' :
                               Math.round(sumActiveBoostsCommission()) + '%'
                            )
                        }
                        <div className="pop-over-container">
                            <PopoverHover
                                content={graphCaptionPopoverText}
                                header = {null}
                                img = {null}
                                itemToPopover = {
                                ( <i className="material-icons" style={{ margin: '0 10px 0 10px', fontSize: '36px', display: 'flex' }} >info</i> ) 
                                }
                                horizontalPosn = "right"
                            />
                        </div>
                    </div>
                </div> */}
            </div>
          {/* </div> */}
        {/* </div> */}

        
    </ShopperBonusBoostsGraphWrapper>
  );
//};
};

export default ShopperBonusBoostsGraph;