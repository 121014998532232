import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import log from "Utils/logger";

import * as RoundUpsActions from 'Flux/round-ups/actions';

const useCheckPromoCode = ( check_code, type, promoCode ) => {
  console.log ('*** ** useCheckPromoCode: hook triggered with check_code: ', check_code);
  console.log ('*** ** useCheckPromoCode: hook triggered with type: ', type);
  const isCheckingPromoCodeRef = useRef(false);
  const hasCheckedPromoCodeRef = useRef(false);
  const [isCheckingPromoCode, setIsCheckingPromoCode] = useState(false);
  const [checkCodeResponse, setCheckCodeResponse] = useState(null);
  const dispatch = useDispatch();
  
  const checkPromoCode = async ()  => {
    isCheckingPromoCodeRef.current = true;
    setIsCheckingPromoCode(true);
    console.log('2.1 *** ** checkPromoCode: about to commence checkPromoCodeValid with check_code: ', check_code);
    console.log('2.2 *** ** checkPromoCode: about to commence SET isCheckingPromoCodeRef.current: ', isCheckingPromoCodeRef.current);
    console.log('2.3 *** ** checkPromoCode: about to commence SET isCheckingPromoCodeRef ', isCheckingPromoCodeRef);
    try {
      const result = await dispatch(RoundUpsActions.checkPromoCodeValid(check_code, type));
      console.log ('4.0 *** ** checkPromoCode result: ', result);
     
      setCheckCodeResponse(result.data);

    } catch (exception) {
      console.log(exception);
      log({
        event: "System-Error",
        source: "check Promo Code",
        info: exception,
        ...exception,
      });
    } finally {
      console.log('5.0 *** ** checkPromoCode: about to commence SET FALSE isCheckingPromoCodeRef: ', isCheckingPromoCodeRef);
      isCheckingPromoCodeRef.current = false;
      hasCheckedPromoCodeRef.current = true;
      setIsCheckingPromoCode(false);
    }
  };

  useEffect(() => {
    console.log ('2.0 *** ** useCheckPromoCode: useEffect triggered with check_code: ', check_code);
    console.log ('2.0 *** ** useCheckPromoCode: useEffect triggered with isCheckingPromoCodeRef.current: ', isCheckingPromoCodeRef.current);
    console.log('2.0 *** ** useCheckPromoCode: useEffect triggered with isCheckingPromoCode: ', isCheckingPromoCode);
    console.log ('2.0 *** ** useCheckPromoCode: useEffect triggered with hasCheckedPromoCodeRef.current: ', hasCheckedPromoCodeRef.current);
    console.log ('2.0 *** ** useCheckPromoCode: useEffect triggered with checkCodeResponse: ', checkCodeResponse);

    if ( !isCheckingPromoCode
         && !promoCode
         && check_code 
         && type
        ) {
      checkPromoCode(check_code, type);
    }
  }, [check_code, type]);

  useEffect(() => {
    console.log ('2.5 *** ** useCheckPromoCode TEST useEffect update in useHook isCheckingPromoCodeRef.current: ', isCheckingPromoCodeRef);
  }, [ isCheckingPromoCode ]);

  return { checkPromoCode, checkCodeResponse, isCheckingPromoCode };
};

export default useCheckPromoCode;
