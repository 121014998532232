import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import log from "Utils/logger";

import CONFIG from "Config";

import * as RoundUpsActions from 'Flux/round-ups/actions';

const useActivatePromoCode = ( code, type, isPromoCodeValid, activateOnClick ) => {
  console.log ('*** ** useActivatePromoCode: hook triggered with code: ', code);
  console.log ('*** ** useActivatePromoCode: hook triggered with type: ', type);
  const isActivatingPromoCodeRef = useRef(false);
  const hasCheckedPromoCodeRef = useRef(false);
  const [isActivatingPromoCode, setIsActivatingPromoCode] = useState(false);
  const [activateCodeResponse, setActivateCodeResponse] = useState(null);
  const dispatch = useDispatch();
  
 
  const activatePromoCode = async ()  => {
    isActivatingPromoCodeRef.current = true;
    //setIsActivatingPromoCode(true);
    console.log('2.1 *** ** activatePromoCode: about to commence activatePromoCodeValid with code: ', code);
    //console.log('2.2 *** ** activatePromoCode: about to commence SET isActivatingPromoCodeRef.current: ', isActivatingPromoCodeRef.current);
    try {


      const result = await dispatch(RoundUpsActions.activatePromoCode(code, type));
    
      console.log ('4.0 *** ** activatePromoCode result: ', result);
     
      if (result?.data?.promo_code?.active) {
        console.log ('4.1 *** ** activatePromoCode result.data.promo_code: ', result.data.promo_code);
        setActivateCodeResponse(result.data.promo_code);
      } else if (result?.data?.valid?.false) {
        console.log ('4.2 *** ** activatePromoCode result.data.promo_code: ', result.data.promo_code);
        setActivateCodeResponse(data.valid);
      }
      

    } catch (exception) {
      console.log(exception);
      log({
        event: "System-Error",
        source: "activatePromoCode",
        info: exception,
        ...exception,
      });
    } finally {
      console.log('5.0 *** ** activatePromoCode: about to commence SET FALSE isActivatingPromoCodeRef.current: ', isActivatingPromoCodeRef.current);
      isActivatingPromoCodeRef.current = false;
      hasCheckedPromoCodeRef.current = true;
      //setIsActivatingPromoCode(false);
    }
  };

  useEffect(() => {
    console.log ('2.0 *** ** useActivatePromoCode: useEffect triggered with code: ', code);
    if ( !isActivatingPromoCodeRef.current
         && code 
         && type
         && isPromoCodeValid
         && activateOnClick
        ) {
      activatePromoCode(code, type);
    }
  }, [code, type, isPromoCodeValid]);


  return { activatePromoCode, activateCodeResponse, isActivatingPromoCodeRef };
};

export default useActivatePromoCode;
