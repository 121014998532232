export default ({ theme }) => `
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;

  .content-container {
    max-width: 1366px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 40px);
    flex-direction: column;

    .content {
      display: flex;
      flex-direction: column;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;


      .member-cards-container{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: flex-start;
        padding: 10px;
        width: 100%;
      }

        .logos-container{
          flex-direction: row;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;

          .logo{
            display: flex;
            padding: 5px;
            margin: 20px;
            background: #ffffff;
            border-radius: 3px;
            max-width: 15vh;
            box-shadow: rgb(0 0 0 / 40%) 0px 0px 20px 2px;
            
            @media(max-width: 768px) {
              margin: 10px;
              max-width: 8vh;
            }
          }
        }

      
    }

    h2 {
      font-weight: bolder;
    }

    p {
      font-size: 20px;
    }

  }
`;
