/* eslint-disable no-nested-ternary */
export default ({
  theme, large, small, medium, active, disabled, color,
}) => `

.toggle-switch-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

 
  .toggle-switch-wrapper {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .toggle-switch-updating {
    position: absolute;
    right: -60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  .toggle-switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-right: 5px;
  }

  .slider.loading {
    cursor: wait;
  }


  .slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: ${theme.activeNotificationColor};
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }



/* display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: ${theme.secondaryTextColor};
    // border-radius: ${large ? '22.5px' : medium ? '17.5px' : small ? '12.5px' : '17.5px'};
    border-radius: 3px;
    margin: ${large ? '0px 5px' : '0px 2px'};
    padding: ${large ? '10px' : '3px 3px'};
    outline: none;
    cursor: pointer;
    background-color: ${active ? theme.darkSecondaryColor : theme.secondaryColor};
    font-size: ${large ? '17px' : medium ? '14px' : small ? '11px' : '17px'};
    border: none;
    box-shadow: 0 0 3px ${theme.secondaryColor};

    .text {
      margin: 0 14px;
    }

    &:hover {
      background-color: ${theme.secondaryColor};
      filter: brightness(125%);
    }

    &:disabled {
      background-color: ${theme.borderColor};
      cursor: not-allowed;
    } */
  `;
