export default ({ theme }) => {
  return `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius:5px;
    background-color: ${theme.darkSecondaryColor};
    color: ${theme.secondaryTextColor};
    margin-bottom:10px;

    .theme-highlight{
      color: ${theme.alternateAccentColor};
    }
    
    .new-boost-circle{
      display: flex;
      flex-direction: column;
      align-items: center;


      .boost-avail-circle-caption{
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      h2 {
        font-size: large;
        margin: 5px;
      }
      
      .circle{
        position: relative;
        height: 100px;
        width: 100px;
        margin: 10px 10px;
        border-radius: 50%;
        background: ${theme.alternateAccentColor};



        .circle-content{
          position: relative;
          display: flex;
          align-content: center;
          justify-content: center;
          align-items: center;
          height: 100%;

          i {
            font-size: larger;
          }
        }

        &:before{
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          height: 80%;
          width: 80%;
          background: #000;
          border-radius: 50%;
        }
      }
    }

    .recommendation-container{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 10px;
    }


    .bonus-boost-recommendation{
      display: flex;
      flex-direction: row;
      -webkit-box-pack: start;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;
      flex-wrap: wrap;

      .boost-available-graphic{
        display: flex;
        font-size: 30px;
        align-items: center;
      }


    }
    
    .bonus-boost-header-and-instructions{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    .bonus-boost-header{
      display: flex;
      flex-direction: column;
      

      .boost-name{
        color: ${theme.accentColor};
      }

      .get-boost-line{

      }

      h2{
        margin: 5px 0px;
        padding: 0px 10px;
        display: flex;
        align-items: center;
      }
    }

    .boost-actions-container{
      display: flex;
      flex-direction: column;

      .bonus-boost-description-and-action{
        display: flex;
        flex-flow: row wrap;
        -webkit-box-align: center;
        /* align-items: center; */
        -webkit-box-pack: start;
        justify-content: flex-start;
        /* max-width: 600px; */
        flex-direction: column;

        button{
          width: fit-content;
        }

        p {
          margin: 5px 10px;
        }
      }

      .bonus-boost-external-component{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-evenly;
        width: 800px;

        p {
          margin: 10px;
        }
      }
    }

    


  `;
};
