export default () => `
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;

  .store-banner-container {
    display: flex;
    align-items: flex-start;
    transition: opacity 0.5s ease-in-out;
    position: relative; /* Add this line to make the container a positioning context */

    .image-container {
      position: relative;
      display: flex;
      align-items: center;
    }

    .store-banner {
      align-items: flex-start;
      max-width: 580px;
      padding-bottom: 10px;
      position: relative;
      overflow: hidden;
    }

    .store-logo {
      width: 125px;
      height: auto;
      border-radius: 50%;
      position: absolute;
      top: 10px; /* Adjust the top position as needed */
      left: 10px; /* Adjust the left position as needed */
    }
  }

  &> p {
    margin: 5px;
    font-size: 20px;
  }
`;
