export default ({ theme, active, count }) => `
    font-size: 20px;
    position: relative;

    button {
      font-size: 20px;
      font-weight: bold;
      text-transform: uppercase;
      outline: none;
      border: none;
      background-color: ${theme.neutralColor};
      padding: 0 10px 5px;
      color: ${theme.lightSecondaryColor};
      cursor: pointer;
      display: flex;

      @media(max-width: 768px) {
        font-size: 12px;
        padding: 0 5px 5px;
      }

      &.active {
        border-bottom: solid 1px ${theme.darkSecondaryColor};
        color: ${theme.darkSecondaryColor};
      }

      &:hover {
        color: ${active ? `${theme.darkSecondaryColor}` : `${theme.lightSecondaryColor}`};
      }

      .label {
        white-space: nowrap;
        width: 100%;
        text: overflow: visible;
      }

      .count {
        background-color: ${count && count > 0 ? theme.alternateAccentColor : null};
        color: ${count && count > 0 ? '#000' : null};
        margin-left: 5px;
        padding: 0px 2px;
        dipslay: flex;
        min-width: 35px;
      }
    }

    .active-indicator {
      height: 3px;
      background-color: ${active ? `${theme.darkSecondaryColor}` : `transparent`};
    }
  `;
