import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-grid-system';
import log from "Utils/logger";
import { isChrome, isMobile, isSafari, isEdge, isIOS, isMacOs } from 'react-device-detect';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Keyboard, Navigation, Pagination, Autoplay } from 'swiper/modules';
import numeral from 'numeral';

import SignInForm from 'SharedComponents/sign-in-form';
import DashboardCard from 'SharedComponents/dashboard-card';
import ShopperBonusBoostsGraph from 'SharedComponents/bonus-boost-graph';
import GraphTotalBoostAmounts from 'SharedComponents/graph-total-amounts';
import ShowShopLogo from 'SharedComponents/show-shop-logo';
import PaymentDetails from 'SharedComponents/payment-details-bpay';
import Footer from 'SharedComponents/footer';

import * as AppActions from "Flux/app/actions";

import ExtensionSlide from './ExtensionSlide';

import useFetchShopperBoostProfile from 'SharedHooks/fetch-shopper-boost-profile';
import useFetchRoundUpsShopperData from 'SharedHooks/fetch-roundups-shopper-data';
import useCheckIsExtensionInstalled from 'SharedHooks/check-extension-installed';
import useFetchRoundUpsTransactions from 'SharedHooks/fetch-round-ups-transactions';
import useFetchCashbackTransactions from 'SharedHooks/fetch-cashback-transactions';
import useFetchCashbackSummary from 'SharedHooks/fetch-cashback-summary';
import useFetchStores from "SharedHooks/fetch-stores";
import useFetchShopperProfile from 'SharedHooks/fetch-shopper-profile';

import CONFIG from 'Config';
import styles from './index.css';

const DashboardWrapper = styled.div`${styles}`;

const Dashboard = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams(); 
  const isExtensionInstalled = useSelector((state) => state.app.isExtensionInstalled);

  const initialTab = searchParams.get("tab");
  console.log('settings - initialTab:', initialTab);
  const { fetchStores } = useFetchStores();

  const authentication = useSelector((state) => state.authentication);
  const app = useSelector((state) => state.app);
  const boostsEnabled = useSelector((state) => state.app.partnerContent.boosts_enabled);

  //const roundups = useSelector((state) => state.roundups);
  const dispatch = useDispatch();

  const shops = useSelector((state) => state.shopping.stores);

  console.log ('*** Dashboard page triggered');
  console.log('*** Dashboard triggered with window.deferredInstallPrompt:', window.deferredInstallPrompt);

  const [localIsExtensionInstalled, setLocalIsExtensionInstalled] = useState(isExtensionInstalled);

  const { fetchShopperProfile } = useFetchShopperProfile();
  const { fetchShopperBoostProfile } = useFetchShopperBoostProfile();
  //const { isFetchingRoundUpsShopperDataRef, hasFetchedRoundUpsShopperDataRef, fetchRoundUpsShopperData } = useFetchRoundUpsShopperData();
  const { fetchRoundUpsShopperData } = useFetchRoundUpsShopperData();
  const { checkIsExtensionInstalled } = useCheckIsExtensionInstalled();

  const { fetchRoundUpsTransactions } = useFetchRoundUpsTransactions();
  const { fetchCashbackSummary } = useFetchCashbackSummary();
  

  const cashbackSummary = useSelector(state => state.transactions.cashBackSummary);
  const roundUpsTransactions = useSelector(state => state.roundUpsTransactions);

  const totalCashback = useSelector(state => state.transactions.cashBackSummary.total_commission);
  const totalRoundUps = (roundUpsTransactions.roundUpsTotalPaid + roundUpsTransactions.roundUpsTotalClearing + roundUpsTransactions.roundUpsTotalCleared) / 100;
  const totalContributeNow = (roundUpsTransactions.contributeNowTotalPaid + roundUpsTransactions.contributeNowTotalClearing + roundUpsTransactions.contributeNowTotalCleared) / 100;

console.log('*** Dashboard isExtensionInstalled: ', isExtensionInstalled);
  
  const summaryGraphAmounts = [
    { name: 'CASHBACK', value: totalCashback },
    authentication?.user?.roundups_visible 
      ? { name: 'ROUNDUPS', value: totalRoundUps } 
      : undefined,
    authentication?.user?.contribute_now_visible 
      ? { name: 'CONTRIBUTE NOW', value: totalContributeNow } 
      : undefined, 
  ].filter(item => item !== undefined);

  function onInstallChromeExtension() {
    const webstoreWindow = window.open(`https://chrome.google.com/webstore/detail/boost-your-super/${CONFIG.chromeExtensionId}`, '_blank');
    webstoreWindow.focus();
  }

  function checkStandaloneMode() {
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true;
    console.log('checkStandaloneMode:', isStandalone);
    console.log('window.matchMedia("(display-mode: standalone)").matches:', window.matchMedia('(display-mode: standalone)').matches);
    console.log('window.navigator.standalone:', window.navigator.standalone);
    return isStandalone;
  }

    useEffect(() => {
    console.log('dashboard - useEffect - *** extensionInstalled updated');
    console.log('dashboard - useEffect - *** extensionInstalled updated - isExtensionInstalled: ', isExtensionInstalled);
    console.log('dashboard - useEffect - *** extensionInstalled updated - localIsExtensionInstalled: ', localIsExtensionInstalled);
    if (isExtensionInstalled !== localIsExtensionInstalled) {
      console.log('dashboard - useEffect - *** extensionInstalled updated - EXECUTING');
      setLocalIsExtensionInstalled(isExtensionInstalled);
    }
  }, [isExtensionInstalled]);

  function handleInstallButtonClick() {
    console.log('handleInstallButtonClick');
    if (!window.deferredInstallPrompt) {
      console.log('No deferredInstallPrompt - returning');
      return;
    }
    window.deferredInstallPrompt.prompt();

    window.deferredInstallPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
        log({
          'A2HSprompt': 'User accepted the A2HS prompt'
        });
      } else {
        console.log('User dismissed the A2HS prompt');
        log({
          'A2HSprompt': 'User dismissed the A2HS prompt'
        });
      }
      window.deferredInstallPrompt = null;
      dispatch(AppActions.setBeforeInstallPromptFire(false));
    });
  }

  const isStandalone = checkStandaloneMode();
  
  const installButtonText = isStandalone 
    ? "App is Installed"
    : (app.hasBeforeInstallPromptBeenFired && window.deferredInstallPrompt)
      ? (!isMobile ? "Add to Homescreen" : "Install App")
      : "May already be installed";

  
  const renderNotLoggedInContent = () => {
      return (
        <DashboardWrapper>
          <div className="sign-in-form-container">
          {/* <div style={{ maxWidth: 600, padding: 10, width: 'calc(100% - 20px)', height: '100%' }}> */}
            <SignInForm
              showEmailLogin
              header={(
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                  <h1>Login</h1>
                  <span>To access your Dashboard</span>
                </div>
              )}
              showSocialLogin
            />
          </div>
        </DashboardWrapper>
      );
  };

  const cardImageContentShops = () => {

    return(
      <div className="logo-container">
      {
        shops.sort((a, b) => parseInt(b.clicks) - parseInt(a.clicks)).slice(0, 6).map((shop) => (
          <ShowShopLogo shop={shop} key={shop.retail_store_id} logoSize={"60px"}/>
        ))
      }
      </div>
    )
  }

  const cardImageContentChromeExtension = () => {
    const imageExtensionHowItWorksBackgroundWebsite = `${CONFIG.assetsUrl}/assets/images/ExtensionHowItWorksBackgroundWebsite.png`
    const imageExtensionActivateOnSite = `${CONFIG.assetsUrl}/assets/images/ExtensionActivateOnSite.png`
    const imageExtensionCouponExample = `${CONFIG.assetsUrl}/assets/images/ExtensionCouponExample.png`
    const logoUrl = `${CONFIG.assetsUrl}/assets/merchants_logos/logo-400px-EtihadAirways.png`
    
    return (
      <Swiper 
        slidesPerView={1}
        pagination={{ clickable:true }}
        navigation={true}
        grabCursor={false}
        keyboard={{ enabled:true }}
        modules={[Pagination, Navigation, Autoplay]}
        autoplay={{ delay: 7000, disableOnInteraction: true }}
        className="mySwiper"
      >
      <SwiperSlide>
        <ExtensionSlide
          imageBackgroundUrl={imageExtensionHowItWorksBackgroundWebsite}
          logoUrl={logoUrl}
          imageActivateOnSiteUrl={imageExtensionActivateOnSite}
          imageExtensionCouponExample={null}
          valuePropText="Activate Directly on Site"
          backgroundPosition="50% 50%"
        />
      </SwiperSlide>
      <SwiperSlide>
      <ExtensionSlide
          imageBackgroundUrl={imageExtensionHowItWorksBackgroundWebsite}
          logoUrl={logoUrl}
          imageActivateOnSiteUrl={imageExtensionActivateOnSite}
          imageExtensionCouponExample={imageExtensionCouponExample}
          valuePropText="Get Coupons"
          backgroundPosition="50% 50%"
        />
      </SwiperSlide>
      </Swiper>
    )
  }



  if (!authentication.user) {
    return renderNotLoggedInContent();
  } else {

    return (
      <DashboardWrapper>
        <h2 className="title">Dashboard</h2>
        <div className="cards-container">
          <Container fluid className="links-container" style={{ margin: 0, padding: 0 }}>
              <Row style={{ margin: 0, padding: 0, width: '100%' }}>
              <Col xl={4} lg={4} md={6} sm={12} xs={12} className="links-group" style={{ margin: 0, padding: 0 }}>
                  <DashboardCard
                    title="Total Boost Summary"
                    howItWorks="Roundups are Boost Your Super's most effective offering."
                    buttonText="Transactions"
                    buttonLink="/transactions"
                    buttonDisabled={false}
                    openInNewWin={isStandalone ? false : true}
                    highlight={false}
                    valuePropText={null}
                    cardImageContent={
                      <GraphTotalBoostAmounts 
                        data={summaryGraphAmounts}
                        header={`TOTAL:${numeral(totalCashback + totalRoundUps + totalContributeNow).format('$0,0.00')}`}
                      />
                    }
                    icon={null}
                    onClick={() => navigate('/transactions')}
                  />
                </Col>
                { !isMobile && (isChrome || isEdge ) ?
                  <Col xl={4} lg={4} md={6} sm={12} xs={12} className="links-group" style={{ margin: 0, padding: 0 }}>
                    <DashboardCard
                      title={ !isEdge ? "Chrome Extension" : "Chrome/Edge Extension"}
                      howItWorks={ !isEdge 
                                  ? "Activate cashback to super & find coupons directly on participating websites."
                                  : "Activate cashback to super & find coupons directly on participating websites. Works on Edge browsers"
                      }
                      buttonText={!localIsExtensionInstalled ? "Install Extension..." : "Extension Installed"}
                      buttonLink={null}
                      buttonDisabled={false}
                      openInNewWin={false}
                      highlight={false}
                      valuePropText={null}
                      cardImageContent={cardImageContentChromeExtension()}
                      icon={!localIsExtensionInstalled ? "extension" : "check_circle"}
                      onClick={() => onInstallChromeExtension()}
                    />
                  </Col>
                : null }
                <Col xl={4} lg={4} md={6} sm={12} xs={12} className="links-group" style={{ margin: 0, padding: 0 }}>
                  <DashboardCard
                    title="Bonus Boost Power"
                    howItWorks="Earn Bonus Boosts to increase your Boost Power by taking action such as filling out surveys & making personal contributions to your super."
                     buttonText="More..."
                    buttonLink="/settings?tab=bonus-boost-power"
                    buttonDisabled={false}
                    openInNewWin={isStandalone ? false : true}
                    highlight={false}
                    valuePropText="Boost Even Faster"
                    cardImageContent={
                      <ShopperBonusBoostsGraph 
                        innerRadius={50}
                        outerRadius={80}
                      />
                    }
                    icon={null}
                    onClick={() => navigate('/settings?tab=bonus-boost-power')}
                  />
                </Col>
                {authentication?.user?.roundups_visible ?
                  <Col xl={4} lg={4} md={6} sm={12} xs={12} className="links-group" style={{ margin: 0, padding: 0 }}>
                    <DashboardCard
                      title="Round-Ups To Super"
                      howItWorks="Round up your spending to the nearest $1 and place the change into your super."
                      buttonText="Setup Roundups"
                      buttonLink="/settings?tab=round-ups"
                      buttonDisabled={false}
                      openInNewWin={isStandalone ? false : true}
                      highlight={false}
                      valuePropText="Our Most Impactful Service"
                      cardImageContent={<img src={`${CONFIG.assetsUrl}/assets/images/RoundUpSpareChangeToSuper-300x600px.png`} />}
                      icon={null}
                      onClick={() => navigate('/settings?tab=round-ups')}
                    />
                  </Col>
                : null }
                <Col xl={4} lg={4} md={6} sm={12} xs={12} className="links-group" style={{ margin: 0, padding: 0 }}>
                  <DashboardCard
                    title="Cashback To Super"
                    howItWorks="Click through and purchase from our partner shops. We earn a commission and share it to your super account."
                    buttonText="Goto Shops"
                    buttonLink="/shops"
                    buttonDisabled={false}
                    openInNewWin={isStandalone ? false : true}
                    highlight={false}
                    valuePropText={null}
                    cardImageContent={cardImageContentShops()}
                    icon="store_mall_directory"
                    onClick={() => navigate('/shops')}
                  />
                </Col>
                { !isSafari ?
                  <Col xl={4} lg={4} md={6} sm={12} xs={12} className="links-group" style={{ margin: 0, padding: 0 }}>
                    <DashboardCard
                      title={ 
                        isStandalone 
                        ? "App is Installed"
                        : (app.hasBeforeInstallPromptBeenFired && window.deferredInstallPrompt)
                            ? !isMobile ? "Add to Homescreen" : "Install App"
                            : "Browser not ready"
                      }
                      howItWorks={
                        isStandalone
                        ? "App is installed. Quick access to shops that can Boost Your Super."
                        : (!app.hasBeforeInstallPromptBeenFired || !window?.deferredInstallPrompt)
                          ? "Browser not ready to install. Browser may require 2+ visits before it allows installation."
                            : !isMobile 
                              ? "Get Boosting with a quick click." 
                              : "Make it easy to Boost on the Go."
                      }
                      buttonText={
                        isStandalone 
                        ? "App is Installed"
                        : (app.hasBeforeInstallPromptBeenFired && window.deferredInstallPrompt)
                            ? !isMobile ? "Add to Homescreen" : "Install App"
                            : "Browser not ready"
                      }
                      buttonLink={null}
                      buttonDisabled={false}
                      openInNewWin={isStandalone ? false : true}
                      highlight={false}
                      valuePropText={!isMobile ? "Quick access to Boost" : " Boost on the Go"}
                      cardImageContent={
                        !isMobile ? <img src={`${CONFIG.assetsUrl}/assets/images/AddToHomeScreenAndroid.png`}/>
                        : <img src={`${CONFIG.assetsUrl}/assets/images/InstallAppToMobileAndroid.png`}/>
                      } 
                      icon={!isStandalone ? "get_app" : "check_circle"}
                      onClick={
                        (app.hasBeforeInstallPromptBeenFired && window.deferredInstallPrompt) 
                        ? handleInstallButtonClick
                        : null 
                      }
                    />
                  </Col>
                 : null }
                { isSafari ?
                  <Col xl={4} lg={4} md={6} sm={12} xs={12} className="links-group" style={{ margin: 0, padding: 0 }}>
                    <DashboardCard
                      title={ !isMobile ? "Add to Homescreen" : "Install App"
                      }
                      howItWorks={!isMobile ? "Get Boosting with a quick click." : "Make it easy to Boost on the Go."}
                      buttonText={null}
                      buttonLink={null}
                      buttonDisabled={true}
                      openInNewWin={isStandalone ? false : true}
                      highlight={false}
                      valuePropText={!isMobile ? "Quick access to Boost" : " Boost on the Go"}
                      cardImageContent={
                        isMobile 
                        ? <img src={`${CONFIG.assetsUrl}/assets/images/AddToHomescreenSafari.png`} />
                        : <img src={`${CONFIG.assetsUrl}/assets/images/AddToHomeScreenMac.png`} />
                      }
                      icon="get_app"
                      onClick={null}
                    />
                  </Col>
                : null}
                  <Col xl={4} lg={4} md={6} sm={12} xs={12} className="links-group" style={{ margin: 0, padding: 0 }}>
                    <DashboardCard
                      title={ "Your Super Details"}
                      howItWorks={"Add your BPay or SMSF details so we can pay your super."}
                      buttonText={"Update Payment Details"}
                      buttonLink='/settings?tab=payment-details'
                      buttonDisabled={false}
                      openInNewWin={isStandalone ? false : true}
                      highlight={false}
                      valuePropText={null}
                      cardImageContent={
                        <img src={`${CONFIG.assetsUrl}/assets/images/BPAY_SMSF.png`} />
                      }
                      icon="settings"
                      onClick={() => navigate('/settings?tab=payment-details')}
                    />
                  </Col>
            </Row> 
            </Container>
        </div>
        <Footer />
      </DashboardWrapper>
    )
  }
}

export default Dashboard;