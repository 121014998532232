import * as AppActionTypes from './constants';

const initialState = {
  hasBeforeInstallPromptBeenFired: false,
  landingPageContent: null,
  partnerContent: null,
  isExtensionInstalled: false,
};

const actionsMap = {
  [AppActionTypes.SET_BEFORE_INSTALL_PROMPT_FIRE](state, { payload }) {
    console.log('AppActionTypes triggered in reducer. SET_BEFORE_INSTALL_PROMPT_FIRE with payload : ', { payload })
    return {
      ...state,
      hasBeforeInstallPromptBeenFired: payload,
    };
  },
  [AppActionTypes.SET_LANDING_PAGE_CONTENT](state, { payload }) {
    return {
      ...state,
      landingPageContent: payload,
    };
  },
  [AppActionTypes.SET_PARTNER_CONTENT](state, { payload }) {
    console.log('AppActionTypes triggered in reducer. SETTING Partner Content Now with payload : ', { payload })
    return {
      ...state,
      partnerContent: payload,
    };
  },
  [AppActionTypes.UNSET_PARTNER_CONTENT](state) {
    console.log('AppActionTypes triggered in reducer. UNSETTING Partner Content which has state : ', state);
    return {
      ...state,
      partnerContent: null,
    };
  },
  [AppActionTypes.SET_IS_EXTENSION_INSTALLED](state, { payload }) {
    console.log('AppActionTypes triggered in reducer. SET_IS_EXTENSION_INSTLLED which has state : ', state);
    return {
      ...state,
      isExtensionInstalled: payload,
    };
  },
};

export default function items(state = initialState, action) {
  console.log('APP ACTION REDUCER initiated')
  console.log(['APP function in reducer action: ' , action]);
  console.log(['APP reducer about to update state currently: ', state ]);
  const reduceFn = actionsMap[action.type];
  if (!reduceFn) {
    console.log('APP ACTION REDUCER did not find ACTION : ', action);
    return state;
  }
  
  console.log("**** APP ACTION reducer action : ", action, " AND state", state);
  return reduceFn(state, action);
}
