import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-grid-system';
import Spinner from 'react-spinner-material';
import { BarLoader } from 'react-spinners';

import styled from 'styled-components';

import ActionButton from 'SharedComponents/action-button';
import ModalDialogV3 from 'SharedComponents/modal-dialog-v3';
import DropDownSelect from 'SharedComponents/dropdown-select';
import PopoverHover from 'SharedComponents/popover-hover';
import TextInput, { isRequired } from 'SharedComponents/text-input';
import Formsy, { withFormsy } from 'formsy-react'; // Import withFormsy

import log from "Utils/logger";

import * as RoundUpsActions from 'Flux/round-ups/actions';

import styles from './SetDefaultAmounts.css';

const SetDefaultAmountsWrapper = styled.div`${styles}`;
//const TextInputWithFormsy = withFormsy(TextInput);

const validRoundUpLimits = [{
  label: 'No limit',
  value: 0,
}, {
  label: 'AU $7.50',
  value: 750, 
}, {
  label: 'AU $10.00',
  value: 1000, 
}, {
  label: 'AU $12.50',
  value: 1250, 
}];


const validRoundUpAmounts = [{
  label: 'AU $0',
  value: 0, 
}, {
  label: 'AU $1.00',
  value: 100, 
}, {
  label: 'AU $2.00',
  value: 200, 
}, {
  label: 'AU $5.00',
  value: 500, 
}];


const SetDefaultAmounts = (
  { //authentication, 
    //app, 
    //roundups, 
    registerNewRoundupsShopper,
    registeringNewShopperForRoundups,
    requestConsentLink,
    requestingConsentLinkRef,
    onError, 
    //RoundUpsActions,
    localActiveBankConsentLink,
    //hasFetchedRoundUpsShopperData, 
  }
) => {

  const dispatch = useDispatch();
  //const authentication = useSelector(state => state.authentication);
  const roundups = useSelector(state => state.roundups);

  console.log('SetDefaultAmounts triggered with roundups: ', roundups);
  console.log('SetDefaultAmounts triggered with roundups.roundUpToNearest: ', roundups.roundUpToNearest);

  //const [showModalDialogV3, setShowModalDialogV3] = useState(false);

  //const [defaultContributeNowAmount, setDefaultContributeNowAmount] = useState(2.00);

  const [localRoundups , setLocalRoundups] = useState(roundups);

  const [roundUpAmount, setRoundUpAmount] = useState(localRoundups.roundUpToNearest);
  const [roundUpLabel, setRoundUpLabel] = useState(validRoundUpAmounts.find((item) => item.value === localRoundups.roundUpToNearest).label);
  const [updatingRoundUpAmounts, setUpdatingRoundUpAmounts] = useState(false);

  const numericWeeklyLimit = localRoundups.roundUpWeeklyLimit === "No limit" ? 0 : parseInt(localRoundups.roundUpWeeklyLimit, 10);
  const defaultWeeklyLimit = validRoundUpLimits.find((item) => item.value === numericWeeklyLimit);
  
  const [weeklyLabel, setWeeklyLabel] = useState(defaultWeeklyLimit ? defaultWeeklyLimit.label : 'No limit');
  //const [weeklyLabel, setWeeklyLabel] = useState(validRoundUpLimits.find((item) => item.value === localRoundups.roundUpWeeklyLimit).label);
  const [weeklyLimit, setWeeklyLimit] = useState(localRoundups.roundUpWeeklyLimit);
  const [updatingWeeklyLimit, setUpdatingWeeklyLimit] = useState(false);
  

  useEffect(() => {

    if ( !localRoundups.active && !registeringNewShopperForRoundups ){ //&& !roundups.currentJobs.some(job => job.job_type === 'bank_consent')) {
      registerNewRoundupsShopper();
      console.log('SetDefaultAmounts useEffect triggered to registerNewRoundupsShopper');
    }

    console.log('SetDefalutAmounts - mounted');
    return (() => {
      console.log('SetDefaultAmounts - unmounted');
    });
  }, []);

  useEffect(() => {
    console.log('SetDefaultAmounts useEffect triggered with roundups: ', roundups);
    console.log('SetDefaultAmounts useEffect triggered with localRoundups: ', localRoundups);
    if (roundups !== localRoundups) {
      console.log('SetDefaultAmounts useEffect triggered to setLocalRoundups');
      setLocalRoundups(roundups);
    }
  }, [roundups]);

  useEffect(() => {
    console.log('SetDefaultAmounts useEffect triggered to request consent link : ', localRoundups);
    if (  
          // !localRoundups.bankConsented
             !localActiveBankConsentLink
          && !requestingConsentLinkRef
          && !registeringNewShopperForRoundups 
          //&& hasFetchedRoundUpsShopperData 
        ) {
      console.log('**UseEffect SetDefaultAmounts triggered to request user consent');
      console.log('**UseEffect SetDefaultAmounts localRoundups: ', localRoundups);
      console.log('**UseEffect SetDefaultAmounts localActiveBankConsentLink: ', localActiveBankConsentLink);
      console.log('**UseEffect SetDefaultAmounts requestingConsentLinkRef: ', requestingConsentLinkRef);
      console.log('**UseEffect SetDefaultAmounts registeringNewShopperForRoundups: ', registeringNewShopperForRoundups);
      requestConsentLink();
    }
  }, [localRoundups.bankConsented, localActiveBankConsentLink]);
   //}, [roundups.bankConsented, localActiveBankConsentLink, hasFetchedRoundUpsShopperData]);
  
  useEffect(() => {
    console.log('SetDefaultAmounts useEffect triggered with localRoundups.roundUpToNearest: ', localRoundups.roundUpToNearest);
    console.log('SetDefaultAmounts useEffect triggered with roundUpAmount: ', roundUpAmount);
    //console.log('SetDefaultAmounts useEffect triggered with prevRoundUpAmount: ', prevRoundUpAmount);
    if (roundUpAmount !== localRoundups.roundUpToNearest) {
      console.log('SetDefaultAmounts useEffect roundUpAmount changing to: ', localRoundups.roundUpToNearest);
      setRoundUpAmount(localRoundups.roundUpToNearest);
      setRoundUpLabel(validRoundUpAmounts.find((item) => item.value === localRoundups.roundUpToNearest).label);
      //setPrevRoundUpAmount( roundUpAmount );
    }
  }, [ localRoundups.roundUpToNearest ]);


  useEffect(() => {
    console.log('SetDefaultAmounts useEffect triggered with localRoundups.roundUpWeeklyLimit: ', localRoundups.roundUpWeeklyLimit);
    console.log('SetDefaultAmounts useEffect triggered with weeklyLimit: ', weeklyLimit);
    if (weeklyLimit !== localRoundups.roundUpWeeklyLimit) {
      console.log('SetDefaultAmounts useEffect weeklyLimit changing to: ', localRoundups.roundUpWeeklyLimit);
      setWeeklyLimit(localRoundups.roundUpWeeklyLimit);
      // Convert the string to number for comparison
      //setWeeklyLabel(validRoundUpLimits.find((item) => item.value === localRoundups.roundUpWeeklyLimit).label);
      
      setWeeklyLabel(validRoundUpLimits.find((item) => item.value === numericWeeklyLimit).label);
    }
  }, [localRoundups.roundUpWeeklyLimit, weeklyLimit, validRoundUpLimits]);
  

  
  async function updateRoundUpAmount( newRoundUpAmount ) {
    console.log('updateRoundUpAmounts triggered');
    setUpdatingRoundUpAmounts(true);
    try {
      const response = await dispatch(RoundUpsActions.updateRoundUpAmounts( newRoundUpAmount ))
      console.log('updateRoundUpAmounts response: ', response);
      dispatch(RoundUpsActions.setRoundUpAmount( newRoundUpAmount ));
    } catch (exception) {
      console.log('updateRoundUpAmounts exception: ', exception);
      //console.log('updateRoundUpAmounts authentication: ', authentication);
      log({
        event: "System-Error",
        source: "SetDefaultAmounts- updateRoundUpAmounts",
        //'shopper_id': authentication? authentication.user.shopper_id : 'Not Signed In',
        info: exception,
        ...exception,
      });
      onError('Error updating round up amount. Please try again.');
      setTimeout(() => {
        onError('');
      }, 3000);
      
    } finally {
      setUpdatingRoundUpAmounts(false);
    }
  }

  async function updateWeeklyLimit( newLimit ) {
    console.log('updateWeeklyLimit triggered with newLimit: ', newLimit);
    setUpdatingWeeklyLimit(true);
    try {
      const response = await dispatch(RoundUpsActions.updateWeeklyLimit( newLimit ))
      console.log('updateWeeklyLimit response: ', response);
      dispatch(RoundUpsActions.setWeeklyLimit( newLimit ));
    } catch (exception) {
      console.log('updateWeeklyLimit exception: ', exception);
      log({
        event: "System-Error",
        source: "SetDefaultAmounts- updateWeeklyLimit",
        //'shopper_id': authentication? authentication.user.shopper_id : 'Not Signed In',
        info: exception,
        ...exception,
      });
    } finally {
      setUpdatingWeeklyLimit(false);
    }
  }

  const onChangeRoundUpAmount = (value) => {
    console.log('onChangeDefaultRoundUpAmount triggered');
    console.log ('onChangeDefaultRoundUpAmount new value is: ',value);
    updateRoundUpAmount ( value.value )
  };

  const onChangeWeeklyLimit = (value) => {
    console.log('onChangeWeeklyLimit triggered');
    console.log ('onChangeWeeklyLimit new value is: ',value);
    updateWeeklyLimit( value.value )
  };


  return (
    <SetDefaultAmountsWrapper>

        <div className="content-container">

          <div className="item-container">
            <div className="item">
              <div className="title-and-limit">
                  <h2 className="title">Round Up Amount:</h2>
                  <div className="limit">
                    { updatingRoundUpAmounts
                      ? <div className="loading-container">
                          <span>Updating...</span>
                          <BarLoader
                            sizeUnit={"px"}
                            size={400}
                            color="#000"
                            loading={true}
                          />
                        </div>
                      : <DropDownSelect 
                          className="dropdown-menu"
                          value={ roundUpAmount } 
                          label={ roundUpLabel }
                          valueField="validRoundUpAmounts.value" 
                          onChange={(value) => {onChangeRoundUpAmount(value)}}
                          options={ validRoundUpAmounts }
                          labelField="validRoundUpAmounts.label"
                        />
                    }
                  </div>
                  <span className="pop-over-container">
                    <PopoverHover
                      content={
                        <div style={{
                          maxWidth: '300px', padding: 10, fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left',
                        }}
                        >
                        Select the amount you would like to round up to. For example, if you select $2.00, and your spend was $4.50, then we round your spend up to $6.00 and place $1.50 into your super accont.
                        </div>
                      }
                      header = {null}
                      img = {null}
                      itemToPopover = {
                        ( <i className="material-icons" style={{ margin: '0 5px 0 5px', fontSize:'34px' }} >info</i> )
                      }
                      anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                      }}
                    />
                  </span>
                </div>
            </div>
          </div>

            <div className="item-container">
              <div className="item">
                <div className="title-and-limit">
                  <h2 className="title">Round Up Weekly Limit:</h2>
                  <div className="limit">
                  { updatingWeeklyLimit
                      ? <div className="loading-container">
                        <span>Updating...</span>
                        <BarLoader
                          sizeUnit={"px"}
                          size={400}
                          color="#000"
                          loading={true}
                        />
                        </div>
                      : <DropDownSelect 
                        className="dropdown-menu"
                        value={weeklyLimit} 
                        label={weeklyLabel}
                        valueField="validRoundUpLimits.value" 
                        onChange={(value) => {onChangeWeeklyLimit(value)}}
                        options={validRoundUpLimits}
                        labelField="validRoundUpLimits.label"
                      />
                  }
                  </div>
                  <span className="pop-over-container">
                    <PopoverHover
                      content={
                        <div style={{
                          maxWidth: '300px', padding: 10, fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left',
                        }}
                        >
                        Any ROUNDUP transaction that will cause the weekly limit to be exceeded will be excluded.
                        </div>
                      }
                      header = {null}
                      img = {null}
                      itemToPopover = {
                        ( <i className="material-icons" style={{ margin: '0 5px 0 5px', fontSize:'34px' }} >info</i> )
                      }
                      anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
        </div>
    </SetDefaultAmountsWrapper>
  );
}

export default SetDefaultAmounts;