export default () => `
  .facebook-login {
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 37.5px;
    border-radius: 3px;
    background-color: #3b5998;
    color: #fff;
    border: none;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px; */
    border: 1px solid transparent;

    &:hover {
      cursor: pointer;
      filter: brightness(95%);
    }

    .icon {
      font-size: 25px;
      margin: 0 10px 0 0px;
    }

    span.button-text {
      margin-right: 20px;
      white-space: nowrap;
      font-size: 14px;
    }
  }
`;
