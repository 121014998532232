export default ({ theme }) => {
  return `
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .sign-in-form-container {
      max-width: 600px;
      width: calc(100% - 20px);
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  `;
}
