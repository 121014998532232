export default ({ theme }) => `

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  
/*   .content-container {
    width: calc(100% - 40px);
    max-width: 1366px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap; */

    .acceptance-card{
      display: flex;
      flex-direction: column;
      /* max-width: 50%; */
      padding: 20px;
      margin: 20px;
      background-color: ${theme.darkBackgroundColor};

      .promo-code-entry-and-validate-and-activate{
        display: flex;
        flex-direction: column;

        .promo-code-entry-and-validate{
          display: flex;
          flex-direction: column;
        
          .promo-code-entry{
            display: flex;
          }

          .validation-message{
            color: #ff007d;
            margin: -10px 0 20px 20px;
            minHeight:10px;
          }
        }

        .activate-button-container{
          display: flex;
          margin: 0px 10px;
          height: fit-content;
        }

      }

    }


    .title {
      font-size: 30px;
      text-align: center;
      margin: 10px;
    }

    .description {
      font-size: 20px;
      text-align: left;
      margin: 10px;
      max-width: unset;
    }

    .checkbox-entry{
      display: flex;
      padding: 5px;
      margin-bottom: 5px;
      align-items: center;

      .input-checkbox{
        min-width: 30px;
        height: 30px;
        margin-right: 10px;

        :checked:after{
          color: black;
        }
      }

    h3 {
      font-weight: bold;
      text-align: center;
      font-weight: bolder;
      font-size: 17px;
      color: ${theme.accentColor};
      text-transform: uppercase;
    }

    button {
      margin: 10px;
    }

    h2 {
      font-weight: bolder;
      align-items: center;
      text-align: center;
      justify-content: center;
    }

    p {
      font-size: 20px;
    }
}

`;
