export default () => `
  .content-container {
    padding: 10px;
    margin: 5px;
    max-width: 400px;

    .store-details {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
    }
  }
`;

