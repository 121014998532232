export default ({ theme, maxRows }) => {
  return `
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    width: calc(100% - 20px);
    padding: 0px 10px;
    margin: 5px 0px;
    
    @media (max-width: 768px) {
      margin: 3px 0px;
    }

    .tags-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      margin: 10px 0px;

      .tag-row-container {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        position: relative;
        align-items: center;

          .tag-row-nav {
            position: sticky;
            max-width:30px;

            .button-tag-row-nav {
              text-transform: uppercase;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: row;
              background-color: #fff; /* ${theme.backgroundColor}; */
              color: ${theme.primaryTextColor};
              font-size: 14px;
              padding: 5px 10px;
              margin: 0 5px 5px 0;
              font-weight: bold;
              height: 30px;
              width:30px;
              border-radius: 50%;
              border-color: transparent;

              @media (max-width: 768px) {
                font-size: 12px;
                padding: 5px 5px;
                height: 25px;
                width:25px
              }

              &:hover {
                background-color: ${theme.shadowColor}
                color: ${theme.darkSecondaryColor}
                font-weight: bold;
                /* filter: brightness(125%); */
                /* cursor: pointer; */
                /* box-shadow: 0 0 0 1px ${theme.borderColor}; */
              }

              i {
                transform: scale(1.1);
              } 

            }

          }

          .tag-row {
            display: flex;
            align-items: center;
            flex-direction: row;
            flex-wrap: nowrap;
            overflow-x: auto; /* Enable horizontal scrolling */
            justify-content: flex-start;
            width: calc(100%-20px);
            white-space: nowrap; /* Prevent text wrapping */
            scrollbar-width: thin; /* Standard scrollbar width for Firefox */
            scrollbar-color: transparent transparent; /* Standard scrollbar color for Firefox */

              &::-webkit-scrollbar {
                background: transparent;
                height: 0px;
                width: 0;
              }



            .tags {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              flex-direction: row;
              flex-wrap: ${maxRows > 1 ? 'no-wrap' : 'wrap'};
              /* margin: 0 0 0 10px; */

              .tag {
                text-transform: uppercase;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: row;
                background-color: #fff; /* ${theme.backgroundColor}; */
                color: ${theme.primaryTextColor};
                font-size: 14px;
                padding: 5px 10px;
                margin: 0 5px 5px 0;
                border-radius: 5px;
                font-weight: bold;
                border-color: ${theme.primaryTextColor};
                border-width: 1px;

                img {
                  max-width: 24px;
                }

                @media (max-width: 768px) {
                  font-size: 12px;
                  padding: 5px 5px;

                  img {
                    max-width: 20px;
                  }
                }

                &.active {
                  color: ${theme.darkSecondaryColor};
                  font-weight: bold;
                  background-color: ${theme.borderColor};
                  cursor: pointer;
                }

                &:hover {
                  font-weight: bold;
                  filter: brightness(125%);
                  cursor: pointer;
                  box-shadow: 0 0 0 1px ${theme.borderColor};
                }
              }
            }
          }
        
      }
    }
  `;
};
