/* eslint-disable no-nested-ternary */
export default ({
  theme, large, small, medium, active, disabled, color,
}) => `
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: ${theme.secondaryTextColor};
    // border-radius: ${large ? '22.5px' : medium ? '17.5px' : small ? '12.5px' : '17.5px'};
    border-radius: 3px;
    margin: ${large ? '0px 5px' : '0px 2px'};
    padding: ${large ? '10px' : '3px 3px'};
    outline: none;
    cursor: pointer;
    background-color: ${active ? theme.darkSecondaryColor : theme.secondaryColor};
    font-size: ${large ? '17px' : medium ? '14px' : small ? '11px' : '17px'};
    border: none;
    box-shadow: 0 0 3px ${theme.secondaryColor};

    .text {
      margin: 0 14px;
    }

    &:hover {
      background-color: ${theme.secondaryColor};
      filter: brightness(125%);
    }

    &:disabled {
      background-color: ${theme.borderColor};
      cursor: not-allowed;
    }
  `;
