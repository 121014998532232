import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { useNavigate, useLocation } from 'react-router-dom';

import styles from './index.css';

const BottomNavigationWrapper = styled(BottomNavigation)`${styles}`;

function SimpleBottomNavigation({ user }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = React.useState(location.pathname);

  React.useEffect(() => {
    setValue(location.pathname);
  }, [location]);

  return (
    <BottomNavigationWrapper
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
        navigate(newValue);
      }}
      showLabels
      className="bottom-navigation"
      style={{ boxShadow: '0 -3px 3px rgba(0, 0, 0, 0.1)' }}
    >
      <BottomNavigationAction label={ user ? "Dashboard" : "Home"} value="/" style={{ color: value === '/' ? '#000000' : '#0000008a' }} icon={<i className="material-icons">{ user ? "dashboard" : "home"}</i>} />
      <BottomNavigationAction label="Shops" value="/shops" style={{ color: value === '/shops' ? '#000000' : '#0000008a' }} icon={<i className="material-icons">store_mall_directory</i>} />
      {/* {
        user ? (
          <BottomNavigationAction label="Transactions" value="/transactions" style={{ color: value === '/transactions' ? '#000000' : '#0000008a' }} icon={<i className="material-icons">receipt</i>} />
        ) : null
      } */}
      {
        user ? (
          <BottomNavigationAction label="Settings" value="/settings" style={{ color: value === '/settings' ? '#000000' : '#0000008a' }} icon={<i className="material-icons">settings</i>} />
        ) : (
            <BottomNavigationAction label="Login" value="/login" style={{ color: value === '/login' ? '#000000' : '#0000008a' }} icon={<i className="material-icons">account_circle</i>} />
          )
      }
    </BottomNavigationWrapper>
  );
}

export default connect((state) => ({ user: state.authentication.user }))(SimpleBottomNavigation);
