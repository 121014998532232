import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import log from "Utils/logger";

//import CONFIG from "Config";

import * as ShoppingActions from "Flux/shopping/actions";

const useFetchFavoriteShops = () => {
  console.log ("*** ** fetchFavoriteShops: hook triggered")
  const isFetchingFavoriteShopsRef = useRef(false);
  const hasFetchedFavoriteShopsRef = useRef(false);
  const dispatch = useDispatch();
  const storedFavoriteShops = useSelector(state => state.shopping.favorites);
  const storedStores = useSelector(state => state.shopping.stores);
  const user = useSelector(state => state.authentication.user);


  
  const fetchFavoriteShops = async () => {
    isFetchingFavoriteShopsRef.current = true;
    try {
      if (!storedStores || !storedStores.length || !user) {
        console.log('*** ** fetchFavoriteShops: exit function as no shops or no user');
        isFetchingFavoriteShopsRef.current = false;
        return;
      }
      const result = await dispatch(ShoppingActions.fetchFavoriteShops());
      //const newFavoriteShops = result.data.data;

     console.log('*** ** fetchFavoriteShops: result: ', result);

     const newFavoriteShops = result.data.favorites.map(favorite => favorite.retail_store_id)
     
      console.log('*** ** fetchFavoriteShops: storedFavoriteShops: ', storedFavoriteShops)
      console.log ('*** ** fetchFavoriteShops: newFavoriteShops: ', newFavoriteShops);
      
      console.log('*** ** fetchFavoriteShops: JSON.stringify(storedFavouriteShops): ', JSON.stringify(storedFavoriteShops));
      console.log ('*** ** fetchFavoriteShops: JSON.stringify(newFavoriteShops): ', JSON.stringify(newFavoriteShops));
      
      if (shouldUpdateStore(newFavoriteShops, storedFavoriteShops)) {
        console.log('*** ** fetchFavoriteShops: DIFFERENCE - should update store');
        await dispatch(ShoppingActions.setFavoritesOnStores(newFavoriteShops));
        dispatch(ShoppingActions.addFavoriteShops());
      }
    

    } catch (exception) {
      console.log(exception);
      log({
        event: "System-Error",
        source: "Fetch FavoriteShops",
        info: exception,
        ...exception,
      });
    } finally {
      isFetchingFavoriteShopsRef.current = false;
      hasFetchedFavoriteShopsRef.current = true;
    }
  };

  function shouldUpdateStore(newFavoriteShops, storedFavoriteShops) {

    if (newFavoriteShops.length !== storedFavoriteShops.length) {
      return true; 
    }

    const storedRetailerIds = new Set(storedFavoriteShops.map(shop => shop.retail_store_id));
  
    for (const shopId of newFavoriteShops) {
      if (!storedRetailerIds.has(shopId)) {
        return true;
      }
    }
  
    return false; // No differences found
  }

  useEffect(() => {
    if ( user
        && storedStores?.length > 0
        && !isFetchingFavoriteShopsRef.current
        && !hasFetchedFavoriteShopsRef.current) {
      fetchFavoriteShops();
    }
  }, []);

   return { fetchFavoriteShops };
};

export default useFetchFavoriteShops;
