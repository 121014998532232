export default ({ theme }) => {
  return `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin: 10px;


    .links-group{
      max-width: 95vw !important;
    }

    .title {
      font-size:40px;
      @media (max-width: 768px) {
        font-size: 25px;
      }
    }

    .cards-container {
      display: flex;
      flex-direction: row;
      max-width: 1366px;
      margin-bottom: 240px;
      @media (max-width: 1024) {
        margin-bottom: 160px;
      }
    }

    .card-image{
      background-position: top right;
      background-size: cover;
      background-repeat: no-repeat;
      overflow: hidden;
      min-height: 200px;
      width: 100%;
    }

    .logo-container{
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-evenly;
      height: 100%;
      align-content: center;
    }


    .swiper {
      width: 100%;
      height: 100%;
    }

    .swiper-wrapper {
      display: flex;
    }

    .swiper-slide {
      /* width: 100%; */  
      /* height: 100%; */ 
      display: flex; 
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    

    .swiper-button-prev,
    .swiper-button-next {
      color: #000;
    }
    

    .swiper-pagination {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .swiper-pagination-bullet {
      width: 16px; /* Ensure pagination dots have a visible width */
      height: 16px; /* Ensure pagination dots have a visible height */
      background-color: #fff; /* Ensure pagination dots are a visible color */
    }

    .swiper-pagination-bullet-active {
      width: 20px; /* Ensure pagination dots have a visible width */
      height: 20px; /* Ensure pagination dots have a visible height */
      background-color: #fff; /* Ensure pagination dots are a visible color */
    }

    .sign-in-form-container {
      max-width: 600px;
      width: calc(100% - 20px);
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    `;
  }