export default ({ theme, value }) => `
  width: calc(100% - 20px);
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${theme.headerHeight}px;
  padding: 0px 10px;
  position: relative;

  input[type="text"] {
    width: 100%;
    border-radius: 30px;
    border: solid 1px ${theme.borderColor};
    padding: ${value ? '10px 60px 10px 20px' : '10px 20px'};
    color: ${theme.primaryTextColor}
    outline: none;
    font-size: 30px;
    height: calc(100% - 10px);
    height: 30px;
  }
`;
