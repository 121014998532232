import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import log from "Utils/logger";

import * as TestimonialActions from 'Flux/testimonials/actions';

const useFetchTestimonials = () => {
  console.log ('*** * fetchTestimonials: hook triggered');
  const isFetchingTestimonialsRef = useRef(false);
  const hasFetchedTestimonialsRef = useRef(false);
  const dispatch = useDispatch();
  
  const storedTestimonials = useSelector(state => state.testimonials || null);

  console.log('*** * fetchTestimonials: storedTestimonials:', storedTestimonials)

  const fetchTestimonials = async () => {
    isFetchingTestimonialsRef.current = true;
    try {
      const result = await dispatch(TestimonialActions.fetchTestimonials());
      console.log ('*** * fetchTestimonials: fetch  result  is:', result)
      console.log('*** * fetchTestimonials: storedTestimonials:', storedTestimonials)

      const newTestimonials = result.data.testimonials;

      console.log('*** * fetchTestimonials: newTestimonials:', newTestimonials);

      if (shouldUpdateStore(newTestimonials, storedTestimonials)) {
        console.log('*** * fetchTestimonials: and storedTestimonials are different');
        console.log('*** * fetchTestimonials: DISPATCH SET boostProfile with newTestimonials: ', newTestimonials);
        dispatch(TestimonialActions.setTestimonials(newTestimonials));
      }
    } catch (exception) {
      console.log(exception);
      log({
        event: "System-Error",
        source: "Fetch Shopper boostProfile",
        //'shopper_id': authentication? authentication.user.shopper_id : '000',
        info: exception,
        ...exception,
      });
    } finally {
      isFetchingTestimonialsRef.current = false;
      hasFetchedTestimonialsRef.current = true;
    }
  };

  function shouldUpdateStore(newTestimonials, storedTestimonials) {

    if (!isEqual(newTestimonials,storedTestimonials)) {
      console.log('*** * fetchTestimonials: confirmed not equal');
      return true; // Differences found
    }
  
    return false; // No differences found
  }

  useEffect(() => {
    console.log('*** * fetchTestimonials: useEffect triggered');
    if (   //!storedTestimonials
        !isFetchingTestimonialsRef.current 
        && !hasFetchedTestimonialsRef.current) {
        fetchTestimonials();
    }
  } , []);

  return { fetchTestimonials };
};

export default useFetchTestimonials;