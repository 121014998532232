import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import log from "Utils/logger";

import * as RoundUpsActions from 'Flux/round-ups/actions';

const useUpdateEnableContributeNow = () => {
  console.log ('*** ** updateEnableContributeNow: hook triggered')
  //const isUpdatingEnableContributeNowRef = useRef(false);
  const hasUpdatedEnableContributeNowRef = useRef(false);
  const dispatch = useDispatch();
  const storedEnableContributeNow = useSelector(state => state.roundups.contributeNowEnabled);
  const [updatingContributeNowEnabled, setIsUpdatingContributeNowEnabled] = useState(false);

  const updateEnableContributeNow = useCallback(async (newEnableContributeNowState) => {

    if (updatingContributeNowEnabled) {
      console.log('*** ** updateEnableContributeNow: isUpdatingEnableContributeNowRef is true - lets exit function.')
      return; 
    }
    setIsUpdatingContributeNowEnabled(true);
    //console.log('*** ** updateEnableContributeNow: isUpdatingEnableContributeNowRef is false - lets set isUpdatingEnableContributeNowRef to true.')
    
    try {
      const result = await dispatch(RoundUpsActions.updateContributeNowEnabled(newEnableContributeNowState));
      const newEnableContributeNow = result.data.data.contributeNowEnabled;

      console.log('*** ** updateEnableContributeNow: storedEnableContributeNow: ', storedEnableContributeNow)
      console.log ('*** ** updateEnableContributeNow: newEnableContributeNow: ', newEnableContributeNow);
      
      if (shouldUpdateStore(newEnableContributeNow, storedEnableContributeNow)) {
        console.log('*** ** updateEnableContributeNow: newEnableContributeNow and storedEnableContributeNow are different');
        console.log('*** ** updateEnableContributeNow: newEnableContributeNow.keys.length:',Object.keys( newEnableContributeNow).length)
        console.log('*** ** updateEnableContributeNow: storedEnableContributeNow.keys.length:', Object.keys(storedEnableContributeNow).length)
        console.log('*** ** updateEnableContributeNow: stringify(newEnableContributeNow): ', JSON.stringify(newEnableContributeNow));
        console.log('*** ** updateEnableContributeNow: stringify(storedEnableContributeNow): ', JSON.stringify(storedEnableContributeNow));
          console.log('*** ** updateEnableContributeNow: DISPATCH SET roundUpsUserData with newEnableContributeNow: ', newEnableContributeNow);
          dispatch(RoundUpsActions.setContributeNowEnabled( result.data.data.contributeNowEnabled ));
      }

    } catch (exception) {
      console.log(exception);
      log({
        event: "System-Error",
        source: "Fetch shopperData",
        //'shopper_id': authentication? authentication.user.shopper_id : '000',
        info: exception,
        ...exception,
      });
    } finally {
      //isUpdatingEnableContributeNowRef.current = false;
      setIsUpdatingContributeNowEnabled(false);
      console.log('*** ** updateEnableContributeNow: isUpdatingEnableContributeNowRef is true - lets set isUpdatingEnableContributeNowRef to false.')
      //await dispatch(RoundUpsActions.isFetchingEnableContributeNow(false));
      hasUpdatedEnableContributeNowRef.current = true;
    }
  }, [ updatingContributeNowEnabled ]); 

  function shouldUpdateStore(newEnableContributeNow, storedEnableContributeNow) {
    if (!storedEnableContributeNow) { 
      console.log('*** ** updateEnableContributeNow: storedEnableContributeNow is null shouldUpdateStore : TRUE' );
      return true; // Always update if stored data is initially null
    }

    if (!isEqual(newEnableContributeNow, storedEnableContributeNow)) {
          console.log('*** ** updateEnableContributeNow: newEnableContributeNow and storedEnableContributeNow are the same shouldUpdateStore : FALSE' );
        return true; // differences found
    }

      return false;
  }

  return { 
            updatingContributeNowEnabled, 
            updateEnableContributeNow 
          };
};

export default useUpdateEnableContributeNow;
