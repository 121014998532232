import React, { Component } from 'react';
//import Popover from 'react-tiny-popover';

export default class PopoverText extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isPopoverOpen: false,
		}
	}

	render() {
		const { isPopoverOpen } = this.state;

		return (
			<Popover
		    isOpen={isPopoverOpen}
		    position={'top'}
				padding={10}
				onClickOutside={() => this.setState({ isPopoverOpen: false })}
		    content={(
	        <div className="table-popover-content" style={{ backgroundColor: '#fff', padding: 5, borderRadius: 5, boxShadow: '0 0 10px 3px rgba(0, 0, 0, 0.3)', border: 'solid 1px #d9d9d9',  maxWidth: 200 }}>
	          {this.props.content}
	        </div>
		    )}
			>
				<i className="fa fa-info-circle" style={{ margin: '0 0 0 10px' }} onClick={() => this.setState({ isPopoverOpen: !isPopoverOpen })}></i>
			</Popover>
		);
	}
}
