export default ({ theme }) => `
    display: flex;
    -webkit-box-align: center;
    align-items: flex-start;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;

    .form-entry {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      color: rgb(73, 92, 99);

      .checkbox-entry{
        display: flex;
        padding: 5px;
        margin-bottom: 5px;
        align-items: center;

        .input-checkbox{
          min-width: 30px;
          height: 30px;
          margin-right: 10px;

          :checked:after{
            color: black;
          }
        }

        .input-label{
          font-size: 14px;
        }

        .highlight-message {
          color: red !important;
        }
        
      }

      .text-entry{

        .input-currency-symbol::before {
          content: "$";
          position: absolute;
          transform: translate(0px, 265%);
          pointer-events: none;
          width: 20px;
          text-align: center;
          font-size: smaller;

          @media(max-width:912px) {
            transform: translate(0px, 285%);
          }
          
        }
      }

      .comments-entry{
        display: flex;
        flex-wrap: wrap;
        width: 100%;


        label{
          margin: 0px 0px -5px 0px;
          font-size: small;
          font-weight: lighter;
          padding: 0px;
          width: 100%;
        }

        textarea{
          display: flex;
          font-family: inherit;
          width: 100%;
          resize: none;
          font-size: inherit;
          resize: none;
          height: 120px;
          padding: 10px 15px;
          margin: 10px 0px;
          border-color: ${theme.borderColor};
        }

        .comments-error-notification{
          color: #ff007d;
        }
      }


      .issue-bank-label {
        margin: 10px 0px -5px;
        font-size: small;
        font-weight: lighter;
        padding: 0px;
        width: 100%;
      }
    }

    @media(max-width: 425px) {
      width: calc(100% - 20px);
      padding: 10px;
    }
  `;

