export default ({ theme }) => `
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;

  .material-icons.styling{
    color:${theme.accentColor};
    font-size: 36px;
    padding: 0px 5px;
  }


  .content-container {
    max-width: 1366px;
    padding: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: calc(100% - 40px);
    flex-direction: column;

    .heading{
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      align-items: center;

    }

    .key-points {
      display: flex;
      align-items: center;
      padding: 5px;      
    }

    .content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .right-content, .left-content {
        margin-top: 50px;
        padding-bottom: 50px;
        flex: 1 1 0px;
      }
    }

    @media(max-width: 768px) {
      .content {
        flex-direction: column;

        .categories-container {
          border-bottom: solid 2px #d9d9d9;
        }

        .right-content, .left-content {

          flex: unset;
        }
      }
    }

    h2 {
      font-weight: bolder;
    }
    

    p {
      font-size: 20px;
    }

    .shops-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }

    .categories-container {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-wrap: wrap;
      // border-bottom: solid 2px #d9d9d9;

      .category {
        background-color: #ffead1;
        color: ${theme.accentColor};
        margin: 5px;
        border-radius: 26px;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 15px;
        font-weight: bolder;
        font-size: x-large;
      }
    }
  }
`;
