import React from 'react';

const TermsAndConditions = () => (
  <section style={{ minHeight: 'calc(100vh - 400px)' }}>
    <h2>Terms and Conditions</h2>
    <p>This agreement outlines your obligations as a member or user of the website boostyoursuper.com.au and the browser extension “Boost Your Super Notifier” and explains Boost Your Super's obligations to you. By using the Website, you agree that you have read and you understand, and consent to our Privacy Policy and the following Terms and Conditions. boostyoursuper.com.au allows online shoppers to raise money for their super fund whenever they shop online at the stores on the Boost Your Super site. Whenever a purchase is successfully tracked via the link then the shopper will raise money for their super fund for which they provided the required payment details.  If you have objections to the Terms & Conditions or Privacy Policy you should not access or use the Site </p>
    <h4>Boost Your Super is Not Qualified to Provide Tax Advice</h4>
    <p>Boost Your Super is not qualified to provide tax implications of payments we make to your superannuation fund.Boost Your Super makes non-concessional after tax contributions payments to your super. There are non-concessional contributions caps that may potentially have a tax implication. You shouldobtain specific, individual advice from a qualified tax advisor to determine any impact this may have on your personal circumstances.</p>
    <h4>Boost Your Super is Not a Financial Services Provider</h4>
    <p>Boost Your Super does not provide a financial product or financial product advice. We do not promote or recommend any specific financial products (eg super fund products). By using the platform you acknowledge we cannot provide you with any financial advice. You should obtain specific, individual advice from a licensed financial advisor to determine any impact of using Boost Your Super in your own personal circumstances. We do publish information from 3rd parties in relation to making contributions to super where we reasonably examine the information is correct. However, we are not liable for any incorrect information from 3rd parties that we might mistakenly publish.</p>
    <h4>Email Permission</h4>
    <p>When you register with Boost Your Super you are giving us permission to email you. We may send you email regarding any account activity (eg transactions or updates to your account) as well as marketing emails with content such as merchants and their products as well as voucher codes and sales from our merchants. If you no longer wish to receive marketing emails, you can unsubscribe at any time from the footer of any email. You must not use the Boost Your Super Website for any unlawful purpose.</p>
    <p>You must not post or introduce software on the Site that:</p>
    <ul>
      <li>is designed and intended to disrupt, disable, harm or otherwise impede in any manner, including aesthetic disruptions or distortions, the operation of this Site or any other software, firmware, hardware, computer system or network (sometimes referred to as "viruses" or "worms");</li>
      <li>would disable or impair this Site or any other software, firmware, hardware, computer systems or networks in any way where such disablement or impairment is caused by the elapsing of a period of time, exceeding an authorized number of copies, advancement to a particular date or other numeral (sometimes referred to as "time bombs," "time locks" or "drop dead" devices);</li>
      <li>would permit you or any other person to access this Site or any other software, firmware, hardware, computer systems or networks to cause such disablement or impairment or transmit information (sometimes referred to as "traps," "access codes," or "trap door" devices, or "spyware"); or</li>
      <li>which may contain any other similar harmful, malicious or hidden procedures, routines or mechanisms which would cause such programs to cease functioning or to damage or corrupt data, storage media, programs, equipment or communications or otherwise interfere with operations.</li>
    </ul>
    <p>On an event of your breach of any of the conditions above, your membership, if created, will be cancelled and you may be prosecuted for damages or expenses arising directly or indirectly out of your actions.</p>
    <h4>Supply of Products</h4>
    <p>We refer you to 3rd party online stores who supply products you can purchase. We do not sell or supply any products directly to consumers</p>
    <h4>Consumer Guarantees</h4>
    <p>Under the Australian Consumer Law, consumer guarantees apply. Nothing in these Terms and Conditions nullifies the application of those guarantees. If you are interested in more information about your rights as a consumer, see the Australian Consumer Law website. We do not sell any products on our site, so if you have an issue with an online store, you will need to address your grievance directly with them.</p>
    <h4>Referral Relationships</h4>
    <p>We have paid referral relationships with the partner stores listed on the Boost Your Super website. We refer you to our partner stores via a link. These links may be found within partner logos, partner names or shop online buttons. By clicking the links you acknowledge that we have referred you to the partner store. When you purchase a product or complete an agreed transaction, we are paid a referral fee by the partner store. When we are paid the referral fee, we pass on the advertised portion to your nominated superannuation account.</p>
    <h4>Referral Payments</h4>
    <p>In the unusual situation that a Boost Your Super partner does not make an agreed payment, we will make every reasonable attempt to receive payment from them. Once payment has been received by us, funds will be passed on to your nominate super account. In the case that no funds are forthcoming from the partner, we will be unable to pass on contributions. We will immediately consider termination of our relationship with the partner store in question. In the case that a lower amount of funds are received from the partner than expected, your super accountwill receive a reduced amount that is proportionate to what we have received. Upon receipt of the expected balance, we will pay the nominated account the remaining amount that was advertised.</p>
    <h4>Limitation of Liability</h4>
    <p>To the extent permitted by applicable laws, we do not accept any liability for:</p>
    <ol>
      <li>loss of income, loss of profits, loss of contracts, loss of data or for any indirect or consequential loss or damage of any kind howsoever arising and whether caused by tort (including negligence), breach of contract or otherwise.</li>
      <li>any damage or injury of any nature caused by the use or misuse of products purchased after having been referred by Boost Your Super;</li>
      <li>errors, mistakes or inaccuracies on the Boost Your Super website;</li>
      <li>you acting, or failing to act, on any information contained on or referred to on the Boost Your Super website and/or any linked website;</li>
      <li>any unauthorised access to or use of our secure servers and/or personal information and/or financial information stored on those servers;</li>
      <li>any interruption or cessation of transmission to or from the Boost Your Super website; and/or</li>
      <li>any bugs, viruses, trojan horses or other harmful code or communications which may be transmitted to or through the Boost Your Super website by any third party.</li>
    </ol>
    <h4>Payments to Super Accounts</h4>
    <p>We will only make payments to nominated superannuation accounts once referral fees have been received from our partners and reconciled into our bank account. By using this service you give us explicit permission to make the payments to your super fund on your behalf. The payments are made as non-concessional after tax contributions. Should you wish to make payments in any other way (eg spouse contributions) and/or understand any tax implications you shouldobtain specific, individual advice from a licensed financial advisor to determine any impact this may have on your personal circumstances. We make payments within 21 business days of the end of each quarter and may increase the frequency at our discrepancy. We accept no liability for any reasonable delays of payments to your super account. We make no promises or representations about the amount of money a nominated super account will receive from Boost Your Super. This will depend on the nature and amount of products bought after having been referred from the Boost Your Super website. We will not be liable or held responsible if a member does not receive the amount of money they were hoping to or expecting to. If a member registers incorrect payment details (such as incorrect Bpay details), we cannot be held responsible for payments that are made into the wrong account. If you notice that payments are not being received into the correct account, please contact us immediately and we will endeavour to have the payments reversed. If there are bank fees involved in reversing transactions, we reserve the right to subtract these fees from payments made to nominated super accounts.</p>
    <h4>Copyright and Trademarks</h4>
    <p>All of the images, logos, text, content, and unique method of helping members boost their superannuation at boostyoursuper.com.au are copyright. The Boost Your Super trade mark and logo are trade marks of Boost Your Super. All other trade marks are the property of their respective owners, who are not associated with us. The above trademarks or logos may be used to promote Boost Your Super in a manner that we alone deem appropriate. Our trademarks and logos must not be used for any other purpose without our specific, written prior permission. The information contained on our website is subject to change without notice.</p>
    <h4>General</h4>
    <p>We reserve the right to change the Terms and Conditions of Boost Your Super at any time.</p>
    <h4>GOVERNING LAW</h4>
    <p>These Terms of Service are governed by, and are to be construed in accordance with, the laws of the State of Victoria, Australia. You agree that the courts of the State of Victoria, Australia have non-exclusive jurisdiction with respect to any matter arising from these Terms of Service or the use of this Website.</p>
  </section>
)

export default TermsAndConditions;
