export default ({ theme }) => `
    @font-face {
      font-family: 'Lato';
      font-display: auto;
      src: url(https://fonts.gstatic.com/s/lato/v14/S6uyw4BMUTPHjx4wXiWtFCc.woff2) format('woff2');
    }

    html {
      overflow: scroll;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;  /* fix for iOS sticky scrolling   */
    }

    html body::-webkit-scrollbar {  
      width: 0px;
      background: transparent;
    }
    
    ::-webkit-scrollbar {
      width: 0px;  /* remove scrollbar space */
      background: transparent;  /* optional: just make scrollbar invisible */
    }

    body {
      margin: 0px !important;
      font-family: 'Lato';
    }

    .hide-scrollbars {
      -ms-overflow-style: none;  // IE 10+
      overflow: -moz-scrollbars-none;  // Firefox
    }
    
    .hide-scrollbars::-webkit-scrollbar {
      display: none;  // Safari and Chrome
    }
    aside.top-nav {}

    h1 {
      font-size: 30px;
      margin: 20px 0 10px;
      text-align: left;
    }

    h4 {
      font-size: 18px;
      margin: 7px 0 5px;
      text-align: left;
    }

    p {
      margin: 10px 0px 20px;
      text-align: left;
    }

    .main {
      height: 100vh;
      width: 100vw;
      overflow: auto;
      background-color: ${theme.neutralColor};
      color: ${theme.neutralTextColor};

      &.no-nav {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        overflow: auto;
      }

      &>.mobile {
        height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;

        .app-bar {
          width: 100%;
          z-index: 2;
          position: sticky;
          top:0px;
        }

        &>.content {
          width: 100%;
          /* z-index: 0;  */
          height: 100%;
        }

        .navigation {
          z-index:2;
        }

        .contribute-now-floating-button {
          position: fixed;
          bottom: 70px;
          left: 30px;
          z-index: 99; /* Adjust the z-index as needed */
        }

        .bottom-navigation {
          height: 56px;
          width: 100%;
          position: fixed;
          bottom: 0;
        }
      }

      &>.desktop {
        height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;

        &>.content {
          width: 100%;
          height: calc(100% - 64px);
          overflow: auto;
        }

        .contribute-now-floating-button {
          position: fixed;
          bottom: 60px;
          left: 30px;
          z-index: 99; /* Adjust the z-index as needed */
        }

        .navigation {
          width: 100%;
          position: sticky;
          top: 0;
          z-index:2;
        }
      }
    }

    .banner {
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(100% - 40px);
      padding: 20px;
      font-size: 18px;
      font-weight: bold;
      background: linear-gradient(0.25turn, #3f87a6, #ebf8e1, #f69d3c);
      color: ${theme.primaryTextColor};

      @media(min-width: 1024px) {
        padding-left: 220px;
        width: calc(100% - 240px);
      }
    }

    .flex-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    a {
      text-decoration: none !important;
    }
  `;
