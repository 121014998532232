export default ({ theme }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .content-container {
    max-width: 1366px;
    padding: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin: 10px 0 40px;

    h4 {
      color: ${theme.darkSecondaryColor};
    }

    p {
      color: ${theme.lightSecondaryColor};
    }

    ol {
      li {
        margin: 10px 0;
      }
    }
  }
`;
