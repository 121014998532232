import React, { useCallback, memo } from 'react'; // Import memo
import styled from 'styled-components';

import Tab from './tab';

import styles from './index.css.js';

const TabsWrapper = styled.div`${styles}`;

const Tabs = ({ activeTab, onActiveTabChanged, tabs }) => {
  const memoizedOnActiveTabChanged = useCallback(
    newActiveTab => {
      onActiveTabChanged(newActiveTab);
    },
    [onActiveTabChanged]
  );

  return (
    <TabsWrapper className="hide-scrollbars">
      {tabs.map(tab => (
        <Tab
          label={tab.label}
          value={tab.value}
          active={activeTab === tab.value}
          onClick={memoizedOnActiveTabChanged}
          count={tab.count}
          key={tab.value}
        />
      ))}
    </TabsWrapper>
  );
};

export default Tabs; // Wrap the component with memo
