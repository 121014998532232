export default ({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &>.content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 20px;

      width: calc(100% - 40px);
      max-width: 1366px;
    }

    .links-container {
      .payment-method-designation {
        background-color: ${theme.lightBorderColor};
        padding: 10px 10px;
        margin-top: 10px;

        .switcher {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-top: solid 1px ${theme.borderColor};
        }
      }
    }
  `;
