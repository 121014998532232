export default ({ theme }) => `
  background-color: #212121;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  
    .content-container {
      max-width: 1366px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 40px 0;
      color: #FFFFFF;

      .links-container {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        padding: 40px !important;

        .links-group {
          // width: calc(100% - 80px) !important;
          // margin: 10px !important;
          // padding: 10px !important;
          .header {
            margin: 10px 0;
            span {
              color: #747474;
              font-size: 20px;
              margin: 10px 0;
            }
          }

          .links {
            color: ${theme.neutralColor};
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: flex-start;

            .link {
              color: #C8C8C8;
              text-decoration: none;
              text-transform: uppercase;
              font-size: 15px;
              margin: 4px 0;

              &:hover {
                color: #FFFFFF;
              }

              &.image-link {
                display: flex;

                .image-container {
                  background-color: transparent;
                  height: 20px;
                  width: 20px;
                  border-radius: 3px;
                  margin: 0 10px 0 0;

                  img {
                    height: 100%;
                    width: 100%;
                    border-radius: 3px;
                  }
                }
              }
            }
          }
        }
      }
      .gen-advice-container{
        display: flex;
        align-items: flex-start;
        flex-direction: row;

        p {
          font-size: larger;
          padding: 10px 40px 20px 40px;
        }
      }

    }
  `;
