import React from 'react';
import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';
import moment from 'moment';
import MuiDialogContent from '@mui/material/DialogContent';

import IconButton from 'SharedComponents/icon-button';
import RaiseTransactionInquiryForm from 'SharedComponents/form-raise-transaction-inquiry';

import styles from './index.css';

const RaiseTransactionInquiryDialogWrapper = styled(Dialog)`
  ${styles}
`;

const RaiseTransactionInquiryDialog = ({
  open,
  proceedToTransaction,
  fullScreen,
  onRaiseInquiry,
  onCloseRaiseInquiryDialog,
  onAfterRaiseInquiry,
  uniqueID,
  storeName,
  clickTime,
  onFetchCauseDonations
}) => {
  const windowWidth = window.innerWidth;
  return (
    <RaiseTransactionInquiryDialogWrapper open={open} fullScreen={windowWidth < 768}>
      <div className="content-container">
        <div
          style={{
					  display: 'flex',
					  width: '100%',
					  alignItems: 'center',
					  justifyContent: 'flex-end',
          }}
        >
          <IconButton onClick={ () => onCloseRaiseInquiryDialog() }>
            <i className="material-icons">close</i>
          </IconButton>
        </div>
        <h1>Raise Inquiry</h1>
        <p>Raise an inquiry for us to investigate a missing transaction.</p>
        <div className="store-details">
          <span>Store:<b> {storeName}</b></span>
          <span>Unique ID:<b> {uniqueID}</b></span>
          <span>Click time:<span style={{fontWeight:"bold"}}>&nbsp;{moment(clickTime).local().format('DD MMM YYYY, h:mm:ss a')}</span></span>
        </div>
        <RaiseTransactionInquiryForm
          onAfterRaiseInquiry={onAfterRaiseInquiry}
          uniqueID={uniqueID}
          storeName={storeName}
          onCloseDialog={onCloseRaiseInquiryDialog}
          delayOnAfterSignIn
          showEmailLogin
          onFetchCauseDonations={ onFetchCauseDonations }
        />
      </div>
    </RaiseTransactionInquiryDialogWrapper>
  );
};

export default RaiseTransactionInquiryDialog;

