import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import log from 'Utils/logger'; // Assuming you have a logError function in your logger utility
import ActionButton from 'SharedComponents/action-button';
import styles from './index.css.js';

const NotFoundWrapper = styled.div`${styles}`;

const NotFound = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authentication = useSelector(state => state.authentication); // Assuming you have authentication state in your redux store

  const handleClick = () => {
    log({
      shopper_id: authentication.user ? authentication.user.shopper_id : '000',
      event: 'System-Error',
      source: 'User clicked Reload button on 404 page',
    });
    navigate('/shops');
  };

  return (
    <NotFoundWrapper>
      <h1>Yipes... something went haywire!</h1>
      <div>
        <p>ooops... it's not you it's us. Something went wrong :(. We have logged the error and will look into it. Please do some retail therapy :)...</p>
        <ActionButton onClick={handleClick} text="Click for retail therapy" />
      </div>
    </NotFoundWrapper>
  );
};

export default NotFound;

