export default ({ theme }) => `
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  max-width: 100vw;
  overflow: auto;
  margin-top: 10px;
  border-bottom: 1px solid ${theme.borderColor};

  @media(max-width: 768px) {
    width: 100vw;
    justify-content: space-between;
  }
`;
