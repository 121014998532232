/* eslint-disable react/require-default-props */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import CONFIG from 'Config';

import ActionButton from 'SharedComponents/action-button';
//import consumerDataRightLogo from 'Images/logo-400px-ConsumerDataRight.png';



import styles from './Slide.css';

const SlideWrapper = styled.div`${styles}`;

const Slide = ({
  imageUrl, title, text1, text2, text3, text4, backgroundPosition,
}) => {
  const consumerDataRightLogo = `${CONFIG.assetsUrl}/assets/images/logo-400x200px-ConsumerDataRight.png`;
  return (
    <SlideWrapper imageurl={imageUrl} backgroundposition={backgroundPosition}>

      <div className="text-container">
        <h1><span className="highlight-text">{title}</span></h1>
        <p>{text1}</p>
        <p>{text2}</p>
        <div className="button-container">
          <img src={consumerDataRightLogo} alt="Consumer Data Right Logo" style={{ width: '100px', height: 'auto', marginRight: '20px', borderRadius: '3px' }} />
          <p>{text3}</p>
        </div>
        <p className="calculation-details">{text4}</p>

      </div>
    </SlideWrapper>
  );
};

Slide.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text1: PropTypes.string,
  text2: PropTypes.string,
  text3: PropTypes.string,
  backgroundPosition: PropTypes.string.isRequired,
};

export default Slide;
