import React, { useEffect } from 'react';
import moment from 'moment';
import Spinner from 'react-spinner-material';
import styled from 'styled-components';

import Avatar from 'SharedComponents/avatar';

import styles from './ActivityLog.css';

const ActivityLogWrapper = styled.div`${styles}`;

const ActivityLog = ({ activityLogs, isFetchingActivityLogs }) => {

   return (
    <ActivityLogWrapper className="activity-logs" style={{ minHeight: 500 }}>
      {isFetchingActivityLogs ? (
        <div style={{ height: 200, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
          <Spinner spinnerColor="#000000" size={20} spinnerWidth={3} />
          <span style={{ margin: '10px 0' }}>Fetching Activity Logs</span>
        </div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '10px 0' }}>
          { (activityLogs && !activityLogs.length) ? (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: 20 }}>
              <h2 style={{ fontSize: 20 }}>We have not recorded changes in your account information</h2>
              <p>Changes in your account information made by you and your collaborators will be displayed here.</p>
            </div>
          ) : (
            <div className="list" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column' }}>
              {activityLogs && activityLogs.map(activityLog => (
                <div key={activityLog.id} className="activity-log-item" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px' }}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: 'calc(100% - 100px)' }}>
                    {activityLog.title.toLowerCase().indexOf('bank') > -1 ? (
                      <Avatar icon="credit_card" color="red" size={40} />
                    ) : activityLog.title.toLowerCase().indexOf('cause images') > -1 ? (
                      <Avatar icon="panorama" color="purple" size={40} />
                    ) : activityLog.title.toLowerCase().indexOf('cause information') > -1 ? (
                      <Avatar icon="note" color="blue" size={40} />
                    ) : (
                      <Avatar icon="list_alt" color="orange" size={40} />
                    )}
                    <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: 'calc(100% - 60px)', margin: 10 }}>
                      <span style={{ fontWeight: 'bolder', fontSize: 14 }}>
                        <b>{`${activityLog.shopper_fname} ${activityLog.shopper_lname ? activityLog.shopper_lname : ''}`}</b>
                        &nbsp;
                        {activityLog.title}
                        &nbsp;on&nbsp;
                        {moment(activityLog.timestamp).local().format('Do MMM YYYY')}
                      </span>
                      <small>{moment(activityLog.timestamp).local().fromNow()}</small>
                    </div>
                  </div>
                  <div style={{ width: 100 }}>
                    <span style={{ marginLeft: 10 }}>{moment(activityLog.timestamp).local().format('Do MMM YY')}</span>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </ActivityLogWrapper>
  );
};

export default ActivityLog;