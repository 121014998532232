import React, { Component } from 'react';
import styled from 'styled-components';

import IconButton from 'SharedComponents/icon-button';

import styles from './index.css.js';

const SearchWrapper = styled.div`${styles}`;

const Search = ({ value, onChange, onClearSearch }) => (
  <SearchWrapper value={value}>
    <input
      type="text"
      placeholder="search; e.g. dress, travel, wine, hotel, fashion"
      onChange={onChange}
      value={value}
    />
    {
      value ? (
        <div style={{ position: 'absolute', top: 12, right: 15 }}>
          <IconButton onClick={onClearSearch}>
            <i className="material-icons">close</i>
          </IconButton>
        </div>
      ) : null
    }
  </SearchWrapper>
);

export default Search;
