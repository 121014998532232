export default ({ theme, shop, logoSize }) => `
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: ${theme.neutralColor};
    margin: 10px;
    flex-direction: column;
    // cursor: pointer;
    outline: none;
    border: none;

    .logo-image-container {
      height: ${logoSize ? logoSize : "120px"};
      width: ${logoSize ? logoSize : "120px"};
      box-shadow: rgb(0 0 0 / 20%) 0px 0px 6px 2px;
      display: flex;
      align-items: center:
      justify-content: center;
      background-image: url(${shop.retail_store_logo_url});
      background-attachment: local;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      border-radius: 5px;

      @media(max-width:769px){
        height: 60px;
        width: 60px;
      }

      .img {
        width: 100%;
        max-width: 100%;
        max-height: 100%;
      }
    }

    .header, .footer {
      width: calc(100% - 6px);
      padding: 5px 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      text-transform: capitalize;
    }
  `;
