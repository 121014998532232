import React from 'react';
import styled from 'styled-components';

import Footer from 'SharedComponents/footer';

//import styles from './index.css';
import styles from './index.css.js';

//const FAQWrapper = styled.div`${styles}`;
//const FinancialServicesGuideWrapper = styled.section`${styles}`;
const FinancialServicesGuideWrapper = styled.div`${styles}`;


const FinancialServicesGuide = () => (
  <FinancialServicesGuideWrapper>
    <div className="content">
      <div className="content-container" style={{ minHeight: 'calc(100vh - 400px)' }}>
        <h1>Financial Services Guide</h1>

        <br />
        <h2>Important information</h2>
        <p>This Financial Services Guide (FSG) is intended to inform you of certain basic matters relating to our relationship, prior to us providing you with a financial service. The matters covered by the FSG include, who we are, how we can be contacted, what services we are authorised to provide to you, how we (and any other relevant parties) are remunerated, details of any potential conflicts of interest, and details of our internal and external dispute resolution procedures, along with how you can access them.</p>
        <p>It is intended that this FSG should assist you in determining whether to use any of the services described in this document. Please note that Boost Your Super will only be providing general advice and not providing personal financial advice.</p>
        <p>Please note that general advice does not take into account your individual objectives, financial situation or needs. You should seek advice from a licensed financial planner should you wish to receive personal advice. </p>
        <p>Hejaz Financial Advisers Pty Ltd has authorised A.C.N. 631 200 425 Pty Ltd (T/As Boost Your Super) to distribute this FSG.</p>
        <h1>Before You Receive Our Advice</h1>

        <h2>Who will be providing the financial services to me?</h2>
        <p>A.C.N. 631 200 425 Pty Ltd (ABN 30 631 200 425) T/As Boost Your Super (Boost Your Super) is a Corporate Authorised Representative, Number: 1296717, of Hejaz Financial Advisers Pty Ltd (HFA) (ABN 49 634 683 613) Australian Financial Services Licence (AFSL) Number: 517686.</p>
        <p> Our contact details are: Boost Your Super, U2/19 Beaconsfield Parade, Port Melbourne VIC, 3207. Email: admin@boostyoursuper.com.au</p>
        <p>HFA, under its AFSL, authorises Corporate Authorised Representatives (CARs) to carry on their business within the provisions of authorised financial services.</p>
        <p>HFA's contact details are: Level 11, 2 Queen Street, Melbourne VIC 3000, Ph 1300 043 529, Email: advisers@hejazfs.com.au</p>
        
        <p style={{backgroundColor: '#EFEFEF', padding: '20px' }}><b>Lack of Independence:</b> You should be aware that pursuant to s923A of the Corporations Act our business rules prohibit us from using the terms independent, impartial, or unbiased. S923A(2)(a)(i) prohibits the use of these terms if life insurance commission is received and not fully rebated to the client.</p>

        <h2> What kinds of financial services are you authorised to provide me and what kinds of products do those services relate to?</h2>
        <p> Boost Your Super can provide general advice only in the following areas: </p>
        <p> Financial strategies and financial advice Superannuation (including Self Managed Super Funds).</p>
        <p> Please note that “general advice” does not take account of your objectives, financial situation or needs. Before acting on this general advice, you should consider the appropriateness of the advice having regard to your objectives, financial situation or needs.</p>
        
        <h2>Who do you act for when you provide services for me?</h2>
        <p>Boost Your Super is responsible for the financial services provided to you, in its capacity as a CAR of HFA.</p>

        <h2>How will I pay for the service?</h2>
        <p>Boost Your Super does not charge for the provision of financial advice. Further, Boost Your Super does not charge you for signing up and using the cash back service available on the Boost Your Super platform.</p>

        <h2>Do you receive remuneration, commission, fees or other benefits in relation to providing the financial services or assistance to me and how is that commission calculated?</h2>
        <p> Boost Your Super earns a referral commissions when you click through to and make a purchase from the shops listed on our platforms (including our Website, Apps and Browser Extensions). Boost Your Super takes between 0% and 50% of the referral commission earned depending on any Bonus Boost offers that may be present at the time of purchase and our members Boost Power. The amount of commission you earn is displayed against each shop on our Website.</p>
        <p> Boost Your Super reserves the right to vary commission rates at its discretion and does not accept any form of conflicted remuneration.</p>

        <h2>Do you have any referral arrangements in place?</h2>
        <p>Boost Your Super has referral arrangements in place with our partners including the shops listed on our website. When you click through to these shops from our platforms and make a purchase, we may earn a referral commissions which we use to both provide cash back to your superannuation account and provide a revenue stream for Boost Your Super to be able to provide our services to you.</p>

        <h2>Will you provide me advice, which is suitable to my needs and financial circumstances?</h2>
        <p>No, Boost Your Super provides general advice only. We do not take into account your individual objectives, financial situation or needs. To obtain advice that is suitable to your needs and financial circumstances you should seek personal financial advice from a financial adviser authorised to provide personal advice.</p>

        <h2>What should I know about the risks of the products or strategies you recommend to me?</h2>
        <p>We will only give you general advice and do not recommend any financial products nor express an opinion on financial products. We offer cash back to your superannuation account through our shopping partners. You should seek personal financial advice from an authorised financial adviser.</p>

        <h2>What information do you maintain on members and can I examine my file?</h2>
        <p>We keep a record of your personal profile, which may include your name, email, date of birth, address, phone number and the details of your superannuation fund. We also keep a record of your transactions made through the Boost Your Super platform including the status and amount of cash back earned with each purchase.</p>
        <p>We are committed to your privacy and the security of your personal information. We implement this through our privacy policy which is readily available on our website.</p>
        <p>Most of the information we hold on you is available within your account and can be found by logging in. Should you wish to examine all the information we have on your file, we ask you make a request in writing and allow up to ten (10) business days for the information to be forwarded.</p>
        <p>If the request is extensive, we may charge a fee to cover the cost of verifying and retrieving the required information.</p>

        <h2>Who can I complain to if I have a complaint about the provision of the financial services to me?</h2>
        <p>If you have any complaint about the service provided to you, you should take the following steps:</p>
        <p>Contact Boost Your Super and tell us about your complaint. Our contact details are:</p>
<p>Email: accounts@boostyoursuper.com.au<br/>
Ph: 0402 763 882 ​</p>
<p>If your complaint is not satisfactorily resolved within 5 business days, please contact the Compliance Manager of HFA on 1300 043 529 or put your complaint in writing and send it to</p>
<p>HFA Compliance Manager<br/>
Level 11<br/>
2 Queen Street<br/>
Melbourne VIC 3000<br/>
Or via email to compliance@hejazfs.com.au</p>
<p>HFA is a member of the Australian Financial Complaints Authority Limited (AFCA). If you feel our response is inadequate or if you have not received a response within 30 days, you have the right to complain to AFCA.</p>
 
<p>They can be contacted on 1800 931 678, you can submit an online complaint form available at www.afca.org.au, or you may write to them at info@afca.org.au or GPO Box 3, Melbourne  VIC  3001. This service is provided to you free of charge.</p>
 
<p>The Australian Securities and Investments Commission (ASIC) also has an Infoline: 1300 300 630 which you may use to make a complaint and obtain information about your rights.</p>





        <h2>Contacting us</h2>
        <p>Boost Your Super welcomes your comments regarding this Financial Services Guide. If you have any questions about this Privacy Policy and would like further information, please contact us by any of the following means during business hours Monday to Friday.</p>

        <p><b>Email:&nbsp;</b><a href="mailto:admin@boostyoursuper.com.au">admin@boostyoursuper.com.au</a></p>
      </div>
    </div>
    <Footer />
  </FinancialServicesGuideWrapper>
);

export default FinancialServicesGuide;
