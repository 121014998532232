export default ({ theme, labelColor }) => `
  width: calc(100% - 20px);
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .formsy-container {
    .form-container {
      padding-bottom: 10px;
      margin-bottom: 10px;

      &.form-background {
        background-color: rgba(0, 0, 0, 0.4);
        padding: 20px;
        border-radius: 10px;
      }
    }

    .social-login {
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: solid 1px #d9d9d9;
      border-bottom: solid 1px #d9d9d9;
      padding: 20px;
      margin: 10px 0;
      flex-wrap: wrap;

      button {
        box-shadow: 0 0 0 2px #d9d9d9 !important;
        border-radius: 3px !important;

        &:hover {
          box-shadow: 0 0 0 2px #d9d9d9, 3px 3px 6px #d9d9d9 !important;
        }
      }
    }

    .login-link {
      text-decoration: none;
      color: ${labelColor || theme.darkSecondaryColor};
      margin: 10px;

      &:hover {
        text-decoration: underline;
      }
    }

    .form-footer {
      border-top: solid 2px #d9d9d9;
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap-reverse;

      @media(max-width: 387px) {
        justify-content: flex-end;
      }
    }
  }
`;
