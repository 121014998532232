import * as CalculatorActionTypes from './constants';


const initialState = {
  //user: !storedUser ? null : JSON.parse(storedUser),
  age: 35,
  gender: 'Not Specified',
  desired_retirement_age: 70,
  income: 86000,
  current_super_balance: 75000,
  pa_investment_return: 5,
  inflation: 3.7,
  superfund_fees_pa: 0,
  superfund_fees_pc: 0,
  before_tax_contributions: 0,
  before_tax_contributions_frequency: 'M',
  after_tax_contributions: 0,
  after_tax_contributions_frequency: 'M',
  round_ups_after_tax_contributions: 0,
  round_ups_after_tax_contributions_frequency: 'M',
  personal_after_tax_contributions: 0,
  personal_after_tax_contributions_frequency: 'M',
  other_after_tax_contributions: 0,
  other_after_tax_contributions_frequency: 'M',
  
};

const actionsMap = {
  [CalculatorActionTypes.SET_SHOPPER_CALCULATOR_DATA](state, { payload }) {
    const {
      age,
      gender,
      desired_retirement_age,
      income,
      current_super_balance,
      pa_investment_return,
      inflation,
      superfund_fees_pa,
      superfund_fees_pc,
      before_tax_contributions,
      before_tax_contributions_frequency,
      after_tax_contributions,
      after_tax_contributions_frequency,
    } = payload;
    
    return {
      ...state,
      age,
      gender,
      desired_retirement_age,
      income,
      current_super_balance,
      pa_investment_return,
      inflation,
      superfund_fees_pa,
      superfund_fees_pc,
      before_tax_contributions,
      before_tax_contributions_frequency,
      after_tax_contributions,
      after_tax_contributions_frequency,
    };
  },
  //UNSET not used. We could probably delete this. No ned to clear when shopper logs out.
  [CalculatorActionTypes.UNSET_SHOPPER_CALCULATOR_DATA](state) {
    return {
      ...state,
      age: null,
      gender: null,
      desired_retirement_age: null,
      income: null,
      current_super_balance: null,
      pa_investment_return: null,
      inflation: null,
      superfund_fees_pa: null,
      superfund_fees_pc: null,
      before_tax_contributions: null,
      before_tax_contributions_frequency: null,
      after_tax_contributions: null,
      after_tax_contributions_frequency: null,
    };
  },
  // [CalculatorActionTypes.LOCATION_CHANGE]: (state, action) => {
  //   console.log('CALCULATOR reducer LOCATION_CHANGE action: ', action)
  //   console.log('CALCULATOR reducer LOCATION_CHANGE state: ', state)
  //   // Your logic to handle route change, if needed.
  //   // You can also return the current state to keep it unchanged.
  //   return {
  //     ...state,
  //   };
  // },

};

export default function calculators(state = initialState, action) {
  console.log(['CALCULATOR reducer initiated with reducer action: ' , action]);
  console.log(['CALCULATOR reducer about to update state currently: ', state ]);
  const reduceFn = actionsMap[action.type];
  if (!reduceFn) {
      console.log ('NO ACTION FOUND FOR CALCULATOR REDUCER. ACTION was : ', action);
    return state;
  }
  return reduceFn(state, action);
}
