export default ({ theme }) => {
  return  `
    display: flex;
    align-items: inherit;
    justify-content: center;
    flex-direction: row;


    .amount-with-boost {
      display: flex;
      width: 100%;
      justify-content: flex-start;
      flex-direction: column;
      align-items: inherit;

      .rate-header {
        display: flex;
        font-weight: normal;
        font-size: smaller;
      }

      .rate-amount{
        display: flex;
        flex-direction: row;
        font-size: larger;
        position: relative;
        align-items: inherit;
        color: gray;


      
        .highlight-number {
          color: ${theme.alternateAccentColor};
        }

        .estimate-rate-amount {
          color: initial;
        }


        .slash-through::before{
          background: ${theme.lightSecondaryColor};
          content: "";
          height: 2px;
          position: absolute;
          transform: translate(-15%, 600%) rotate(-35deg);
          width: 25%;

          @media(max-width: 768px) {
            height: 1.5px;
            width: 30px;
            transform: translate(-15%, 600%) rotate(-25deg);
          }

        }

        .pop-over-container {
          display: flex;
        }

      }

      @media(max-width: 768px) {
        font-size: smaller;
        i {
          font-size: medium;
        }
      }

      .rate-type {
        font-size: smaller;
        font-weight: normal;
      }
      
    }
    
  
  `;
  };
  