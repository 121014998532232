import React, { useEffect, useState }  from 'react';
import numeral from 'numeral';
import moment from 'moment';
import styled from 'styled-components';
import Spinner from 'react-spinner-material';
import DataTable from 'react-data-table-component-with-filter';

import PopoverHover from 'SharedComponents/popover-hover';
import ActionButton from 'SharedComponents/action-button';

import RaiseTransactionInquiryDialog from 'SharedComponents/dialog-raise-transaction-inquiry';
import RaiseTransactionInquiryForm from '../../components/form-raise-transaction-inquiry';

import CONFIG from 'Config';
//import PopoverText from './PopoverText';

import styles from './index.css';

// define default format for numbers in numeral
numeral.defaultFormat('0,0.00');

// set default format for nulls
numeral.nullFormat('0.00');
numeral.zeroFormat('0.00');


  //<pre>{JSON.stringify(data, null, 2)}</pre>;

  // const TransactionStatus = (transaction_status,paid_charity) =>
  
  // {
  //   console.log('calculating transactionStatus using');
  //   console.log('transaction_status : ', transaction_status );
  //   console.log('paid_charity : ', paid_charity );
  //   let transactionStatus = 'Clicked';
  //   transactionStatus = paid_charity === '1' ? 'Paid'
  //   : transaction_status === '5' ? 'Inquiry Raised'
  //   : transaction_status === '6' ? 'Manually Approved'
  //   : transaction_status === '4' ? 'Pending'
  //   : transaction_status === '3' ? 'Void'
  //   : transaction_status === '2' ? 'Approved'
  //   : transaction_status === '7' ? 'Payment Bounced'
  //   : 'Clicked';
  //   return (transactionStatus);
  // };


const TableWrapper = styled.div`${styles}`;

const Table = ({
  tableData, 
  //currentPage, 
  pageSize, 
  isFetchingTableData,
  showRaiseInquiryDialog,
  onShowRaiseInquiryDialog,
  //onCloseRaiseInquiryDialog,
  //onFetchCauseDonations,
  //KeyTransactionStatusDescriptionsMap,
  //roundupsVisibility,
  //tableColumns,
  dataName,

}) => {

  console.log('tableData in Table is : ', tableData);
  console.log('typeof(tableData) is: ', typeof(tableData));
  console.log('showRaiseInquiryDialog in Table status is : ', showRaiseInquiryDialog);
  console.log('props.onShowRaiseInquiryDialog in Table status is : ', onShowRaiseInquiryDialog);
  console.log('dataName in Table is : ', dataName);
  //console.log('expandedComponent in Table is : ' + <pre>{JSON.stringify(expandedComponent)}</pre> + ' in Table');

  //const [displayedTransactions, setDisplayedTransactions] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handlePageChange = (newPage, pageSize) => {
    console.log('handlePageChange in Transactions Table Triggered with newPage: ', newPage);
    //console.log('handlePageChange in Transactions Table Triggered with pageSize: ', pageSize);
    setPageNumber(newPage);
    //setRowsPerPage(rowsPerPage);
  }

  const handleChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
    console.log('handleChangeRowsPerPage in Transactions Table Triggered with currentRowsPerPage: ', currentRowsPerPage);
    console.log('handleChangeRowsPerPage in Transactions Table Triggered with currentPage: ', currentPage);
    setRowsPerPage(currentRowsPerPage);
    setPageNumber(currentPage);
  }

  // Define the expanded component functions
  const ExpandedComponentMap = {
    ExpandedComponentRoundUps: ExpandedComponentRoundUps,
    ExpandedComponentContributeNow: ExpandedComponentContributeNow,
  };

  // Get the appropriate expanded component function based on dataName
  const ExpandedComponent = ExpandedComponentMap[dataName];

  const ExpandedComponentRoundUps = ({data}) => 
    
  (
    <div className="expander-row-container">
      <div className="transaction-details">
        
        
        <div className="transaction-item"> Date Time: <span style={{fontWeight:"bold"}}>&nbsp;{moment(data.created_at).local().format('DD MMM YYYY, h:mm:ss a')}</span></div>
        <div className="transaction-item"> Spent: <span style={{fontWeight:"bold"}}>&nbsp;{'$'+ numeral(-data.amount).format()}</span></div>
        <div className="transaction-item"> Round Up To Setting: <span style={{fontWeight:"bold"}}>&nbsp;{'$'+numeral(data.roundup_amount_cents/100).format()}</span></div>
        <div className="transaction-item"> Boost To Super: <span style={{fontWeight:"bold"}}>&nbsp;{'$'+numeral(data.debit_amount_cents/100).format()}</span></div>
        {/* <div className="transaction-item"> Transaction ID: <span style={{fontWeight:"bold"}}>&nbsp;{data._id}</span></div> */}
        {/* <div className="transaction-item"> Account ID: <span style={{fontWeight:"bold"}}>&nbsp;{data.account}</span></div> */}
        {/* {!!data.notes} */}
        <div className="transaction-item"> Status: 
          <span style={{fontWeight:"bold"}}>&nbsp;{ data.status}</span>
          {/* <span style={{fontWeight:"bold"}}>&nbsp;{TransactionStatus (data.status,data.paid_charity)}</span> */}
            <span className="pop-over-container">
              <PopoverHover
                content={
                  <div style={{
                    maxWidth: '300px', padding: 10, fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left',
                  }}
                  >
                  status descriptions coming soon... {/* {KeyTransactionStatusDescriptionsMap[TransactionStatus (data.transaction_status,data.paid_charity)]} */}
                  </div>
                }
                header = {null}
                img = {null}
                itemToPopover = {
                  ( <i className="material-icons" style={{ margin: '0 5px 0 5px', verticalAlign: '-40%' }} >info</i> )
                }
                horizontalPosn = "right"
              />
            </span>
        </div>
                <div className="transaction-item"> Description: <span style={{fontWeight:"bold"}}>&nbsp;{data.description}</span></div>
{/*           { 
          !!data.description
          ? <div>
              <div className="transaction-item"><b> TRANSACTION NOTES</b> </div>
              <div className="notes">{data.description}</div>
            </div>
          : null
        } */}
      </div>
      <div>
      {/* <pre>{JSON.stringify(data, null, 2)}</pre>  */}
      {/* {(!!data.boost_name || data.transaction_status != 1) 
        ? null 
        : (
          <div className="actions-container">
              <div className="actions-header">
              { 
              (moment().diff(moment(data.transaction_date),'hours') > timeToAllowRaiseInquiry(data.affiliate_network_id) ) && ((moment().diff(moment(data.transaction_date),'days') < 120))
                ? "You have " + (120 - (moment().diff(moment(data.transaction_date),'days'))) + " days to raise an Inquiry"
                : ((moment().diff(moment(data.transaction_date),'hours') < timeToAllowRaiseInquiry(data.affiliate_network_id)))
                  ? "Raise Inquiry avail. in approx " + moment().add((timeToAllowRaiseInquiry(data.affiliate_network_id) - (moment().diff(moment(data.transaction_date),'hours'))), 'hours').fromNow()
                  : "Cannot raise after 120 days"
              }
              </div>
              <div className="actions-buttons">
                <ActionButton
                  text="Raise&nbsp;Inquiry"
                  onClick={() =>  onShowRaiseInquiryDialog() }
                  disabled={(moment().diff(moment(data.transaction_date),'hours') > timeToAllowRaiseInquiry(data.affiliate_network_id) ) && ((moment().diff(moment(data.transaction_date),'days') < 120))
                              ? false
                              : true
                          }
                  title="Raise an inquiry for us to investigate missing funds"
                />
                { 
                  showRaiseInquiryDialog ? (
                    <RaiseTransactionInquiryDialog
                      size="sm"
                      open={ showRaiseInquiryDialog }
                      onCloseRaiseInquiryDialog={ onCloseRaiseInquiryDialog }
                      uniqueID={data.transaction_id}
                      storeName={data.merchant_name}
                      clickTime={data.transaction_date}
                      transactionStatus={data.transaction_status}
                      onFetchCauseDonations={ onFetchCauseDonations }
                      contentComponent={(
                        <RaiseTransactionInquiryForm 
                          showEmailLogin
                          labelColor="#88888"
                          showBackground={false}
                        />
                      )}
                    />
                  ) : null
                }

                <div className="pop-over-container">
                    <PopoverHover
                      content={
                        <div style={{
                          maxWidth: '300px', padding: 10, fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left',
                        }}
                        >
                        If a purchase you made is not showing you have up to 120 days after the Click Time to raise an Inquiry for us to invistigate.
                        Each shop has a different time frame before they allow an Inquiry to be raised.
                        </div>
                      }
                      header = {null}
                      img = {null}
                      itemToPopover = {
                        ( <i className="material-icons" style={{ margin: '0 5px 0 5px', verticalAlign: '-40%' }} >info</i> )
                      }
                      anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                      }}
                    />
                </div>
              </div>
            </div>
          )
      } */}
      </div>
    </div>
  );

  const ExpandedComponentContributeNow = ({data}) => 
    
    (
      <div className="expander-row-container">
        <div className="transaction-details">
          <div className="transaction-item"> Date Time: <span style={{fontWeight:"bold"}}>&nbsp;{moment(data.paymentData.created_at).local().format('DD MMM YYYY, h:mm:ss a')}</span></div>
          <div className="transaction-item"> Bank Reference: <span style={{fontWeight:"bold"}}>&nbsp;{data.party_bank_ref}</span></div>
          <div className="transaction-item"> Boost To Super: <span style={{fontWeight:"bold"}}>&nbsp;{'$'+numeral(data.paymentData.payout.amount/100).format()}</span></div>
          <div className="transaction-item"> Description: <span style={{fontWeight:"bold"}}>&nbsp;{data.description}</span></div>
          {/* <div className="transaction-item"> Transaction ID: <span style={{fontWeight:"bold"}}>&nbsp;{data._id}</span></div> */}
          {/* <div className="transaction-item"> Account ID: <span style={{fontWeight:"bold"}}>&nbsp;{data.account}</span></div> */}
          {/* {!!data.notes} */}
          <div className="transaction-item"> Status: 
            <span style={{fontWeight:"bold"}}>&nbsp;{ data.status}</span>
            {/* <span style={{fontWeight:"bold"}}>&nbsp;{TransactionStatus (data.status,data.paid_charity)}</span> */}
              <span className="pop-over-container">
                <PopoverHover
                  content={
                    <div style={{
                      maxWidth: '300px', padding: 10, fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left',
                    }}
                    >
                    status currently being updated... {/* {KeyTransactionStatusDescriptionsMap[TransactionStatus (data.transaction_status,data.paid_charity)]} */}
                    </div>
                  }
                  header = {null}
                  img = {null}
                  itemToPopover = {
                    ( <i className="material-icons" style={{ margin: '0 5px 0 5px', verticalAlign: '-40%' }} >info</i> )
                  }
                  horizontalPosn = "right"
                />
              </span>
          </div>
{/*           { 
            !!data.description
            ? <div>
                <div className="transaction-item"><b> TRANSACTION NOTES</b> </div>
                <div className="notes">{data.description}</div>
              </div>
            : null
          } */}
        </div>
        <div>
        {/* <pre>{JSON.stringify(data, null, 2)}</pre>  */}
        {/* {(!!data.boost_name || data.transaction_status != 1) 
          ? null 
          : (
            <div className="actions-container">
                <div className="actions-header">
                { 
                (moment().diff(moment(data.transaction_date),'hours') > timeToAllowRaiseInquiry(data.affiliate_network_id) ) && ((moment().diff(moment(data.transaction_date),'days') < 120))
                  ? "You have " + (120 - (moment().diff(moment(data.transaction_date),'days'))) + " days to raise an Inquiry"
                  : ((moment().diff(moment(data.transaction_date),'hours') < timeToAllowRaiseInquiry(data.affiliate_network_id)))
                    ? "Raise Inquiry avail. in approx " + moment().add((timeToAllowRaiseInquiry(data.affiliate_network_id) - (moment().diff(moment(data.transaction_date),'hours'))), 'hours').fromNow()
                    : "Cannot raise after 120 days"
                }
                </div>
                <div className="actions-buttons">
                  <ActionButton
                    text="Raise&nbsp;Inquiry"
                    onClick={() =>  onShowRaiseInquiryDialog() }
                    disabled={(moment().diff(moment(data.transaction_date),'hours') > timeToAllowRaiseInquiry(data.affiliate_network_id) ) && ((moment().diff(moment(data.transaction_date),'days') < 120))
                                ? false
                                : true
                            }
                    title="Raise an inquiry for us to investigate missing funds"
                  />
                  { 
                    showRaiseInquiryDialog ? (
                      <RaiseTransactionInquiryDialog
                        size="sm"
                        open={ showRaiseInquiryDialog }
                        onCloseRaiseInquiryDialog={ onCloseRaiseInquiryDialog }
                        uniqueID={data.transaction_id}
                        storeName={data.merchant_name}
                        clickTime={data.transaction_date}
                        transactionStatus={data.transaction_status}
                        onFetchCauseDonations={ onFetchCauseDonations }
                        contentComponent={(
                          <RaiseTransactionInquiryForm 
                            showEmailLogin
                            labelColor="#88888"
                            showBackground={false}
                          />
                        )}
                      />
                    ) : null
                  }

                  <div className="pop-over-container">
                      <PopoverHover
                        content={
                          <div style={{
                            maxWidth: '300px', padding: 10, fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left',
                          }}
                          >
                          If a purchase you made is not showing you have up to 120 days after the Click Time to raise an Inquiry for us to invistigate.
                          Each shop has a different time frame before they allow an Inquiry to be raised.
                          </div>
                        }
                        header = {null}
                        img = {null}
                        itemToPopover = {
                          ( <i className="material-icons" style={{ margin: '0 5px 0 5px', verticalAlign: '-40%' }} >info</i> )
                        }
                        anchorOrigin={{
                          vertical: 'center',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'center',
                          horizontal: 'right',
                        }}
                      />
                  </div>
                </div>
              </div>
            )
        } */}
        </div>
      </div>
    );

  if (isFetchingTableData && !tableData.length) {
    return (
      <div style={{
        width: '100%', padding: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 80,
      }}
      >
        <Spinner spinnerColor="#000000" size={20} spinnerWidth={3} />
        <p>Fetching transactions</p>
      </div>
    );
  }

  if (!tableData.length) {
    return (
      <div style={{
        width: '100%', padding: 10, display: 'flex', alignItems: 'center', justifyContent: 'center', height: 80,
      }}
      >
        <p>You currently have no Contribute Now transactions to display. Contribute Now transactions will appear on this table immediately after a successful contribution.</p>
      </div>
    );
  }

  const roundUpsColumns = [
    {
      name: '#',
      selector: (row,index) => ((index+1)+(pageNumber-1)*rowsPerPage),
      maxWidth: '60px',
      minWidth: 'unset',
    },
    {
      name: 'ACTION DATE',
      selector: row => new Date(row.created_at), // Convert to Date object
      format: row => moment(row.created_at).local().format('Do MMM YYYY'), // Format for display
      filterable: true,
      sortable: true,
    },
    {
      name: 'WITHDRAWAL DATE',
      selector: row => new Date(row.withdrawalDate), // Convert to Date object
      format: row => moment(row.withdrawalDate).local().format('Do MMM YYYY'), // Format for display
      filterable: true,
      sortable: true,
    },
    {
      name: 'BANK REF',
      selector: row => row.party_bank_ref,
      filterable: true,
      sortable: true,
    },
    // {
    //   name: 'UNIQUE ID',
    //   selector: row => row._id,
    //   filterable: true,
    //   sortable: true,
    // },
    // {
    //   name: 'STORE',
    //   selector: row => row.merchant_name,
    //   filterable: true,
    //   sortable: true,
    // },
    // {
    //   name: 'BONUS BOOST',
    //   selector: row => 
    //     row.boost_name != null
    //       ? row.boost_name
    //       : '-',
    //   conditionalCellStyles: [
    //     {
    //       when: row => 
    //         !!row.boost_name && row.boost_name.includes('Partner'),
    //       classNames: ['partner-bonus'],
    //     },
    //     {
    //       when: row => 
    //         row.boost_name == null,
    //       style: {color: 'white'},
    //     }
    //   ],
    //   filterable: true,
    //   sortable: true,
    //   minWidth: '230px',
    // },
    // {
    //   name: 'SPENT AMT',
    //   selector: row => numeral(-row.amount).value(), // Remove '$' here
    //   format: row => `$ ${numeral(-row.amount).format()}`, // Keep '$' in the formatted output
    //   filterable: true,
    //   sortable: true,
    //   sortFunction: sortAmount,
    // },
    {
      name: 'SPENT AMT',
      selector: row => -row.amount_cents, // Return the numerical value without any string formatting
      format: row => `$ ${numeral(-row.amount_cents / 100).format()}`, // Keep '$' in the formatted output
      filterable: true,
      sortable: true,
      //sortFunction: sortAmount,
    },
    {
      name: 'ROUND UP SETTING',
      selector: row => numeral(row.roundup_amount_cents / 100).value(), // Remove '$' here
      format: row => `$ ${numeral(row.roundup_amount_cents / 100).format()}`, // Keep '$' in the formatted output
      filterable: true,
      sortable: true,
    },
    {
      name: 'BOOST TO SUPER',
      //selector: row => numeral(row.debit_amount_cents / 100).value(), // Remove '$' here
      selector: row => row.debit_amount_cents, // Remove '$' here
      format: row => `$ ${numeral(row.debit_amount_cents / 100).format()}`, // Keep '$' in the formatted output
      filterable: true,
      sortable: true,
    },
    
    // {
    //   name: 'PAID DATE',
    //   selector: row => 
    //     row.paid_charity_date != null
    //       ? moment(row.paid_charity_date).local().format('Do MMM YYYY')
    //       : '-',
    //   conditionalCellStyles: [
    //     {
    //       when: row => 
    //       row.paid_charity_date == null,
    //       style: {color: 'white'},
    //     }
    //   ],
    //   filterable: true,
    //   sortable: true,
    // },
    {
      name: 'STATUS',
      selector: row => row.status,
      filterable: true,
      sortable: true,
    },

  ]

  const contributeNowColumns = [
    {
      name: '#',
      selector: (row,index) => ((index+1)+(pageNumber-1)*rowsPerPage),
      maxWidth: '60px',
      minWidth: 'unset',
    },
    {
      name: 'DATE',
      selector: row => moment(row.paymentData.created_at).unix(),
      filterable: true,
      sortable: true,
      format: row => moment(row.paymentData.created_at).format('Do MMM YYYY'),
    },
    {
      name: 'BANK REF',
      selector: row => row.party_bank_ref,
      filterable: true,
      sortable: true,
    },

    // {
    //   name: 'BONUS BOOST',
    //   selector: row => 
    //     row.boost_name != null
    //       ? row.boost_name
    //       : '-',
    //   conditionalCellStyles: [
    //     {
    //       when: row => 
    //         !!row.boost_name && row.boost_name.includes('Partner'),
    //       classNames: ['partner-bonus'],
    //     },
    //     {
    //       when: row => 
    //         row.boost_name == null,
    //       style: {color: 'white'},
    //     }
    //   ],
    //   filterable: true,
    //   sortable: true,
    //   minWidth: '230px',
    // },
/*     {
      name: 'SPENT AMT',
      selector: row => numeral(row.amount).format(), // Remove '$' here
      format: row => `$ ${numeral(row.amount).format()}`, // Keep '$' in the formatted output
      filterable: true,
      sortable: true,
    }, */
    // {
    //   name: 'SPENT AMT',
    //   selector: row => '$ ' + numeral(row.amount).format(),
    //   filterable: true,
    //   sortable: true,
    // },
/*     {
      name: 'ROUND UP SETTING',
      selector: row => '$ ' + numeral(row.roundupAmountCents/100).format(),
      filterable: true,
      sortable: true,
    }, */
    {
      name: 'BOOST TO SUPER',
      selector: row => numeral((row.paymentData.payout.amount) / 100).format(), // Remove '$' here
      format: row => `$ ${numeral((row.paymentData.payout.amount) / 100).format()}`, // Keep '$' in the formatted output
      filterable: true,
      sortable: true,
    },
    // {
    //   name: 'BOOST TO SUPER',
    //   selector: row => '$ ' + numeral((row.paymentData.payout.amount)/100).format(),
    //   filterable: true,
    //   sortable: true,
    // },
    // {
    //   name: 'PAID DATE',
    //   selector: row => 
    //     row.paid_charity_date != null
    //       ? moment(row.paid_charity_date).local().format('Do MMM YYYY')
    //       : '-',
    //   conditionalCellStyles: [
    //     {
    //       when: row => 
    //       row.paid_charity_date == null,
    //       style: {color: 'white'},
    //     }
    //   ],
    //   filterable: true,
    //   sortable: true,
    // },
    {
      name: 'STATUS',
      selector: row => row.status,
      filterable: true,
      sortable: true,
    },

  ]


  return (
    <TableWrapper>
        <DataTable
          columns={
            dataName === 'RoundUpsTransactions'
            ? roundUpsColumns
            : dataName === 'ContributeNowTransactions'
            ? contributeNowColumns
            : null
          }
          pageSize={pageSize}
          data={tableData}
          highlightOnHover={true}
          responsive={true}
          customStyles={styles}
          defaultSortFieldId={2}
          defaultSortAsc={false}
          pagination
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          expandableRows
          expandableRowsComponent={
            dataName === 'RoundUpsTransactions' 
            ? ExpandedComponentRoundUps 
            : dataName === 'ContributeNowTransactions'
            ? ExpandedComponentContributeNow
            : null
          }
          expandOnRowClicked={true}
        />  
    </TableWrapper>
  );
};

export default Table;