export default ({ theme }) => {
  return `
    width: 100%;
    background-color: ${theme.neutralColor}
    height: calc(100% - 120px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: calc(100% - 20px);
      padding: 10px;

      .shopping-notification{
        display: flex;
      }

      h1, p {
        text-align: center;
      }

      img {
        width: 150px;
        maxWidth: 200px;
        height: auto;
      }
    }
  `;
}
