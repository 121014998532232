export default ({ theme }) => {
  return `
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: auto;
      width: 100%;
      // flex-wrap: wrap;
      padding: 0;
    }

    .pagination li {
      background-color: ${theme.lightSecondaryColor};
      color: ${theme.secondaryTextColor};
      margin: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 !important;
      border-radius: 3px;
      min-width: 40px !important;
      height: 40px;
      width: 40px;
      border-radius: 20px;
    }

    .pagination li a {
      color:  ${theme.secondaryTextColor};
      text-indent: initial !important;
      width: initial !important;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2px 10px;
      font-size: 17px;
    }

    .pagination li.disabled {
      background-color: ${theme.borderColor};
      color: ${theme.secondaryTextColor};
      pointer-events: none;
    }

    .pagination li.active {
      background-color: ${theme.darkSecondaryColor};
      color: ${theme.secondaryTextColor};
    }

    html {
      overflow: scroll;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;  /* fix for iOS sticky scrolling   */
    }
    ::-webkit-scrollbar {
      width: 0px;  /* remove scrollbar space */
      background: transparent;  /* optional: just make scrollbar invisible */
    }

    .hide-scrollbars {
      -ms-overflow-style: none;  // IE 10+
      overflow: -moz-scrollbars-none;  // Firefox
    }

    .hide-scrollbars::-webkit-scrollbar {
      display: none;  // Safari and Chrome
    }
  `;
}
