export default ({ theme }) => `
    width: calc(100% - 20px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px;

    .body {
      width: 100%;

      .header {
        display: block !important;
        h4: {
          font-weight: bolder;
        }
      }

      .top-tip {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        margin: 10px 0;
        span {
          font-size: 15px;
          font-weight: bold;
          margin: 5px 0 4px;
        }
      }
    }

    .person {
      width: calc(100% - 20px);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      align-self: flex-start;
      margin: 10px;

      span {
        text-align: center;
        font-size: 14px;
      }

      .avatar {
        background-color: ${theme.borderColor};
        height: 50px;
        width: 50px;
        border-radius: 25px;
        margin: 10px;

        img {
          height: 50px;
          width: 50px;
          border-radius: 25px;
        }
      }

      .name-boosted-by {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        
        .name {
          font-size: 18px;
          font-weight: bold;
        }

        .boosted-by {
          font-size: 14px;
        }
      }
    }
  `;
