export default ({ theme }) => {
  return `
    margin: 20px 0;
    width: 100%;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex-wrap: wrap;
  `;
}
