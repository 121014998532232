import React from 'react';
import styled from 'styled-components';


import generateContributions from 'Images/bys-generate-contributions.png';
import homeDeposit from 'Images/bys-home-deposit.png';
import maternityLeave from 'Images/bys-maternity-leave.png';
import selfEmployed from 'Images/bys-self-employed.png';

import styles from './index.css';

const WhoCanUseBYSWrapper = styled.div`${styles}`;

const WhoCanUseBYS = ({ content }) => (
  <WhoCanUseBYSWrapper>
    <div className="content-container" id="who-can-use-bys">
      <h2 className="title">{content.who_can_use_bys_heading}</h2>
      <div className="content">
        <div className="left-content">
          <p>{content.who_can_use_bys_text_1}</p>
          <p>{content.who_can_use_bys_text_2}</p>
        </div>
        <div className="right-content">
          <h3 className="title">{content.cash_contributions_from_bys_can_help_text}</h3>
          <div className="benefits-container">
            <div className="benefit">
              <div className="image-container">
                <img src={content.cash_contributions_from_bys_can_help_image_1} alt="generate contributions" />
              </div>
              <p className="description">{content.cash_contributions_from_bys_can_help_text_for_image_1}</p>
            </div>
            <div className="benefit">
              <div className="image-container">
                <img src={content.cash_contributions_from_bys_can_help_image_2} alt="maternity leave" />
              </div>
              <p className="description">{content.cash_contributions_from_bys_can_help_text_for_image_2}</p>
            </div>
            <div className="benefit">
              <div className="image-container">
                <img src={content.cash_contributions_from_bys_can_help_image_3} alt="self employed" />
              </div>
              <p className="description">{content.cash_contributions_from_bys_can_help_text_for_image_3}</p>
            </div>
            <div className="benefit">
              <div className="image-container">
                <img src={content.cash_contributions_from_bys_can_help_image_4} alt="home deposit" />
              </div>
              <p className="description">{content.cash_contributions_from_bys_can_help_text_for_image_4}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </WhoCanUseBYSWrapper>
);

export default WhoCanUseBYS;
