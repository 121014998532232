import React, { useState, useRef, useEffect } from 'react';
import numeral from 'numeral';
import moment from 'moment';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Formsy, { withFormsy } from 'formsy-react'; // Import Formsy without HOC
import { Container, Row, Col } from 'react-grid-system';
import { BarLoader } from 'react-spinners';
import { isSafari, isIOS, isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';

import ActionButton from 'SharedComponents/action-button';
import TextInput, { isRequired } from 'SharedComponents/text-input';
import PopoverHover from 'SharedComponents/popover-hover';
import ContributeNowButton from 'SharedComponents/contribute-now-button';
import ToggleSwitch from 'SharedComponents/toggle-switch';

import * as RoundUpsActions from 'Flux/round-ups/actions';

import useCheckPromoCode from 'SharedHooks/check-promo-code';
import useActivatePromoCode from 'SharedHooks/activate-promo-code';
import useFetchActivePromoCodes from 'SharedHooks/fetch-shopper-promo-codes';

import useFetchRoundUpsShopperData from 'SharedHooks/fetch-roundups-shopper-data';
import useUpdateEnableContributeNow from 'SharedHooks/update-enable-contribute-now';

import log from "Utils/logger";
import styles from './index.css';

// Define default format for numbers in numeral
numeral.defaultFormat('0,0.00');

// Set default format for nulls
numeral.nullFormat('0.00');
numeral.zeroFormat('0.00');

const SetContributeNowWrapper = styled.section`${styles}`;
const TextInputWithFormsy = withFormsy(TextInput);

const SetContributeNow = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  console.log('SetContributeNow Index has been triggered');

  const roundups = useSelector(state => state.roundups);
  const authentication = useSelector(state => state.authentication);

    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [localRoundups, setLocalRoundups] = useState(roundups);

    //const [isFetchingRoundUpsShopperData, setFetchingRoundUpsUserData] = useState(false);
    //const [hasFetchedRoundUpsUserData, setHasFetchedRoundUpsUserData] = useState(false);

    //const { isFetchingRoundUpsShopperDataRef, hasFetchedRoundUpsShopperDataRef, fetchRoundUpsShopperData } = useFetchRoundUpsShopperData();
    console.log('*** ** fetchRoundUpsShopperData: TRIGGER FROM SET-CONTRIBUTE NOW');

    const { updatingContributeNowEnabled, updateEnableContributeNow } = useUpdateEnableContributeNow();


    const [isPromoCodeValid, setIsPromoCodeValid] = useState(false);
    const [promoCode, setPromoCode] = useState("");
    const [checkCode, setCheckCode] = useState("");
    const [activateOnClick, setActivateOnClick] = useState(false);
    const [contributeNowCharge, setContributeNowCharge] = useState(1);
    const [validationError, setValidationError] = useState('');
    const [activePromoCode, setActivePromoCode] = useState({});

    const [updatingAcceptedContributeNowTerms, setUpdatingAcceptedContributeNowTerms] = useState(false);
    //const [updatingContributeNowEnabled, setUpdatingContributeNowEnabled] = useState(false);
    const [updatingContributeNowContinueNotification, setUpdatingContributeNowContinueNotification] = useState(false);
    const [updatingContributeNowDefaultAmount, setUpdatingContributeNowDefaultAmount] = useState(false);

    const { fetchActivePromoCodes, fetchActivePromoCodesResponseContributeNow, isFetchingActivePromoCodesRef } = useFetchActivePromoCodes();
    const { checkPromoCode, checkCodeResponse, isCheckingPromoCode } = useCheckPromoCode(checkCode, 'contribute_now', promoCode);
    const { activatePromoCode, activateCodeResponse, isActivatingPromoCode } = useActivatePromoCode(checkCode, 'contribute_now', isPromoCodeValid, activateOnClick);
  
    const debounceTimeoutRef = useRef(null);
    const formRef = useRef(null);

    useEffect(() => {
      console.log('useEffect in Set contribute triggered with roundups: ', roundups);
      setLocalRoundups(roundups || {});
    }, [roundups]);

    // useEffect(() => {
    //   console.log('useEffect in Set contribute triggered with updatingContributeNowEnabledRef: ',updatingContributeNowEnabledRef);
    //   setUpdatingContributeNowEnabled(updatingContributeNowEnabledRef);
    // }, [updatingContributeNowEnabledRef]);

 
    const hasRoundupsDebitEnabled = function(localRoundups) {
      if (
        localRoundups &&
        localRoundups.linkedAccounts &&
        Array.isArray(localRoundups.linkedAccounts) &&
        localRoundups.linkedAccounts.length > 0
      ) {
        for (let i = 0; i < localRoundups.linkedAccounts.length; i++) {
          if (localRoundups.linkedAccounts[i].debit_enabled === true) {
            return true;
          }
        }
      }
      return false;
    };

    async function updateAcceptedContributeNowTerms() {
      console.log('updateAcceptedContributeNowTerms triggered with !localRoundups.acceptedContributeNowTerms: ', !localRoundups.acceptedContributeNowTerms);
      setUpdatingAcceptedContributeNowTerms(true);
  
      try {
        const result = await dispatch(RoundUpsActions.updateAcceptedContributeNowTerms(!localRoundups.acceptedContributeNowTerms));
        console.log('updateAcceptedContributeNowTerms using result: ', result);
        dispatch(RoundUpsActions.setAcceptedContributeNowTerms( result.data.data.acceptedContributeNowTerms ));
      } catch (exception) {
        console.log('updateAcceptedContributeNowTerms exception: ', exception);
        log({
          event: "System-Error",
          'shopper_id': authentication? authentication.user.shopper_id : 'Not Signed In',
          source: "AboutContributeNow- UpdateAcceptedContributeNowTerms",
          info: exception,
          ...exception,
        });
        onError(exception);
      } finally {
        setUpdatingAcceptedContributeNowTerms(false);
      }
    }

    async function updateContributeNowContinueNotification() {
      console.log('updateContributeNowContinueNotification triggered with !localRoundups.contributeNowContinueNotification: ', !localRoundups.contributeNowContinueNotification);
      setUpdatingContributeNowContinueNotification(true);
      try {
        const result = await dispatch(RoundUpsActions.updateContributeNowContinueNotification(!localRoundups.contributeNowContinueNotification));
        console.log('updateContributeNowContinueNotification using result: ', result);
        dispatch(RoundUpsActions.setContributeNowContinueNotification( result.data.data.contributeNowContinueNotification ));
      } catch (exception) {
        console.log('updateContributeNowContinueNotification exception: ', exception);
        log({
          event: "System-Error",
          'shopper_id': authentication? authentication.user.shopper_id : 'Not Signed In',
          source: "AboutContributeNow- UpdateContributeNowContinueNotification",
          info: exception,
          ...exception,
        });
        onError(exception);
        setTimeout(() => {
          onError('');
        }, 3000);
      } finally {
        setUpdatingContributeNowContinueNotification(false);
      }

    }

    useEffect(() => {
      console.log ('useEffect localRoundups.acceptedRoundupsTerms: ', localRoundups.acceptedRoundupsTerms);
      console.log ('useEffect localRoundups.acceptedContributeNowTerms: ', localRoundups.acceptedContributeNowTerms);
      if (!localRoundups.acceptedRoundupsTerms ) {
          const errorMessage1 = "You will need to set up Round Ups before you can use Contribute Now";
          setErrorMessage(errorMessage1);
          setHasError(true);
      } else if ( !localRoundups.linkedAccounts || localRoundups.linkedAccounts.length === 0 ) {
          const errorMessage2 = "You must link a bank account (open banking) found under the ROUND UPS TAB settings before you can use CONTRIBUTE NOW";
          setErrorMessage(errorMessage2);
          setHasError(true);
      } else if (!hasRoundupsDebitEnabled(localRoundups)) {
          const errorMessage3 = "You must enable a DEBIT STATUS of an account found in YOUR LINKED ACCOUNTS under ROUND UPS TAB settings. This is where funds will be sourced to make contributions.";
          setErrorMessage(errorMessage3);
          setHasError(true);
      } else if ( !localRoundups.acceptedContributeNowTerms ) {
          const errorMessage4 = "Please accept the CONTRIBUTE NOW T&Cs (including charges) before you can enable Contribute Now";
          setErrorMessage(errorMessage4);
          setHasError(true);
      }else if ( !localRoundups.contributeNowEnabled ) {
          const errorMessage5 = "You must enable CONTRIBUTE NOW before you can use this service";
          setErrorMessage(errorMessage5);
          setHasError(false);
      } else {
          setHasError(false);
          setErrorMessage('');
      }
    }, [localRoundups.acceptedRoundupsTerms, localRoundups.acceptedContributeNowTerms, localRoundups.linkedAccounts, localRoundups.contributeNowEnabled]);
  
    useEffect(() => {
      console.log('useEffect isPromoCodeValid triggered with isPromoCodeValid:' , isPromoCodeValid);
    }, [isPromoCodeValid]);
  
  
    const toggleAcceptedContributeNowTerms = () => {
      console.log('toggleAcceptedRoundUpTerms triggered');
      console.log('toggleAcceptedROundUpTerms roundups.acceptedRoundupsTerms: ', roundups.acceptedRoundupsTerms);
      console.log('toggleAcceptedROundUpTerms !roundups.acceptedRoundupsTerms: ', !roundups.acceptedRoundupsTerms);
      updateAcceptedContributeNowTerms();
    };

    const onChangePromoCode2 = (event) => {
      const newPromoCode = event.target.value;
      console.log('1. onChangePromoCode2 triggered with newPromoCode: ', newPromoCode);
  
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
      
      debounceTimeoutRef.current = setTimeout(() => {
        setCheckCode(newPromoCode);
        if (newPromoCode.length < 2) {
        setIsPromoCodeValid(false);
        setValidationError('');
        }
  
      }, 700);
    };

    useEffect(() => {
      console.log('6.0 useEffect checkCodeResponse - promo response triggered with checkCodeResponse: ', checkCodeResponse);
        if (!promoCode && checkCodeResponse?.valid && checkCodeResponse?.promo_code?.active && !checkCodeResponse?.promo_code?.shopper) {
          console.log('6.1 checkCodeResponse VALID PROMO CODE FOUND');
          setIsPromoCodeValid(true);
          setValidationError('VALID');
        } else {
          console.log('6.1 checkCodeResponse VALID PROMO CODE NOT FOUND');
          setTimeout(() => {
            console.log('6.2 checkCodeResponse VALID PROMO CODE NOT FOUND - setting isPromoCodeValid to false');
            setIsPromoCodeValid(false);
            setValidationError('Invalid promo code');
          }, 400); // Delay setting the validation error by 500ms
        }
    }, [checkCodeResponse]);
  
    useEffect(() => {
      console.log('7.0 useEffect isCheckingPromoCode triggered with status: ', isCheckingPromoCode);
      if (isCheckingPromoCode) {
        setValidationError('checking...');
      }
    }, [isCheckingPromoCode]);

    useEffect(() => {
      console.log('8.0 useEffect fetchActivePromoCodesResponseContributeNow triggered with fetchActivePromoCodesResponseContributeNow: ', fetchActivePromoCodesResponseContributeNow);
      console.log('8.02 useEffect activateCodeResponse triggered with activateCodeResponse: ', activateCodeResponse);
      if (fetchActivePromoCodesResponseContributeNow && fetchActivePromoCodesResponseContributeNow.active && fetchActivePromoCodesResponseContributeNow.shopper.active) {
        console.log('8.1 useEffect fetchActivePromoCodesResponseContributeNow triggered with fetchActivePromoCodesResponseContributeNow: ', fetchActivePromoCodesResponseContributeNow);
        setActivePromoCode(fetchActivePromoCodesResponseContributeNow);
        setPromoCode(fetchActivePromoCodesResponseContributeNow.code);
      }
    },  [fetchActivePromoCodesResponseContributeNow ]);
  
    useEffect(() => {
      console.log('8.2 useEffect activateCodeResponse triggered with activateCodeResponse: ', activateCodeResponse);
      if (activateCodeResponse?.active 
        && activateCodeResponse?.shopper?.active
        ) {
        console.log('8.3 useEffect activateCodeResponse valid code triggered with activateCodeResponse: ', activateCodeResponse);
        setActivePromoCode(activateCodeResponse);
        setPromoCode(activateCodeResponse.code);
        setValidationError('');
      } else if (activateCodeResponse?.valid === false || activateCodeResponse?.shopper.active === null) {
          console.log('8.4 useEffect activateCodeResponse code not valid activateCodeResponse: ', activateCodeResponse);
          setValidationError('Activation Error - try another code');
        }
    }, [activateCodeResponse]);

    const handleActivatePromoCode = () => {
      if (isPromoCodeValid) {
        setActivateOnClick(true);
      }
    };
  
    useEffect(() => {
      if (activateOnClick) {
        activatePromoCode();
        setActivateOnClick(false);
      }
    }, [activateOnClick]);
  
    
    const checkForIsPromoCodeValid = () => {
      return isPromoCodeValid;
    };

    const toggleEnableContributeNow = () => {
      console.log('toggleEnableContributeNow triggered');
      console.log('toggleEnableContributeNow localRoundups.contributeNowEnabled: ', localRoundups.contributeNowEnabled);
      console.log('toggleEnableContributeNow !localRoundups.contributeNowEnabled: ', !localRoundups.contributeNowEnabled);
      if ( !localRoundups.contributeNowEnabled && hasError ) {
        return;
      } else {
        updateEnableContributeNow(!localRoundups.contributeNowEnabled);
      }
    }

    const toggleContributeNowContinueNotification = () => {
      console.log('toggleContributeNowContinueNotification triggered');
      console.log('toggleContributeNowContinueNotification localRoundups.contributeNowContinueNotification: ', localRoundups.contributeNowContinueNotification);
      console.log('toggleContributeNowContinueNotification !localRoundups.contributeNowContinueNotification: ', !localRoundups.contributeNowContinueNotification);
        updateContributeNowContinueNotification();
    }

  //const handleStepError = (errorMessage) => {
  const onError = (errorMessage) => {
    setHasError(true);
    setErrorMessage(errorMessage);
    console.log('error on updating localRoundups object found: ', errorMessage)
  };



    return (
      <SetContributeNowWrapper>
        <div>
          <h3 >We make it easy to make a contribution to your super. Any. Time. You. Like.</h3>
        </div>

        <h2>Contribute Now Settings</h2>
        <div>
      <Container fluid className="links-container" style={{ margin: 0, padding: 0, width: '100%' }}>
        <Row style={{ margin: 0, padding: 0, width: '100%' }}>
          <Col xl={6} lg={6} md={6} sm={12} xs={12} className="links-group" style={{ margin: 0, padding: 0 }}>
            <div className="acceptance-card">
              <h2 className="title">Enable Contribute Now</h2>
              <div className="promo-code-entry-and-validate-and-activate">
                  <div className="promo-code-entry-and-validate">
                    <div className="promo-code-entry">
                      <Formsy
                        ref={formRef} style={{ maxWidth: '300px', padding: '0px 10px' }}
                        >
                          <TextInputWithFormsy
                            name="promo-code"
                            type="text"
                            validations={{
                              isExisty: false,
                              customValidation: checkForIsPromoCodeValid,
                            }}
                            required
                            value={ activePromoCode.code ? activePromoCode.code : checkCode }
                            onChange={onChangePromoCode2}
                            placeholder="Promo Code"
                            label={activePromoCode.code ? "Promo Code - Activated" : "Promo Code"}
                            disabled={ activePromoCode.code }
                          />
                      </Formsy>
                    </div>
                      { 
                        <span className="validation-message">
                            { validationError && checkCode.length > 1 ? validationError : null}
                        </span>
                      } 
                  </div>
                  <div className="activate-button-container">
                    { !activePromoCode.code && isPromoCodeValid ?
                      ( <ActionButton
                            text="Activate"
                            onClick={() =>  handleActivatePromoCode() }
                            color="#fff"
                            large={true}
                            //disabled={ !isPromoCodeValid || isActivatingPromoCode }
                          />
                          ) : null}
                  </div>
                  <ul>
                <li>  <b>Monthly Charge (inc GST):&nbsp;</b> 
                    { 
                      activePromoCode?.description
                      ? <span> <del style={{color:"red" }}>AU $ {numeral(contributeNowCharge).format()} per month</del>&nbsp; AU $ {numeral((1-activePromoCode.discount_percentage*.01)*contributeNowCharge).format()} per month</span>
                      : isPromoCodeValid && checkCodeResponse?.promo_code?.discount_percentage
                      ? <span> <del style={{color:"red" }}>AU $ {numeral(contributeNowCharge).format()} per month</del>&nbsp; AU $ {numeral((1-checkCodeResponse.promo_code.discount_percentage*.01)*contributeNowCharge).format()} per month</span>
                      : <span>AU $ {numeral(contributeNowCharge).format()} per month</span>
                    }
                </li>
                <li>  <b>Promo Code Details :&nbsp;</b>
                    { 
                      activePromoCode?.description
                      ? <span style={{ color: 'red' }}>{activePromoCode.description}</span>
                      : isPromoCodeValid && checkCodeResponse?.promo_code?.description 
                      ? <span style={{ color: 'red' }}>{checkCodeResponse.promo_code.description}</span>
                      : "You have not entered a valid promo code"
                    }
                </li>
                </ul>
                </div>


              <div className="enable-contribute-now-button">
                <ToggleSwitch
                  isChecked={ localRoundups.contributeNowEnabled }
                  onToggle={() => {toggleEnableContributeNow()}}
                  updating={updatingContributeNowEnabled}
                  disabled={hasError || updatingContributeNowEnabled }
                />
              </div>
             

              <div className="error-message"><text> &nbsp; {errorMessage}</text></div>
            </div>
          </Col>

          <Col xl={6} lg={6} md={6} sm={12} xs={12} className="links-group" style={{ margin: 0, padding: 0 }}>
          <div className="acceptance-card">
            <h2 className="title">Contribute Now Button</h2>
            <p className="description">Once enabled you can use this button to make a contribution straight to your super.</p>
            <div className="button-container">
              <ContributeNowButton
                largeButton={ isMobile ? false : true }
                contributeAndShop={false}
              />
            </div>
            <h2 className="title">Contribute And Shop Button</h2>
            <p className="description">When displayed on a shop and clicked, the amount will be contributed straight to your super from your bank. You will be then be taken to the shop where you can earn extra cashback for your super. </p>
            <div className="button-container">  
              <ContributeNowButton
                largeButton={ isMobile ? false : true }
                contributeAndShop={true}
                onShopNow={null}
                onShopNowType={null}
                storeDetails={null}
              />
            </div>
          </div>
              {/*  <ContributeNowButton
                      largeButton={ isMobile ? false : true }
                      contributeAndShop={true}
                      onShopNow={onShopNow}
                      onShopNowType={'deal'}
                      storeDetails={deal}
                    /> */}
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={12} className="links-group" style={{ margin: 0, padding: 0 }}>
            <div className="acceptance-card">
                <h2 className="title">Terms & Conditions (Including Charges)</h2>
                <ul>
                <li>  <b>Monthly Charge (inc GST):&nbsp;</b> 
                    { 
                      activePromoCode?.description
                      ? <span> <del style={{color:"red" }}>AU $ {numeral(contributeNowCharge).format()} per month</del>&nbsp; AU $ {numeral((1-activePromoCode.discount_percentage*.01)*contributeNowCharge).format()} per month</span>
                      : isPromoCodeValid && checkCodeResponse?.promo_code?.discount_percentage
                      ? <span> <del style={{color:"red" }}>AU $ {numeral(contributeNowCharge).format()} per month</del>&nbsp; AU $ {numeral((1-checkCodeResponse.promo_code.discount_percentage*.01)*contributeNowCharge).format()} per month</span>
                      : <span>AU $ {numeral(contributeNowCharge).format()} per month</span>
                    }
                </li>
                <li>  <b>Promo Code Details :&nbsp;</b>
                    { 
                      activePromoCode?.description
                      ? <span style={{ color: 'red' }}>{activePromoCode.description}</span>
                      : isPromoCodeValid && checkCodeResponse?.promo_code?.description 
                      ? <span style={{ color: 'red' }}>{checkCodeResponse.promo_code.description}</span>
                      : "You have not entered a valid promo code"
                    }
                </li>
                <li>  <b>Promo Code Expiry Date :&nbsp;</b> 
                     { 
                      activePromoCode?.shopper?.end_date
                      ? <span><span style={{ color: 'red' }}>{moment(activePromoCode?.shopper?.end_date).local().format('Do MMM YYYY')} </span><span>&nbsp;After this date you will be charged normal monthly fees unless you cancel prior to this date. You will be notified via email before this date.</span></span>
                      : isPromoCodeValid && checkCodeResponse?.promo_code?.shopper?.end_date 
                      ? <span><span style={{ color: 'red' }}>{moment(checkCodeResponse.promo_code.shopper.end_date).local().format('Do MMM YYYY')} </span><span>&nbsp;After this date you will be charged normal monthly fees unless you cancel prior to this date. You will be notified via email before this date.</span></span>
                      : "N/A"
                     }
                </li>
                <li>  <b>Charge Date :&nbsp;</b> Last Day of every month</li>
                <li>  <b>No Round Ups? :&nbsp;</b> Monthly charge will only be applied if you have round-ups for that month</li>
                <li>  <b>Charge Account :&nbsp;</b> Charges will be taken from your funding account once it is set up</li>
                <li>  <b>Insufficient Funds? :&nbsp;</b> If you have insufficient funds on the date we try to charge you then we simply will NOT charge you for that month</li>
                <li>  <b>Cancel Anytime :&nbsp;</b> You can cancel your roundups at any time by deleting all connected banks at step 3</li>
                <li>  <b>Variations to T&Cs :&nbsp;</b>We reserve the right to unilaterally vary these T&Cs. If any variation is made they will be reflected here and you will be notified via email. </li>
                <li>  <b>Bank Connection Approval Expiry :&nbsp;</b>If you provide approval to connect to a bank and share your data so that round ups can be calculated, the approval will automatically expire after one year. You will be required to manually reinstate approval to continue to use the Round Ups service. </li>
                <li>  <b>Transaction Issues :&nbsp;</b>If you have any issues with transactions you can email us at accounts@boostyoursuper.com.au </li>
              </ul>





              <div className="checkbox-entry">
                  <input
                    className="input-checkbox"
                    name="accept-roundup-charges"
                    type="checkbox"
                    placeholder="Accept roundup Terms"
                    checked={localRoundups.acceptedContributeNowTerms}
                    required
                    onChange={ localRoundups.acceptedContributeNowTerms ? null : toggleAcceptedContributeNowTerms }
                    disabled={ localRoundups.acceptedContributeNowTerms }
                  />
                  <span>I agree to the Terms & Conditions (including Charges) as described below.</span>
                  <span className="pop-over-container">
                      <PopoverHover
                        content={
                          <div style={{
                            maxWidth: '300px', padding: 10, fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left',
                          }}
                          >
                          You must accept the Terms & Conditions in order to use this service.
                          </div>
                        }
                        header = {null}
                        img = {null}
                        itemToPopover = {
                          ( <i className="material-icons" style={{ margin: '0 5px 0 5px', verticalAlign: '-40%' }} >info</i> )
                        }
                        anchorOrigin={{
                          vertical: 'center',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'center',
                          horizontal: 'right',
                        }}
                      />
                  </span>
              </div>

            </div>
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={12} className="links-group" style={{ margin: 0, padding: 0 }}>
              <div className="acceptance-card">
                <div className="title-and-limit">
                    <h2 className="title">Contribute Now Notification</h2>
                    <p className="description">Once you are comfortable with how it works you can turn off the notification that confirms you want to making a contribution. </p>
                    <div className="limit">
                      <div className="enable-contribute-now-button">
                        <ToggleSwitch
                          isChecked={ localRoundups.contributeNowContinueNotification }
                          onToggle={() => {toggleContributeNowContinueNotification()}}
                          updating={updatingContributeNowContinueNotification}
                          disabled={ updatingContributeNowContinueNotification }
                        />
                      </div>
                    </div>
{/*                     <span className="pop-over-container">
                      <PopoverHover
                        content={
                          <div style={{
                            maxWidth: '300px', padding: 10, fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left',
                          }}
                          >
                          You can turn off the confirmation notification that appears before you make a contribution.
                          </div>
                        }
                        header = {null}
                        img = {null}
                        itemToPopover = {
                          ( <i className="material-icons" style={{ margin: '0 5px 0 5px', fontSize:'34px' }} >info</i> )
                        }
                        anchorOrigin={{
                          vertical: 'center',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'center',
                          horizontal: 'right',
                        }}
                      />
                    </span> */}
                  </div>

              </div>
          </Col>
        </Row>
      </Container>
    </div>
  </SetContributeNowWrapper>
    );


};


SetContributeNow.propTypes = {
  roundups: PropTypes.object.isRequired,
  authentication: PropTypes.object.isRequired,
};

export default SetContributeNow;
