export default ({ theme }) => `
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;


  .all-stats-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: calc(100%-20px);

  

    .stat-card-container{
      display: flex;
      flex-direction: column;
      max-width:440px;
      align-items: stretch;

      .stat-card {
        /* width: 200px; */
        min-width: 200px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        padding: 5px;
        /* box-shadow: 0 0 3px 1px ${theme.borderColor}; */
        margin: 5px;
        border-radius: 8px;

  /*       &:last-child {
          margin-right: 20px;
        } */

        .label {
          text-align: end;
          text-transform: capitalize;
          font-weight: bold;
          font-size: larger;
        }

        .value {
          font-size: 25px;
          text-align: end;
          font-weight: bold;
          width: 125px;
        }

        .pop-over-container{
          display: flex;
          flex-direction:row;
          justify-content: center
          margin: 0 10px 0 10px;
          height: 100%;
          align-items: center;

          p{
            margin: 0px;
          }
        }

  /*       @media(max-width: 768px) {
          max-width: 150px;
          min-width: 150px;
          width: 150px;
        }

        @media(max-width: 425px) {
          max-width: 100px;
          min-width: 100px;
          width: 100px;
        } */
      }
    }
  }

  @media(max-width: 768px) {
    width: 100vw;
  }
`;
