import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import moment from "moment";

import log from "Utils/logger";

import CONFIG from "Config";

import * as ShoppingActions from "Flux/shopping/actions";

const useFetchDeals = () => {
  console.log ('*** ** fetchDeals: hook triggered');
  const isFetchingDealsRef = useRef(false);
  const hasFetchedDealsRef = useRef(false);
  const dispatch = useDispatch();
  
  const storedDeals = useSelector(state => state.shopping.deals);
  
  const fetchDeals = async () => {
    isFetchingDealsRef.current = true;
    try {
      const result = await dispatch(ShoppingActions.fetchDeals());
      //const newDeals = result.data.data;

      console.log('*** ** fetchDeals: storedDeals: ', storedDeals)
      console.log ('*** ** fetchDeals: result.data.coupons: ', result.data.coupons);
      

      const newDeals = result.data.coupons.map(coupon => {
        let promotionTypeTitle, rate, commission;
        if (coupon.code) {
          coupon.code.length
            ? (promotionTypeTitle = "Coupon")
            : (promotionTypeTitle = "Promotion");
        } else {
          promotionTypeTitle = "Promotion";
        }
        coupon.promotionTypeTitle = promotionTypeTitle;

        let expiry;
        let visibleExpiry;
        let endDateObject = new Date(coupon.end_date);

        if (endDateObject.toString() !== "Invalid Date") {
          if (moment(coupon.end_date).diff(moment(), "hours") < 48) {
            if (moment(coupon.end_date).diff(moment(), "minutes") < 60) {
              visibleExpiry = moment(coupon.end_date).fromNow(true);
            } else {
              visibleExpiry =
                moment(coupon.end_date).diff(moment(), "hours") + " hours";
            }
          } else {
            visibleExpiry = moment(coupon.end_date).fromNow(true);
          }
        }

        endDateObject.toString() === "Invalid Date"
          ? (expiry =
            moment(coupon.start_date).fromNow(true) +
            " old &nbsp;&nbsp;&nbsp;" +
            '<span className="coupon-expiry">NO EXPIRY</span>')
          : (expiry =
            moment(coupon.start_date).fromNow(true) +
            " old &nbsp;&nbsp;&nbsp; " +
            '<span className="coupon-expiry"><i className="fa fa-clock-o"></i>&nbsp;' +
            visibleExpiry +
            "</span>");
        coupon.expiry = expiry;

        if (endDateObject.toString() === "Invalid Date") {
          coupon.end_date_for_sorting = moment()
            .add(1001, "years")
            .format("YYYY-MM-DD HH:mm:ss");
        } else {
          coupon.end_date_for_sorting = moment(coupon.end_date).format(
            "YYYY-MM-DD HH:mm:ss"
          );
        }

        if (coupon.commission_rate) {
          if (!coupon.commission_rate.length) {
            rate = "";
          } else {
            if (coupon.commission_description.indexOf("$") > -1) {
              commission =
                "$" +
                (parseFloat(coupon.commission_rate) / 2).toFixed(2) +
                coupon.commission_description.slice(1);
            } else {
              commission =
                (parseFloat(coupon.commission_rate) / 2).toFixed(1) +
                coupon.commission_description;
            }
            rate = commission;
          }
        } else {
          rate = "";
          log({
            event: "System-Error",
            source: "no-rate calc on store",
          });
        }
        coupon.rate = rate;

        coupon.clipboardCode = coupon.code;
        coupon.retail_store_logo_url = coupon.custom_logo_directory
          ? coupon.custom_logo_directory.length &&
            coupon.custom_logo_directory !== "null"
            ? CONFIG.assetsUrl +
            "/assets/" +
            coupon.custom_logo_directory
            : coupon.avatar_url
          : coupon.avatar_url;
        coupon.retail_store_logo_url_alt_text = coupon.merchant_name;
        coupon.coupon_banner_image_url = coupon.coupon_banner_url;
        coupon.shopNowLink =
          CONFIG.assetsUrl +
          "/transaction/" +
          coupon.retail_store_web_name +
          "/" +
          coupon.id +
          "/coupon/browser/";
        coupon.shareLink =
          CONFIG.assetsUrl + "/shop/deal/" + coupon.coupon_id;
        return coupon;
      });
      
      if (shouldUpdateStore(newDeals, storedDeals)) {
        console.log('*** ** fetchDeals: DISPATCH SET deals with newDeals: ', newDeals);
        dispatch(ShoppingActions.addDeals(newDeals));

        const promotedDeals = newDeals.filter(
          deal => deal.currently_promoted === "1"
        );
        dispatch(ShoppingActions.addPromotedDeals(promotedDeals));

        if (result.data.promotions.length) {
          const { promotions } = result.data;
          dispatch(ShoppingActions.setCurrentPromotion(promotions[0]));
        }
      }

    } catch (exception) {
      console.log(exception);
      log({
        event: "System-Error",
        source: "Fetch Deals",
        info: exception,
        ...exception,
      });
    } finally {
      isFetchingDealsRef.current = false;
      hasFetchedDealsRef.current = true;
    }
  };

  function shouldUpdateStore(newDeals, storedDeals) {
     if (storedDeals.length !== newDeals.length) {
        return true; 
      }
    return false;
  }

  useEffect(() => {
    if (!isFetchingDealsRef.current && !hasFetchedDealsRef.current) {
      fetchDeals();
    }
  }, []);

  return { fetchDeals };
};

export default useFetchDeals;
