import * as TestimonialActionTypes from './constants';
import axios from 'axios';

import CONFIG from 'Config';

export function setTestimonials(items) {
  return {
    type: TestimonialActionTypes.SET_TESTIMONIALS,
    payload: items,
  }
}

export function removeTestimonials() {
  return {
    type: TestimonialActionTypes.REMOVE_TESTIMONIALS,
  }
}

export function fetchTestimonials() {
  return () => {
    return axios({
      url: `${CONFIG.apiV3Url}/testimonials/resources`,
      method: 'GET',
    });
  }
}
