export default ({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &>.content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 20px;

      width: calc(100% - 40px);
      max-width: 1366px;
    }

    .links-container {
      .payment-method-designation {
        background-color: ${theme.lightBorderColor};
        padding: 10px 10px;
        margin-top: 10px;

        .switcher {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-top: solid 1px ${theme.borderColor};
        }
      }
    }

    .about-bonus-boosts{
      background-color: ${theme.darkBackgroundColor};
      display: flex;
      flex-direction: column;
      background-color: ${theme.darkBackgroundColor};
      padding: 10px 10px;
      margin: 10px;
      max-width: 100%;
      height: 100%;
  
      .about-bonus-boosts-header{
        background-color: ${theme.darkBackgroundColor};
        text-transform: capitalize;
        justify-content: center;
        font-size: 25px;
        padding: 0px 20px;
        text-align: left;
        background-color: black;
        color: white;
        display: flex;
  
        h1{
          text-align: center;
          margin: 10px 0px;
        }
  
        .highlight-in-header{
          color: ${theme.alternateAccentColor};
        }
      }
      
      h3 {
        margin: 0px;
      }
      p {
        margin: 0px;
      }
  
    }
  
    .about-bonus-boosts-entry{
      background-color: ${theme.darkBackgroundColor};
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 10px 0px;
      padding: 10px;

    }

    .bonus-boosts-info-container{
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10px;
      width: calc(100% - 20px);
    }

    .sign-in-form-container {
      max-width: 600px;
      width: calc(100% - 20px);
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

  `;
