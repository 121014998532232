import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
//import moment from 'moment';

import styles from './index.css';

const StoreBannerWrapper = styled.div`${styles}`;

const StoreBanner = ({ storeBannerUrl, retailName, storeLogo }) => {
    return (
      <StoreBannerWrapper>
        <div className="store-banner-container"> 
          <img className="store-banner" src={ storeBannerUrl } alt={retailName} />
          <img className="store-logo" src={storeLogo} alt="Store Logo" />
        </div>
      </StoreBannerWrapper>
    );
};
export default StoreBanner;