/* eslint-disable react/require-default-props */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import ActionButton from 'SharedComponents/action-button';

import styles from './Slide.css';

const SlideWrapper = styled.div`${styles}`;

const Slide = ({
  imageUrl, title, text1, text2, text3, backgroundPosition,
}) => (
    <SlideWrapper imageurl={imageUrl} backgroundposition={backgroundPosition}>
      <div className="text-container">
        <h1><span className="highlight-text">{title}</span></h1>
        <p>{text1}</p>
        <p>{text2}</p>
        <p>{text3}</p>
        <div className="button-container">
          <Link to="/signup"><ActionButton text="Sign Up Free" large={true} /></Link>
        </div>
      </div>
    </SlideWrapper>
  );

Slide.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text1: PropTypes.string,
  text2: PropTypes.string,
  text3: PropTypes.string,
  backgroundPosition: PropTypes.string.isRequired,
};

export default Slide;
