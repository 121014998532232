export default ({ theme }) => `
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;

    .swiper-pagination {
      margin-top: 20px; 
    }

    .swiper-button-prev,
    .swiper-button-next {
      color: #fff;
      display: none;
    }
    
    .swiper-wrapper {
      margin: 30px 0px;
      padding-bottom: 20px;
    }

    .swiper-pagination {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .swiper-pagination-bullet {
      width: 16px; 
      height: 16px;  
    }
    
    .swiper-pagination-bullet-active {
      width: 20px; 
      height: 20px; 
      background-color: #000;
    }

    .content-container {
      width: calc(100% - 40px);
      max-width: 1366px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      padding: 20px 20px 80px;

      .carousel-container {
        width: 100%;

        .controls-container {
          margin-top: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          top: 50px;

          .paging-dots-container {
            display: flex;
            align-items: center;
            justify-content: center;

            .paging-dot {
              height: 16px;
              width: 16px;
              border-radius: 8px;
              margin: 3px;
              outline: none;
              transition: border-radius 1s;
              transition: height 0.3s;
              transition: width 0.3s;
              border: none;
              background-color: ${theme.darkPrimaryColor};

              &.active {
                height: 20px;
                width: 20px;
                border-radius: 10px;
                transition: border-radius 1s;
                transition: height 0.3s;
                transition: width 0.3s;
              }
            }
          }
        }
      }
    }
  `;
