import React, { useEffect, useState, useCallback  } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { isChrome, isMobile } from 'react-device-detect';

import CONFIG from 'Config';

import ActionButton from 'SharedComponents/action-button';
import ShareStrip from 'SharedComponents/share-strip';
import PopoverHover from 'SharedComponents/popover-hover';

//import useCheckIsExtensionInstalled from 'SharedHooks/check-extension-installed';

import log from 'Utils/logger';

import styles from './index.css';

const BonusBoostRecommendationWrapper = styled.div`${styles}`;

let isChromeExtensionInstalledInterval;

const BonusBoostRecommendation = ({ specifiedBoost }) => {
  const user = useSelector((state) => state.authentication.user);
  const shopperBoostProfile = useSelector((state) => state.shopping.shopperBoostProfile);
  const partnerContent = useSelector((state) => state.app.partnerContent);
  const isExtensionInstalled = useSelector((state) => state.app.isExtensionInstalled);

  const [recommendedBonusBoost, setRecommendedBonusBoost] = useState({});
  const [localIsExtensionInstalled, setLocalIsExtensionInstalled] = useState(
    isExtensionInstalled ? isExtensionInstalled : false
  );

  //const { checkIsExtensionInstalled } = useCheckIsExtensionInstalled();

  const currentPath = window.location.pathname;
  const currentPathisNotOnCalculators = !currentPath.includes('/superannuation-calculators');

  const currentUserBoostNames = shopperBoostProfile.map(({ boost_name }) => boost_name);

  const recommendedBonusBoostList = [
    {
      boostName: "FavesBonusBoost5%",
      boostAmount: "5%",
      howToGetBoost: "Add 3 or more shops to your faves list. Do this by clicking the heart of yor favourite shops. We will then add FavesBonusBoost5% to your profile. Usually within 24Hrs.",
      step1: "1. Simply choose 3 or more of your favourite retailers by clicking on the heart under their logo.",
      step2: "2. We add an extra 5% with each purchase you make.",
      step3: "3. Watch your super balance keep growing.",
      onSiteLocation: "/shops",
      buttonText: "Add Faves Now",
      boostImage: null,
      boostIcon: "favorite",
      colorAssociation: "red",
    },
    {
      boostName: "BYSclickExtBonusBoost10%",
      boostAmount: "10%",
      howToGetBoost: "Click through to 3 shops from our chrome extension. You do NOT need to make a purchase to qualify. We will then add ClickExtBonusBoost10% to your profile. Usually within 24Hrs.",
      step1: "1. Simply click through to 3 shops from our chrome extension.",
      step2: "2. You do NOT need to make a purchase to qualify.",
      step3: "3. Watch your super grow.",
      onSiteLocation: null,
      buttonText: "Add extension at Chromestore",
      boostImage: null,
      boostIcon: "extension",
      colorAssociation: "#7b7bf3",
    },
    {
      boostName: "ReferFriend1Bonus10%",
      boostAmount: "10%",
      howToGetBoost: "Refer a friend using your unique referal link. If they sign up using your referal link we will add Refer Friend 1 10% to your profile. Max of 4 friends at 10% each.",
      step1: "1. Refer a friend by sending them your unique referal link.",
      step2: "2. When they sign up using your link we add 10% to your profile.(Max of 4 friends).",
      step3: "3. We add an extra 10% per referral to your profile.",
      onSiteLocation: null,
      buttonText: null,
      boostImage: null,
      boostIcon: "share",
      colorAssociation: "#f17ff1"
    },
    {
      boostName: "Refer Friend 2 Bonus 10%",
      boostAmount: "10%",
      howToGetBoost: "Refer another friend using your unique referal link. If they sign up using your referal link then we will add Refer Friend 1 10% to your profile. You can refer up to a max of 4 friends at 10% each.",
      step1: "1. Refer a friend by sending them your unique referal link.",
      step2: "2. When they sign up using your link we add 10% to your profile.(Max of 4 friends).",
      step3: "3. We add an extra 10% per referral to your profile.",
      onSiteLocation: null,
      buttonText: null,
      boostImage: null,
      boostIcon: "share",
      colorAssociation: "#f17ff1"
    },
    {
      boostName: "FeedbackBonusBoost5%",
      boostAmount: "5%",
      howToGetBoost: "Tell us how we can improve through a survey.",
      step1: "1. Simply share your feedback in a quick survey.",
      step2: "2. We add an extra 5% to your profile.",
      step3: "3. Watch your super grow.",
      onSiteLocation: null,
      buttonText: null,
      boostImage: null,
      boostIcon: "poll",
      colorAssociation: "aqua",
    },
    {
      boostName: "SalSacrificeBonusBoost5%",
      boostAmount: "5%",
      howToGetBoost: "If you have ever Salary Sacrificed to your super then let us know. Email us at accounts@boostyoursuper.com.au",
      step1: "1. If you haven't salary sacrificed before set it up through your work (usually HR department).",
      step2: "2. Once a contribution to your super through salary sacrificing has been made then let us know by emailing us at accounts@boostyoursuper.com.au",
      step3: "3. We add an extra Bonus Boost of 5% to your profile.",
      onSiteLocation: null,
      buttonText: null,
      boostImage: null,
      boostIcon: "currency_exchange",
      colorAssociation: "magenta",
    },
    {
      boostName: "PersContribBonusBoost5%",
      boostAmount: "5%",
      howToGetBoost: "Make a Personal Contribution to your super for $2 or more. Let us know when you have done this by emailing us at accounts@boostyoursuper.com.au",
      step1: "1. Make a Personal Contribution to your super for $2 or more.",
      step2: "2. Let us know when you have done this by emailing us at accounts@boostyoursuper.com.au",
      step3: "3. We add an extra 5% Bonus Boost to your profile.",
      onSiteLocation: null,
      buttonText: null,
      boostImage: null,
      boostIcon: "savings",
      colorAssociation: "cyan"
    },
    {
      boostName: "SurveyBonusBoost10%",
      boostAmount: "10%",
      howToGetBoost: "Complete this short survey and we will add SurveyBonusBoost10% to your account normally within 1-2 business days.",
      step1: "1. Simply share your feedback in a quick survey.",
      step2: "2. We add an extra 5% Bonus Boost to your profile. ",
      step3: "3. Watch your super grow.",
      onSiteLocation: null,
      buttonText: "Quick survey",
      boostImage: null,
      boostIcon: "sentiment_neutral",
      colorAssociation: "pink"
    },
    {
      boostName: "quickFeedbackBonusBoost1%",
      boostAmount: "1%",
      howToGetBoost: "Simply email us a suggestion on how we can improve our service to you. Email us at accounts@boostyoursuper.com.au",
      step1: "1. Email us a suggestion on how we can improve our service to you at account@boostyoursuper.com.au",
      step2: "2. We add an extra 1% Bonus Boost to your profile. ",
      step3: "3. Watch your super grow.",
      onSiteLocation: null,
      buttonText: null,
      boostImage: null,
      boostIcon: "poll",
      colorAssociation: "red"
    },
    {
      boostName: "SuperCalculatorBonusBoost5%",
      boostAmount: "5%",
      howToGetBoost: "Ensure you are signed in and use the Super Calculator. You must do a calculation with an added contribution to qualify. We will then add SuperCalculatorBonus5% to your profile. Usually within 24Hrs.",
      step1: "1. Ensure you are logged in.",
      step2: "2. Enter a Round Ups After Tax contribution on the Calculator.",
      step3: "3. Click calculate. That's it! We add an extra 5% Bonus Boost to your profile so you earn more.",
      onSiteLocation: !user ? "/login" : currentPathisNotOnCalculators ? "/superannuation-calculators" : null,
      buttonText: !user ? "Login" : currentPathisNotOnCalculators ? "Go to Super Calculator" : null,
      boostImage: null,
      boostIcon: "calculate",
      colorAssociation: "#96e996"
    },
  ]

  useEffect(() => {
    console.log('BonusBoostRecommender - useEffect calc boost kicked off');
    console.log('BonusBoostRecommender - useEffect calc boost recommendedBonusBoost: ', recommendedBonusBoost);
    console.log('BonusBoostRecommender - useEffect calc boost isExtensionInstalled: ', isExtensionInstalled);
    console.log('BonusBoostRecommender - useEffect calc boost shopperBoostProfile: ' , shopperBoostProfile);
    calculateRecommendedBoost();
    return () => {
      window.clearInterval(isChromeExtensionInstalledInterval);
    }
  },[ shopperBoostProfile, specifiedBoost]);

  useEffect(() => {
    console.log('BonusBoostRecommender - useEffect ');
    console.log('BonusBoostRecommender - useEffect - isExtensionInstalled: ', isExtensionInstalled);

    if (isExtensionInstalled !== localIsExtensionInstalled) {
      setLocalIsExtensionInstalled(isExtensionInstalled);
    }
  }, [isExtensionInstalled]);


  // useCallback to memoize event handlers for performance
  const onLearnHowItWorks = useCallback(() => {
    const howItWorksWindow = window.open(`${window.location.host}/notifier/testrun`, '_blank');
    howItWorksWindow.focus();
  }, []);

  const onInstallChromeExtension = (() => {
    const webstoreWindow = window.open(`https://chrome.google.com/webstore/detail/boost-your-super/${CONFIG.chromeExtensionId}`, '_blank');
    webstoreWindow.focus();

    const isChromeExtensionInstalledInterval = window.setInterval(() => {
      const img = new Image();
      img.src = `chrome-extension://${CONFIG.chromeExtensionId}/assets/images/bys_logo.png`;

      img.onload = () => {
        checkIsExtensionInstalled();
        window.clearInterval(isChromeExtensionInstalledInterval);
      };
    }, 2000);
  });

  const triggerExternalUrl = useCallback(() => {
    if (recommendedBonusBoost.boostName === 'BYSclickExtBonusBoost10%') {
      onInstallChromeExtension();
    } else if (recommendedBonusBoost.boostName === 'SurveyBonusBoost10%') {
      const surveyWindow = window.open(`https://us20.list-manage.com/survey?u=ca1d281def8eb1859036d0f7e&id=0e811dd7df`, '_blank');
      surveyWindow.focus();
    }
  }, [recommendedBonusBoost.boostName, onInstallChromeExtension]);


  const calculateRecommendedBoost = () => {
    
    var calcRecommendedBoost;

    //const currentUserBoostNames = shopperBoostProfile.map(( { boost_name } ) => boost_name );
    console.log( 'BonusBoostsStatus for currentUserBoosts is : ', currentUserBoostNames);
    console.log('BonusBoostStatus of specifiedBoost in calculateREcommendedBoost is: ', specifiedBoost)

    var favesBonusBoostStatus = currentUserBoostNames.includes('FavesBonusBoost5%');
    var clkExtBonusBoostStatus = currentUserBoostNames.includes('BYSclickExtBonusBoost10%');
    
    var persContribBonusBoostStatus = currentUserBoostNames.includes('PersContribBonusBoost5%');
    var referFriend1BonusBoostStatus = currentUserBoostNames.includes('ReferFriend1Bonus10%');
    var salSacrificeBonusBoostStatus = currentUserBoostNames.includes('SalSacrificeBonusBoost5%');
    var referFriend2BonusBoostStatus = currentUserBoostNames.includes('ReferFriend2Bonus10%');
    var referFriend3BonusBoostStatus = currentUserBoostNames.includes('ReferFriend3Bonus10%');
    var referFriend4BonusBoostStatus = currentUserBoostNames.includes('ReferFriend4Bonus10%');
    var referredFriendBonusBoostStatus = currentUserBoostNames.includes('ReferredFriendBonus10%');
    var feedbackBonusBoostStatus = currentUserBoostNames.includes('FeedbackBonusBoost5%');
    var quickFeedbackBonusBoostStatus = currentUserBoostNames.includes('quickFeedbackBonusBoost1%');
    var surveyBonusBoostStatus = currentUserBoostNames.includes('SurveyBonusBoost10%');
    var survey2BonusBoostStatus = currentUserBoostNames.includes('SurveyBonusBoost5%');
    var superCalculatorBonusBoostStatus = currentUserBoostNames.includes('SuperCalculatorBonusBoost5%');

    if ( specifiedBoost ) {
      console.log('**specifiedBoost BonusBoostStatus: ', specifiedBoost);
      calcRecommendedBoost = recommendedBonusBoostList.filter(boost => boost.boostName === specifiedBoost);
    } else if ( !favesBonusBoostStatus ) {
      console.log('**favesBonusBoostStatus is: ', favesBonusBoostStatus);
      calcRecommendedBoost = recommendedBonusBoostList.filter(boost => boost.boostName === "FavesBonusBoost5%");
    } else if ( !superCalculatorBonusBoostStatus ) {
      console.log('**superCalculatorBonusBoostStatus is: ', superCalculatorBonusBoostStatus);
      calcRecommendedBoost = recommendedBonusBoostList.filter(boost => boost.boostName === "SuperCalculatorBonusBoost5%");
    } else if (isChrome && !isMobile && !clkExtBonusBoostStatus && !isExtensionInstalled){
      console.log('**isExtensionInstalled BonusBoostStatus is: ', isExtensionInstalled);
      calcRecommendedBoost = recommendedBonusBoostList.filter(boost => boost.boostName === "BYSclickExtBonusBoost10%");
    } else if ( !persContribBonusBoostStatus ) {
      console.log('**persContribBonusBoostStatus is: ', persContribBonusBoostStatus);
      calcRecommendedBoost = recommendedBonusBoostList.filter(boost => boost.boostName === "PersContribBonusBoost5%");
    } else if ( !referFriend1BonusBoostStatus ) {
      calcRecommendedBoost = recommendedBonusBoostList.filter(boost => boost.boostName === "ReferFriend1Bonus10%");
    } else if ( !surveyBonusBoostStatus ) {
      calcRecommendedBoost = recommendedBonusBoostList.filter(boost => boost.boostName === "SurveyBonusBoost10%");
    } else if ( !salSacrificeBonusBoostStatus ) {
      calcRecommendedBoost = recommendedBonusBoostList.filter(boost => boost.boostName === "SalSacrificeBonusBoost5%");
    } else {
      calcRecommendedBoost = recommendedBonusBoostList.filter(boost => boost.boostName === "quickFeedbackBonusBoost1%");
    }

    //calcRecommendedBoost = recommendedBonusBoostList.filter(boost => boost.boostName === "SuperCalculatorBonus5%");

    setRecommendedBonusBoost({
      ...calcRecommendedBoost[0]
    });
      
   };

   return (
    <BonusBoostRecommendationWrapper>
      
        { recommendedBonusBoost.boostName && !currentUserBoostNames.includes(recommendedBonusBoost.boostName) ? 
          (
            <div className="recommendation-container">
              <div className="bonus-boost-recommendation">
                <div className="bonus-boost-header-and-instructions">

                  <div className="bonus-boost-header">
                    <h2 style={{ textAlign: "center", }} >BONUS BOOSTS ARE HERE TO HELP YOU &nbsp; <span className="theme-highlight">BOOST EVEN FASTER</span>
                      <span className="pop-over-container">
                        <PopoverHover
                          content={
                            <div style={{
                              maxWidth: '340px', /* padding: 10, fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left', */
                            }}
                            >
                              <p><strong>EARNING BONUS BOOSTS</strong> You can earn Bonus Boosts by taking actions we believe will help towards you retiring with more super (eg salary sacrificing) or helping us better understand you (eg filling in surveys).</p>
                              <p><strong>INCREASES YOUR BONUS BOOST POWER</strong> Each ACTIVE Bonus Boost you earn is added to your Bonus Boost Power. This increases the amount you earn on each transaction.</p>
                              <p><strong>MAX BONUS BOOST POWER</strong> is 100%.</p>
                            </div>
                          }
                          header = {'ABOUT BONUS BOOSTS'}
                          img = {null}
                          itemToPopover = {
                            ( <i className="material-icons" style={{ margin: '0 5px 0 5px', verticalAlign: '-40%', color: 'white', fontSize:'44px', }} >info</i> )
                          }
                          anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'center',
                            horizontal: 'right',
                          }}
                        />
                      </span>
                    
                    </h2>
                    <h2 className="get-boost-line" style={{ fontSize: "large", }}>Get &nbsp; <span style={{ color: `${recommendedBonusBoost.colorAssociation}`}}>{recommendedBonusBoost.boostName}</span></h2>
                  </div>
                  <div className="boost-actions-container">
                    { recommendedBonusBoost.boostName.indexOf('ReferFriend') > -1 ?
                      (
                        <div className="bonus-boost-external-component">
                          <ShareStrip 
                            buttonText="Refer Friend"
                            headerText={recommendedBonusBoost.howToGetBoost}
                          />
                        </div>
                      ) : (
                        <div className="bonus-boost-description-and-action">
                            <p>{recommendedBonusBoost.step1}</p>
                            <p>{recommendedBonusBoost.step2}</p>
                            <p>{recommendedBonusBoost.step3}</p>
                              { recommendedBonusBoost.buttonText != null ?
                                ( recommendedBonusBoost.onSiteLocation != null ?
                                  <Link to={`${recommendedBonusBoost.onSiteLocation}`}>
                                    <ActionButton 
                                      style={{margin: "10px"}}
                                      text={recommendedBonusBoost.buttonText}
                                      large={true}
                                    />
                                  </Link>
                                  : 
                                  <ActionButton 
                                    style={{margin: "10px"}}
                                    text={recommendedBonusBoost.buttonText}
                                    onClick={triggerExternalUrl}
                                    large={true}
                                  />
                                ) : null
                              }
                        </div>
                      )
                    }
                  </div>
                </div>
                <div className="boost-available-graphic">
                  <div className="new-boost-circle">
                    <div className="circle" style={{ background: `linear-gradient( ${recommendedBonusBoost.colorAssociation} , ${partnerContent.alternate_accent_color})`, }}>
                      <div className="circle-content">
                        <i className="material-icons" >{recommendedBonusBoost.boostIcon}</i>
                      </div>
                    </div> 
                    <div className="boost-avail-circle-caption">
                      <h2 style={{ fontSize: "medium" }}>Recommended Bonus Boost</h2>
                      <h2 style={{ color: `${recommendedBonusBoost.colorAssociation}`}}>{recommendedBonusBoost.boostName}</h2>
                    </div>{/* linear-gradient(0deg, #FF512 0%, #F09819 100%), */}
                  </div>
                </div>
              </div>
            </div>
          ) : (null)
        }
      
    </BonusBoostRecommendationWrapper>
  );

}

export default BonusBoostRecommendation;
