import React, { Component } from 'react';
import styled from 'styled-components';
import toast, { Toaster } from 'react-hot-toast';

import styles from './index.css.js';

const ToastNotificationWrapper = styled.section`${styles}`;

const ToastNotify = ({ message, notificationType,loadingMessage, loadingErrorMessage })  => {


  const renderSwitchMessage = (notificationType, message, loadingMessage, loadingErrorMessage, myPromise ) => {
    switch(notificationType) {
      case 'success':
        return toast.success(message);
      case 'error':
        return toast.error(message);
      case 'custom':
        return toast.custom(message);
      case 'loading':
        return toast.promise(myPromise, {
          loading: loadingMessage,
          success: message,
          error: loadingErrorMessage,
        });
      case 'default':
        return toast(message);
    };
  }

  console.log(['about to Toast following message: ', message]);


  return (
    <ToastNotificationWrapper>
      <div classname="toaster-notification">           
        {renderSwitchMessage(notificationType, message, loadingMessage, loadingErrorMessage)}
      </div>
    </ToastNotificationWrapper>
  );
  
};

export default ToastNotify;
