import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
//import moment from 'moment';
import log from 'Utils/logger';

import styles from './index.css';

const NotificationBannerWrapper = styled.div`${styles}`;

const NotificationBanner = ({ user, title, description, subtext }) => {
  console.log('NOTIFICATIONS BANNER TRIGGERED WITH TITLE: ', title);
  log({
    event: 'UserNotificationTriggered',
    shopper_id: user.shopper_id,
    notificationTitle: title,
  })
    return (
      <NotificationBannerWrapper>
        <h1 className="title"> { title } </h1>
        <p className="description"> { description } </p>
        <small className="sub-text"> { subtext } </small>
      </NotificationBannerWrapper>
    );
};
export default connect(
  (state) => ({ user: state.authentication.user })
)(NotificationBanner);