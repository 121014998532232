import React, { useState } from 'react';
import styled from 'styled-components';

import Footer from 'SharedComponents/footer';
import Tabs from 'SharedComponents/tabs';

import TermsAndConditions from './TermsAndConditions';
import SpecialOffers from './SpecialOffers';

import styles from './index.css';

const TermsAndConditionsWrapper = styled.section`${styles}`;


const PrivacyPolicy = () => {
  const [activeTab, setActiveTab] = useState('terms-and-conditions');
  return (
    <TermsAndConditionsWrapper>
      <div className="content">
        <h1 style={{ textAlign: 'left', marginLeft: 10 }}>Terms and Conditions</h1>
        <Tabs tabs={[{ label: 'Terms and Conditions', value: 'terms-and-conditions' }, { label: 'Special Offers', value: 'special-offers' }]} activeTab={activeTab} onActiveTabChanged={(nextTab) => setActiveTab(nextTab)} />
        <div className="content-container" style={{ minHeight: 'calc(100vh - 400px)', width: 'calc(100% - 20px)' }}>
          {
            activeTab === 'special-offers' ? (
              <SpecialOffers />
            ) : (
                <TermsAndConditions />
              )
          }
        </div>
      </div>
      <Footer />
    </TermsAndConditionsWrapper>
  );
}

export default PrivacyPolicy;
