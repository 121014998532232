export default ({ theme }) => {
  return `
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    overflow: auto;
    padding: 10px 0;

    .category {
      width: calc(100% - 20px);
      padding: 10px;
      text-align: left;
      color: ${theme.secondaryColor};
      outline: none;
      border: none;
      font-size: 18px;
      background-color: ${theme.neutralColor};
      padding: 10px;
      border-top-right-radius: 20.5px;
      border-bottom-right-radius: 20.5px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 0.5;
      min-height: 41px;

      &.active {
        color: ${theme.darkSecondaryColor};
        font-weight: bold;
        background-color: ${theme.borderColor};
        cursor: pointer;
      }

      &:hover {
        color: ${theme.darkSecondaryColor};
        font-weight: bold;
        background-color: ${theme.lightBorderColor};
        cursor: pointer;
      }
    }
  `;
}
