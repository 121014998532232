import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CONFIG from 'Config';
import log from "Utils/logger";

import * as AppActions from 'Flux/app/actions';

const useCheckIsExtensionInstalled = () => {
  console.log ("*** ** check ExtensionInstalledStatus hook triggered")
  const isExtensionInstalledRef = useRef(false);
  const checkingIsExtensionInstalledRef = useRef(false);
  const dispatch = useDispatch();
  const storedIsExtensionInstalled = useSelector(state => state.app.isExtensionInstalled);

  const checkIsExtensionInstalled = async () => {
    console.log ("*** ** check ExtensionInstalledStatus function triggered")
    checkingIsExtensionInstalledRef.current = true;
    try {
      const img = new Image();
      img.src = `chrome-extension://${CONFIG.chromeExtensionId}/assets/images/bys_logo.png`;
  
      img.onload = () => {
        isExtensionInstalledRef.current = true;
        console.log('*** ** check ExtensionInstalledStatus EXTENSION INSTALLED');
        if ( storedIsExtensionInstalled !== isExtensionInstalledRef.current ) {
          dispatch(AppActions.setIsExtensionInstalled(true));
          console.log('*** ** check ExtensionInstalledStatus extension IS INSTALLED');
          console.log('*** ** check ExtensionInstalledStatus updating isExtensionInstalled which is isExtensionInstalledRef.current: ', isExtensionInstalledRef.current);
        }
      };

        img.onerror = () => {
          isExtensionInstalledRef.current = false;
          console.log('*** ** check ExtensionInstalledStatus EXTENSION NOT INSTALLED]');
          if ( storedIsExtensionInstalled !== false ) {
            dispatch(AppActions.setIsExtensionInstalled(false));
            console.log('*** ** check ExtensionInstalledStatus extension IS NOT INSTALLED');
            console.log('*** ** check ExtensionInstalledStatus updating isExtensionInstalled which is isExtensionInstalledRef.current: ', isExtensionInstalledRef.current);
          }
        };
    } catch (exception) {
      console.log(exception);
      log({
        event: "System-Error",
        source: "Check Extension Installed Hook",
        'shopper_id': authentication? authentication.user.shopper_id : '000',
        info: exception,
        ...exception,
      });
    } finally {
      checkingIsExtensionInstalledRef.current = false;
    }
  };

  useEffect(() => {
    console.log('*** ** check ExtensionInstalledStatus useEffect triggered with storedIsExtensionInstalled : ', storedIsExtensionInstalled);
    if ( //!storedIsExtensionInstalled
         //&& 
         !checkingIsExtensionInstalledRef.current) {
      checkIsExtensionInstalled();
    }
  } , []);

  return { checkIsExtensionInstalled };
};

export default useCheckIsExtensionInstalled;
