import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-grid-system';

import styles from './index.css';

import Testimonials from 'SharedComponents/testimonials';
import HowItWorks from 'SharedComponents/how-it-works';

const HowItWorksTestimonialWrapper = styled.div`${styles}`;

const HowItWorksTestimonial = (props) => {
  return (
    <HowItWorksTestimonialWrapper>
      <Container fluid style={{ width: '100%', margin: 0, padding: 0 }}>
        <Row style={{ width: '100%', margin: 0, padding: 0 }}>
          <Col lg={6} md={6} sm={12} xs={12} style={{ margin: 0, padding: 0 }}>
            <HowItWorks />
          </Col>
          <Col lg={6} md={6} sm={12} xs={12} style={{ margin: 0, padding: 0 }}>
            <Testimonials />
          </Col>
        </Row>
      </Container>
    </HowItWorksTestimonialWrapper>
  );
}

export default HowItWorksTestimonial;
