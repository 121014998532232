export default ({ theme, deal, dealWidthMobile }) => {
  return `
    display: flex;
    flex-direction: column;
    justify-content: flex-between;
    align-items: center;
    justify-content: flex-start;
    width: 400px;
    min-width: 400px;
    margin: 10px;
    background-color: ${theme.neutralColor};
    box-shadow: rgb(199 199 199) 4px 1px 12px;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
    border-top: ${deal.currently_promoted === "1" ? `solid 10px ${theme.alternateAccentColor}` : "none"};
    white-space: normal;
    height: fit-content;


    @media(max-width: 425px) {
      width: ${dealWidthMobile};
      min-width: ${dealWidthMobile};
      margin: 5px;
    }

    &:hover {
      transition: all .3s ease-in-out;
      box-shadow: 0 0 45px -3px hsla(0,0%,55%,.75);
      transform: scale(1.04);
    }

    .deal-banner-and-logo-image-container {
      width: 100%;
      position: relative;
      overflow: hidden; 

      .deal-banner-image-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: flex-start;
        flex-direction: column;
        height: 150px;
        
        overflow: hidden; /* Hide the overflow of child elements */
        }
        
        img.deal-banner-image {
          max-width: none; /* Remove any max-width constraint */
          max-height: none; /* Remove any max-height constraint */
        }
    

      .store-image-container {
        height: 120px;
        width: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: flex-start;
        margin: 0px 10px 10px 0;
        flex-direction: column;
        margin: 7px 10px 10px -10px;
        border: none;
        outline: none;
        background-color: transparent;
        cursor: pointer;
        position: absolute;
        top: 10px; /* Adjust the top position as needed */
        left: 10px;

        img.store-image {
          width: 100%;
          border-radius: 50%;
        }

        small.store-name {
          margin: 10px;
          display: none;
        }
      }
    }

    .deal-info {
      width: calc(100% - 20px);
      h3 {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 18px;
        margin: 0;
      }

      h2 {
        font-size: 26px;
        margin: 10px 0;
      }

      p {

      }

      div.expiry {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        span.times {
          display: flex;
          align-items: center;
        }
      }
    }



    .coupon-container {
      width: calc(100% - 10px);
      padding: 5px;
      // background-color: orange;
      border-top-right-radius: 3px;
      border-top-left-radius: 3px;
      border-bottom: solid 1px #d9d9d9;
      min-height: 53px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;

      .boost-amount {
        justify-content: center;
      }
  
      .deal-code-container {
        display: flex;
        flex-direction: column;
        border: none;
        background-color: unset;
  
        .click-to-copy {
          margin: 3px 0;
  
          &.active {
            color: ${theme.darkSecondaryColor};
          }
        }
  
        .code-container {
          min-width: 120px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          height: 100%;
          
          .code {
            text-transform: uppercase;
            font-size: 18px;
            background-color: ${theme.borderColor};
            padding: 3px;
            color: ${theme.darkSecondaryColor};
            margin: 3px 0;
            border-radius: 3px;
            text-align: center;
          }
  
          &:hover {
            filter: brightness(1.1);
            cursor: copy;
          }
        }
      }
  
      .no-code-required-container {
        display: flex;
      }
    }

    .actions-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      width: calc(100% - 20px);
      padding: 0px 0px 20px 0px;

      button.shop-now {
        padding: 0 30px;
        background-color: ${theme.darkSecondaryColor};
        color: ${theme.secondaryTextColor};
        border-radius: 3px;
        /* padding: 3px 5px; */
        outline: none;
        height: 50px;
        border-radius: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
        border: none;
 
        cursor: pointer;
        margin: 10px 0;

        /* border-radius: 11.5px; */
        

        &:hover {
          background-color: ${theme.lightSecondaryColor};
        }
      }

      .share-this-deal {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border: solid 1px ${theme.borderColor};
        border-radius: 12.5px;
        outline: none;
        padding: 3px 6px;

        i {
          font-size: 18px;
          margin: 0 5px 0 0;
        }
      }
    }
  `;
}
