import React, { Component } from 'react';
import styled from 'styled-components';
import Spinner from 'react-spinner-material';

import IconButton from 'SharedComponents/icon-button';

import styles from './index.css';

const SimplePaginationWrapper = styled.div`${styles}`;

const SimplePagination = ({ totalItems, perPage, currentPage, onFetchNextPage, onFetchPreviousPage, isFetchingShopperDonations }) => {
  return (
    <SimplePaginationWrapper>
      {
        isFetchingShopperDonations ? (
          <Spinner spinnerColor="000000" size={16} spinnerWidth={2} />
        ) : null
      }
      &nbsp;&nbsp;
      <span>Showing Page {currentPage} of {Math.ceil(totalItems / perPage)}</span>&nbsp;&nbsp;
      <IconButton onClick={onFetchPreviousPage} disabled={currentPage - 1 === 0}><i className="material-icons">chevron_left</i></IconButton>&nbsp;&nbsp;
      <IconButton onClick={onFetchNextPage} disabled={Math.ceil(totalItems / perPage) === currentPage}><i className="material-icons">chevron_right</i></IconButton>
    </SimplePaginationWrapper>
  )
}

export default SimplePagination;
