export default ({ backgroundposition, imageurl, theme }) => `
  height: 100vh;
  max-height: 600px;
  width:100%;
  background-image: url(${imageurl});
  background-size: 100%;
  background-position: left;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  background-repeat: no-repeat;

  @media(max-width: 1400px) {
    background-size: cover;
    background-position: ${backgroundposition};
  }

  .button-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin: 30px 0;

    @media(max-width: 820px) {
      margin: 10px 0;
    }
  }

  .overlay-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;


    .text-container {
      backdrop-filter: contrast(0.8);
      padding: 20px 40px 20px 60px;
      height: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      text-align: left;
      color: #000;
      max-width: calc(60% - 100px);

      h1 {
        font-weight: bolder;
        text-transform: uppercase;
        /* background-color: ${theme.alternateAccentColor}; */
        /* padding: 0px 10px; */
        color: #000;

        @media(min-width: 768px) {
          font-size: 50px;
        }
      }

      p {
        margin: 5px 0;
        font-weight: bolder;
        font-size: 18px;
      }

      @media(max-width: 820px) {
        /* color: #fff; */
        backdrop-filter: contrast(0.5);
        width: 100%;
        max-width: unset;
        padding: 0px 10px 20px 10px;
        justify-content: flex-start;
        height: unset;
      }
    }

    @media(max-width: 820px) {
      height: unset;
    }


  }


  .compare-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    flex-direction: column;
    
    p {
      font-size: 18px;
      font-weight: bolder;
      margin: 0;
    }
  }



  .highlight-text {
    background-color: ${theme.alternateAccentColor};
    padding: 0px 3px;
  }

  .demographic-images-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .demographic-age-image {
      width: 300px;
      height: auto; 
      object-fit: contain; 

       @media(max-width: 820px) {
        width: 180px;
       }

    }

    .demographic-gender-image {
      width: 250px;
      object-fit: contain;

      @media(max-width: 820px) {
        width: 150px;
      }

    }

  }

  .user-type-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 40%;
    height: 100%;

    .user-type {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: flex-end;

      h1 {
        font-size: 35px;
        font-weight: bolder;
        margin: 0;
        color: ${theme.alternateAccentColor};
        background-color: #000;
        text-transform: uppercase;
        padding: 0px 10px;
      }

      p {
        padding: 10px 10px 20px;
        margin: 0px;
        backdrop-filter: contrast(0.4);
        color: #000;
        font-weight: bolder;

        @media(max-width: 820px) {
          padding: 5px 10px 5px;
        }
      }

      @media(max-width: 820px) {
        justify-content: flex-start;

        h1 {
          font-size: 18px;
        }

      }
    }

    @media(max-width: 820px) {
      max-width: 100%;
    }

  }

`;
