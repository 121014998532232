import axios from 'axios';
import { browserName, isMobile, isIOS, deviceType, isDesktop, isTablet } from 'react-device-detect';

import CONFIG from '../../../config';
import * as ShoppingActionTypes from './constants';

export function addStores(stores) {
  console.log(['ShoppingActionTypes ADD_STORES initiated with following stores' ,stores ]);
  return {
    type: ShoppingActionTypes.ADD_STORES,
    payload: stores,
  };
}

export function addDeals(deals) {
  return {
    type: ShoppingActionTypes.ADD_DEALS,
    payload: deals,
  };
}

export function addPromotedDeals(deals) {
  return {
    type: ShoppingActionTypes.ADD_PROMOTED_DEALS,
    payload: deals,
  };
}

export function setCurrentPromotion(promotion) {
  return {
    type: ShoppingActionTypes.SET_CURRENT_PROMOTION,
    payload: promotion,
  };
}

export function addCategories(categories) {
  return {
    type: ShoppingActionTypes.ADD_CATEGORIES,
    payload: categories,
  };
}

export function addStoreTags(tags) {
  return {
    type: ShoppingActionTypes.ADD_STORE_TAGS,
    payload: tags,
  };
}

export function removeStores() {
  return {
    type: ShoppingActionTypes.REMOVE_STORES,
  };
}

export function removeDeals() {
  return {
    type: ShoppingActionTypes.REMOVE_DEALS,
  };
}

export function removePromotedDeals() {
  return {
    type: ShoppingActionTypes.REMOVE_PROMOTED_DEALS,
  };
}

export function removeCategories() {
  return {
    type: ShoppingActionTypes.REMOVE_CATEGORIES,
  };
}

export function fetchStores() {
  console.log(['fetchStores function triggered in ShoppingActionTypes']);
  return () => axios({
    url: `${CONFIG.apiV3Url}/stores/resources?client=pwa`,
    method: 'GET',
  });
}

export function fetchDeals() {
  return () => axios({
    url: `${CONFIG.apiV3Url}/deals/resources?client=pwa`,
    method: 'GET',
  });
}

export function fetchCategories() {
  return () => axios({
    url: `${CONFIG.apiV3Url}/storetags/resources`,
    method: 'GET',
  });
}

export function fetchStoreTags() {
  return () => axios({
    url: `${CONFIG.apiV3Url}/storetags/tags`,
    method: 'GET',
  });
}

export function getForwardingUrl(store, type) {
  function checkStandaloneMode() {
    return window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true;
  }

  return (dispatch, getState) => {
    const { authentication } = getState();
    const { user } = authentication;

    return axios({
      url: `${CONFIG.apiV3Url}/transactions/resource`,
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + window.localStorage.getItem('auth_token')
      },
      data: {
        retail_store_id: store.retail_store_id,
        //shopper_id: shopperID,
        installation_id: checkStandaloneMode() ? 'installedApp' : 'web',
        //installation_id: checkStandaloneMode() ? 'Installed_PWA' : 'web-beta-v0.0.1',
        //charity_id: charityID,
        tracking_url: store.tracking_url,
        client: browserName + (isMobile ? ' Mobile' : [])  +  (isDesktop ? ' deskTop' : [])  +  (isTablet ? ' Tablet' : []) + (isIOS ? ' iOS' : []) ,
        ...(type === 'deal' ? { coupon_id: store.coupon_id } : {}),
      },
    });
  };
}

export function setFavoritesOnStores(favorites) {
  return {
    type: ShoppingActionTypes.SET_FAVORITES_ON_STORES,
    payload: favorites, 
  }
}

export function setFavoriteOnStore(favorite) {
  return {
    type: ShoppingActionTypes.SET_FAVORITE_ON_STORE,
    payload: favorite, 
  }
}

export function unSetFavoriteOnStore(favorite) {
  return {
    type: ShoppingActionTypes.UN_SET_FAVORITE_ON_STORE,
    payload: favorite, 
  }
}

export function addFavoriteShop(favorites) {
  return { 
    type: ShoppingActionTypes.ADD_FAVORITE_SHOP,
    payload: favorites, 
  };
}

export function addFavoriteShops(favorites) {
  console.log('[ADD_FAVORITE_SHOPS triggered in ShoppingActions with favorites payload: ', favorites);
  return { 
    type: ShoppingActionTypes.ADD_FAVORITE_SHOPS,
    payload: favorites, 
  };
}

export function removeFavoriteShop(favorites) {
  return { 
    type: ShoppingActionTypes.REMOVE_FAVORITE_SHOP, 
    payload: favorites,
  };
}

export function updateFavoriteShop(favorites) {
  return { 
    type: ShoppingActionTypes.UPDATE_FAVORITE_SHOP,
    payload: favorites,
   };
}

export function removeFavoriteShops() {
  return {
    type: ShoppingActionTypes.REMOVE_FAVORITE_SHOPS,
  };
}

export function fetchFavoriteShops() {
  return (dispatch, getState) => {
    const { app, authentication } = getState();
    console.log(['fetchFavoriteShops function in SHOPPIN ACTIONS triggered']);
    return axios({
      url: `${CONFIG.apiV3Url}/favorites/resources`, 
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + window.localStorage.getItem('auth_token'),
      },
    });
  };
}

export function insertFavoriteShop(retailStoreId) {
  return (dispatch, getState) => {
    const { app, authentication } = getState();
    console.log(['insertFavoriteShop function in Shopping ACTIONS triggered']);
    console.log(['store to add to faves is ' ,retailStoreId]);
    return axios({
      url: `${CONFIG.apiV3Url}/favorites/resource`, 
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + window.localStorage.getItem('auth_token'),
      },
      data: JSON.stringify({
        _token: window.localStorage.getItem('csrf_token'),
        retail_store_id: retailStoreId
        }),
    });
  };
}


export function deleteFavoriteShop(retailStoreId) {
  return (dispatch, getState) => {
    const { app, authentication } = getState();
    console.log(['deleteFavoriteShop function in Shopping ACTIONS triggered']);
    console.log(['store to delete to faves is ' ,retailStoreId]);
    return axios({
      url: `${CONFIG.apiV3Url}/favorites/resource`, 
      method: 'DELETE',
      mode: 'CORS',
      cache: 'default',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + window.localStorage.getItem('auth_token'),
      },
      data: JSON.stringify({ 
        _token: window.localStorage.getItem('csrf_token'),
        retail_store_id: retailStoreId
        }),
    });
  };
}

export function fetchStoreBoostProfiles() {
  console.log(['fetchStoreBoostProfiles function triggered in ShoppingActionTypes']);
  return () => axios({
    url: `${CONFIG.apiV3Url}/stores/boosts`,
    method: 'GET',
    ////should remove headers requirement for stores/boosts so these can be shown when not signed in
    headers: {
      'Authorization': 'Bearer ' + window.localStorage.getItem('auth_token')
    },
  });
}

export function fetchShopperBoostProfile() {
  console.log('flux action fetchShopperBoostProfile triggered');
  return (dispatch, getState) => {
    const { authentication } = getState();

    const { user } = authentication;
/*     const ownedCause = collaboratingCauses.find(
      (cause) => cause.charity_id === user.charity_id,
    );
 */
    return axios({
      url: `${CONFIG.apiV3Url}/shopper/boosts?id=${user.shopper_id}`,
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + window.localStorage.getItem('auth_token')
      },
    });
  };
}

export function toggleRightDrawer(open) {
  return {
    type: ShoppingActionTypes.TOGGLE_RIGHT_DRAWER,
    payload: open,
  };
}

export function addStoreBoosts( storeBoostProfiles ) {
  return {
    type: ShoppingActionTypes.ADD_STORE_BOOSTS,
    payload: storeBoostProfiles, 
  }
}

export function setStoreBoostProfilesOnStores( storeBoostProfiles ) {
  return {
    type: ShoppingActionTypes.SET_STORE_BOOSTS_ON_STORES,
    payload: storeBoostProfiles, 
  }
}

export function setShopperBoostProfile(shopperBoostProfile) {
  return {
    type: ShoppingActionTypes.ADD_SHOPPER_BOOSTS,
    payload: shopperBoostProfile,
  };
}

export function unsetShopperBoostProfile() {
  return {
    type: ShoppingActionTypes.UNSET_SHOPPER_BOOSTS,
  };
}
