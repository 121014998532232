import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import styles from './index.css.js';

const CategoriesWrapper = styled.div`${styles}`;

const Categories = ({ categories, isFetchingCategories, onCategoryClicked, selectedCategory }) => {
  return (
    <CategoriesWrapper>
      {
        categories.map((category) => (
          <button className={classNames('category', { active: category.category_name.toLowerCase() === (selectedCategory ? selectedCategory.toLowerCase() : '')})} onClick={onCategoryClicked} key={category.category_id} onClick={() => onCategoryClicked(category.category_name)}>{category.category_name}</button>
        ))
      }
      <div style={{ height: 40, width: '100%' }}>&nbsp;</div>
    </CategoriesWrapper>
  )
}

export default Categories;
