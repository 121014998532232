export default ({ theme }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .content {
    max-width: 1366px;
    margin-left: auto;
    margin-right: auto;

    .content-container {
      width: calc(100% - 20px);
      padding: 10px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      margin: 10px 0 40px;

      section {
      }

      h4 {
        color: ${theme.darkSecondaryColor};
      }

      p {
        color: ${theme.lightSecondaryColor};
      }
    }
  }
`;
