import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { extractInitials } from 'Utils';

import style from './index.css';

const AvatarWrapper = styled.div`${style}`;

const Avatar = ({ name, onClick, icon, color, size, backgroundColor }) => {
  return (
    <AvatarWrapper onClick={onClick} size={size} color={color} backgroundColor={backgroundColor}>
      {
        name ? extractInitials(name) : (
          <i className="material-icons" style={{ color }}>{icon}</i>
        )
      }
    </AvatarWrapper>
  );
}

Avatar.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  size: PropTypes.string,
}

export default Avatar;
