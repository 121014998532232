import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  XShareButton,
  XIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
  TwitterShareButton,
} from 'react-share';

import ActionButton from 'SharedComponents/action-button';
import IconButton from 'SharedComponents/icon-button';
import style from './index.css';

const ShareStripWrapper = styled.div`${style}`;

const ShareStrip = ({
  adornment, url, text, title, headerText, buttonText,
}) => {
  const [isSharing, setSharing] = useState(false);
  const user = useSelector((state) => state.authentication.user);
  const collaboratingCauses = useSelector((state) => state.authentication.collaboratingCauses);
  let ownedCause;

  if (user) {
    ownedCause = collaboratingCauses.find((cause) => cause.charity_id === user.charity_id);
  }

  const shareUrl = url || `${window.location.origin}${ownedCause ? `?referrer=${ownedCause.referrer_hash}` : ''}`;
  const shareText = text || `Hi,\n How are you doing?\nI thought you’d be interested in this!\nI’ve been using Boost Your Super to add funds to my super each time I shop.\nYou can earn cashback for your super\nand also round up your spare change to super. It’s easy and FREE,\nJust use the link below to get started.\nIt’s a no brainer! Enjoy! You can thank me later :)`;
  const shareTitle = title || 'Boost Your Super when you Shop';

  async function onShare() {
    try {
      const shareResult = await navigator.share({
        title: shareTitle,
        text: shareText,
        url: shareUrl,
      });
    } catch (exception) {
      console.log(exception);
    }
  }

  return (
    <ShareStripWrapper adornment={!!adornment} isSharing={isSharing}>
      {!isSharing ? (
        <div className="share-container right-align">
          <span style={{ margin: '0 10px' }}>{headerText || 'Share Boost Your Super with friends'}</span>
          {/* <ActionButton large={true} text={buttonText || 'Share'} onClick={() => { navigator.share ? onShare() : setSharing(true); }} icon="share" /> */}
          <ActionButton large={true} text={buttonText || 'Share'} onClick={() => setSharing(true)} icon="share" />
        </div>
      ) : (
        <div className="share-container">
          <div className="share-buttons-container">
            <FacebookShareButton className="button-container" url={shareUrl} quote={`${shareTitle} – ${shareText}`}>
              <FacebookIcon round size={30} />
            </FacebookShareButton>
            <LinkedinShareButton className="button-container" url={shareUrl} description={shareText} title={shareTitle}>
              <LinkedinIcon round size={30} />
            </LinkedinShareButton>
            <TwitterShareButton className="button-container" url={shareUrl} title={title || text ? `${shareTitle} – ${shareText}` : `Hi,\n How are you doing? Hope you’re well!\nI thought you’d be interested in this!\nI’ve been using Boost Your Super to add funds to my super when I shop\nIt’s so easy and FREE\nJust use this link to get started\nIt’s a no brainer!Enjoy! You can thank me later!`} via="Boost Your Super">
              <XIcon round size={30} iconFillColor='black' bgStyle={{ fill: 'white' }} />
            </TwitterShareButton>
            <WhatsappShareButton className="button-container" url={shareUrl} title={`${shareText}`} separator=" ">
              <WhatsappIcon round size={30} />
            </WhatsappShareButton>
            <EmailShareButton className="button-container" url={shareUrl} body={`${shareText}\n\n${url}`} subject={shareTitle}>
              <EmailIcon round size={30} />
            </EmailShareButton>
          </div>
          <IconButton onClick={() => setSharing(false)}><i className="material-icons">close</i></IconButton>
        </div>
      )}
      {!isSharing ? adornment : null}
    </ShareStripWrapper>
  );
};

ShareStrip.propTypes = {
  adornment: PropTypes.node,
  url: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
  headerText: PropTypes.string,
  buttonText: PropTypes.string,
};

export default ShareStrip;
