import Account from "../../containers/Account";

export default [{
  label: 'T & Cs',
  tag: 'terms-and-conditions',
  compulsoryAction: false,
  excludeOnSafariMac: false,
  excludeOnMobile: false,
  excludeOnDesktop: false,
}, {
  label: 'Round Up Amounts',
  tag: 'default-amounts-and-limits',
  compulsoryAction: false,
  excludeOnSafariMac: false,
  excludeOnDesktop: false,
},
{
  label: 'Connect Bank',
  tag: 'connect-bank',
  compulsoryAction: false,
  excludeOnSafariMac: false,
  excludeOnMobile: false,
  excludeOnDesktop: false,
},

];