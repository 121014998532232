export default ({ backgroundimage }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
  
  .content-container {
    display: flex;
    max-width: 1366px;
    width: 100%;

    .image-container {
      background-image: url(${backgroundimage});
      background-size: cover;
      background-position: center;
      height: inherit;
      flex-grow: 1;
      display: flex;
      flex: 1 1 0px;
      /* filter: FlipH; */
      filter: grayscale(100%);

    }

    .text-container {
      display: flex;
      flex-grow: 1;
      width: calc(100% - 40px);
      height: calc(100% - 40px);
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      padding: 20px;
      color: #000;
      text-align: left;
      flex: 1 1 0px;

      h1 {
        font-weight: bolder;
      }

      p {
        font-size: 20px;
      }
    }

    @media(max-width: 768px) {
      background-image: url(${backgroundimage});
      background-size: cover;
      background-position: center;
      
      .image-container {
        display: none;
      }
      
      .text-container {
        background-size: cover;
        background-position: center;
        backdrop-filter: contrast(0.4) grayscale(0.1);
        background-color: rgba(0, 0, 0, 0.1);
        color: #fff;
      }
    }
  }
`;
