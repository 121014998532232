export default ({ theme }) => {
  return `
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 10px 0;

    .deals-container {
      display: flex;
      width: 100%;
      justify-content: center;
    }

    .deals-masonry-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      flex-direction: row;

      .xblock {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    
    .header {
      width: calc(100% - 20px);
      display: flex;
      flex-direction: column;

      .description {
        display: flex;
        flex-direction: column;
        width: 100%;

        p.header-text {
          margin: 0;
          font-size: 20px;
        }
      }

      .sorter {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .visible-items {

          .matches {

            .matched-term {
              font-weight: bold;
              background: ${theme.alternateAccentColor};
              padding: 0 3px;
            }
          
          }

        }
      }
    }

    @media(max-width: 425px) {
      .header {
        .sorter {
          flex-direction: column;

          .visible-items {
            align-self: flex-start;
          }

          .dropdown-menu {
            width: 100% !important;
          }
        }
      }
    }
  `;
}
