import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Spinner from 'react-spinner-material';
import styled from 'styled-components';

import PopoverHover from 'SharedComponents/popover-hover';

import styles from './index.css';

const ShopperBonusBoostsTableWrapper = styled.div`${styles}`;

const ShopperBonusBoostsTable = () => {

  console.log ("ShopperBonusBoostsTable component triggered")
  const app = useSelector((state) => state.app);
  const shopperBoostProfile = useSelector((state) => state.shopping.shopperBoostProfile);
 
  const selfAwardedPopoverText = (boost_description) => {
    return(
      <div className="ext-popover-text-container" style={{ maxWidth: '800px' }}>
        <div className="text-content-container" style={{ margin: '20px' }}>
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',}} >
              <i className="material-icons" style={{ color: '#47E6B1', margin: '0px 0px 5px 15px'}}>check_circle</i>
              <h2 style={{ margin: '0px 0px 5px 5px' }}><i>Awarded due to an ACTION you took. </i></h2>
            </div>
              <p style={{ display: 'flex', margin: '10px 0px 10px 40px', fontSize: 'large' }}>{boost_description}</p>
              <em style={{ margin: '0px 0px 0px 40px' }}>- Keep an eye out for actions you can take to increase your Boost Power</em>
        </div>
      </div>
    )
  };


  return (
    <ShopperBonusBoostsTableWrapper className="bonus-boost-profile">
        {
/* 					!shopperBoostProfile ? (
  					<div style={{
    					height: 200, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column',
  					}}
  					>
    					<Spinner spinnerColor="#000000" size={20} spinnerWidth={3} />
    					<span style={{ margin: '10px 0' }}>Fetching Bonus Boost Profile</span>
  					</div>
					) : */ 
          (
  					<div style={{
    					display: 'flex', flexDirection: 'column', width: '100%', padding: '10px 0',
  					}}
  					>
    					{
								!shopperBoostProfile.length ? (
                  <div style={{
                                  display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', 
                  }}
                  >
                    <h2 style={{ fontSize: 20 }}>You do not currently have any Bonus Boosts</h2>
                  </div>
								) : (

                <div className="table-container hide-scrollbars">
                  <h2 style={{
								    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center', 
                    flexDirection: 'column', 
                  }}>Bonus Boosts on Your Profile</h2>
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th style={{ textAlign: '-webkit-left' }}>Boost&nbsp;Name</th>
                        <th>Sponsor</th>
                        <th>Boost&nbsp;Amount</th>
                        <th>Start&nbsp;Date</th>
                        <th>End&nbsp;Date</th>
              {/*       <th>Is&nbsp;Default</th>
                        <th>Date&nbsp;Linked</th> */}
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      
                      {
                        shopperBoostProfile.sort((a, b) => b.commission_percentage - a.commission_percentage).map((shopperBoostProfile,i) => {
                          return (
                            <tr key={shopperBoostProfile.boost_id}>
                              <td data-title="Number"><div className="td" >{(i + 1)}</div></td>
                              <td data-title="Name"><div className="td" style={{ textAlign: '-webkit-left' }}>{shopperBoostProfile.boost_name}</div></td>
                              <td data-title="Sponsor">
                                <div className="td">
                                  {
                                    shopperBoostProfile.boost_name.indexOf('Partner') == -1 ? (
                                      <div className="td-with-info-button" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}> SELF
                                        <div className="pop-over-container">
                                          <PopoverHover
                                            content = {selfAwardedPopoverText(shopperBoostProfile.boost_description)}
                                            header = {null}
                                            img = {null}
                                            itemToPopover = { 
                                              (<i className="material-icons" style={{ margin: '0 2px 0 2px', display: 'flex' }} >info</i>)
                                            }
                                            horizontalPosn = "right"
                                          />
                                        </div>
                                      </div>
                                    ) : (
                                      <img src={app.partnerContent.partner_logo_small} alt={app.partnerContent.partner_name} />
                                    )
                                    }
                                  </div>
                              </td>
                              <td data-title="Percentage"><div className="td" >{(shopperBoostProfile.commission_percentage*2).toFixed(1) + '%'}</div></td>
                              <td data-title="Expiry"><div className="td" >{moment(shopperBoostProfile.start_date).format('Do MMM YYYY')}</div></td>
                              <td data-title="End"><div className="td" >
                                { shopperBoostProfile.end_date == null ? 'None' :
                                  moment(shopperBoostProfile.end_date).format('Do MMM YYYY')
                                  }
                                  </div>
                              </td>
                              <td data-title="Status"><div className="td" >
                                { shopperBoostProfile.boost_status === 'ACTIVE' ?
                                (
                                  <div className="active-notification">ACTIVE</div>
                                ) : (
                                  shopperBoostProfile.boost_status
                                )
                                  }
                                </div>
                              </td>
                              
                                
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </table>
                </div>
								)
							}
            </div>
					)
				}
    </ShopperBonusBoostsTableWrapper>
  );
};

export default ShopperBonusBoostsTable;