import React, { useState, useEffect } from 'react';
import CountUp from 'react-countup';


import styled from 'styled-components';


import styles from './tab.css.js';

const TabWrapper = styled.div`${styles}`;

const Tab = ({ label, value, onClick, active, count }) => {
  console.log('Tab triggered with count is ', count);
  console.log('Tab triggered with active is ', active);
  console.log('Tab triggered with label is ', label);
  console.log('Tab triggered with value is ', value);
  console.log('Tab triggered with onClick is ', onClick);
  
  const easeOutCubic = (t) => 1 - Math.pow(1 - t, 3);
  const easeInOutCubic = (t) => t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
  const [prevCount , setPrevCount] = useState(0);

  return(
    <TabWrapper className="tab" active={active} count={count}>
      <button onClick={() => onClick(value)}>
        <span className="label">{label}</span>&nbsp;
        {
          count !== undefined && count !== null ?  (
            <span className="count">
                <CountUp
                  //delay={3} this delay seems to cause flashing of initial value after count is finished
                  key={count}
                  start={prevCount}
                  end={count}
                  duration={3}
                  separator=","
                  onEnd={() => {
                    setPrevCount(count);
                  }}
                  useEasing={true}
                  easingFn={easeInOutCubic()}
                />
            </span>
          ) : null
        }
      </button>
      <div className="active-indicator"></div>
    </TabWrapper>
  );
};

export default Tab;
