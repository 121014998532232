export default ({ theme, backgroundPosition, imageBackgroundUrl }) => `
  height: 100%;
  max-height: 400px;
  width:100%;
  background-image: url(${imageBackgroundUrl});
  background-size: cover;
  background-position: ${backgroundPosition};
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  background-repeat: no-repeat;


  .activate-example{
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    display: flex;
    flex-direction: column;
    img {
      max-width: 140px;
      border: 1px solid black;
      margin-bottom: 10px;
    }
  }

  .logo-holder{
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px;
    img {
      max-width: 80px;
    }
  }

  .value-prop-slide {
    position: absolute;
    bottom: 30px;
    left: 0;
    background: ${theme.alternateAccentColor};
    padding: 5px;
    font-weight: bolder;
    font-size: 12px;
    text-transform: uppercase;
  }

  .text-container {
    backdrop-filter: contrast(0.8);
    padding: 20px 40px 20px 60px;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    color: #000;
    max-width: calc(40% - 100px);

    h1 {
      font-weight: bolder;

      @media(min-width: 768px) {
        font-size: 50px;
      }
    }

    p {
      margin: 5px 0;
      font-weight: bolder;
      font-size: 18px;
    }

    @media(max-width: 769px) {
      /* color: #fff; */
      // backdrop-filter: contrast(0.5);
      /* width: 100% */;
      /* max-width: unset; */
    }
  }
`;
