export default ({ backgroundimage }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .content-container {
    width: 100%;
    max-width: 1366px;
    background-image: url(${backgroundimage});
    background-size: cover;
    background-position: center;
    color: #000;
    margin: 20px 0 0;

    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 40px 20px;
      width: calc(100% - 40px) !important;
      backdrop-filter: contrast(0.5);
    }

    h1 {
      font-weight: bolder;
      text-align: center;
      font-size: 60px;
    }

    p {
      font-size: 40px;
      text-align: center;
    }
  }
`;
