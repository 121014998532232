export default ({ theme }) => `
    display: flex;
    -webkit-box-align: center;
    align-items: flex-start;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;

    @media(max-width: 425px) {
      width: calc(100% - 20px);
      padding: 10px;
    }
  `;

