import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import IconButton from 'SharedComponents/icon-button';
import styles from './index.css';

const AccordionWrapper = styled.div`
  ${styles}
`;

const Accordion = ({ header, content, link, headerLeftPadding, onToggle }) => {
  const [isOpen, setIsOpen] = useState(false);

  // Function to handle toggling the accordion open/close
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  // Effect to trigger the onToggle prop when the accordion state changes
  useEffect(() => {
    if (onToggle) {
      onToggle(isOpen); // Notify parent when accordion is opened or closed
    }
  }, [isOpen, onToggle]);

  return (
    <AccordionWrapper isOpen={isOpen} headerLeftPadding={headerLeftPadding}>
      <div className="accordion-header" role="button" onClick={handleToggle}>
        <IconButton onClick={handleToggle}>
          <i className="material-icons">
            {!isOpen ? 'keyboard_arrow_right' : 'keyboard_arrow_down'}
          </i>
        </IconButton>
        <div className="header-container">
          <h3>{header}</h3>
        </div>
      </div>
      {isOpen && (
        <div className="content">
            {content}
            {link}
        </div>
      )}
    </AccordionWrapper>
  );
};

Accordion.propTypes = {
  header: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,  // Updated to allow JSX content
  link: PropTypes.node,
  headerLeftPadding: PropTypes.string,
  onToggle: PropTypes.func,  // Optional callback prop for when the accordion is toggled
};

export default Accordion;
