import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import styles from './ExtensionSlide.css';

const SlideWrapper = styled.div`${styles}`;

const Slide = ({
  imageBackgroundUrl, logoUrl, imageActivateOnSiteUrl, imageExtensionCouponExample, valuePropText, text2, text3, backgroundPosition,
}) => (
    <SlideWrapper imageBackgroundUrl={imageBackgroundUrl} backgroundPosition={backgroundPosition}>
      <div className="activate-example">
        <img src={imageActivateOnSiteUrl} alt="Activate on site" />
        {imageExtensionCouponExample && (
        <img src={imageExtensionCouponExample} alt="Example coupon" />
        )}
      </div>
      <div className="logo-holder">
        <img src={logoUrl} alt="etihad-logo"/>
      </div>
      <div className="value-prop-slide">
        {valuePropText}
      </div>
    </SlideWrapper>
  );

Slide.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text1: PropTypes.string,
  text2: PropTypes.string,
  text3: PropTypes.string,
  backgroundPosition: PropTypes.string.isRequired,
};

export default Slide;
