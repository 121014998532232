export default ({ theme }) => `
  .forgot-password-link {
    text-decoration: none;
    color: ${theme.darkSecondaryColor};
    font-size: 20px;
    margin: 10px;

    &:hover {
      text-decoration: underline;
    }
  }
`;
