export default ({ theme }) => {
  return `
    width: 100%;

    .bonus-boost-profile{
      display: flex;
      flex-direction: column;
      minHeight: 500;
      width: 100%;
    }

    .profile-heading{
      align-items: left;
      justifyContent: center;
      margin-top: 10px;
    }


    .bonus-boost-item {
      background-color: ${theme.neutralColor};
      border-bottom: solid 1px ${theme.borderColor};
      width: 100%;
  
      &:hover {
        background-color: ${theme.lightBorderColor};
      }
    }

    .ext-popover-text-container {
      padding: 20px;
    }

  
    .table-container {
      width: 100%;
      overflow: auto;
  
      table {
        border-spacing: 0;
        min-width: 100%;
        thead {
          border-bottom: solid 2px ${theme.lightBorderColor};
          height: 80px;
  
          tr {
            height: 70px;
  
            th {
              text-align: center;
              height: 100%;
              font-size: 18px;
              font-weight: bold;
              border: none;
              padding: 10px;
              border-bottom: 1px solid ${theme.borderColor};
              border-top: 1px solid ${theme.borderColor};
              text-transform: uppercase;
            }
          }
        }
  
        tbody {
          tr {
            background-color: ${theme.neutralColor};
            box-shadow: 0 0 0 1px ${theme.borderColor};
            height: 60px;
  
            &:hover {
              background-color: ${theme.lightBorderColor};
            }
  
            td {
              text-align: -webkit-center;;
              border: none;
              padding: 10px;
              white-space: nowrap;
              
              td-with-info-button {
                display: flex !important;
                flex-direction: row;
              }

              img {
                border-radius: 50%;
                max-width: 40px;
              }

              p {
                margin: 0px;
              }

              .active-notification{
                background-color: ${theme.activeNotificationColor};
                width: fit-content;
                padding: 3px 15px;
                border-radius: 3px;
                }
              }
            }

          }
        }
      }
    }
  



  `
};