import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import WebFont from 'webfontloader';
import { HelmetProvider } from 'react-helmet-async';
import { Workbox } from 'workbox-window';
import log from "Utils/logger";

import storeConfiguration from 'Flux/storeConfiguration';

import { GoogleOAuthProvider } from '@react-oauth/google';


import App from './app';
const mountPoint = document.getElementById("app");
const rootContainer = createRoot(mountPoint);
const store = storeConfiguration();

console.log('Store at entry point is:', store);

rootContainer.render(
  <React.StrictMode>
    <GoogleOAuthProvider 
      clientId="609957849770-h7f9v1jjddl7sl74mbouhpkldmomtqi8.apps.googleusercontent.com"
      onScriptLoadSuccess={() => {
        console.log('onScriptLoadSuccess');
      }}
      onScriptLoadError={() => {
        console.log('onScriptLoadError');
      }}
    >
      <HelmetProvider>
        <App store={store} /> 
      </HelmetProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);
  

WebFont.load({
  google: {
    families: ['Lato']
  }
});


  const newServiceWorkerUrl = '/service-worker.js'; // New Workbox SW


  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(registrations => {
      let anyUnregistered = false;

      // console.log('service worker registrations are:', registrations);
      // log({
      //   event: "System-Notification",
      //   notification: `registrations are ${registrations}`,
      //   source: "service-worker - about to assess for unregistration",
      // });

      for (let registration of registrations) {
        if (registration.active) {
          const scriptURL = registration.active.scriptURL;
            console.log('** service worker scriptURL is:', scriptURL);
            /* log({
              event: "System-Notification",
              notification: `scriptURL to be assessed is ${registration.active.scriptURL}`,
              source: "service-worker - about to assess for unregistration",
            }); */

          //if (oldServiceWorkerUrls.some(oldUrl => scriptURL.endsWith(oldUrl))) {
            if (   scriptURL.endsWith('precache-service-worker.js')
                || scriptURL.endsWith('data-service-worker.js')
                || scriptURL.endsWith('service-workers/precache-service-worker.js')
                || scriptURL.endsWith('service-workers/data-service-worker.js')
                ) 
            {
              registration.unregister().then(success => {
              if (success) {
                console.log(`Unregistered old service worker: ${registration.active.scriptURL}`);
                log({
                  event: "System-Notification",
                  notification: `Unregistered old service worker: ${registration.active.scriptURL}`,
                  source: "service-worker - UNREGISTER old service worker",
                });
                anyUnregistered = true;
              } 
            })
            .catch(error => {
              console.error(`Error unregistering service worker: ${error}`);
              log({
                event: "System-Error",
                source: `service-worker - error during unregistering ${registration.active.scriptURL}`,
                errorIs: error,
              });
            });
          }
        }
      }

      // Log if no service workers were unregistered
      setTimeout(() => {
        if (!anyUnregistered) {
          console.log('No old service workers were unregistered.');
          // log({
          //   event: "System-Notification",
          //   notification: "No old service workers were unregistered.",
          //   source: "service-worker - UNREGISTER old service worker",
          // });
        }
      }, 1000); // Delay to ensure all promises have been resolved
    }).then(() => { 


      const wb = new Workbox(newServiceWorkerUrl);
      let isUpdatePending = false; // Flag to track update status

      // Listen for the "waiting" event, which indicates a new SW is available
      wb.addEventListener('waiting', () => {
        isUpdatePending = true; // Set the flag to indicate an update is available
        // Optional: Notify the user (e.g., with a toast or banner)
        //if (confirm('A new version of the app is available. Do you want to update now?')) {
        wb.messageSW({ type: 'SKIP_WAITING' }); // Tell the new SW to activate immediately
        console.log('new service worker available - SKIP WAITING MESSAGE - now');// Optional: If you need to do any cleanup or data migration, do it here.
       /*  log({
          event: "System-Notification",
          notification: "Service worker waiting - has been told to skip waiting",
          source: "service-worker - SKIP WAITING",
        }); */
        //}
      });

      // Optional: Listen for the "controlling" event to confirm the new SW is active
      wb.addEventListener('controlling', (event) => {
        if (isUpdatePending) { // Check if an update was pending
          console.log('new service worker available - tiggerig reload');// Optional: If you need to do any cleanup or data migration, do it here.
          log({
            event: "System-Notification",
            notification: "Service worker controlling event triggering reload new service worker",
            source: "service-worker - registration",
          });
          window.location.reload(); // Reload the page to use the new SW
        }
      });



      wb.register().then(() => {
        // log({
        //   event: "System-Notification",
        //   notification: "Service workers supported.",
        //   source: "service-worker - NEW WORKBOX registered",
        // });
      }).catch((error) => {
        console.error('Error during service worker registration:', error);
        // log({
        //   event: "System-Error",
        //   source: "service-worker - error during service worker registration",
        //   errorIs: error,
        // });
      });

    });
    } else {
      console.log('Service workers are not supported.');
      // log({
      //   event: "System-Notification",
      //   notification: "Service workers are not supported.",
      //   source: "service-worker - check browser",
      // });
    }



