import React, { useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import log from "Utils/logger";

import * as RoundUpsActions from 'Flux/round-ups/actions';

const useFetchRoundUpsShopperData = () => {
  console.log ('*** ** fetchRoundUpsShopperData: hook triggered')
  const isFetchingRoundUpsShopperDataRef = useRef(false);
  const hasFetchedRoundUpsShopperDataRef = useRef(false);
  const dispatch = useDispatch();
  const storedRoundUpsShopperData = useSelector(state => state.roundups);
  //const isFetchingRoundUpsShopperData = useSelector(state => state.roundups.isFetchingRoundUpsShopperData);
  const user = useSelector(state => state.authentication.user);

  console.log('*** ** fetchRoundUpsShopperData: user:', user);  

  const fetchRoundUpsShopperData = useCallback(async () => {

    if (isFetchingRoundUpsShopperDataRef.current === true
        || !user
      ) {
      console.log('*** ** fetchRoundUpsShopperData: isFetchingRoundUpsShopperDataRef is true OR no user - lets exit function.');
      console.log ('*** ** fetchRoundUpsShopperData: isFetchingRoundUpsShopperDataRef:', isFetchingRoundUpsShopperDataRef.current);
      console.log ('*** ** fetchRoundUpsShopperData: user:', user);
      return; 
    }
    isFetchingRoundUpsShopperDataRef.current = (true);
    console.log('*** ** fetchRoundUpsShopperData: isFetchingRoundUpsShopperDataRef is false - lets set isFetchingRoundUpsShopperDataRef to true.')
    //await dispatch(RoundUpsActions.isFetchingRoundUpsShopperData(true));
    
    try {
      const result = await dispatch(RoundUpsActions.fetchRoundUpsShopperData());
      const newRoundUpsShopperData = result.data.data;

      console.log('*** ** fetchRoundUpsShopperData: storedRoundUpsShopperData: ', storedRoundUpsShopperData)
      console.log ('*** ** fetchRoundUpsShopperData: newRoundUpsShopperData: ', newRoundUpsShopperData);
      
      if (shouldUpdateStore(newRoundUpsShopperData, storedRoundUpsShopperData)) {
        console.log('*** ** fetchRoundUpsShopperData: newRoundUpsShopperData and storedRoundUpsShopperData are different');
        console.log('*** ** fetchRoundUpsShopperData: newRoundUpsShopperData.keys.length:',Object.keys( newRoundUpsShopperData).length)
        console.log('*** ** fetchRoundUpsShopperData: storedRoundUpsShopperData.keys.length:', Object.keys(storedRoundUpsShopperData).length)
        console.log('*** ** fetchRoundUpsShopperData: stringify(newRoundUpsShopperData): ', JSON.stringify(newRoundUpsShopperData));
        console.log('*** ** fetchRoundUpsShopperData: stringify(storedRoundUpsShopperData): ', JSON.stringify(storedRoundUpsShopperData));
          console.log('*** ** fetchRoundUpsShopperData: DISPATCH SET roundUpsUserData with newRoundUpsShopperData: ', newRoundUpsShopperData);
          dispatch(RoundUpsActions.setRoundUpsShopperData(newRoundUpsShopperData));
      }
      //   } else {
      //   console.log('*** ** fetchRoundUpsShopperData: newRoundUpsShopperData and storedRoundUpsShopperData are the same - NO STORE UPDATE REQD');
      // }
    } catch (exception) {
      console.log(exception);
      log({
        event: "System-Error",
        source: "Fetch shopperData",
        //'shopper_id': authentication? authentication.user.shopper_id : '000',
        info: exception,
        ...exception,
      });
    } finally {
      isFetchingRoundUpsShopperDataRef.current = false;
      console.log('*** ** fetchRoundUpsShopperData: isFetchingRoundUpsShopperDataRef is true - lets set isFetchingRoundUpsShopperDataRef to false.')
      //await dispatch(RoundUpsActions.isFetchingRoundUpsShopperData(false));
      hasFetchedRoundUpsShopperDataRef.current = true;
    }
  }, [ isFetchingRoundUpsShopperDataRef]); 

  function shouldUpdateStore(newRoundUpsShopperData, storedRoundUpsShopperData) {
    if (!storedRoundUpsShopperData) { 
      console.log('*** ** fetchRoundUpsShopperData: storedRoundUpsShopperData is null shouldUpdateStore : TRUE' );
      return true; // Always update if stored data is initially null
    }

        // Check if any key other than 'updatedAt' or 'updated_at' in linkedAccounts has changed
    for (const key in storedRoundUpsShopperData) {
      console.log('*** ** fetchRoundUpsShopperData: key:', key);
      if (newRoundUpsShopperData.hasOwnProperty(key)
          && key !== "updatedAt"
          && !isEqual(storedRoundUpsShopperData[key], newRoundUpsShopperData[key])) {
        
        if (key === "linkedAccounts") {
          console.log('*** ** fetchRoundUpsShopperData: checking linkedAccounts key: ', key);
          const storedAccounts = storedRoundUpsShopperData.linkedAccounts || [];
          const newAccounts = newRoundUpsShopperData.linkedAccounts || [];

          if (storedAccounts.length !== newAccounts.length) {
            console.log('*** ** fetchRoundUpsShopperData: linkedAccounts length mismatch. set shouldUpdateStore to true.');
            return true;
          }

          for (let i = 0; i < storedAccounts.length; i++) {
            const storedAccount = storedAccounts[i];
            const newAccount = newAccounts[i];
            for (const accountKey in storedAccount) {
              if (accountKey !== "updated_at" && !isEqual(storedAccount[accountKey], newAccount[accountKey])) {
                console.log('*** ** fetchRoundUpsShopperData: DIFFERENT KEY in LinkedAccounts THAT IS NOT updated_at FOUND IS : ',key,' linkedAccounts. set shouldUpdateStore to true.');
                return true;
              }
            }
          }
        } else {
          console.log('*** ** fetchRoundUpsShopperData: DIFFERENT KEY FOUND: ',key, ' set shouldUpdateStore to true.');
          return true;
        }
      }
    }

    // Check for new properties in newRoundUpsShopperData
    for (const key in newRoundUpsShopperData) {
      if (!storedRoundUpsShopperData.hasOwnProperty(key)) {
        console.log('*** ** fetchRoundUpsShopperData: NEW KEY FOUND: ',key,' set shouldUpdateStore to true.');
        return true;
      }
    }

    return false; // No differences found
  }


  useEffect(() => {
    console.log('*** ** fetchRoundUpsShopperData: useEffect triggered - with isFetchingRoundUpsShopperDataRef:', isFetchingRoundUpsShopperDataRef.current);
    console.log('*** ** fetchRoundUpsShopperData: useEffect triggered - with hasFetchedRoundUpsShopperDataRef:', hasFetchedRoundUpsShopperDataRef.current);
    if (!isFetchingRoundUpsShopperDataRef.current && !hasFetchedRoundUpsShopperDataRef.current) {
      console.log('*** ** fetchRoundUpsShopperData: useEffect triggered - with conditions correct to fetch data.');
      fetchRoundUpsShopperData();
    }
  }, []);

  return { 
            //isFetchingRoundUpsShopperDataRef: isFetchingRoundUpsShopperDataRef.current, 
            //hasFetchedRoundUpsShopperDataRef: hasFetchedRoundUpsShopperDataRef.current, 
            fetchRoundUpsShopperData 
          };
};

export default useFetchRoundUpsShopperData;
