export default ({ theme,valuePropTextBackgroundColor,unsetCardImageHeight }) => {
  return `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${theme.primaryColor};
    /* padding: 20px; */
    margin: 10px;
    /* box-shadow: 0 0 10px 0 rgba(0,0,0,0.1); */
    background-color: ${theme.neutralColor};
    box-shadow: rgb(199 199 199) 4px 1px 12px;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;

    &:hover {
      transition: all .3s ease-in-out;
      box-shadow: 0 0 45px -3px hsla(0,0%,55%,.75);
      transform: scale(1.01);
    }


    .dashboard-card-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;

      .value-prop {
        position: absolute;
        top: 195px;
        left: 10px;
        background: ${valuePropTextBackgroundColor ? valuePropTextBackgroundColor : theme.alternateAccentColor};
        padding: 5px;
        font-weight: bolder;
        font-size: 12px;
        text-transform: uppercase;
        color: ${valuePropTextBackgroundColor ? "white" : "black" };
      }

      .image-container {
        height: ${unsetCardImageHeight ? "unset" : "240px"};
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        overflow: hidden;
      }

      .card-details{
        display: flex;
        flex-direction: column;
        width: 100%;


        .card-info-container {
          display: flex;
          flex-direction: column;
          padding:12px 24px;
          border-top: solid 1px #d9d9d9;;

          .title-and-nav-button {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .title {
              font-size: 20px;
              font-weight: bold;
              /* color: ${theme.secondaryTextColor}; */
              margin: 0;
              display: flex;
              align-content: flex-start;
              flex-wrap: wrap;
            }
            .nav-buttons {
              margin: 0;
              display: flex;
              justify-content: space-between;
              align-items: center;

              .material-icons {
                font-size: 16px;
                cursor: pointer;
              }
            }

          }
          .explanation {
            font-size: 14px;
            /* color: ${theme.secondaryTextColor}; */
            margin: 0;
          }
        }
      }

    }

    

    `;
  }
  