export default () => `
  .dialog-app-bar {
    background-color: #fff !important;

    .toolbar {
      justify-content: space-between;
    }
  }

  .dialog-content {
    padding: 0 !important;

    &.full-width {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }
`;
