import * as TransactionsActionTypes from './constants';

const initialState = {
  page: 1,
  count: 0,
  items: [],
  perPage: 2000,
  cashBackSummary: {},
};

const actionsMap = {
  [TransactionsActionTypes.ADD_TRANSACTIONS](state, { payload }) {
    return {
      ...state,
      payload,
    }
  },
  [TransactionsActionTypes.SET_TRANSACTION_SUMMARY](state, action) {
    return {
      ...state,
      cashBackSummary: action.payload,
    };
  },
  [TransactionsActionTypes.REMOVE_TRANSACTIONS]() {
    return {
      ...state,
      page: 1,
      count: 0,
      items: [],
    }
  },
  [TransactionsActionTypes.SET_TRANSACTIONS](state, { payload }) {
    return {
      ...state, ...payload
    }
  }
}

export default function items(state = initialState, action) {
  console.log(['TRANS reducer initiated with actionauthenticating function in reducer action: ' , action]);
  console.log(['TRANS reducer about to update state currently: ', state ]);
  const reduceFn = actionsMap[action.type];
  if (!reduceFn) {
    console.log ('NO ACTION FOUND FOR TRANS REDUCER. ACTION was : ', action);
    return state;
  }
  return reduceFn(state, action);
}
