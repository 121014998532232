import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Spinner from 'react-spinner-material';
import { isChrome, isMobile } from 'react-device-detect';


import ShareStrip from 'SharedComponents/share-strip';
import SimplePopover from 'SharedComponents/popover';
import PopoverHover from 'SharedComponents/popover-hover';
import ContributeNowButton from 'SharedComponents/contribute-now-button';
//import icons from '@material-ui/icons';

import IconButton from 'SharedComponents/icon-button';

import Deal from 'SharedComponents/deal';
import StoreDetails from 'SharedComponents/store-details/index.js';

import styles from './index.css.js';

const RightDrawerWrapper = styled.div`${styles}`;

const RightDrawer = ({
  onClose, open, sharedItem, onSetSharedItem, onShopNow, onSearchTermChanged, deals, authentication, hasFetchedStoreTags,
}) => {
  const [currentSharedItem, setCurrentSharedItem] = useState(sharedItem);

  console.log('sharedItem in Right Drawer V2 is ', sharedItem);
  //console.log('isFetchingSharedItem in Right Drawer V2 is ', isFetchingSharedItem);

  const { user, collaboratingCauses, collaborators } = authentication;

  let ownedCause;

  if (authentication.user) {
    ownedCause = collaboratingCauses.find((cause) => cause.charity_id === user.charity_id);
  }

  useEffect(() => {
    console.log('useEffect triggered in Right Drawer with sharedItem : ', sharedItem);
    if (currentSharedItem !== sharedItem) {
      setCurrentSharedItem(sharedItem);


    }
  },[sharedItem]);

  //this section is sometimes showing a spinner in tiny section of right screen when right
  //drawer is closed.
  if (!sharedItem && open) {
    return (
      <RightDrawerWrapper open={open}>
        <div className="contents" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ margin: '10px' }}>Please wait...</div>
          <Spinner spinnerColor="#000000" size={20} spinnerWidth={3} />
        </div>
      </RightDrawerWrapper>
    );
  }

  if (!sharedItem) {
    return null;
  }

  const { item, type } = sharedItem;
  const url = `${window.location.origin}/shop?tab=${type === 'store' ? 'stores' : 'deals'}&${type === 'store' ? `retail_store_id=${item.retail_store_id}` : `coupon_id=${item.coupon_id}`}&shared=true&referrer=${ownedCause ? ownedCause.referrer_hash : null}`;

  const headerText = item.merchant_name;

  const storeDeals = deals.filter(
    deal => deal.retail_store_id === item.retail_store_id
  );
  const tipsHeaderText = 'Tips to ensure you boost successfully';

  const afterYouShopHeaderText = 'What happens after I shop?';


  const afterYouShopInfo = (
    <div className="tips-and-info-content">
      <div className="info">
        <div style={{
          width: 'calc(100% - 20px)', padding: 10, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left',
        }}
        >
          <h3 style={{ textAlign: 'left', alignSelf: 'start', margin: '20px 0 10px' }}>Notification:</h3>
          <div style={{
            width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
          }}
          >
            <i className="material-icons" style={{ color: '#47E6B1' }}>check_circle</i>
            <span>Stores usually confirm purchases within 24 hrs but can be up to 5 days</span>
          </div>
          <div style={{
            width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
          }}
          >
            <i className="material-icons" style={{ color: '#47E6B1' }}>check_circle</i>
            <span>We notify you via email and add your purchase to your account</span>
          </div>
          <div style={{
            width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
          }}
          >
            <i className="material-icons" style={{ color: '#47E6B1' }}>check_circle</i>
            <span>If your purchase is not in your acct after 5 days email us and we will look into it</span>
          </div>
        </div>
    </div>

    <div className="info">
        <div style={{
          width: 'calc(100% - 20px)', padding: 10, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left',
        }}
        >
          <h3 style={{ textAlign: 'left', alignSelf: 'start', margin: '20px 0 10px' }}>Approval:</h3>
          <div style={{
            width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
          }}
          >
            <i className="material-icons" style={{ color: '#47E6B1' }}>check_circle</i>
            <span>Stores normally wait 30 days to approve payment to allow for cancellations/returns</span>
          </div>
          <div style={{
            width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
          }}
          >
            <i className="material-icons" style={{ color: '#47E6B1' }}>check_circle</i>
            <span>Period can be longer eg travel must be consumed before approval</span>
          </div>
          <div style={{
            width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
          }}
          >
            <i className="material-icons" style={{ color: '#47E6B1' }}>check_circle</i>
            <span>We notify you via email when approved</span>
          </div>
        </div>
    </div>

    <div className="info">
        <div style={{
          width: 'calc(100% - 20px)', padding: 10, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left',
        }}
        >
          <h3 style={{ textAlign: 'left', alignSelf: 'start', margin: '20px 0 10px' }}>Payment:</h3>
          <div style={{
            width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
          }}
          >
            <i className="material-icons" style={{ color: '#47E6B1' }}>check_circle</i>
            <span>Once approved stores pay us in thier next payment cycle</span>
          </div>
          <div style={{
            width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
          }}
          >
            <i className="material-icons" style={{ color: '#47E6B1' }}>check_circle</i>
            <span>Payment cycles vary from store to store and can be 1-6 weeks</span>
          </div>
          <div style={{
            width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
          }}
          >
            <i className="material-icons" style={{ color: '#47E6B1' }}>check_circle</i>
            <span>Once we reconcile payments in our account we make BPAY payments weekly</span>
          </div>
          <div style={{
            width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
          }}
          >
            <i className="material-icons" style={{ color: '#47E6B1' }}>check_circle</i>
            <span>Payments to BSB accounts are done monthly</span>
          </div>
          <div style={{
            width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
          }}
          >
            <i className="material-icons" style={{ color: '#47E6B1' }}>check_circle</i>
            <span>We notify you via email when we have made a payment</span>
          </div>

        </div>
    </div>

    <div className="info">
        <div style={{
          width: 'calc(100% - 20px)', padding: 10, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left',
        }}
        >
          <h3 style={{ textAlign: 'left', alignSelf: 'start', margin: '20px 0 10px' }}>Growth:</h3>
          <div style={{
            width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
          }}
          >
            <i className="material-icons" style={{ color: '#47E6B1' }}>check_circle</i>
            <span>Once your super account has received funds they can grow</span>
          </div>
          <div style={{
            width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
          }}
          >
            <i className="material-icons" style={{ color: '#47E6B1' }}>check_circle</i>
            <span>The amounts can earn interest AND compound with the rest of your super</span>
          </div>
          <div style={{
            width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
          }}
          >
            <i className="material-icons" style={{ color: '#47E6B1' }}>check_circle</i>
            <span>Compound effect means it can help money already in your super grow faster</span>
          </div>
        </div>
    </div>
  </div>

  );
  
  const popOverTips = (
    //<div className="contents">
              <div className="tips-and-info-content">
                <div className="tips">
                  <div style={{
                    width: 'calc(100% - 20px)', padding: 10, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left',
                  }}
                  >
                    <h3 style={{ textAlign: 'left', alignSelf: 'start', margin: '20px 0 10px' }}>Tips to ensure you boost successfully:</h3>
                    <div style={{
                      width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
                    }}
                    >
                      <i className="material-icons" style={{ color: '#47E6B1' }}>check_circle</i>
                      <span>If installed <strong>disable ad blocking software</strong> entirely while shopping</span>
                    </div>
                    <div style={{
                      width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
                    }}
                    >
                      <i className="material-icons" style={{ color: '#47E6B1' }}>check_circle</i>
                      <span>Ensure cart is empty before clicking through</span>
                    </div>
                    <div style={{
                      width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
                    }}
                    >
                      <i className="material-icons" style={{ color: '#47E6B1' }}>check_circle</i>
                      <span>Click through again if there's any error</span>
                    </div>
                    <div style={{
                      width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
                    }}
                    >
                      <i className="material-icons" style={{ color: '#47E6B1' }}>check_circle</i>
                      <span>Click through each time you make a new purchase</span>
                    </div>
                  </div>
                </div>
                <div className="info">
                  <div style={{
                    width: 'calc(100% - 20px)', padding: 10, display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left',
                  }}
                  >
                    <h3 style={{ textAlign: 'left', alignSelf: 'start', margin: '20px 0 10px' }}>Boost may not work if:</h3>
                    <div style={{
                      width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
                    }}
                    >
                      <i className="material-icons" style={{ color: '#FF6961' }}>remove_circle</i>
                      <span>Click to store from other site eg Google after clicking from here</span>
                    </div>
                    <div style={{
                      width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
                    }}
                    >
                      <i className="material-icons" style={{ color: '#FF6961' }}>remove_circle</i>
                      <span>Items added to cart before clicking through</span>
                    </div>
                    <div style={{
                      width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
                    }}
                    >
                      <i className="material-icons" style={{ color: '#FF6961' }}>remove_circle</i>
                      <span>Returns, exchanges or cancellations</span>
                    </div>
                    <div style={{
                      width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
                    }}
                    >
                      <i className="material-icons" style={{ color: '#FF6961' }}>remove_circle</i>
                      <span>Purchase Gift Cards or purchases using Gift Cards</span>
                    </div>
                    <div style={{
                      width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
                    }}
                    >
                      <i className="material-icons" style={{ color: '#FF6961' }}>remove_circle</i>
                      <span>Promo codes or coupons not sourced from Boost Your Super</span>
                    </div>
                  </div>
                </div>
              </div>
    //  </div>       
  );

  return (
    <RightDrawerWrapper open={open}>
      <div className="header">
        <span className="header-text">{headerText}</span>
        <div className="header-close-icon-container">
          <IconButton onClick={onClose}>
            <i className="material-icons">close</i>
          </IconButton>
        </div>
      </div>
      <div className="contents">
        <div style={{ margin: '-10px' }}>
          <ShareStrip
            url={url}
            title={`${item.rate} for your super at ${item.merchant_name}`}
            text={type === 'store' ? `${item.merchant_description}` : `${item.description}`}
            buttonText="Share Now"
            headerText={type === 'store' ? 'Share this store' : 'Share this offer'}
          />
        </div>
        <br />
        {
          sharedItem.type === 'store' ? (
            <div className="store">
              <StoreDetails
                storeLogo = {item.retail_store_logo_url}
                storeBannerUrl = {item.retail_store_banner_url}
                retailName = {item.merchant_name}
                dealsCount = {item.deals_count}
                storeSummary = {item.summary}
                storeRate = {item.rate}
                onShopNow = {onShopNow}
                onSearchTermChanged = {onSearchTermChanged}
                item = {item}
                onClose = {onClose}
                hasFetchedStoreTags = {hasFetchedStoreTags}
              />
           <div className="shopping-faqs-container">   
              <div className="shopping-info-container">
                <div className="how-to-shop-item-container">
                  <div className="how-to-shop-text-container">
                    <span>{ afterYouShopHeaderText }</span>
                  </div>
                  <div className="pop-over-container">
                    <PopoverHover
                      content={ afterYouShopInfo }
                      header = {null}
                      img = {null}
                      itemToPopover = {
                        ( <i className="material-icons" style={{ margin: '0 10px 0 10px', display: 'flex' }} >info</i> )
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="shopping-info-container">
                <div className="how-to-shop-item-container">
                  <div className="how-to-shop-text-container">
                    <span>{ tipsHeaderText }</span>
                  </div>
                  <div className="pop-over-container">
                    <PopoverHover
                      content={ popOverTips }
                      header = {null}
                      img = {null}
                      itemToPopover = {
                        ( <i className="material-icons" style={{ margin: '0 10px 0 10px', display: 'flex' }} >info</i> ) 
                      }
                    />
                  </div>
                </div>
              </div>

            </div>


              <div className="shop-and-contribute-container">
                <div className="shop-now-container">
                  <button type="button" className="shop-now large" onClick={() => onShopNow(item, 'shop')}>Shop&nbsp;Now</button>
                </div>
                <div className="contribute-now-holder">
                  <ContributeNowButton
                    contributeAndShop={true}
                    largeButton={true}
                    onShopNow={onShopNow}
                    onShopNowType={'shop'}
                    storeDetails={item}
                  />
                </div>
              </div>
              {
                storeDeals.length ? (
                //currentSharedItemDeals.length ? (
                  <div className="active-deals">
                    <h3 className="header" ><span>{`${storeDeals.length} ${parseInt(storeDeals.length) === 1 ? 'Active Offer' : 'Active Offers'}`}</span></h3>
                    <div className="offers-container">
                      {
                        storeDeals.map((deal) => (
                        //currentSharedItemDeals.map((deal) => (
                          <Deal
                            deal={{
                              ...deal, merchant_name: item.merchant_name, retail_store_logo_url: item.retail_store_logo_url, retail_store_id: item.retail_store_id,
                            }}
                            key={deal.coupon_id}
                            onShopNow={onShopNow}
                            onSetSharedItem={onSetSharedItem}
                            sharedItem={sharedItem}
                            showShareButton={false}
                            dealWidthMobile='75vw'
                            disableDisplayTandCs={true}
                          />
                        ))
                      }
                    </div>
                  </div>
                ) : null
              }
            </div>         
          ) : (
            <div className="store">
              <p>On average it takes 1-2 days for stores to confirm the amount you have raised once you have completed your shopping. We will notify you via email as soon as we know the amount raised. If you make a purchase and have not received an email from us within 5 business days - please let us know so we can chase it up for you.</p>
              <div className="active-deals">
                <Deal deal={item} onShopNow={onShopNow} showShareButton={false} />
              </div>
              <div className="info-heading-text">
                <h3>
                  <SimplePopover
                    content={ afterYouShopInfo }
                    header = {null}
                    img = {null}
                    textToPopOver = { afterYouShopHeaderText }
                  />
                 </h3>
              </div>
              <div className="info-heading-text">
                <h3>
                  <SimplePopover
                    content={ popOverTips }
                    header = {null}
                    img = {null}
                    textToPopOver = { tipsHeaderText }
                  />
                 </h3>
              </div>
            </div>
          )
        }
      </div>
    </RightDrawerWrapper>
  );
};

export default connect((state) => ({ authentication: state.authentication }))(RightDrawer);
