import React from 'react';
import styled from 'styled-components';

import styles from './testimonial.css';

import joan from 'Images/joan.jpg';

const TestimonialWrapper = styled.div`${styles}`;

const Testimonial = (props) => {
  return (
    <TestimonialWrapper>
      <div className="person">
        <div className="avatar">
          <img src={joan} />
        </div>
        <span>Boosted Super by $1500</span>
      </div>
      <div className="body">
        <div className="header">
          <h4>Joan has been using Boost Your Super since July 2019</h4>
          <small>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</small>
        </div>
        <div className="top-tip">
          <span>Joan's Top Tip</span>
          <small>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.</small>
        </div>
      </div>
    </TestimonialWrapper>
  );
}

export default Testimonial;
