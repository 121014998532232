export default ({ theme }) => `
  width: fit-contents;
  background-color: ${theme.neutralColor};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 10px 0;
  border-radius: 8px;

  .menu-item {
    width: 100%;
    padding: 10px;
    color: ${theme.secondaryColor};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: ${theme.neutralColor};
    border: none;
    outline: none;
    font-size: 15px;
    cursor: pointer;

    &:hover {
      background-color: ${theme.lightBorderColor};
    }
  }
`;
