export function extractInitials(string) {
	return string
		.split(' ')
		.map((segment) => `${segment[0]}`)
		.join('')
		.toUpperCase()
		.replace(/[^a-z0-9+]+/gi, '')
		.slice(0, 2);
}

export function camelCaseToSentenseCase(str) {
	const result = str.replace(/([A-Z])/g, ' $1');
	return result.charAt(0).toUpperCase() + result.slice(1);
}

export function sleep(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}
