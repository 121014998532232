import React, { useState, useEffect } from 'react';
import numeral from 'numeral';
import { useSelector } from 'react-redux';
import { LineChart, Line, Sector, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList, Text } from 'recharts';
import Spinner from 'react-spinner-material';
import styled from 'styled-components';

import PopoverHover from 'SharedComponents/popover-hover';

import styles from './index.css';

const GraphCalculatorGrowthChartWrapper = styled.div`${styles}`;

const GraphCalculatorGrowthChart = ({data}) => {

  console.log ("GraphCalculatorGrowthChart component triggered with table data: ", data);

  const WrappedText = styled(Text)`
  white-space: pre-wrap;
  text-align: center;
`;

function CustomizedTick({ x, y, payload }) {
  return (
    <WrappedText x={x} y={y} dy={16} textAnchor="middle" fill="#666" fontSize={10} fontWeight="bold">
      {payload.value}
    </WrappedText>
  );
}

  const totalAmounts = (data) => {
    const dataArray = Array.isArray(data) ? data : data?.data || [];
    return dataArray.reduce((acc, entry) => acc + entry.value, 0);
  };

  const cellColors = ['#cc9400','#eead00','#febe11','#ffc732','#fed155','#ffda76','#ffe399','#ffecbb','#fff5dd','#fed155','#ffcd47','#ffc93a','#ffc62d','#ffc220','#febf13','#ffbb06','#f8b500','#ebab00','#dea200']; 


  const chartData = Array.isArray(data) ? data : data.data;

  return (
    <GraphCalculatorGrowthChartWrapper>
      <div className="totals-label-container">
        TOTAL:&nbsp;{ numeral(totalAmounts(data)).format('$0,0.00')}
      </div>

      <ResponsiveContainer width="100%" height="100%"> 
        <LineChart data={data} className="bar-chart-holder">
          <XAxis 
            dataKey="age"
            //interval={0}
            //tick={<CustomizedTick />}
          /> 
          <YAxis />
{/*           <Tooltip 
            formatter={(value) => {
              if (value === 0) {
                return '$0.00'; 
              } else {
                return numeral(value).format('$0,0.00');
              }
            }}
          /> */}
          <Line
            dataKey="balanceNoBoost"
            stroke="#8884d8"
            type="monotone"
            dot={false}
          />
          <Line
            dataKey="balanceTotalBoost"
            stroke="#82ca9d"
            type="monotone"
            dot={false}
          />
{/*             <LabelList
              dataKey="value"
              type="monotone" 
              formatter={(value) => {
                if (value === 0) {
                  return '$0.00'; 
                } else {
                  return numeral(value).format('$0,0.00');
                }
              }}
            /> */}
            {/* {
              chartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={cellColors[index % cellColors.length]} />
              ))
            } */}
          
        </LineChart>
      </ResponsiveContainer>
    </GraphCalculatorGrowthChartWrapper>
  );
};

export default GraphCalculatorGrowthChart;
