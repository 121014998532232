import PropTypes from 'prop-types';
import React, { useState, useEffect, useMemo, useCallback, useRef, memo } from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import styles from './index.css';

import PopoverHover from 'SharedComponents/popover-hover';

import carbonOffSetAvailable5 from 'Images/carbonOffSetAvailable5.svg';
import carbonNeutral4 from 'Images/carbonNeutral4.svg';
import carbonNeutral from 'Images/carbonNeutral.svg';
import giftIcon5 from 'Images/giftIcon5.svg';
import christmasIcon6 from 'Images/christmasIcon6.svg';
import shoesIcon1 from 'Images/shoesIcon1.svg';
import shoesIcon7 from 'Images/shoesIcon7.svg';
import dressIcon2 from 'Images/dressIcon2.svg';
import fashionIcon3 from 'Images/fashionIcon3.svg';
import jeansIcon2 from 'Images/jeansIcon2.svg';
import dressIcon3 from 'Images/dressIcon3.svg';
import pantsIcon1 from 'Images/pantsIcon1.svg';
import teesIcon1 from 'Images/teesIcon4.svg';
import blackFridayIcon from 'Images/blackFridayIcon.svg';
import activeWearIcon4 from 'Images/activeWearIcon4.svg';

const StoreTagsWrapper = styled.div`${styles}`;

function shuffleArray(array) {
  const shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
}

const StoreTags = ({ storeTags, onSearchTermChanged, onClose, maxRows, hasFetchedStoreTags }) => {

  console.log('storeTags component triggered with storeTags: ', storeTags);
  //const initialTagRowRefs = useMemo(() => Array(maxRows).fill(null).map(() => useRef(null)), [maxRows]);
  const initialTagRowRefs = Array(maxRows).fill(null).map(() => useRef(null));

  
  const altAccentColor = useSelector(state => {
    if (state.app && state.app.partnerContent) {
      return state.app.partnerContent.alternate_accent_color || 'black';
    }
    return 'black'; // Default if partnerContent is null or undefined
  });

  console.log ('tags altAccentColor: ', altAccentColor);

  if (typeof storeTags === 'string') {
    storeTags = [storeTags];
  } else if (!Array.isArray(storeTags)) {
    storeTags = [];
  }
  
  const highlightTagList = [
    {
      tagName : 'Valentine', 
      tagTextColor: '#000000', 
      tagBackgroundColor: '#f0505c',
      tagIcon : 'favorite',
      isMaterialIcon: true,
      tagToolTip : null,
      priority: isMobile ? 1 : 2,
    },
    {
      tagName : 'Black Friday', 
      tagTextColor: altAccentColor,//'#ffba00', //altAccentColor, //'#ffba00',
      tagBackgroundColor: '#000000',
      tagIcon : null,
      isMaterialIcon: true,
      tagToolTip : null,
      priority: isMobile ? 1 : 2,
    },
    {
      tagName : 'Mothers Day', 
      tagTextColor: '#000000', 
      tagBackgroundColor: '#f0505c',
      tagIcon : 'filter_vintage',
      isMaterialIcon: true,
      tagToolTip : null,
      priority: isMobile ? 1 : 2,
    },
    {
      tagName : 'Fathers Day', 
      tagTextColor: '#000000', 
      tagBackgroundColor: '#3d72ff',
      tagIcon : 'emoji_events',
      isMaterialIcon: true,
      tagToolTip : null,
      priority: isMobile ? 1 : 2,
    },
    {
      tagName : 'CO2 Offset Avail', 
      tagTextColor: '#000000', 
      tagBackgroundColor: '#84ff89',
      tagIcon : carbonOffSetAvailable5,
      isMaterialIcon: false,
      tagToolTip : 'Store offers a carbon offset program that you can use towards offsetting your carbon footprint.',
      priority: null,
    },
    {
      tagName : 'Carbon Neutral', 
      tagTextColor: '#000000', 
      tagBackgroundColor: '#84ff89',
      tagIcon : carbonNeutral,
      isMaterialIcon: false,
      tagToolTip: 'Store is certified as Carbon Neutral',
      priority: null,
    },
    {
      tagName : 'Recycled Materials', 
      tagTextColor: '#000000', 
      tagBackgroundColor: '#84ff89',
      tagIcon : 'recycling',
      isMaterialIcon: true,
      tagToolTip: 'Products made from recycled materials',
      priority: null,
    },
    {
      tagName : 'Recycled Packaging', 
      tagTextColor: '#000000', 
      tagBackgroundColor: '#84ff89',
      tagIcon : 'recycling',
      isMaterialIcon: true,
      tagToolTip: 'Products delivered in packaging that can be recycled',
      priority: null,
    },
    {
      tagName : 'Climate Active', 
      tagTextColor: '#000000', 
      tagBackgroundColor: '#84ff89',
      tagIcon : 'verified',
      isMaterialIcon: true,
      tagToolTip : 'Store is Climate Active Certified. Climate Active certification is government backed and awarded to organisations that have credibly reached a state of carbon neutrality against the requirements of the Climate Active Carbon Neutral Standard.',
      priority: null,
    },
    {
      tagName : 'gifts', 
      tagTextColor: '#FFFFFF', 
      tagBackgroundColor: '#000000',
      tagIcon : giftIcon5,
      isMaterialIcon: false,
      tagToolTip : null,
      priority: null,
    },
    {
      tagName : 'Christmas decorations', 
      tagTextColor: '#000000', 
      tagBackgroundColor: '#FFFFFF',
      tagIcon : christmasIcon6,
      isMaterialIcon: false,
      tagToolTip : null,
      priority: 3,
    },
    {
      tagName : 'Shoes', 
      tagTextColor: '#FFFFFF', 
      tagBackgroundColor: '#000000',
      tagIcon : shoesIcon7,
      isMaterialIcon: false,
      tagToolTip : null,
      priority: null,
    },
    {
      tagName : 'Dress', 
      tagTextColor: '#FFFFFF', 
      tagBackgroundColor: '#000000',
      tagIcon : dressIcon3,
      isMaterialIcon: false,
      tagToolTip : null,
      priority: null,
    },
    {
      tagName : 'fashion', 
      tagTextColor: '#FFFFFF', 
      tagBackgroundColor: '#000000',
      tagIcon : fashionIcon3,
      isMaterialIcon: false,
      tagToolTip : null,
      priority: null,
    },
    {
      tagName : 'jeans', 
      tagTextColor: '#FFFFFF', 
      tagBackgroundColor: '#000000',
      tagIcon : pantsIcon1,
      isMaterialIcon: false,
      tagToolTip : null,
      priority: null,
    },
    {
      tagName : 'tees', 
      tagTextColor: '#FFFFFF', 
      tagBackgroundColor: '#000000',
      tagIcon : teesIcon1,
      isMaterialIcon: false,
      tagToolTip : null,
      priority: null,
    },
    {
      tagName : 'activewear', 
      tagTextColor: '#FFFFFF', 
      tagBackgroundColor: '#000000',
      tagIcon : activeWearIcon4,
      isMaterialIcon: false,
      tagToolTip : null,
      priority: null,
    },  
    {
      tagName : 'beauty', 
      tagTextColor: '#FFFFFF', 
      tagBackgroundColor: '#000000',
      tagIcon : 'spa',
      isMaterialIcon: true,
      tagToolTip : null,
      priority: null,
    },
    {
      tagName : 'Baby', 
      tagTextColor: '#FFFFFF', 
      tagBackgroundColor: '#000000',
      tagIcon : 'child_care',
      isMaterialIcon: true,
      tagToolTip : null,
      priority: null,
    },
    {
      tagName : 'Flowers', 
      tagTextColor: '#FFFFFF', 
      tagBackgroundColor: '#000000',
      tagIcon : 'local_florist',
      isMaterialIcon: true,
      tagToolTip : null,
      priority: null,
    },
    {
      tagName : 'Pets', 
      tagTextColor: '#FFFFFF', 
      tagBackgroundColor: '#000000',
      tagIcon : 'pets',
      isMaterialIcon: true,
      tagToolTip : null,
      priority: null,
    },
    {
      tagName : 'Drinks', 
      tagTextColor: '#FFFFFF', 
      tagBackgroundColor: '#000000',
      tagIcon : 'local_bar',
      isMaterialIcon: true,
      tagToolTip : null,
      priority: null,
    },
    {
      tagName : 'Toys', 
      tagTextColor: '#FFFFFF', 
      tagBackgroundColor: '#000000',
      tagIcon : 'toys',
      isMaterialIcon: true,
      tagToolTip : null,
      priority: null,
    },
    {
      tagName : 'travel',
      tagTextColor: '#FFFFFF', 
      tagBackgroundColor: '#000000',
      tagIcon : 'flight',
      isMaterialIcon: true,
      tagToolTip : null,
      priority: null,
    },
    {
      tagName : 'Food',
      tagTextColor: '#FFFFFF', 
      tagBackgroundColor: '#000000',
      tagIcon : 'restaurant',
      isMaterialIcon: true,
      priority: null,
    },
    {
      tagName : 'sports',
      tagTextColor: '#FFFFFF',  
      tagBackgroundColor: '#000000',
      tagIcon : 'directions_run',
      isMaterialIcon: true,
      tagToolTip : null,
      priority: null,
    },
    {
      tagName : 'Maternity', 
      tagTextColor: '#FFFFFF', 
      tagBackgroundColor: '#000000',
      tagIcon : 'pregnant_woman',
      isMaterialIcon: true,
      tagToolTip : null,
      priority: null,
    },
    {
      tagName : 'School',
      tagTextColor: '#FFFFFF', 
      tagBackgroundColor: '#000000',
      tagIcon : 'school',
      isMaterialIcon: true,
      tagToolTip : null,
      priority: null,
    },
    
  ]


  
  
  const [shuffledStoreTags, setShuffledStoreTags] = useState(storeTags);
  const [tagRowRefs, setTagRowRefs] = useState(initialTagRowRefs);

  useEffect(() => {
    console.log('storeTags - useEffect - *** Main component mounted');

    if (storeTags?.length > 0) {
      const shuffledTags = shuffleArray(storeTags);
      console.log('storeTags - useEffect - shuffledTags: ', shuffledTags);
      //const shuffledTags = useMemo(() => shuffleArray(storeTags), [storeTags.length]);
      setShuffledStoreTags(shuffledTags);
    }

    return () => {
      console.log('storeTags - useEffect - *** Main component unmounted');
    };

  }, [storeTags]);


  const rows = useMemo(() => {
    
    
    if (!shuffledStoreTags || !Array.isArray(shuffledStoreTags) || shuffledStoreTags.length === 0) {
      return [];
    }

    const tagsPerRow = maxRows ? Math.ceil(shuffledStoreTags.length / maxRows) : shuffledStoreTags.length;
    const computedRows = [];
    for (let i = 0; i < maxRows; i++) {
      const startIndex = i * tagsPerRow;
      const endIndex = startIndex + tagsPerRow;
      computedRows.push(shuffledStoreTags.slice(startIndex, endIndex));
    }

    computedRows.forEach((row) => {
      for (let i = 0; i < row.length; i++) {
        const tag = row[i];
        const tagLower = tag.toLowerCase();
        const highlightTag = highlightTagList.find((highlightTag) => highlightTag.tagName.toLowerCase() === tagLower);
        if (highlightTag && highlightTag.priority !== null) {
          const currentIndex = row.indexOf(tag);
          if (currentIndex !== -1) {
            row.splice(currentIndex, 1);
            const desiredIndex = Math.min(Math.max(0, highlightTag.priority), row.length);
            row.splice(desiredIndex, 0, tag);
          }
        }
      }
    });

    return computedRows;
  }, [shuffledStoreTags, maxRows]);


  console.log('storeTags triggered with shuffledStoreTags: ', shuffledStoreTags);


  const handleTagClick = (tagText) => {
    console.log('handleTagClick triggered with tagText: ', tagText);
    const tagTextObject = {
      target: {
        value: tagText,
      },
    };

    onSearchTermChanged(tagTextObject);
    if (isMobile) {
      if (typeof onClose === 'function') {
        onClose();
      }
    }
  };



  const [hideLeftButtons, setHideLeftButtons] = useState(Array(maxRows).fill(true));
  const [hideRightButtons, setHideRightButtons] = useState(Array(maxRows).fill(false));

  const scrollTags = (direction, rowIndex) => {
    const container = tagRowRefs[rowIndex].current;
    if (container) {
      const newScrollLeft = container.scrollLeft + direction * 200;
      const maxScrollLeft = container.scrollWidth - container.clientWidth;
      const clampedScrollLeft = Math.min(maxScrollLeft, Math.max(0, newScrollLeft));

      container.scrollTo({
        left: clampedScrollLeft,
        behavior: 'smooth',
      });
    }

    updateButtonVisibility(rowIndex);
    
  };

  const updateButtonVisibility = (rowIndex) => {
    const container = tagRowRefs[rowIndex].current;
    if (container) {
      const clampedScrollLeft = Math.min(
        container.scrollWidth - container.clientWidth,
        Math.max(0, container.scrollLeft)
      );

      const hideScrollLeftButton = clampedScrollLeft <= 0;
      const hideScrollRightButton = clampedScrollLeft >= container.scrollWidth - container.clientWidth;

      setHideLeftButtons((prevHideLeftButtons) => {
        const updatedHideLeftButtons = [...prevHideLeftButtons];
        updatedHideLeftButtons[rowIndex] = hideScrollLeftButton;
        return updatedHideLeftButtons;
      });

      setHideRightButtons((prevHideRightButtons) => {
        const updatedHideRightButtons = [...prevHideRightButtons];
        updatedHideRightButtons[rowIndex] = hideScrollRightButton;
        return updatedHideRightButtons;
      });
    }
  };

  useEffect(() => {
    if (isMobile) {
      tagRowRefs.forEach((ref, rowIndex) => {
        if (ref.current) {
          ref.current.addEventListener('scroll', () => {
            updateButtonVisibility(rowIndex);
          });
        }
      });
    }
  }, [isMobile]);

  return (
    <StoreTagsWrapper maxRows={maxRows}>
      <div className="tags-container">
        {maxRows
          ? rows.map((row, rowIndex) => (
              <div className="tag-row-container" key={rowIndex}>
                <div className="tag-row-nav">
                  <button className="button-tag-row-nav">
                    {!hideLeftButtons[rowIndex] && hasFetchedStoreTags &&
                    (
                      <i
                        className="material-icons"
                        onClick={() => scrollTags(-1, rowIndex)} 
                      >
                        navigate_before
                      </i>
                    )}
                  </button>
                </div>
                <div className="tag-row" ref={tagRowRefs[rowIndex]}>
                  <div className="tags">
                    {row.map((tag, index) => {
                      const tagLower = tag.toLowerCase();
                      const matchingHighlightTag = highlightTagList.find(
                        (highlightTag) => highlightTag.tagName.toLowerCase() === tagLower
                      );
                      return (
                        <button
                          key={index}
                          className="tag"
                          onClick={() => handleTagClick(tag)}
                          style={{
                            backgroundColor: matchingHighlightTag?.tagBackgroundColor || '#fff',
                            color: matchingHighlightTag?.tagTextColor || '#000',
                          }}
                        >
                            {matchingHighlightTag && (
                              matchingHighlightTag.isMaterialIcon ? (
                                <i className="material-icons">{matchingHighlightTag.tagIcon}</i>
                              ) : (
                                <img
                                  src={matchingHighlightTag.tagIcon}
                                  alt={matchingHighlightTag.tagName}
                                  className="tag-icon"
                                />
                              )
                            )}
                          {tag}
                          {matchingHighlightTag && matchingHighlightTag.tagToolTip && (
                              <PopoverHover
                              content={ 
                                <div style={{
                                  maxWidth: '300px',
                                  padding: 10,
                                  fontSize: '16px',
                                  fontWeight: 'bold',
                                  display: 'flex',
                                  alignItems: 'flex-start',
                                  justifyContent: 'flex-start',
                                  flexDirection: 'column',
                                  textAlign: 'left',
                                }}
                                >
                                {matchingHighlightTag.tagToolTip}
                                </div>
                              }
                              header = {null}
                              img = {null}
                              itemToPopover = {
                                ( <i className="material-icons" style={{ margin: '0 5px 0 5px', display: 'flex' }} >info</i> )
                              }
                            />
                          )  
                          }
                        </button>
                      );
                    })}
                  </div>
                </div>
                <div className="tag-row-nav">
                  <button className="button-tag-row-nav">
                    {!hideRightButtons[rowIndex] && hasFetchedStoreTags && (
                      <i
                        className="material-icons"
                        onClick={() => scrollTags(1, rowIndex)} // Scroll tags to the right
                      >
                        navigate_next
                      </i>
                    )}
                  </button>
                </div>
              </div>
            ))
          : (
            <div className="tag-row-container">
              <div className="tag-row">
                <div className="tags">
                  {hasFetchedStoreTags && storeTags.map((tag, index) => {
                      const tagLower = tag.toLowerCase(); // Make it case-insensitive
                      const matchingHighlightTag = highlightTagList.find(
                        (highlightTag) => highlightTag.tagName.toLowerCase() === tagLower
                      );
                      return (
                        <button
                          key={index}
                          className="tag"
                          onClick={() => handleTagClick(tag)}
                          style={{
                            backgroundColor: matchingHighlightTag?.tagBackgroundColor || '#fff',
                            color: matchingHighlightTag?.tagTextColor || '#000',
                          }}
                        >
                            {matchingHighlightTag && (
                              matchingHighlightTag.isMaterialIcon ? (
                                <i className="material-icons">{matchingHighlightTag.tagIcon}</i>
                              ) : (
                                <img
                                  src={matchingHighlightTag.tagIcon}
                                  alt={matchingHighlightTag.tagName}
                                  className="tag-icon"
                                />
                              )
                            )}
                          {tag}
                          {matchingHighlightTag && matchingHighlightTag.tagToolTip && (
                              <PopoverHover
                              content={ 
                                <div style={{
                                  maxWidth: '300px',
                                  padding: 10,
                                  fontSize: '16px',
                                  fontWeight: 'bold',
                                  display: 'flex',
                                  alignItems: 'flex-start',
                                  justifyContent: 'flex-start',
                                  flexDirection: 'column',
                                  textAlign: 'left',
                                }}
                                >
                                {matchingHighlightTag.tagToolTip}
                                </div>
                              }
                              header = {null}
                              img = {null}
                              itemToPopover = {
                                ( <i className="material-icons" style={{ margin: '0 5px 0 5px', display: 'flex' }} >info</i> )
                              }
                            />
                          )  
                          }

                        </button>
                      );
                    })}
                
                </div>
              </div>
            </div>
          )}
      </div>
    </StoreTagsWrapper>
  );
};

StoreTags.propTypes = {
  storeTags: PropTypes.array.isRequired,
  onSearchTermChanged: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  maxRows: PropTypes.number,
  hasFetchedStoreTags: PropTypes.bool.isRequired,
};

export default memo(StoreTags);



