import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Formsy from 'formsy-react';
import axios from 'axios';
import qs from 'qs';
import log from "Utils/logger";


import TextInput, { isRequired } from 'SharedComponents/text-input';
import ActionButton from 'SharedComponents/action-button';
import DropDownSelect from 'SharedComponents/dropdown-select';
import PopoverHover from 'SharedComponents/popover-hover';

import * as TransactionActions from 'Flux/transactions/actions';

import styles from './index.css';
const RaiseTransactionInquiryFormWrapper = styled(Formsy)`${styles}`;

@connect((state) => ({
	transactions: state.transactions,
	authentication: state.authentication
}), (dispatch) => ({
	TransactionActions: bindActionCreators(TransactionActions, dispatch),
	
}))
export default class RaiseTransactionInquiryForm extends Component {
	static contextTypes = {
		router: PropTypes.object.isRequired,
	}

	static propTypes = {
		onAfterSubmit: PropTypes.func,
	}

	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
		this.updateCommentValid = this.updateCommentValid.bind(this);
		this.onValid = this.onValid.bind(this);
		this.onInvalid = this.onInvalid.bind(this);
		this.onValidSubmit = this.onValidSubmit.bind(this);
		this.onInvalidSubmit = this.onInvalidSubmit.bind(this);
		this.onRaiseTransactionInquiry = this.onRaiseTransactionInquiry.bind(this);
	}

	state = {
		isFormValid: false,
		isCommentsValid: true,
		isSubmittingInquiry: false,
		error: false,
		message: '',
		commentsMessage: '',
		form: {
			ordernumber: '',
			amountspent:'',
			commentsfromcust: ''
		}
	}

	componentDidUpdate(prevProps,prevState){
		if (prevState.form.commentsfromcust !== this.state.form.commentsfromcust){
			console.log('Transaction Raise Inquiry form cDU called')
			this.updateCommentValid();
		}
	}


	onChange(event) {
		console.log('form change event is : ', event);
		console.log('this.state is: ', this.state)
    this.setState({ 
			form: Object.assign({},
				this.state.form, 
				{ [event.target.name]: event.target.value }) });
  }

	updateCommentValid (){
		this.state.form.commentsfromcust.match(/^[-\w\s\.\,\:\+\$\!\?\%\@\&]*$/)
		? this.setState({
				...this.state, 
				isCommentsValid: true,
				commentsMessage: ''
			})
		: this.setState({
				...this.state,
				isCommentsValid: false,
				commentsMessage: 'Character not allowed'
			})
	}

	async onRaiseTransactionInquiry() {
		const { uniqueID, authentication,onFetchCauseDonations } = this.props;
		const { TransactionActions } = this.props;
		const { form } = this.state;

		this.setState({
			isSubmittingInquiry: true,
			error: false,
			message: '',
		});

		try {
			const onRaiseTransactionInquiryResult = await TransactionActions.onRaiseTransactionInquiry({ 
				uniqueID: uniqueID,
				orderNumber: form.ordernumber,
				spentAmount: form.amountspent,
				commentsFromCust: form.commentsfromcust,
			});

			console.log('[onRaiseTransactionInquiryResult]', onRaiseTransactionInquiryResult);
			
			if (!onRaiseTransactionInquiryResult.data.status) {
				this.setState({
					error: true,
					message: onRaiseTransactionInquiryResult.data.error,
				}, () => {
					setTimeout(() => {
						this.setState({
							error: false,
							message: '',
						});
					}, 3000);
				});
			} else {
				this.setState({
					message: 'Inquiry raised successfully',
				});
				console.log('successfully raised Inquiry - lets trigger onFetchCauseDonations ')
				onFetchCauseDonations(1);

				if (this.props.onCloseDialog) {
					this.props.onCloseDialog();
				}

				if (this.props.onAfterRaiseTransactionInquiry) {
					this.props.onAfterRaiseTransactionInquiry();
				} else {
					this.context.router.history.push('/transactions');
				}
			}
		} catch(exception) {
			console.log(exception, null, '\t');
			this.setState({
				error: true,
				message: exception.message,
			});
			log({
				shopper_id: authentication.user ? authentication.user.shopper_id : "000",
        event: "System-Error",
        source: "Raise Transaction Inquiry - RaiseTransactionInquiryException", 
        info: exception,
        ...exception,
			});
		} finally {
			this.setState({ isSubmittingInquiry: false });
		}
	}

	onValid() {
		console.log('onValid triggered')
		this.setState({ isFormValid: true });
	}

	onInvalid() {
		console.log('oninvalid triggered')
		this.setState({ isFormValid: false });
	}

	onValidSubmit() {
		this.onRaiseTransactionInquiry();
	}

	onInvalidSubmit() {
		// TODO: show appropriate error messages
		console.log('onInvalidSubmit');
		console.log('ths.state is :', this.state);
		console.log('ths.state.form is :', this.state.form);
	}

	render() {
		const { labelColor, header, storeName } = this.props;

		return (
			<RaiseTransactionInquiryFormWrapper
				ref={(form) => this.raiseIncquiryForm = form}
				style={{ width: '100%' }}
				onValidSubmit={this.onValidSubmit}
				onInvalidSubmit={this.onInvalidSubmit}
				onValid={this.onValid}
				onInvalid={this.onInvalid}
			>
				{header}
				<div style={{ margin: '0', width: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
					{
						!this.state.isFormValid && this.state.onInvalidSubmit ? (
							<div style={{ width: 'calc(100% - 18px)', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10, backgroundColor: '#ff007d', color: '#fff' }}>
								Some fields are invalid. Please complete them then resubmit the form
							</div>
						) : null
					}
					{
						this.state.message ? (
							<span style={{ color: this.state.error ? '#ff007d' : '##03031', fontSize: 20 }}>{this.state.message}</span>
						) : null
					}
				</div>
				<div className="form-entry">
					<div className="checkbox-entry">
						<input
							className="input-checkbox"
							name="alternativebenefit"
							type="checkbox"
							placeholder="Not earning benefit elsewhere"
							required
						/>
						<span>I am not earning any benefit from another company for this purchase</span>
						<span className="pop-over-container">
                <PopoverHover
                  content={
                    <div style={{
                      maxWidth: '300px', padding: 10, fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left',
                    }}
                    >
                    Shops will pay one referrer website for a purchase. If you are receiving a benefit (eg a reward, discount coupon or cashback) from another website they will not approve the inquiry.
                    </div>
                  }
                  header = {null}
                  img = {null}
                  itemToPopover = {
                    ( <i className="material-icons" style={{ margin: '0 5px 0 5px', verticalAlign: '-40%' }} >info</i> )
                  }
									anchorOrigin={{
										vertical: 'center',
										horizontal: 'left',
									}}
									transformOrigin={{
										vertical: 'center',
										horizontal: 'right',
									}}
                />
            </span>
					</div>
					{/* <div className="checkbox-entry">
						<input
							className="input-checkbox"
							name="addblocker"
							type="checkbox"
							placeholder="Addblocker was turned off when I shopped"
						/>
						<span>Addblocker was not installed or completely disabled</span>
						<span className="pop-over-container">
                <PopoverHover
                  content={
                    <div style={{
                      maxWidth: '300px', padding: 10, fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left',
                    }}
                    >
                    Add blockers can block tracking and prevent sales being attributed to you. You may still raise an inquiry and it is likely to be approved by the shop.
                    </div>
                  }
                  header = {null}
                  img = {null}
                  itemToPopover = {
                    ( <i className="material-icons" style={{ margin: '0 5px 0 5px', verticalAlign: '-40%' }} >info</i> )
                  }
                />
            </span>
					</div> */}
					<div className="checkbox-entry">
						<input
							className="input-checkbox"
							name="lastClick"
							type="checkbox"
							placeholder="Last click from this website"
							required
						/>
						<span classname="input-label">The last click through to {storeName} was from Boost Your Super</span>
						<span className="pop-over-container">
                <PopoverHover
                  content={
                    <div style={{
                      maxWidth: '300px', padding: 10, fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left',
                    }}
                    >
                    Shops don't approve payment unless the last click before your purchase was from our platform (website, browser extension or app).
										<span className="highlight-message" style={{ color: 'red'}}>We recommend you try confirm this by checking your browser history and ensuring there were no other clicks to {storeName} after the click time and before the purchase time shown on your invoice.</span>
										<span>If confirmed there were no other clicks, we recommend you include in your comments below that your browser history shows no other clicks to the shop between your click time and purchase time on the invoice.</span>
                    </div>
                  }
                  header = {null}
                  img = {null}
                  itemToPopover = {
                    ( <i className="material-icons" style={{ margin: '0 5px 0 5px', verticalAlign: '-40%' }} >info</i> )
                  }
									anchorOrigin={{
										vertical: 'center',
										horizontal: 'left',
									}}
									transformOrigin={{
										vertical: 'center',
										horizontal: 'left',
									}}
                />
            </span>
					</div>
					<div className="text-entry">
						<TextInput
							name="ordernumber"
							type="tel"
							validations={{ 
								isExisty: true,
								minLength: 3,
								maxLength: 26,
								matchRegexp: /^[\w\-\s\.]+$/ 
								//matchRegexp: /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/  
							}}
							validationErrors={{ 
								isExisty: 'this field is required', 
								isDefaultRequiredValue: 'approval requires exact match',
								minLength: 'invalid - too short',
								matchRegexp: 'invalid order number',
								maxLength: 'invalid - too long',
							}}
							required
							value={this.state.form.ordernumber}
							onChange={this.onChange}
							placeholder="As per your invoice"
							label={<div><span style={{ color: labelColor ? labelColor : null }}>Order Number - must match exactly for approval</span></div>}
							labelColor={labelColor}
							//style={{ marginBottom: "unset"}}
						/>
					</div>
					<div className="text-entry">
						< div className="input-currency-symbol">
							<TextInput
								name="amountspent"
								type="tel"
								validations={{ 
									isExisty: true,
									minLength: 3,
									maxLength: 10,
									matchRegexp: /^\s*-?(\d+(\.\d{2})?|\.\d{2})\s*$/
								}}
								validationErrors={{ 
									isExisty: 'this field is required', 
									isDefaultRequiredValue: 'Amount excluding GST ',
									minLength: 'too short - include cents 0.00',
									matchRegexp: 'invalid - include cents 0.00',
									maxLength: 'invalid - too long',
								}}
								required
								value={this.state.form.amountspent}
								onChange={this.onChange}
								placeholder="0.00 as per your inv."
								label={<div><span style={{ color: labelColor ? labelColor : null }}>Amount spent (excluding GST)</span></div>}
								labelColor={labelColor}
							/>
							
						</div>
					</div>
					<div className="comments-entry">
						<label for="cust-text-entry-area">Comments</label>
						<textarea
							name="commentsfromcust"
							//id="cust-text-entry-area"
							type="text"
							maxlength="264"
							pattern="[a-zA-Z0-9]"
							placeholder="Any comments that might help us get approval. Include any reasons you think may have caused it not to track."
							//label={<div><span style={{ color: labelColor ? labelColor : null }}>Comments</span></div>}
							value={this.state.form.commentsfromcust}
							onChange={this.onChange}
						>

						</textarea>
						{
						this.state.commentsMessage ? (
							<div className="comments-error-notification">
								<span >{this.state.commentsMessage}</span>
							</div>
						) : null
					}
						
					</div>

					<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%', marginTop: 35, marginBottom: 35 }}>
						<ActionButton
							text={this.state.isSubmittingInquiry ? 'Submitting Inquiry' : 'Submit Inquiry'}
							loading={this.state.isSubmittingInquiry}
							active={!this.state.isSubmittingInquiry}
							color="#fff"
							large={true}
							disabled={((this.state.isCommentsValid && this.state.isFormValid) || this.state.isSubmittingInquiry )? false : true}
							title="Fill all mandatory fields before submitting"
						/>
					</div>
				</div>
			</RaiseTransactionInquiryFormWrapper>
		)
	}
}

