/* eslint-disable react/require-default-props */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import ActionButton from 'SharedComponents/action-button';

import styles from './Slide.css';

const SlideWrapper = styled.div`${styles}`;

const Slide = ({
  imageUrl, title, text1, text2, text3, text4, backgroundPosition,
}) => (
    <SlideWrapper imageurl={imageUrl} backgroundposition={backgroundPosition}>

      <div className="text-container">
        <div className="compare-container">
          <p>{text1}</p>
          <p>{text2}</p>
        </div>
        <h1><span className="highlight-text">{title}</span></h1>
        <p>Try our superannuation calculator to see how rounding up spare change can impact super based on <span className="highlight-text">your age and income </span></p>
        <div className="button-container">
          <Link to="/superannuation-calculators"><ActionButton text="Try Calculator" large={true} /></Link>
        </div>
        <p className="calculation-details">{text4}</p>

      </div>
    </SlideWrapper>
  );

Slide.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text1: PropTypes.string,
  text2: PropTypes.string,
  text3: PropTypes.string,
  backgroundPosition: PropTypes.string.isRequired,
};

export default Slide;
