import React, { Component, useState } from 'react';
import styled from 'styled-components';
import { isChrome, isMobile } from 'react-device-detect';

import styles from './index.css.js';

const PopOverWrapper = styled.section`${styles}`;

import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

const SimplePopover =( { content, header, img, itemToPopover }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    console.log(['mouse click event']);
  };

  const handleClose = () => {
    setAnchorEl(null);
    console.log(['handle close']);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <PopOverWrapper
      onClick={handleClick}
    >
      <div className="simple-popover" >
        <span> { itemToPopover } </span> 
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
          <Typography  > { content } </Typography>
        </Popover>
      </div>
    </PopOverWrapper>
  );
};

export default(SimplePopover);
