import React from 'react';
import styled from 'styled-components';
import SignInForm from 'SharedComponents/sign-in-form';

import styles from './index.css';

const NotFoundWrapper = styled.div`${styles}`;

const NotFound = () => (
  <NotFoundWrapper>
    <div className="sign-in-form-container">
      <SignInForm
        header={(
          <div style={{
						  width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start',
          }}
          >
            <h1>Login</h1>
            <span>To access your account</span>
          </div>
				)}
        showSocialLogin
        showEmailLogin
      />
    </div>
  </NotFoundWrapper>
);

export default NotFound;
