export default ({ theme }) => {
  return `
    display: flex;
    align-items: center;
    justify-content: center;
    
    .favorite-button {
      display:flex;
      justify-content: center;
      flex-direction: column;
      padding: 10px;
    }

  `;
}
