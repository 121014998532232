import { combineReducers } from 'redux';

// Import your reducers
import shoppingReducer from './shopping/reducer';
import authenticationReducer from './authentication/reducer';
import transactionsReducer from './transactions/reducer';
import testimonialsReducer from './testimonials/reducer';
import appReducer from './app/reducer';
import faqsReducer from './faqs/reducer';
import calculatorsReducer from './calculators/reducer';
import roundupsReducer from './round-ups/reducer';
import roundUpsTransactionsReducer from './transactions-round-ups/reducer';

// Combine reducers
const createReducer = combineReducers({
  shopping: shoppingReducer,
  authentication: authenticationReducer,
  transactions: transactionsReducer,
  testimonials: testimonialsReducer,
  app: appReducer,
  faqs: faqsReducer,
  calculators: calculatorsReducer,
  roundups: roundupsReducer,
  roundUpsTransactions: roundUpsTransactionsReducer,
});

export default createReducer;
