import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import log from "Utils/logger";

import * as ShoppingActions from 'Flux/shopping/actions';

const useFetchShopperBoostProfile = () => {
  console.log ('*** * fetchShopperBoostProfile: hook triggered');
  const isFetchingShopperBoostProfileRef = useRef(false);
  const hasFetchedShopperBoostProfileRef = useRef(false);
  const dispatch = useDispatch();
  
  const storedShopperBoostProfile = useSelector(state => state.shopping.shopperBoostProfile);
  const user = useSelector(state => state.authentication.user);

  const fetchShopperBoostProfile = async () => {
    isFetchingShopperBoostProfileRef.current = true;
    try {
      if (!user) {
        console.log('*** * fetchShopperBoostProfile: exit function as no shops or no user');
        isFetchingShopperBoostProfileRef.current = false;
        return;
      }
      const result = await dispatch(ShoppingActions.fetchShopperBoostProfile());
      console.log ('*** * fetchShopperBoostProfile: fetch  result  is:', result)
      console.log('*** * fetchShopperBoostProfile: storedShopperBoostProfile:', storedShopperBoostProfile)

      const newShopperBoostProfile = result.data.boosts;

      console.log('*** * fetchShopperBoostProfile: storedShopperBoostProfile.length:', storedShopperBoostProfile.length)
      console.log('*** * fetchShopperBoostProfile: newShopperBoostProfile.length:', newShopperBoostProfile.length)

      if (shouldUpdateStore(newShopperBoostProfile, storedShopperBoostProfile)) {
        console.log('*** * fetchShopperBoostProfile: and storedShopperBoostProfile are different');
        console.log('*** * fetchShopperBoostProfile: DISPATCH SET boostProfile with newShopperBoostProfile: ', newShopperBoostProfile);
        dispatch(ShoppingActions.setShopperBoostProfile(newShopperBoostProfile));
      }
    } catch (exception) {
      console.log(exception);
      log({
        event: "System-Error",
        source: "Fetch Shopper boostProfile",
        //'shopper_id': authentication? authentication.user.shopper_id : '000',
        info: exception,
        ...exception,
      });
    } finally {
      isFetchingShopperBoostProfileRef.current = false;
      hasFetchedShopperBoostProfileRef.current = true;
    }
  };

  function shouldUpdateStore(newShopperBoostProfile, storedShopperBoostProfile) {

    if (newShopperBoostProfile.length !== storedShopperBoostProfile.length) {
      console.log('*** * fetchShopperBoostProfile: confirmed not equal');
      return true; // Differences found
    }
  
    return false; // No differences found
  }

  useEffect(() => {
    if ( user 
        && !isFetchingShopperBoostProfileRef.current 
        && !hasFetchedShopperBoostProfileRef.current) {
        fetchShopperBoostProfile();
    }
  } , []);


  return { 
           fetchShopperBoostProfile };
};

export default useFetchShopperBoostProfile;
