import { browserName, isMobile, isIOS, osName, browserVersion, mobileVendor, deviceType, osVersion, } from 'react-device-detect';
//import { connect } from 'react-redux';


export default function log(event) {
  console.log('logger event details are :', event);
  const usefulDetails = {
    'browserName': browserName, 
    'isMobile': isMobile,
    'isIOS': isIOS,
    'osVersion': osVersion,
    'osName': osName,
    'browserVersion': browserVersion,
    'mobileVendor': mobileVendor,
    'deviceType': deviceType
    //'deviceDetect': deviceDetect -> this just doesn't work
  }
  console.log ('useful details are : ', usefulDetails); 
  const eventAndDetails = {...event, ...usefulDetails};

  console.log('eventAndDetails are : ', eventAndDetails);
  logToTagManager(eventAndDetails);
}

function logToTagManager(event) {
  window.dataLayer.push(event);
}
