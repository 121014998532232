import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-spinner-material';

import styled from 'styled-components';

import styles from './index.css';

const ActionButtonWrapper = styled.button`${styles}`;

const ActionButton = (props) => {
  const iconStyle = {
    fontSize: props.large ? 25 : 25,
    fontWeight: 500,
    marginLeft: props.icon ? '10px' : '0px',
  };

  return (
    <ActionButtonWrapper {...props}>
      {props.loading ? (
        <div style={{ margin: '0 2px' }}>
          <Spinner
            spinnerColor={props.color || '#fff'}
            size={props.large ? 20 : 16}
            spinnerWidth={props.large ? 3 : 2}
          />
        </div>
      ) : (
        <i className="material-icons" style={iconStyle}>
          {props.icon}
        </i>
      )}
      <span className="text">{props.text}</span>
    </ActionButtonWrapper>
  );
};

ActionButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  text: PropTypes.string,
  icon: PropTypes.string,
  style: PropTypes.object,
  large: PropTypes.bool,
  color: PropTypes.string,
  active: PropTypes.bool,
  type: PropTypes.string,
};

export default ActionButton;