import { is } from 'immutable';
import * as RoundUpsActionTypes from './constants';

const initialState = {
  active: false,
  //verified: false,
  bankConnected: false,
  bankConsented: false,
  //bankAccountsSynced: false,
  contributeNowEnabled: false,
  //roundUpsBYSenabled: true,
  contributeNowAmount: 200,
  //roundUpsOnBoardingCompleted: false,
  //transactionsSyncBOM: false,
  //savingsEnabled: false,
  roundUpToNearest: 100,
  roundUpWeeklyLimit: 10000,
  contributionLimit: false,
  //customer_reference: false,
  linkedAccounts: [],
  links: [],
  currentJobs: [],
  acceptedRoundupsTerms: true,
  acceptedContributeNowTerms: true,
  confirmedContactDetails: true,
  promoCodeActive: false,
  promoCode: false,
  promoCodeDetails: false,
  promoCodeExpiryDate: false,
  promoCodeCharge: 2.5,
  roundUpCharge: 4,
  //registeredForRoundUps: false,
  //registeredForContributeNow: false,
  contributeNowContinueNotification: true,
  isFetchingRoundUpsShopperData: false,
}


const actionsMap = {
  [RoundUpsActionTypes.SET_ROUNDUPS_SHOPPER_DATA](state, { payload }) {
    console.log('SET_ROUNDUPS_SHOPPER_DATA reducer initiated with state: ', state);
    console.log('SET_ROUNDUPS_SHOPPER_DATA reducer initiated with payload: ', payload); 
    return {
      ...state,
      ...payload,
    };
  },
  [RoundUpsActionTypes.SET_ACCEPTED_ROUNDUPS_TERMS](state, { payload }) {
    return {
      ...state,
      acceptedRoundupsTerms: payload,
    };
  },
  [RoundUpsActionTypes.SET_ACCEPTED_CONTRIBUTE_NOW_TERMS](state, { payload }) {
    return {
      ...state,
      acceptedContributeNowTerms: payload,
    };
  },
  [RoundUpsActionTypes.SET_CONFIRMED_CONTACT_DETAILS](state, { payload }) {
    console.log(['SET_CONFIRMED_CONTACT_DETAILS reducer about to update state currently: ', state]);
    console.log(['SET_CONFIRMED_CONTACT_DETAILS reducer confirmedContactDetails is: ', payload]);
    return {
      ...state,
      confirmedContactDetails: payload,
    };
  },
  [RoundUpsActionTypes.SET_ROUNDUP_AMOUNT](state, { payload }) {
    return {
      ...state,
      roundUpToNearest: payload,
    };
  },
  [RoundUpsActionTypes.SET_WEEKLY_LIMIT](state, { payload }) {
    return {
      ...state,
      roundUpWeeklyLimit: payload,
    };
  },
  [RoundUpsActionTypes.SET_REGISTERED_FOR_ROUNDUPS](state, { payload }) {
    return {
      ...state,
      registeredForRoundUps: payload,
    };
  },
  [RoundUpsActionTypes.SET_REGISTERED_FOR_CONTRIBUTE_NOW](state, { payload }) {
    return {
      ...state,
      registeredForContributeNow: payload,
    };
  },
  [RoundUpsActionTypes.SET_ENABLED_CONTRIBUTE_NOW](state, { payload }) {
    return {
      ...state,
      contributeNowEnabled: payload,
    };
  },
  [RoundUpsActionTypes.SET_CONTRIBUTE_NOW_CONTINUE_NOTIFICATION](state, { payload }) {
    return {
      ...state,
      contributeNowContinueNotification: payload,
    };
  },
  [RoundUpsActionTypes.SET_ROUNDUPS_LINKED_ACCOUNTS](state, { payload }) {  
    return {
      ...state,
      linkedAccounts: payload,
    };
  },
  [RoundUpsActionTypes.SET_IS_FETCHING_ROUNDUPS_SHOPPER_DATA](state, { payload }) {
    return {
      ...state,
      isFetchingRoundUpsShopperData: payload,
    };
  },
  [RoundUpsActionTypes.UNSET_ROUNDUPS_SHOPPER_DATA]() {
    return   initialState; 
  },
};

export default function roundups(state = initialState, action) {
  console.log(['ROUNDUPS reducer initiated with reducer action: ' , action]);
  console.log(['ROUNDUPS reducer about to update state currently: ', state ]);
  const reduceFn = actionsMap[action.type];
  if (!reduceFn) {
      console.log ('NO ACTION FOUND FOR ROUNDUPS REDUCER. ACTION was : ', action);
    return state;
  }
  return reduceFn(state, action);
}
