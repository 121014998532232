import React from 'react';
import styled from 'styled-components';
import Spinner from 'react-spinner-material';
import PropTypes from 'prop-types';
import SvgIcon from '@mui/material/SvgIcon';
import FacebookLogin from '@greatsumini/react-facebook-login';

import styles from './index.css';

const FacebookLoginWrapper = styled.div`
  ${styles}
`;

const MyFacebookLogin = ({
  onClick,
  onFacebookResponseSuccess,
  onFacebookResponseFailure,
  onFacebookProfileSuccess,
  isAuthenticatingWithFacebook,
  buttonText,
}) => {

  console.log('facebook onClick handler:', onClick);

  const handleClick = (facebookLoginOnClick) => {
    console.log('facebook Button clicked - inside handleClick');
    if (facebookLoginOnClick) {
      console.log('Calling facebookLoginOnClick');
      facebookLoginOnClick();
    } else {
      console.log('facebookLoginOnClick is not defined');
    }
    if (onClick) {
      console.log('Calling custom onClick');
      onClick();
    } else {
      console.log('Custom onClick is not defined');
    }
  };
  
  return (
    <FacebookLoginWrapper>
      <FacebookLogin
        appId={
          process.env.NODE_ENV.toLowerCase() === 'local'
            || process.env.NODE_ENV.toLowerCase() === 'development'
            ? '901987393491013'
            : '740275433559588'
        }
        autoLoad={false}
        fields="name,email,picture"
        onClick={onClick}
        onSuccess={onFacebookResponseSuccess}
        onFail={onFacebookResponseFailure}
        onProfileSuccess={onFacebookProfileSuccess}
        redirectUri={window.location.href}
        isMobile={false}
        icon="fa-facebook"
        textButton="Sign Up with Facebook"
        size="medium"
        cssClass="facebook-login"
        render={({
          onClick: facebookLoginOnClick,
          isDisabled,
        }) => {
          console.log('Rendering facebook login button');
          console.log('facebook login isDisabled:', isDisabled);
          return(
            <button type="button" 
              onClick={() => handleClick(facebookLoginOnClick)}
              className="facebook-login"
              >
              <SvgIcon className="icon">
                <path
                  fill="#fff"
                  d="M17,2V2H17V6H15C14.31,6 14,6.81 14,7.5V10H14L17,10V14H14V22H10V14H7V10H10V6A4,4 0 0,1 14,2H17Z"
                />
              </SvgIcon>
              <span className="button-text">{buttonText}</span>
              {isAuthenticatingWithFacebook ? (
                <Spinner spinnerColor="#FFF" size={20} spinnerWidth={3} />
              ) : null}
            </button>
          )
        }}
      />
    </FacebookLoginWrapper>
  );
};

MyFacebookLogin.propTypes = {
  onClick: PropTypes.func.isRequired,
  onFacebookResponseSuccess: PropTypes.func.isRequired,
  onFacebookResponseFailure: PropTypes.func.isRequired,
  onFacebookProfileSuccess: PropTypes.func.isRequired,
  isAuthenticatingWithFacebook: PropTypes.bool.isRequired,
  buttonText: PropTypes.string.isRequired,
};

export default MyFacebookLogin;
