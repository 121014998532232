export default ({ theme }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;


  .partnerships-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1066px;
    padding: 20px;
    color: #000;

    h1 {
      margin: 30px 0;

  }

  .content-container {
    width: calc(100% - 20px);
    padding: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin: 10px 0 40px;
    min-height: calc(100vh - 400px);
  }


  .partner-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    padding: 20px;
    color: #000;


    h2 {
      font-weight: bolder;
    }

    p {
      margin: 10px 0;
      font-size: larger;
    }

    ul {
      list-style-type: disc;
    }

    li {
      margin: 10px 0;
      font-size: larger;
    }

    a {
      color: #000;
      text-decoration: underline;
    }

  }
 
 

`;
