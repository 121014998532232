export default () => `
height: fit-content !important;


.swiper {
  width: 100vw; 
  max-height: 600px; /*remove or change this amount after testing */
}

.swiper-wrapper {
  display: flex;
}

.swiper-slide {
  width: 100%;  
  height: 100%; 
  display: flex; 
  justify-content: center;
  align-items: center;
  text-align: center;
}
 

.swiper-button-prev,
.swiper-button-next {
  color: #fff;
}
 

.swiper-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-pagination-bullet {
  width: 16px; /* Ensure pagination dots have a visible width */
  height: 16px; /* Ensure pagination dots have a visible height */
  background-color: #fff; /* Ensure pagination dots are a visible color */
}

.swiper-pagination-bullet-active {
  width: 20px; /* Ensure pagination dots have a visible width */
  height: 20px; /* Ensure pagination dots have a visible height */
  background-color: #fff; /* Ensure pagination dots are a visible color */
}


`;
