import * as ShoppingActionTypes from './constants';

const initialState = {
  stores: [],
  deals: [],
  promotedDeals: [],
  categories: [],
  tags: [],
  favorites: [],
  boostsRetailer: [],
  boostsClient: [],
  boostsAffiliate: [],
  shopperBoostProfile: [],
  currentPromotion: null,
  isRightDrawerOpen: false,
};

const actionsMap = {
  [ShoppingActionTypes.TOGGLE_RIGHT_DRAWER](state, { payload }) {
    return {
      ...state,
      isRightDrawerOpen: payload,
    };
  },
  [ShoppingActionTypes.ADD_STORES](state, { payload }) {
    console.log('[ShoppingActionTYpes ADD_STORES initiated at reducer with following payload: ', payload );
    return {
      ...state, stores: payload,
    };
  },
  [ShoppingActionTypes.REMOVE_STORES](state) {
    return {
      ...state, stores: [],
    };
  },
  [ShoppingActionTypes.ADD_DEALS](state, { payload }) {
    return {
      ...state, deals: payload,
    };
  },
  [ShoppingActionTypes.REMOVE_DEALS](state) {
    return {
      ...state, deals: [],
    };
  },
  [ShoppingActionTypes.ADD_PROMOTED_DEALS](state, { payload }) {
    return {
      ...state, promotedDeals: payload,
    };
  },
  [ShoppingActionTypes.REMOVE_PROMOTED_DEALS](state) {
    return {
      ...state, promotedDeals: [],
    };
  },
  [ShoppingActionTypes.ADD_CATEGORIES](state, { payload }) {
    return {
      ...state, categories: payload,
    };
  },
  [ShoppingActionTypes.REMOVE_CATEGORIES](state) {
    return {
      ...state, categories: [],
    };
  },
  [ShoppingActionTypes.ADD_STORE_TAGS](state, { payload }) {
    return {
      ...state, tags: payload,
    };
  },
  [ShoppingActionTypes.SET_CURRENT_PROMOTION](state, { payload }) {
    return {
      ...state, currentPromotion: payload,
    };
  },
  [ShoppingActionTypes.UNSET_SHOPPER_BOOSTS](state) {
    return {
      ...state,
      shopperBoostProfile: [],
    };
  },
  [ShoppingActionTypes.SET_FAVORITES_ON_STORES](state, { payload }) {
    console.log('[REDUCER: SET_FAVORITES_ON_STORES initiated with payload: ', payload );
    console.log('[current state: ', state);
    
    const newStores = state.stores.map((store) => {
      if (payload.includes(store.retail_store_id)) {
        return { ...store, favorite: true};
      }
      return { ...store, favorite: false };
    });
    
    console.log(['stores With Faves Set by Reducer: ', newStores]);
    if (!payload.length) {
      return state;
    }
    ///// remove newState s ws just for checking remember to remove this check
    //const newState = {... state, stores: newStores};
    //console.log('REDUCER About to send back STORES with Favs-set-on-Stores and newState is: ', newState);

    return {...state, stores: newStores };

  },



  [ShoppingActionTypes.SET_FAVORITE_ON_STORE](state, { payload }) {
    console.log('[REDUCER: SET_FAV_ON_SHOP** triggered in reducer with payload: ', payload );
  
  const newFavStores = state.stores.map((store) => {
    if(store.retail_store_id === payload) {
     return{ ...store, favorite: true }
    }
     return{ ...store }
  });
    console.log('[newFavStores in SET_FAV_ON_SHOP list is ]', newFavStores);
    return {
      ...state,
      stores: newFavStores,
    };
  },

  [ShoppingActionTypes.UN_SET_FAVORITE_ON_STORE](state, { payload }) {
    console.log('[REDUCER: UN_SET_FAV_ON_SHOP** triggered in reducer with payload: ', payload );
  
  const newFavStores = state.stores.map((store) => {
    if(store.retail_store_id === payload) {
     return{ ...store, favorite: false }
    }
     return{ ...store }
  });
    console.log('[newFavStores in UN_SET FAV ON STORE list is ]', newFavStores);
    return {
      ...state,
      stores: newFavStores,
    };
  },



  [ShoppingActionTypes.ADD_FAVORITE_SHOP](state, { payload }) {
    console.log('[ADD FAV SHOP** triggered in reducer with payload: ', payload );
  
    const favStores = stores.filter(
      store => store.favorite === true
    );  
  
    return {
      ...state,
      favorites: favStores,
    };
  },


  [ShoppingActionTypes.ADD_FAVORITE_SHOPS](state) {
    console.log('[REDUCER: ADD_FAVORITE_SHOPS to state initiated at reducer with no payload. ');
    
    
    const favStores = state.stores.filter(store => store.favorite === true);
    console.log(['favStore Array is: ', favStores]);

    console.log('Reducer about to add the following favStores to State favorites[]: ', favStores);
    return {
      ...state,
      favorites: favStores,
    };
  },

  [ShoppingActionTypes.REMOVE_FAVORITE_SHOP](state, action) {
    return state.filter(favoriteShop => favoriteShop !== action.favoriteShop);
  },

  [ShoppingActionTypes.UPDATE_FAVORITE_SHOP](state, action) {
    return state.map(favoriteShop =>
      (
      favoriteShop.id === action.favoriteShop.id 
        ? Object.assign({}, favoriteShop, action.favoriteShop)
        : favoriteShop
        ),
    );
  },

  [ShoppingActionTypes.REMOVE_FAVORITE_SHOPS](state) {
  
    const unSetStores = state.stores.map((store) => {
      if(store.favorite === true) {
       return{ ...store, favorite: false }
      }
       return{ ...store }
    });
      
    return {
      ...state,
      favorites: [],
      stores: unSetStores,
    };

  },

  [ShoppingActionTypes.ADD_SHOPPER_BOOSTS](state, action) {
    return {
      ...state,
      shopperBoostProfile: action.payload,
    };
  },

  [ShoppingActionTypes.ADD_STORE_BOOSTS](state, { payload }) {
    console.log('[REDUCER: ADD_STORE_BOOSTS initiated with payload: ', payload );
    console.log('[REDUCER: ADD_STORE_BOOSTS initiated with payload.retail_stores: ', payload.retail_stores );
    console.log('[REDUCER: ADD_STORE_BOOSTS initiated with payload.retail_stores.length: ', payload.retail_stores.length );
    console.log('[current state in set store_boosts: ', state);

    if ( Object.keys(payload).length === 0 ) {
      console.log ("payload.length being triggered in ADD STORES : ", payload.length)
      return state;
    }

    return {
      ...state,
      boostsRetailer: payload.retail_stores ? payload.retail_stores : [],
      boostsAffiliate: payload.affiliates ? payload.affiliates : [],
      boostsClient: payload.clients ? payload.clients : [],
    };
    
  },

  [ShoppingActionTypes.SET_STORE_BOOSTS_ON_STORES](state) {
    //console.log('[REDUCER: SET_STORE_BOOSTS_ON_STORES initiated with payload: ', payload );
    console.log('[current state in set store_boosts: ', state);

    const newStoresWithBoosts = state.stores.map((store) => {
      const retailIndexMatch = state.boostsRetailer.findIndex((retail_store) => retail_store.retail_store_id == store.retail_store_id);
      const affiliateIndexMatch = state.boostsAffiliate.findIndex((affiliate) => affiliate.affiliate_network_id == store.affiliate_network_id);
      if (
            (state.boostsRetailer.some( item => item.retail_store_id == store.retail_store_id))
            || (state.boostsAffiliate.some( item => item.affiliate_network_id == store.affiliate_network_id))
            || !!(state.boostsClient[0]?.boosts)
        )
        {
          return { 
                  ...store, 
                  boosts_retailer: retailIndexMatch > -1 ? state.boostsRetailer[retailIndexMatch].boosts : null, //retailer_boost,
                  boosts_affiliate: affiliateIndexMatch > -1 ? state.boostsAffiliate[affiliateIndexMatch].boosts : null,
                  boosts_client: !!(state.boostsClient[0]?.boosts) ? state.boostsClient[0].boosts : null,
                  };
        }
        return { 
                ...store,
                boosts_retailer: null,
                boosts_affiliate: null,
                boosts_client: null,
                };
    });

    console.log('REDUCER About to send back STORES with BOOSTS -set-on-Stores newStoresWithBoosts, ', newStoresWithBoosts);

    return {
      ...state,
      stores: newStoresWithBoosts 
    };

  },

};

export default function shopping(state = initialState, action) {
  console.log(['SHOPPING REDUCER triggered with reducer action: ' , action]);
  console.log(['SHOPPING Reducer about to update state currently: ', state ]);
  const reduceFn = actionsMap[action.type];
  if (!reduceFn){ 
    console.log('SHOPPING REDUCER did not find ACTION : ', action);
    return state;
  }
  return reduceFn(state, action);
}
