import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Formsy from 'formsy-react';
import axios from 'axios';
import qs from 'qs';

import * as AuthenticationActions from 'Flux/authentication/actions';

import MyInput from 'SharedComponents/text-input';
import ActionButton from 'SharedComponents/action-button';
import CONFIG from 'Config';

const SelfManagedFundsPaymentDetails = () => {
  const dispatch = useDispatch();
  const authentication = useSelector(state => state.authentication);

  const [localAuthentication, setLocalAuthentication] = useState(authentication);

  const { user, collaboratingCauses } = localAuthentication;
  const ownedCause = collaboratingCauses.find((cause) => cause.charity_id === user.charity_id);

  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState('');
  const [form, setForm] = useState({
    charity_bank: ownedCause.charity_bank,
    charity_bank_account: ownedCause.charity_bank_account,
    charity_bank_account_no: ownedCause.charity_bank_account_no,
    charity_bsb: ownedCause.charity_bsb,
    charity_abn: ownedCause.charity_abn,
    electronic_service_address: ownedCause.electronic_service_address,
    is_smsf: "1",
  });

  useEffect(() => {
    if (authentication !== localAuthentication) {
      setLocalAuthentication(authentication);
    }
  }, [authentication]);

  const onChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const onValid = () => {
    setIsFormValid(true);
  };

  const onInvalid = () => {
    setIsFormValid(false);
  };

  const onValidSubmit = () => {
    onUpdateAccountDetails();
  };

  const onInvalidSubmit = () => {
    console.log('onInvalidSubmit');
  };

  const onUpdateAccountDetails = useCallback(async () => {
    setIsSubmitting(true);

    try {
      const onUpdateAccountDetailsResult = await axios({
        url: `${CONFIG.apiV3Url}/charities/resource`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Bearer ' + window.localStorage.getItem('auth_token')
        },
        data: qs.stringify({ ...form }),
      });

      dispatch(AuthenticationActions.setUser(onUpdateAccountDetailsResult.data));

      setError(false);
      setMessage('Payment details updated successfully');
    } catch (exception) {
      setError(true);
      setMessage('Oops! Something went wrong');
      console.log(exception);
    } finally {
      setIsSubmitting(false);
      setTimeout(() => {
        setError(false);
        setMessage('');
      }, 3000);
    }
  }, [form, dispatch]);

  return (
    <div style={{ margin: '10px 0', width: 'calc(100% - 20px)', padding: 10 }}>
      <div style={{ width: '100%', margin: '20px 0 0', display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
        <p style={{ fontSize: 16, margin: '7px 0 0', color: '#303031', textAlign: 'left', }}>
          <b style={{ fontWeight: 'bold' }}>SMSF Details – </b>Update where we send payments to.
        </p>
        <small style={{ margin: '10px 0' }}>Use this form if you have a Self Managed Super Fund that you would like us to pay into.</small>
        <hr style={{ border: 'solid 1px #c5c5c5', margin: '10px 0', width: '100%' }} />
      </div>
      <Formsy
        style={{ width: '100%' }}
        onValidSubmit={onValidSubmit}
        onInvalidSubmit={onInvalidSubmit}
        onValid={onValid}
        onInvalid={onInvalid}
      >
        <MyInput
          name="charity_bank_account"
          type="text"
          validations={{ isExisty: true, minLength: 2, isWords: true }}
          validationErrors={{
            isExisty: 'this field is required',
            isDefaultRequiredValue: 'this field is required',
            minLength: 'too short',
            isWords: 'invalid account name',
            isDefaultRequiredValue: 'this field is required',
          }}
          required
          value={form.charity_bank_account}
          onChange={onChange}
          placeholder="Full Name of Trust"
          label="Full Name of Trust"
        />
        <MyInput
          name="charity_abn"
          type="text"
          validations={{ isExisty: true, minLength: 2 }}
          validationErrors={{
            isExisty: 'this field is required',
            isDefaultRequiredValue: 'this field is required',
            minLength: 'too short',
            maxLength: 'too long',
            isNumeric: 'invalid bsb'
          }}
          required
          value={form.charity_abn}
          onChange={onChange}
          placeholder="ABN of Trust"
          label="ABN of Trust"
        />
        <MyInput
          name="charity_bsb"
          type="text"
          validations={{ isExisty: true, minLength: 3, maxLength: 7, isNumeric: true }}
          validationErrors={{
            isExisty: 'this field is required',
            isDefaultRequiredValue: 'this field is required',
            minLength: 'too short',
            maxLength: 'too long',
            isNumeric: 'invalid bsb'
          }}
          required
          value={form.charity_bsb}
          onChange={onChange}
          placeholder="BSB"
          label="BSB"
        />
        <MyInput
          name="charity_bank_account_no"
          type="text"
          validations={{ isExisty: true, minLength: 3, isNumeric: true }}
          validationErrors={{
            isExisty: 'this field is required',
            isDefaultRequiredValue: 'this field is required',
            minLength: 'too short',
            isNumeric: 'invalid bsb'
          }}
          required
          value={form.charity_bank_account_no}
          onChange={onChange}
          placeholder="Account Number"
          label="Account Number"
        />
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
          <ActionButton
            type="submit"
            text={isSubmitting ? 'Updating Payment Details' : 'Update Payment Details'}
            large={true}
            disabled={isSubmitting || !isFormValid}
            loading={isSubmitting}
            active={!isSubmitting}
          />
          {message && (
            <span style={{ color: error ? '#ff007d' : '#03031', fontSize: 20, marginLeft: 20 }}>{message}</span>
          )}
        </div>
      </Formsy>
    </div>
  );
};

export default SelfManagedFundsPaymentDetails;
