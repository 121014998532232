import * as RoundUpsTransactionsActionTypes from './constants';

const initialState = {
  roundUpsTransactions: [],
  payments: [],
  contributeNowTransactions: [],
  contributeNowTotalClearing: null,
  contributeNowTotalCleared: null,
  contributeNowTotalReceived: null,
  contributeNowTotalPaid: null,
  roundUpsTotalClearing: null,
  roundUpsTotalCleared: null,
  roundUpsTotalRecieved: null,
  roundUpsTotalPaid: null,
}

const actionsMap = {
  [RoundUpsTransactionsActionTypes.SET_ROUNDUPS_TRANSACTIONS](state, action) {
    console.log('ROUNDUPS TRANSACTIONS REDUCER: SET_ROUNDUPS_TRANSACTIONS action called with payload: ', action.payload)
    return {
      ...state,
      roundUpsTransactions: action.payload,
    };
  },
  [RoundUpsTransactionsActionTypes.UNSET_ROUNDUPS_TRANSACTIONS]() {
    return {
      ...state,
      roundUpsTransactions: [],
    };
  },
  [RoundUpsTransactionsActionTypes.SET_PAYMENTS](state, action) {
    console.log('ROUNDUPS TRANSACTIONS REDUCER: SET_PAYMENTS action called with payload: ', action.payload)
    return {
      ...state,
      payments: action.payload,
    };
  },
  [RoundUpsTransactionsActionTypes.UNSET_PAYMENTS]() {
    return { 
      ...state, 
      payments: [] 
    };
  },
  [RoundUpsTransactionsActionTypes.RESET_TO_INITIAL]() {
    console.log('ROUNDUPS TRANSACTIONS REDUCER: RESET_TO_INITIAL action called with initialState: ', initialState)
    return initialState;
  },
  [RoundUpsTransactionsActionTypes.SET_CONTRIBUTE_NOW_TRANSACTIONS](state, action) {
    console.log('ROUNDUPS TRANSACTIONS REDUCER: SET_CONTRIBUTE_NOW_TRANSACTIONS action called with payload: ', action.payload);
    return {
      ...state,
      contributeNowTransactions: action.payload,
    };
  },
  [RoundUpsTransactionsActionTypes.UNSET_CONTRIBUTE_NOW_TRANSACTIONS]() {
    return {
      ...state,
      contributeNowTransactions: [],
    };
  },
  [RoundUpsTransactionsActionTypes.SET_CONTRIBUTE_NOW_TOTALS](state, action) {
    console.log('ROUNDUPS TRANSACTIONS REDUCER: SET_CONTRIBUTE_NOW_TOTALS action called with payload: ', action.payload);
    
    const { payload } = action; // Destructure payload from action
    
    return {
      ...state,
      contributeNowTotalClearing: payload.totalClearing,
      contributeNowTotalCleared: payload.totalCleared,
      contributeNowTotalReceived: payload.totalReceived, // Corrected property name
      contributeNowTotalPaid: payload.totalPaid,
    };
  },
  [RoundUpsTransactionsActionTypes.SET_ROUND_UPS_TOTALS](state, action) {
    console.log('ROUNDUPS TRANSACTIONS REDUCER: SET_ROUND_UPS_TOTALS action called with payload: ', action.payload);
    
    const { payload } = action; // Destructure payload from action
    
    return {
      ...state,
      roundUpsTotalClearing: payload.totalClearing,
      roundUpsTotalCleared: payload.totalCleared,
      roundUpsTotalRecieved: payload.totalReceived, // Corrected property name
      roundUpsTotalPaid: payload.totalPaid,
    };
  },
};

export default function roundUpsTransactions(state = initialState, action) {
  console.log(['ROUNDUPS TRANSACTIONS reducer initiated with reducer action: ' , action]);
  console.log(['ROUNDUPS TRANSACTIONS reducer about to update state currently: ', state ]);
  const reduceFn = actionsMap[action.type];
  if (!reduceFn) {
      console.log ('NO ACTION FOUND FOR ROUNDUPS TRANSACTIONS REDUCER. ACTION was : ', action);
    return state;
  }
  return reduceFn(state, action);
}
