import axios from 'axios';

import log from 'Utils/logger';
import qs from 'qs';

import CONFIG from 'Config';
import * as RoundUpsTransactionsActionTypes from './constants';

export function reSetRoundUpsTransactions() {
  console.log('reSetRoundUpsTransactions function in flux roundups actions triggered with type RESET_TO_INITIAL')
  return {
    type: RoundUpsTransactionsActionTypes.RESET_TO_INITIAL,
  };
}

export function setRoundUpsTransactions (transactions) {
  console.log('setRoundUpsTransactions function in flux roundups actions triggered with transactions: ', transactions)
  return {
    type: RoundUpsTransactionsActionTypes.SET_ROUNDUPS_TRANSACTIONS,
    payload: transactions,
  };
}

export function setContributeNowTransactions (transactions) {
  console.log('setContributeNowTransactions function in flux roundups actions triggered with transactions: ', transactions)
  return {
    type: RoundUpsTransactionsActionTypes.SET_CONTRIBUTE_NOW_TRANSACTIONS,
    payload: transactions,
  };
}

export function unSetContributeNowTransactions() {
  console.log('unSetContributeNowTransactions function in flux roundups actions triggered')
  return {
    type: RoundUpsTransactionsActionTypes.UNSET_CONTRIBUTE_NOW_TRANSACTIONS,
  };
}

export function fetchRoundUpsTransactions() {
  console.log('fetchRoundUpsTransactions function in flux roundups actions triggered')
  return () => axios({
    url: `${CONFIG.apiV3Url}/roundups/transactions`,
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + window.localStorage.getItem('auth_token')
    },
  });
}

export function fetchPayments() {
  console.log('fetchPayments function in flux roundups actions triggered')
  return () => axios({
    url: `${CONFIG.apiV3Url}/roundups/payments`,
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + window.localStorage.getItem('auth_token')
    },
  });
}


export function setPayments (transactions) {
  console.log('setPayments function in flux roundups actions triggered with transactions: ', transactions)
  return {
    type: RoundUpsTransactionsActionTypes.SET_PAYMENTS,
    payload: transactions,
  };
}

export function setPaymentsTotals ( totalClearing, totalCleared, totalReceived, totalPaid) {
  console.log('setPaymentsTotals function in flux roundups actions triggered with totals: ', totalClearing, totalCleared, totalReceived, totalPaid)
  return {
    type: RoundUpsTransactionsActionTypes.SET_CONTRIBUTE_NOW_TOTALS,
    payload: { totalClearing, totalCleared, totalReceived, totalPaid },
  };
}

export function setRoundUpsTotals ( totalClearing, totalCleared, totalReceived, totalPaid) {
  console.log('setRoundUpsTotals function in flux roundups actions triggered with totals: ', totalClearing, totalCleared, totalReceived, totalPaid)
  return {
    type: RoundUpsTransactionsActionTypes.SET_ROUND_UPS_TOTALS,
    payload: { totalClearing, totalCleared, totalReceived, totalPaid },
  };
}

export function UnSetPayments() {
  console.log('unSetPayments function in flux roundups actions triggered')
  return {
    type: RoundUpsTransactionsActionTypes.UNSET_PAYMENTS,
  };
}








