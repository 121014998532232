import React from 'react';
import styled from 'styled-components';

import styles from './index.styles';

const IconButtonWrapper = styled.button`${styles}`;

const IconButton = ({ children, onClick, disabled, type }) => (
  <IconButtonWrapper onClick={onClick} disabled={disabled} type={type ? type : 'button'}>
    {children}
  </IconButtonWrapper>
)

export default IconButton;
