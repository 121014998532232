export default ({ theme }) => `
    width: calc(100% - 60px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px;

    @media(min-width: 768px) {
      h2 {
        font-size: 38px;
      }
    }

    .horizontal-rule {
      width: 100%;
      background-color: ${theme.accentColor};
      height: 0.5px;
      border: none;
      margin: 20px 0;
    }

    .how-it-works-card {
      display: flex;
      align-items: center;
      /* box-shadow: rgb(0 0 0 / 40%) 0px 0px 20px 2px; */
      width: calc(100% - 60px);
      max-width: 10240px;
      /* margin: 20px 0; */
      padding: 20px;
      border-radius: 3px;
      flex-direction: column;

      h1 {
        text-align: center;
      }
      p {
        text-align: center;
      }
    }



    .steps-container {
      display: flex;
      align-items: center;
      justify-content: center;
      /* margin: 30px 0; */

      @media(max-width: 769px) {
        flex-direction: column;

        .arrow {
          transform: rotate(90deg);
          position: relative;
          right: 20px;
        }
      }

      .step {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        max-width: 240px;
        align-self: flex-start;
        margin: 20px 0;

        .icon-container {
          background-color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;

          img {
            width: 40%;
          }
        }

        .description {
          color: ${theme.darkSecondaryColor};
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          margin: 10px 0;

          .pre-title {
            color: ${theme.accentColor};
            font-size: 26px;
          }

          b.title {
            margin: 0 0 5px;
            color: ${theme.accentColor};
            font-weight: bold;
            font-size: 26px;
            position: relative;
            top: 1px;
          }
          

          span.text {
            font-size: 14px;
            text-align: center;
            color: ${theme.secondaryColor};
            margin: 7px 0;
          }
        }
      }

      .arrow {
        margin: 0 20px;
        i {
          transform: rotate(180deg);
          position: relative;
          top: -17px;
        }
      }
    }
  `;
