import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import ActionButton from 'SharedComponents/action-button';
import styles from './index.css';


const JoinBYSWrapper = styled.div`${styles}`;

const JoinBYS = ({ content }) => (
  <JoinBYSWrapper backgroundimage={content.join_bys_image} id="join-bys">
    <div className="content-container">
      <div className="content">
        <h1>{content.join_bys_heading}</h1>
        <p>{content.join_bys_text}</p>
        <div style={{
          display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%',
        }}
        >
          <Link to="/signup"><ActionButton text="Sign Up Free" large={true} /></Link>
        </div>
      </div>
    </div>
  </JoinBYSWrapper>
);

JoinBYS.propTypes = {
  content: PropTypes.object.isRequired,
};

export default JoinBYS;
