import React, { useState } from 'react';
import styled from 'styled-components';

import Footer from 'SharedComponents/footer';
import Tabs from 'SharedComponents/tabs';

import styles from './index.css';

const PartnershipsWrapper = styled.section`${styles}`;


const Partnerships = () => {
  const [activeTab, setActiveTab] = useState('superfunds');
  return (
    <PartnershipsWrapper>
      <div className="partnerships-content">
        <h2>Partner with Boost Your Super</h2>
        <Tabs tabs={[
          { label: 'Superfunds', value: 'superfunds' },
          { label: 'Employers', value: 'employers' },
          { label: 'Merchants', value: 'merchants' }, 
          { label: 'Financial Advisors', value: 'financial-advisors' },
          { label: 'Founders', value: 'founders' }
        ]} activeTab={activeTab} onActiveTabChanged={(nextTab) => setActiveTab(nextTab)} />
        <div className="content-container">
          {
            activeTab === 'superfunds' ? (
              <div className="partner-container">
                <h1>Engage Your Members</h1>
                <p>Partner with us to provide more meaningful engagement with your members</p>
                <ul>
                  <li>Bring your brand to your members daily</li>
                  <li>Help your members learn and earn more super</li>
                </ul>
                <p>Contact us for a super member engagement pack at accounts@boostyoursuper.com.au</p>
              </div>
            ) : activeTab === 'employers' ? (
              <div className="partner-container">
                <h2>Employers - Boost Your Employee Benefits</h2>
                <p>Empower your employees with a simple way to grow their superannuation towards further securing their financial future—all for just $4 a month per employee. With the Round-Ups program, employees can round up their everyday purchases to the nearest dollar, and that extra change goes directly into their super. </p>
                <p>It’s a seamless way to help your staff build a stronger financial future, showing that you care about their long-term wellbeing. Plus, it’s an easy benefit to offer that adds real value to your employee benefits package.</p>
                <ul>
                  <li><strong>Boost retention and engagement:</strong> Employees appreciate meaningful benefits that enhance their financial security.</li>
                  <li><strong>Cost-effective benefit:</strong> For a low monthly fee, offer employees a program that could significantly impact their super balance over time.</li>
                  <li><strong>Show your commitment:</strong> Demonstrates that your business is invested in employees' financial futures, boosting morale and loyalty.</li>
                  <li><strong>Effortless implementation:</strong> Easy to set up with minimal admin required, ensuring a hassle-free experience for your business.</li>
                  <li><strong>Attractive for recruitment:</strong> Stand out in the competitive job market by offering innovative perks that future-proof your employees.</li>
                </ul>
                <p>Contact us employergroup@boostyoursuper.com.au <a href="mailto:employergroup@boostyoursuper.com.au" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', fontWeight: 'bolder' }}>here</a>.</p>
              </div>
            ) : activeTab === 'merchants' ? (
              <div className="partner-container">
                <h2>Join our list of partner stores</h2>
                <p>Feature your store on our website by  joining one of our partner affiliate networks.</p>
                <p>You can join Commission Factory <a href="https://www.commissionfactory.com" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', fontWeight: 'bolder' }}>here</a>.</p>
              </div>
            ) : activeTab === 'financial-advisors' ? (
              <div className="partner-container">
                <h2>Support our Hacks 4 Your Super series</h2>
                <p>We work with financial advisors whom specialise in superannuation. Contact us about participating in our <span font="bold">Hacks 4 Your Super </span>email series where we cover tips and tricks that can help our users to retire with more.</p>
                <h2>Integrate our Round-Ups into client advice</h2>
                <p>We are getting more and more interest from financial advisors on how to use the service and integrate into client advice. Contact us for a free walk through.</p>
                <p>Contact us at <a href="mailto:accounts@boostyoursuper.com.au" target="_blank">accounts@boostyoursuper.com.au</a>.</p>
              </div>
            ) : (
              <div className="partner-container">
                <h2>Co-Founders: Have an idea to help Boost Super?</h2>
                <p>Boost Your Super is a start up and always looking for new ideas to help our users grow their super. If you have an idea you would like to try and are willing to consider a partnership then get in contact with us.</p>
                  <ul>
                    <li>We already have an audience that is interested in super and many of them are happy to test out new products.</li>
                    <li>We have a tech platform and developers ready to help.</li>
                  </ul>
                <p>Contact us at <a href="mailto:accounts@boostyoursuper.com.au" target="_blank">accounts@boostyoursuper.com.au</a>.</p>
              </div>
            )
          }
        </div>
      </div>
      <Footer />
    </PartnershipsWrapper>
  );
}

export default Partnerships;
