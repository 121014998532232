import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Formsy from 'formsy-react';
import axios from 'axios';
import qs from 'qs';
import log from "Utils/logger";


import TextInput, { isRequired } from 'SharedComponents/text-input';
import ActionButton from 'SharedComponents/action-button';
import DropDownSelect from 'SharedComponents/dropdown-select';

import * as AuthenticationActions from 'Flux/authentication/actions';

import styles from './index.css';
const FormLinkBankAccountWrapper = styled(Formsy)`${styles}`;

@connect((state) => ({
	authentication: state.authentication
}), (dispatch) => ({
	AuthenticationActions: bindActionCreators(AuthenticationActions, dispatch),
}))
export default class FormLinkBankAccount extends Component {
	static contextTypes = {
		router: PropTypes.object.isRequired,
	}

	static propTypes = {
		onAfterSubmit: PropTypes.func,
	}

	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
		this.onBankIdChanged = this.onBankIdChanged.bind(this);
		this.onValid = this.onValid.bind(this);
		this.onInvalid = this.onInvalid.bind(this);
		this.onValidSubmit = this.onValidSubmit.bind(this);
		this.onInvalidSubmit = this.onInvalidSubmit.bind(this);
		this.onLinkBankAccount = this.onLinkBankAccount.bind(this);
	}

	state = {
		isFormValid: false,
		isBankIdSelected: false,
		isLinkingAccount: false,
		error: false,
		message: '',
		form: {
      bankName: '',
      accountName: '',
			bsb: '',
			accountNumber: '',
			label: '',
		}
	}

	componentDidMount() {
    //this.onFetchBanks();
  }

	onBankIdChanged(option) {
    this.setState({
      //isBankIdSelected: this.state.form.bankID.option.value ? true : false,
			form: {
        ...this.state.form,
        bankID: option.value,
				label: option.label,
      },
    });
  }
	

	onChange(event) {
    this.setState({ form: Object.assign({}, this.state.form, { [event.target.name]: event.target.value }) });
  }

  async onFetchBanks() {
    const { AuthenticationActions } = this.props;
		const { authentication } = this.props;
    this.setState({ isFetchingBanks: true });

    try {
      const onFetchBanksResult = await AuthenticationActions.fetchCardlinkingBanks();
      console.log([' fetchCardLinkingBanks initiated got result: ', onFetchBanksResult]);
			console.log([' onFetchBanksResult.data.banks is: ', onFetchBanksResult.data.banks]);
			const banks = onFetchBanksResult.data.banks.map((bank) => ({
				//key=bank.id
				value: bank.id,
				label: bank.name
			}));
			console.log('banks is : ', banks);
      AuthenticationActions.setBanks(banks);
    } catch (exception) {
      console.log(exception);
			log({
				shopper_id: authentication.user ? authentication.user.shopper_id : "000",
        event: "System-Error",
        source: "CardLinking - FetchBanksException", 
        info: exception,
        ...exception,
			});
    } finally {
      this.setState({ isFetchingBanks: false });
    }
  }

	async onLinkBankAccount() {
		const { authentication } = this.props;
		const { AuthenticationActions } = this.props;
		const { form } = this.state;

		this.setState({
			isLinkingAccount: true,
			error: false,
			message: '',
		});

		try {
			const onLinkBankAccountResult = await AuthenticationActions.onLinkBankAccount({ data: form.accountNumber, expiry: form.expiry, bankID: form.bankID, bankLabel: form.label });

			console.log('[onLinkBankAccountResult]', onLinkBankAccountResult);
			AuthenticationActions.setLinkedAccounts(onLinkBankAccountResult.data.accounts);

			if (!onLinkBankAccountResult.data.status) {
				this.setState({
					error: true,
					message: onLinkBankAccountResult.data.error,
				}, () => {
					setTimeout(() => {
						this.setState({
							error: false,
							message: '',
						});
					}, 3000);
				});
			} else {
				this.setState({
					message: 'Account linked successfully',
				});

				if (this.props.onCloseDialog) {
					this.props.onCloseDialog();
				}

				if (this.props.onAfterLinkBankAccount) {
					this.props.onAfterLinkBankAccount();
				} else {
					this.context.router.history.push('/account');
				}
			}
		} catch(exception) {
			console.log(exception, null, '\t');
			this.setState({
				error: true,
				message: exception.message,
			});
			log({
				shopper_id: authentication.user ? authentication.user.shopper_id : "000",
        event: "System-Error",
        source: "AccountLinking - LinkBankAccountException", 
        info: exception,
        ...exception,
			});
		} finally {
			this.setState({ isLinkingAccount: false });
			AuthenticationActions.fetchLinkedAccounts();
		}
	}

	onValid() {
		this.setState({ isFormValid: true });
	}

	onInvalid() {
		this.setState({ isFormValid: false });
	}

	onValidSubmit() {
    console.log('this will be called when the form is submitted and valid');
		//this.onLinkBankAccount();
	}

	onInvalidSubmit() {
		// TODO: show appropriate error messages
		console.log('onInvalidSubmit');
	}

	render() {
		const { labelColor, header } = this.props;
		const { authentication } = this.props;
		const options = authentication.banks;

		return (
			<FormLinkBankAccountWrapper
				ref={(form) => this.contactInfoForm = form}
				style={{ width: '100%' }}
				onValidSubmit={this.onValidSubmit}
				onInvalidSubmit={this.onInvalidSubmit}
				onValid={this.onValid}
				onInvalid={this.onInvalid}
			>
				{header}
				<div style={{ margin: '10px 0', width: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
					{
						!this.state.isFormValid && this.state.onInvalidSubmit ? (
							<div style={{ width: 'calc(100% - 18px)', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10, backgroundColor: '#ff007d', color: '#fff' }}>
								Some fields are invalid. Please complete them then resubmit the form
							</div>
						) : null
					}
					{
						this.state.message ? (
							<span style={{ color: this.state.error ? '#ff007d' : '##03031', fontSize: 20 }}>{this.state.message}</span>
						) : null
					}
				</div>
				<TextInput
					name="BSB"
					type="tel"
					validations={{ 
						isExisty: true,
						isLength: 6,
						//maxLength: 6,
						//matchRegexp: /^\+{0,1}(?:[0-9] ?){6,14}[0-9]$/
						//matchRegexp: /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/  
						matchRegexp: /^[0-9]+$/
					}}
					validationErrors={{ 
						isExisty: 'this field is required', 
						isDefaultRequiredValue: 'this field is required',
						isLength: 'must be 6 digits',
						matchRegexp: 'numbers only',
						//maxLength: 'invalid - too long',
					 }}
					required
					value={this.state.form.bsb}
					onChange={this.onChange}
					placeholder="enter your 6 digit BSB"
					label={<div><span style={{ color: labelColor ? labelColor : null }}>BSB</span></div>}
					labelColor={labelColor}
				/>
				<TextInput
					name="Account Number"
					type="tel"
					validations={{ 
						isExisty: true,
						minLength: 6,
            maxLength: 10,
						matchRegexp: /^[0-9]+$/
						//matchRegexp: /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/ 
						//matchRegexp: /^\+{0,1}(?:[0-9] ?){6,14}[0-9]$/ 
					}}
					validationErrors={{ 
						isExisty: 'this field is required', 
						isDefaultRequiredValue: 'this field is required',
						minLength: 'min 6 digits',
            maxLength: 'max 10 digits',
						matchRegexp: 'Numbers only'
					 }}
					required
					value={this.state.form.accountNumber}
					onChange={this.onChange}
					placeholder="enter your account number"
					label={<div><span style={{ color: labelColor ? labelColor : null }}>Account Number</span></div>}
					labelColor={labelColor}
				/>
{/* 				<div className="issuing-bank">
					<div className="issue-bank-label">
						<span>Bank Provider</span>
					</div>
					<DropDownSelect 
						className="dropdown-menu" 
						//values={[]}
						value={this.state.form.bankID}
						labelField="options.label"
						//valueField="options.id"
						options={options}
						dropdownPosition="bottom"
						searchable={true}
						searchBy="label"
						placeholder="Select..."
						//onChange={(value) => console.log('Drop down value selected: ', value)}
						//onChange={(value) => this.onChange(value)}
						
						onChange={this.onBankIdChanged}
					/>
				</div> */}

				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%', marginTop: 35, marginBottom: 35 }}>
					<ActionButton
						text={this.state.isLinkingAccount ? 'Linking Bank Account' : 'Link Bank Account'}
						loading={this.state.isLinkingAccount}
						disabled={this.state.isLinkingAccount}
						active={!this.state.isLinkingAccount}
						color="#fff"
						large={true}
					/>
				</div>
			</FormLinkBankAccountWrapper>
		)
	}
}

