import React, { useEffect, useState }  from 'react';
import numeral from 'numeral';
import moment from 'moment';
import styled from 'styled-components';
import Spinner from 'react-spinner-material';
import DataTable from 'react-data-table-component-with-filter';

import PopoverHover from 'SharedComponents/popover-hover';
import ActionButton from 'SharedComponents/action-button';

import RaiseTransactionInquiryDialog from 'SharedComponents/dialog-raise-transaction-inquiry';
import RaiseTransactionInquiryForm from '../../components/form-raise-transaction-inquiry';

import CONFIG from 'Config';
//import PopoverText from './PopoverText';

import styles from './TransactionsTable.css';

// define default format for numbers in numeral
numeral.defaultFormat('0,0.00');

// set default format for nulls
numeral.nullFormat('0.00');
numeral.zeroFormat('0.00');


  //<pre>{JSON.stringify(data, null, 2)}</pre>;

  const TransactionStatus = (transaction_status,paid_charity) =>
  
  {
    console.log('calculating transactionStatus using');
    console.log('transaction_status : ', transaction_status );
    console.log('paid_charity : ', paid_charity );
    let transactionStatus = 'Clicked';
    transactionStatus = paid_charity === '1' ? 'Paid'
    : transaction_status === '5' ? 'Inquiry Raised'
    : transaction_status === '6' ? 'Manually Approved'
    : transaction_status === '4' ? 'Pending'
    : transaction_status === '3' ? 'Void'
    : transaction_status === '2' ? 'Approved'
    : transaction_status === '7' ? 'Payment Bounced'
    : 'Clicked';
    return (transactionStatus);
  };


const TransactionsTableWrapper = styled.div`${styles}`;

const TransactionsTable = ({
  cashbackTransactions, 
  //currentPage, 
  //pageSize, 
  isFetchingShopperDonations,
  showRaiseInquiryDialog,
  onShowRaiseInquiryDialog,
  onCloseRaiseInquiryDialog,
  onFetchCauseDonations,
  KeyTransactionStatusDescriptionsMap,
}) => {

  console.log('showRaiseInquiryDialog in TransactionsTable status is : ', showRaiseInquiryDialog);
  console.log('props.onShowRaiseInquiryDialog in TransactionsTable status is : ', onShowRaiseInquiryDialog);

  //const [displayedTransactions, setDisplayedTransactions] = useState([...cashbackTransactions]);
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // useEffect(() => {
  //   if (displayedTransactions !== cashbackTransactions) {
  //     console.log('useEffect in Transactions Table Triggered');
  //     setDisplayedTransactions([...cashbackTransactions]);
  //     if (!cashbackTransactions) {
  //       return;
  //     }
  //   }
  // },[cashbackTransactions]);

  const handlePageChange = (newPage, pageSize) => {
    console.log('handlePageChange in Transactions Table Triggered with newPage: ', newPage);
    //console.log('handlePageChange in Transactions Table Triggered with pageSize: ', pageSize);
    setPageNumber(newPage);
    //setRowsPerPage(rowsPerPage);
  }

  const handleChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
    console.log('handleChangeRowsPerPage in Transactions Table Triggered with currentRowsPerPage: ', currentRowsPerPage);
    console.log('handleChangeRowsPerPage in Transactions Table Triggered with currentPage: ', currentPage);
    setRowsPerPage(currentRowsPerPage);
    setPageNumber(currentPage);
  }

  const timeToAllowRaiseInquiry = (affiliate_network_id) => {
    if (affiliate_network_id == 5 || affiliate_network_id == 13) {
      return 120 //6 days
    } else {
      return 24 // hours
    }
  }

  const ExpandedComponent = ({data}) => 
    
    (
      <div className="expander-row-container">
        <div className="transaction-details">
          
            <div className="store-logo-container">
              { !!data.custom_logo_directory
              ? <img className="store-logo" src={`${CONFIG.assetsUrl}/assets/${data.custom_logo_directory}`} alt={data.merchant_name} />
              : <img className="store-logo" src={`${data.avatar_url}`} alt={data.merchant_name} />
              }
          </div>
          <div className="transaction-item"> Store: <span style={{fontWeight:"bold"}}>&nbsp;{data.merchant_name}</span></div>
          <div className="transaction-item"> Click Time: <span style={{fontWeight:"bold"}}>&nbsp;{moment(data.transaction_date).local().format('DD MMM YYYY, h:mm:ss a')}</span></div>
          {!!data.notes}
          <div className="transaction-item"> Status: 
            <span style={{fontWeight:"bold"}}>&nbsp;{TransactionStatus (data.transaction_status,data.paid_charity)}</span>
              <span className="pop-over-container">
                <PopoverHover
                  content={
                    <div style={{
                      maxWidth: '300px', padding: 10, fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left',
                    }}
                    >
                    {KeyTransactionStatusDescriptionsMap[TransactionStatus (data.transaction_status,data.paid_charity)]}
                    </div>
                  }
                  header = {null}
                  img = {null}
                  itemToPopover = {
                    ( <i className="material-icons" style={{ margin: '0 5px 0 5px', verticalAlign: '-40%' }} >info</i> )
                  }
                  horizontalPosn = "right"
                />
              </span>
          </div>
          { 
            !!data.notes
            ? <div>
                <div className="transaction-item"><b> TRANSACTION NOTES</b> </div>
                <div className="notes">{data.notes}</div>
              </div>
            : null
          }
        </div>
        <div>
        {/* <pre>{JSON.stringify(data, null, 2)}</pre>  */}
        {(!!data.boost_name || data.transaction_status != 1) 
          ? null 
          : (
            <div className="actions-container">
                <div className="actions-header">
                { 
                (moment().diff(moment(data.transaction_date),'hours') > timeToAllowRaiseInquiry(data.affiliate_network_id) ) && ((moment().diff(moment(data.transaction_date),'days') < 120))
                  ? "You have " + (120 - (moment().diff(moment(data.transaction_date),'days'))) + " days to raise an Inquiry"
                  : ((moment().diff(moment(data.transaction_date),'hours') < timeToAllowRaiseInquiry(data.affiliate_network_id)))
                    ? "Raise Inquiry avail. in approx " + moment().add((timeToAllowRaiseInquiry(data.affiliate_network_id) - (moment().diff(moment(data.transaction_date),'hours'))), 'hours').fromNow()
                    : "Cannot raise after 120 days"
                }
                </div>
                <div className="actions-buttons">
                  <ActionButton
                    text="Raise&nbsp;Inquiry"
                    onClick={() =>  onShowRaiseInquiryDialog() }
                    disabled={(moment().diff(moment(data.transaction_date),'hours') > timeToAllowRaiseInquiry(data.affiliate_network_id) ) && ((moment().diff(moment(data.transaction_date),'days') < 120))
                                ? false
                                : true
                            }
                    title="Raise an inquiry for us to investigate missing funds"
                  />
                  { 
                    showRaiseInquiryDialog ? (
                      <RaiseTransactionInquiryDialog
                        size="sm"
                        open={ showRaiseInquiryDialog }
                        onCloseRaiseInquiryDialog={ onCloseRaiseInquiryDialog }
                        uniqueID={data.transaction_id}
                        storeName={data.merchant_name}
                        clickTime={data.transaction_date}
                        transactionStatus={data.transaction_status}
                        onFetchCauseDonations={ onFetchCauseDonations }
                        contentComponent={(
                          <RaiseTransactionInquiryForm 
                            showEmailLogin
                            labelColor="#88888"
                            showBackground={false}
                          />
                        )}
                      />
                    ) : null
                  }
                  {/* <pre>{JSON.stringify(data, null, 2)}</pre>  */}
                  <div className="pop-over-container">
                      <PopoverHover
                        content={
                          <div style={{
                            maxWidth: '300px', padding: 10, fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left',
                          }}
                          >
                          If a purchase you made is not showing you have up to 120 days after the Click Time to raise an Inquiry for us to invistigate.
                          Each shop has a different time frame before they allow an Inquiry to be raised.
                          </div>
                        }
                        header = {null}
                        img = {null}
                        itemToPopover = {
                          ( <i className="material-icons" style={{ margin: '0 5px 0 5px', verticalAlign: '-40%' }} >info</i> )
                        }
                        anchorOrigin={{
                          vertical: 'center',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'center',
                          horizontal: 'right',
                        }}
                      />
                  </div>
                </div>
              </div>
            )
        }
        </div>
      </div>
    );

  if (isFetchingShopperDonations && (!cashbackTransactions || !cashbackTransactions.length)) {
    return (
      <div style={{
        width: '100%', padding: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 80,
      }}
      >
        <Spinner spinnerColor="#000000" size={20} spinnerWidth={3} />
        <p>Fetching transactions</p>
      </div>
    );
  }

  if (!cashbackTransactions || !cashbackTransactions.length) {
    return (
      <div style={{
        width: '100%', padding: 10, display: 'flex', alignItems: 'center', justifyContent: 'center', height: 80,
      }}
      >
        <p>There are no transactions to display</p>
      </div>
    );
  }

  const columns = [
    {
      name: '#',
      selector: (row,index) => ((index+1)+(pageNumber-1)*rowsPerPage),
      maxWidth: '60px',
      minWidth: 'unset',
    },
    {
      name: 'UNIQUE ID',
      selector: row => row.transaction_id,
      filterable: true,
      sortable: true,

    },
    {
      name: 'STORE',
      selector: row => row.merchant_name,
      filterable: true,
      sortable: true,
    },
    {
      name: 'BONUS BOOST',
      selector: row => 
        row.boost_name != null
          ? row.boost_name
          : '-',
      conditionalCellStyles: [
        {
          when: row => 
            !!row.boost_name && row.boost_name.includes('Partner'),
          classNames: ['partner-bonus'],
        },
        {
          when: row => 
            row.boost_name == null,
          style: {color: 'white'},
        }
      ],
      filterable: true,
      sortable: true,
      minWidth: '230px',
    },
    {
      name: 'SPENT AMOUNT',
      selector: row => numeral(row.transaction_amount).format(),
      format: row => `$ ${numeral(row.transaction_amount).format()}`,
      filterable: true,
      sortable: true,
    },
    {
      name: 'BOOST (AUD)',
      selector: row => numeral(row.payable_amount).format(), 
      format: row => `$ ${numeral(row.payable_amount).format()}`,
      filterable: true,
      sortable: true,
    },
    {
      name: 'CLICK DATE',
      selector: row => new Date(row.transaction_date), // Convert to Date object
      format: row => moment(row.transaction_date).local().format('Do MMM YYYY'), // Format for display
      filterable: true,
      sortable: true,
    },
    {
      name: 'PAID DATE',
      selector: row => row.paid_charity_date ? new Date(row.paid_charity_date) : null, // Convert to Date object
      format: row => row.paid_charity_date ? moment(row.paid_charity_date).local().format('Do MMM YYYY') : '-', // Format for display
      conditionalCellStyles: [
        {
          when: row => !row.paid_charity_date,
          style: { color: 'white' },
        }
      ],
      filterable: true,
      sortable: true,
    },
    // {
    //   name: 'PAID DATE',
    //   selector: row => 
    //     row.paid_charity_date != null
    //       ? moment(row.paid_charity_date).local().format('Do MMM YYYY')
    //       : '-',
    //   conditionalCellStyles: [
    //     {
    //       when: row => 
    //       row.paid_charity_date == null,
    //       style: {color: 'white'},
    //     }
    //   ],
    //   filterable: true,
    //   sortable: true,
    // },
    {
      name: 'STATUS',
      selector: row => { 
          return TransactionStatus(row.transaction_status,row.paid_charity)
      },
      filterable: true,
      sortable: true,
    },

  ]


  return (
    <TransactionsTableWrapper>
      
        <DataTable
          columns={columns}
          data={cashbackTransactions}
          highlightOnHover={true}
          responsive={true}
          customStyles={styles}
          defaultSortFieldId={2}
          defaultSortAsc={false}
          pagination
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          expandableRows
          expandableRowsComponent={ExpandedComponent}
          expandOnRowClicked={true}
        />  

    </TransactionsTableWrapper>
  );
};

export default TransactionsTable;