export default ({ theme }) => `
display: flex;
flex-direction: column;
align-items: flex-start;
width: 100%;

.content-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: calc(100%-20px);


  .item-container {
    display: flex;
    flex-direction: column;
    max-width:700px;
    align-items: stretch;

    .item{
      /* width: 200px; */
      min-width: 200px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      padding: 5px;
      /* box-shadow: 0 0 3px 1px ${theme.borderColor}; */
      margin: 5px;
      border-radius: 8px;

      .title-and-limit{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        min-height: 64px;

        .title {
          text-align: center;
          margin: 10px;
          font-size: 25px;
        }

        .limit {
          text-align: left;
          width: 200px;

          .dropdown-menu {
            font-size: 25px;
            font-weight: bold;
            /* border-color: red; */
          }

          .loading-container {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
          }

        }

      }
    }
  }



  p {
    font-size: 20px;
  }
}
`;