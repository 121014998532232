export default ({ theme, store }) => {
  return `
    display: flex;
    flex-direction: column;
    width: 200px;
    height: 275px;
    margin: 10px;
    padding: 10px;
    background-color: ${theme.neutralColor};
    box-shadow: 4px 1px 12px ${theme.borderColor};
    border-radius: 3px;

    @media(min-width: 375px) and (max-width: 450px) {
      margin: 5px;
      padding: 5px;
      width: 160px;
      height: 220px;
    }

    @media(max-width: 374px) {
      width: 100%;
    }

    &:hover {
      transform: scale(1.01);
    }

    .store-card-container{
      display: flex;
      flex-direction: column;
      align-content: center;
    }

    .store-header {
      min-height:16px;
      margin-bottom: 2px;
    }

    .store-name-container{
      width: calc(100% - 6px);
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 3px 0px;
      flex-direction: column;
      text-transform: uppercase;

      small.store-name {
        font-size: 11px;
        text-align: center;
        font-weight: bold;
        
        @media (max-width: 450px){
          display: none;
        }

      }



    }

    .store-image-container {
      height: 115px;
      /* width: calc(100% + 10px); */
      display: flex;
      align-items: center;
      justify-content: center;
      /* margin: 0px -5px 10px -5px; */
      background-image: url(${store.retail_store_logo_url});
      background-attachment: local;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      outline: none;
      border: none;
      background-color: transparent;
      cursor: pointer;

      &:hover {
        cursor: pointer;
      }

      @media(min-width: 375px) and (max-width: 450px) {
        width: 100%;
        margin: 0;
        height: 80px;
      }

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .deals-container {
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-size: 12px;

        @media(max-width: 450px){
          font-size: 10px;

          i {
            font-size: small;
          }
        }

      }

      i {
        color: ${theme.darkSecondaryColor};
        font-size: 16px;
      }
    }

    .fav-button {
      display:flex;
      justify-content: center;
      margin: 2px;
    }

    .store-details-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: column;

      small.store-name {
        font-size: 11px;
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
      }

      .store-rate {
        text-align: center;
        max-width: 90px;
        display: flex;
        flex-direction: column;
        align-items: center;
      
        small.store-rate {
          font-size: 10px;
          text-align: center;
          max-width: 90px;
        }
      }

      .actions-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        /* margin: 10px 0 0; */
        align-self: flex-end;

        .more-info {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          background-color: transparent;
          border: solid 1px ${theme.borderColor};
          border-radius: 12.5px;
          outline: none;
          padding: 3px 6px;
          cursor: pointer;

          i {
            font-size: 18px;
            margin: 0 5px 0 0;
          }
        }

        button.shop-now {
          background-color: ${theme.darkSecondaryColor};
          color: ${theme.secondaryTextColor};
          border-radius: 3px;
          padding: 3px 5px;
          outline: none;
          border: none;
          text-transform: uppercase;
          cursor: pointer;
          border-radius: 11.5px;
          padding: 5px 10px;

          &:hover {
            background-color: ${theme.lightSecondaryColor};
            transform: scale(1.01);
          }
        }
      }
    }
  `;
}
