/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Masonry from 'react-masonry-component';
import PropTypes from 'prop-types';

// eslint-disable-next-line import/no-unresolved
import DropdownSelect from 'SharedComponents/dropdown-select';
// eslint-disable-next-line import/no-unresolved
import Pagination from 'SharedComponents/pagination';
import Deal from 'SharedComponents/deal';

import styles from './index.styles.js';

const DealsWrapper = styled.div`${styles}`;
const sortingOptions = [{
  label: 'Alphabetical',
  value: 'alphabetical',
}, {
  label: 'Newest First',
  value: 'newest-first',
}, {
  label: 'Expiring Soon',
  value: 'expiring-soon',
}, {
  label: 'Most Popular',
  value: 'popularity',
}];

const Deals = ({
  items, searchTerm, selectedCategory, onShopNow, onSetSharedItem, currentPromotion,
}) => {
  // const initialSortedItems = items.sort((a, b) => parseInt(b.clicks) - parseInt(a.clicks));
  const [sorting, setSorting] = useState('newest-first');
  const [sortedItems, setSortedItems] = useState(items);
  const [pageItems, setPageItems] = useState([]);

  // CHECK: This might not be the way to do it
  // TODO: use memoization to make these sorting functions faster
  if (items !== sortedItems) {
    if (sorting === 'alphabetical') {
      const alphabeticalSorting = items.sort((a, b) => {
        if (a.merchant_name < b.merchant_name) {
          return -1;
        }

        if (a.merchant_name > b.merchant_name) {
          return 1;
        }

        return 0;
      });
      setSortedItems(alphabeticalSorting);
    } else if (sorting === 'newest-first') {
      const newestFirstSorting = items.sort((a, b) => moment(b.start_date) - moment(a.start_date));
      setSortedItems(newestFirstSorting);
    } else if (sorting === 'expiring-soon') {
      const expiringSoonSorting = items.sort((a, b) => moment(a.end_date_for_sorting) - moment(b.end_date_for_sorting));
      setSortedItems(expiringSoonSorting);
    } else {
      const popularitySorting = items.sort((a, b) => parseInt(b.clicks) - parseInt(a.clicks));
      setSortedItems(popularitySorting);
    }
  }

  return (
    <DealsWrapper>
      <div className="header">
        <div className="description">
          <p className="header-text">{currentPromotion ? currentPromotion.description : null }</p>
        </div>
        <div className="sorter">
          <div className="visible-items">
            {
              selectedCategory ? (
                <span className="matches">
                  Showing promoted deals in
                  {' '}
                  {selectedCategory}
                  {' '}
                  category
                </span>
              ) : searchTerm?.length ? (
                <span className="matches">
                  Showing offers and coupons matching
                  {' '}
                  <span className="matched-term">{searchTerm}</span>
                  {' '}
                  search term
                </span>
              ) : (
                    <span className="matches">Showing <span style={{ fontWeight: 'bold' }}>all</span> hand picked promotions and coupons</span>
                  )
            }
          </div>
          <DropdownSelect
            className="dropdown-menu"
            value={sorting}
            onChange={(sorting) => {
              if (sorting.value !== sorting) {
                if (sorting.value === 'alphabetical') {
                  const alphabeticalSorting = items.sort((a, b) => {
                    if (a.merchant_name < b.merchant_name) {
                      return -1;
                    }

                    if (a.merchant_name > b.merchant_name) {
                      return 1;
                    }

                    return 0;
                  });
                  setSortedItems(alphabeticalSorting);
                } else if (sorting.value === 'newest-first') {
                  const newestFirstSorting = items.sort((a, b) => moment(b.start_date) - moment(a.start_date));
                  setSortedItems(newestFirstSorting);
                } else if (sorting.value === 'expiring-soon') {
                  const expiringSoonSorting = items.sort((a, b) => moment(a.end_date_for_sorting) - moment(b.end_date_for_sorting));
                  setSortedItems(expiringSoonSorting);
                } else {
                  const popularitySorting = items.sort((a, b) => parseInt(b.clicks) - parseInt(a.clicks));
                  setSortedItems(popularitySorting);
                }

                setSorting(sorting.value);
              }
            }}
            options={sortingOptions}
          />
        </div>
      </div>
      <div className="deals-container">
        <Masonry
          className="deals-masonry-container"
          options={{ 
            isFitWidth: true,
            transitionDuration: '0.7s',
            stagger: 100,
          }}
        >
          {
            pageItems.map((deal) => (
              <Deal deal={deal} key={deal.coupon_id} onShopNow={onShopNow} onSetSharedItem={onSetSharedItem} dealWidthMobile='85vw'/>
            ))
          }
        </Masonry>
      </div>
      <Pagination
        pageSize={36}
        items={sortedItems}
        onChangePage={(visibleItems) => {
          setPageItems(visibleItems);
        }}
        sorting={sorting}
      />
    </DealsWrapper>
  );
};

Deals.propTypes = {
  items: PropTypes.array.isRequired,
  searchTerm: PropTypes.string.isRequired,
  selectedCategory: PropTypes.string.isRequired,
  onShopNow: PropTypes.func.isRequired,
  onSetSharedItem: PropTypes.func.isRequired,
  currentPromotion: PropTypes.object.isRequired,
};

export default Deals;
